/* eslint-disable react/no-unused-state */
import React from 'react'
import _ from 'lodash'
import { ISubscriptionPackageList, SubscriptionPackageListItems } from 'x/index'
import * as util from 'x/utils/util'
import * as NavActions from 'x/utils/navigation'
import p from 'x/config/platform-specific'
import { ISelectedAddOn } from './BasePaymentSubscriptionView'

interface IBaseSelectedAddOnViewProps {}

interface IBaseSelectedAddOnViewState {
  subscriptionPackageList: ISubscriptionPackageList
  selectedPackage: SubscriptionPackageListItems
  addOnList: SubscriptionPackageListItems[]
  selectedAddOnlist: ISelectedAddOn[]
}

export default abstract class BaseSelectedAddOnView extends React.Component<IBaseSelectedAddOnViewProps, IBaseSelectedAddOnViewState> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  // abstract didMountCheckPermissionForPhone?: () => void
  // validationSchema: Yup.ObjectSchema<any>
  // imgManagerIDRef: React.RefObject<any>
  // webview: React.RefObject<any>
  // mainPackageItems: any

  constructor(props) {
    super(props)
    this.state = {
      subscriptionPackageList: null,
      selectedPackage: null,
      addOnList: null,
      selectedAddOnlist: null,
    }
  }

  async componentDidMount() {
    await this._initData()
  }

  _initData = async () => {
    const params = this._getParams()
    const { subscriptionPackageList, sku, selectedPackage, selectedAddOn } = params
    const { items } = subscriptionPackageList

    // Filter items that start with the given SKU
    const listAddOnOrder = items.filter((item: SubscriptionPackageListItems) => item.sku.startsWith(sku))
    // Get the key from the first item
    const key = listAddOnOrder[0]?.item_subtype

    // Sort addOnList by the specified key in ascending order
    // const sortedAddOnList = _.orderBy(listAddOnOrder, [`data_json.${key}`], ['asc'])

    const mainPackageType = util.getSubscriptionPackageTypeByPackageName(selectedPackage.item_subtype)
    // const subscriptionTsku = this._getAddOnTskuByPackageType(subscriptionPackageList, mainPackageType)
    const sortedAddOnListByMainPackage = util.getAddOnListCanBuyFromMainPackageSelectedPackage(
      subscriptionPackageList,
      mainPackageType,
      sku
    )
    const addOnList = []
    let selectedAddOnlist = []
    // If the key is 'order_count_quota', initialize addOnList with a placeholder item
    if (key === 'order_count_quota') {
      // ให้แทรก item ที่ไม่มี sku เพื่อให้เลือกแพ็กเสริมแบบไม่เลือกตัวเลือกได้
      addOnList.push({ sku: null })

      // Add each sorted item to the addOnList
      sortedAddOnListByMainPackage.forEach((item) => {
        addOnList.push(item)
      })
      selectedAddOnlist = selectedAddOn
    } else {
      sortedAddOnListByMainPackage.forEach((item) => {
        addOnList.push(item)
        selectedAddOnlist.push({ sku: item.sku, billing_cycle: selectedPackage.billing_cycle, qty: 0 })
      })
      if (!_.isNil(selectedAddOn)) {
        selectedAddOnlist = selectedAddOnlist.map((addOn) => {
          const matchedAddOn = selectedAddOn.find((selectedAddOnItem) => selectedAddOnItem.sku === addOn.sku)
          if (matchedAddOn) {
            return { ...addOn, qty: matchedAddOn.qty, billing_cycle: matchedAddOn.billing_cycle }
          }
          return addOn
        })
      }
    }
    // Update the state with the sorted add-on list
    await util.setStatePromise(this, { subscriptionPackageList, selectedPackage, addOnList, selectedAddOnlist })
  }

  _getParams = () => {
    // @ts-ignore
    const params = util.getNavParams(this.props)
    return params
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  goBack = () => {
    // @ts-ignore
    util.navGoBack(this.props)
  }

  _getLabelUnit = (key: string) => {
    let label = ''
    switch (key) {
      case 'order_count_quota':
        label = 'ออเดอร์'
        break
      case 'mkp_quota':
        label = 'ช่องทางขาย'
        break
      case 'ug_quota':
        label = 'กลุ่มสมาชิก'
        break
      case 'pg_quota':
        label = 'รายการราคา'
        break
      case 'helper_count':
        label = 'ผู้ช่วยร้าน'
        break
      case 'store_count':
        label = 'ร้านค้า'
        break
      default:
        label = ''
        break
    }
    return label
  }

  _getSelectedAddOn = (sku: string) => {
    if (_.isNil(sku)) {
      return null
    }
    const { subscriptionPackageList } = this.state
    const { items } = subscriptionPackageList
    let selectedAddOn = null
    items.forEach((item) => {
      if (item.sku === sku) {
        selectedAddOn = item
      }
    })
    return selectedAddOn
  }

  _onPressMinusBtn = () => {
    const { selectedAddOnlist } = this.state
    if (_.isNil(selectedAddOnlist) || selectedAddOnlist.length === 0) {
      return
    }
    const selectedAddOn = selectedAddOnlist[0]
    const newSelectedAddOn = _.cloneDeep(selectedAddOn)
    newSelectedAddOn.billing_cycle -= 1
    this.setState({ selectedAddOnlist: [newSelectedAddOn] })
  }

  _onPressPlusBtn = () => {
    const { selectedAddOnlist } = this.state
    if (_.isNil(selectedAddOnlist) || selectedAddOnlist.length === 0) {
      return
    }
    const selectedAddOn = selectedAddOnlist[0]
    const newSelectedAddOn = _.cloneDeep(selectedAddOn)
    newSelectedAddOn.billing_cycle += 1
    this.setState({ selectedAddOnlist: [newSelectedAddOn] })
  }

  _onPressPlusBtnByAddOnPackage = (index: number) => {
    const { selectedAddOnlist } = this.state
    const newSelectedAddOnlist = _.cloneDeep(selectedAddOnlist)
    newSelectedAddOnlist[index].qty += 1
    this.setState({ selectedAddOnlist: newSelectedAddOnlist })
  }

  _onPressMinusBtnByAddOnPackage = (index: number) => {
    const { selectedAddOnlist } = this.state
    const newSelectedAddOnlist = _.cloneDeep(selectedAddOnlist)
    newSelectedAddOnlist[index].qty -= 1
    this.setState({ selectedAddOnlist: newSelectedAddOnlist })
  }

  _getSumResult = () => {
    const { selectedAddOnlist } = this.state
    let result = 0
    selectedAddOnlist.forEach((item) => {
      const { sku } = item
      if (!_.isNil(sku)) {
        const selectedAddOn = this._getSelectedAddOn(sku)
        result += selectedAddOn.price_with_vat * item.qty
      }
    })
    return result * selectedAddOnlist[0].billing_cycle
  }

  _callBackFormSelectedAddOn = (selectedAddOn: SubscriptionPackageListItems) => {
    const { selectedPackage } = this.state
    if (selectedAddOn.sku === null) {
      this.setState({
        selectedAddOnlist: [],
      })
    } else {
      this.setState({
        selectedAddOnlist: [
          {
            sku: selectedAddOn.sku,
            billing_cycle: selectedPackage.billing_cycle,
            qty: 1,
          },
        ],
      })
    }
  }

  _navToSelectedAddOnListView = () => {
    // @ts-ignore
    const { navigation } = this.props
    const { addOnList } = this.state
    // ถ้าเป็น order_count_quota เช็กว่ามีแค่ตัวเดียวไหม ถ้ามีมากกว่า 1 ตัว ให้ไปหน้าเลือกแพ็กเสริม
    if (addOnList.length === 1 && addOnList[0].sku === null) {
      p.op.showConfirmationOkOnly('', 'ไม่มีแพ็กเสริมให้เลือก')
      return
    }
    navigation.dispatch(
      NavActions.navToSelectedAddOnListView({
        addOnList,
        callBackFormSelectedAddOn: (selectedAddOn) => this._callBackFormSelectedAddOn(selectedAddOn),
      })
    )
  }

  _onPressFooter = () => {
    const { callBackFromSelectedAddOn, sku } = this._getParams()
    if (_.isFunction(callBackFromSelectedAddOn)) {
      const { selectedAddOnlist } = this.state
      // console.log('selectedAddOnlist => ', selectedAddOnlist)
      if (!_.isNil(selectedAddOnlist) && selectedAddOnlist.length > 0) {
        const newSelectedAddOnlist = []
        selectedAddOnlist.forEach((addOn) => {
          if (addOn.qty !== 0) {
            newSelectedAddOnlist.push(addOn)
          }
        })
        callBackFromSelectedAddOn(newSelectedAddOnlist, sku)
      } else {
        callBackFromSelectedAddOn(selectedAddOnlist, sku)
      }
    }
    this.goBack()
  }
}
