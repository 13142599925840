import React, { PureComponent } from 'react'

import { TouchableOpacity } from 'react-native'
import Icon from 'xui/components/Icon'
import _ from 'lodash'
import { STYLES, COLORS } from 'x/config/styles'

interface XCheckboxProps<DataType = any> {
  checked: boolean
  onPress?: (args: {
    index?: number
    data?: DataType
    name?: string
    // current checked
    checked: boolean
  }) => void
  disabled?: boolean
  index?: number
  name?: string
  data?: DataType
  iconStyle?: { [key: string]: any }
  buttonStyle?: { [key: string]: any }
}

class CheckboxIcon extends PureComponent<XCheckboxProps> {
  render() {
    const { disabled = false, checked = null, iconStyle = {} } = this.props
    return (
      <Icon
        type='FontAwesome'
        style={{
          fontSize: STYLES.FONT_ICON_NORMAL,
          height: STYLES.FONT_ICON_NORMAL,
          width: STYLES.FONT_ICON_NORMAL,
          color: disabled ? COLORS.TEXT_INACTIVE : COLORS.APP_MAIN,
          textAlign: 'center',
          ...iconStyle,
        }}
        name={checked ? 'check-square-o' : 'square-o'}
      />
    )
  }
}

export default class XCheckbox<T> extends PureComponent<XCheckboxProps<T>> {
  static displayName = 'XCheckbox'

  _onPress = () => {
    const { index, name, data, onPress, checked } = this.props
    if (_.isFunction(onPress)) {
      onPress({ index, data, name, checked })
    }
  }

  _renderCheckbox = () => {
    const { disabled = false, checked = false, ...restProps } = this.props

    return <CheckboxIcon {...restProps} disabled={disabled || false} checked={checked || false} />
  }

  render() {
    const { disabled = false, onPress = null, buttonStyle = {} } = this.props
    if (!_.isFunction(onPress)) {
      return this._renderCheckbox()
    }
    return (
      <TouchableOpacity
        style={{
          // flex: 1,
          width: 28,
          height: 28,
          alignItems: 'center',
          justifyContent: 'center',
          ...buttonStyle,
        }}
        disabled={disabled}
        onPress={this._onPress}>
        {this._renderCheckbox()}
      </TouchableOpacity>
    )
  }
}
