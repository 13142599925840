import React, { PureComponent } from 'react'
import { View, Text, TouchableOpacity, Image, Platform, StyleSheet } from 'react-native'

import { STYLES, COLORS, S } from 'x/config/styles'
import * as util from 'x/utils/util'
import Icon from 'xui/components/Icon'
import VStack from 'xui/components/VStack'
import xCONS from 'x/config/constants'
import { List, Map } from 'immutable'
import * as xFmt from 'x/utils/formatter'
import _ from 'lodash'

import FastImage from 'react-native-fast-image'
import { isDevOrProdTestMode } from 'x/utils/util'
import { IProductListItemProps, IProductItemDataMap } from 'x/index'
import { APP_CONFIG } from 'x/config/mode'
import p from 'x/config/platform-specific'
import XFlatPicker from '../../components/XFlatPicker'

const { NO_MARGIN_PADDING } = STYLES
// const COL_WIDTH_CHECK = 32
// const COL_WIDTH_INFO = 36

const PRODUCT_DESC_HEIGHT = 60
const BUTTON_BOTTOM_CARD_HEIGHT = 40
// const { PULL_PRODUCT, PULL_MY_PRODUCT_TO_ORDER, PULL_SELLER_PRODUCT_TO_ORDER, VIEW_SELF, VIEW_SELLER } = xCONS.PRODUCT_VIEW_MODE

const QUICK_ADD_PRODUCT_TO_ORDER_LABEL = `เพิ่ม ${APP_CONFIG.order.create_order.quick_add_product_to_order_qty} ชิ้นในออเดอร์...`
const OUT_OF_STOCK_LABEL = `สินค้าหมด`
const NOT_NAME_PRICE_YET_LABEL = `ยังไม่ได้กำหนดราคา`

export default class BaseUIProductListItem extends PureComponent<IProductListItemProps> {
  isPressed?: boolean

  // constructor(props) {
  //   super(props)
  //   // util.log('BaseUIProductListItem.constructor')
  //   // util.log(props)
  //   this._onPress = this._onPress.bind(this)
  //   this._onPressAddOneProductToCart = this._onPressAddOneProductToCart.bind(this)
  // }

  // _handleDimensionsChange(newDims) {
  //   // console.log('_handleDimensionsChange')
  //   this.mainContentWidth = newDims.width - COL_WIDTH_INFO - COL_WIDTH_CHECK
  //   this.forceUpdate()
  // }

  _onPress = async (): Promise<void> => {
    // this.props.onPress(productId)
    if (this.isPressed) {
      return
    }
    this.isPressed = true
    const { data, onPress = null } = this.props
    if (!Map.isMap(data)) {
      this.isPressed = false
      return
    }
    const productId = data.get('id') || null
    if (productId && _.isFunction(onPress)) {
      // await onPress(product_id)
      await onPress(data)
    }
    await new Promise((delay) => setTimeout(delay, 100))
    this.isPressed = false
  }

  _onPressAddOneProductToCart = async (): Promise<void> => {
    const { data, onPressAddOneProductToCart } = this.props
    if (this.isPressed) {
      return
    }
    if (data && _.isFunction(onPressAddOneProductToCart)) {
      await onPressAddOneProductToCart(data)
    }
    this.isPressed = false
  }

  _renderProductImage = ({ thumbnail_uri, width, thumbnailSize, infoText, infoBgColor }) => (
    <View
      style={{
        height: width,
        width,
        overflow: 'hidden',
        padding: 5,
      }}>
      {thumbnail_uri ? (
        <FastImage
          resizeMode={FastImage.resizeMode.cover}
          style={{ height: thumbnailSize, width: thumbnailSize }}
          source={{ uri: thumbnail_uri }}
        />
      ) : (
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            height: thumbnailSize,
            width: thumbnailSize,
            paddingTop: Math.floor(thumbnailSize / 2 - STYLES.FONT_SIZE_NORMAL / 2),
            backgroundColor: COLORS.BG_LIGHT_GREY,
          }}>
          <Text style={[STYLES.TXT_LABEL, { flex: 1, textAlign: 'center' }]}>ไม่มีรูปภาพ</Text>
        </View>
      )}
      {infoText ? (
        <View
          // style={[_s.infoTextContainer,{ borderTopColor: infoBgColor }]} />
          style={{
            height: 10,
            width: '100%',
            position: 'absolute',
            top: -32,
            right: 10,
            backgroundColor: 'transparent',
            borderStyle: 'solid',
            borderRightWidth: 50,
            borderTopWidth: 50,
            borderRightColor: 'transparent',
            borderTopColor: infoBgColor,
            ...Platform.select({
              ios: {
                top: -5,
              },
            }),
          }}
        />
      ) : null}
      {infoText ? (
        <Text
          // @ts-ignore
          style={_s.infoText}>
          {infoText}
        </Text>
      ) : null}
    </View>
  )

  _renderAddOneProductToOrderButtonItem = () => {
    const isAvailableToSell = this._getIsAvailableToSell()
    const productButtonLabel = this._getAddOneProductToOrderButtonLabel()
    const txtStyle = isAvailableToSell
      ? [S.TEXT_ACTIVE_DARK, S.TEXT_ALIGN_CENTER, S.TEXT_BOLD]
      : [S.TEXT_ACTIVE, S.TEXT_ALIGN_CENTER, S.TEXT_BOLD]
    return (
      <View style={{ width: '100%', flexWrap: 'wrap' }}>
        <Text
          // @ts-ignore
          style={StyleSheet.flatten(txtStyle)}>
          {productButtonLabel}
        </Text>
      </View>
    )
  }

  _renderAddOneProductMultipleChoices = () => {
    const variants = this.props.data.get('v') || List([])
    return (
      <View style={{ paddingLeft: 8, paddingRight: 8 }}>
        <TouchableOpacity
          onPress={(evt) => evt.stopPropagation()}
          style={{
            maxHeight: BUTTON_BOTTOM_CARD_HEIGHT, // Manual
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            // paddingLeft: 5,
            // paddingRight: 5,
          }}>
          <XFlatPicker<IProductItemDataMap>
            //  disabled={!isEditable}
            title='เลือกตัวเลือกสินค้า...'
            overrideMenuWidth={400}
            selectedIndex={0}
            options={variants}
            isButton
            onPick={this._handleOnPickAddOneProductToOrder}
            renderSelectedValue={this._renderAddOneProductToOrderButtonItem}
            renderItem={this._renderAddOneProductToOrderVariantItem}
            // isOptionDisabled={(opt, opts) => {
            //   const vQuantity = opt.get('q') || 0
            //   return vQuantity <= 0
            // }}
          />
        </TouchableOpacity>
      </View>
    )
  }

  _renderAddOneProductSingleChoice = () => {
    const isAvailableToSell = this._getIsAvailableToSell()
    return (
      <View style={{ paddingLeft: 8, paddingRight: 8 }}>
        <TouchableOpacity
          disabled={!isAvailableToSell}
          onPress={this._handleOnPickAddOneProductToOrderSingleChoice}
          style={{
            maxHeight: BUTTON_BOTTOM_CARD_HEIGHT, // Manual
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: isAvailableToSell ? COLORS.APP_MAIN : COLORS.FORM_INACTIVE,
            borderRadius: 8,
            minHeight: 38,
          }}>
          {this._renderAddOneProductToOrderButtonItem()}
        </TouchableOpacity>
      </View>
    )
  }

  // _renderAddOneProductToOrderButton = (variants: List<Map<string, any>>) => {
  _renderAddOneProductToOrderButton = () => {
    const isAvailableToSell = this._getIsAvailableToSell()
    const variants = this.props.data.get('v') || List([])
    if (!isAvailableToSell || variants.size <= 1) {
      return this._renderAddOneProductSingleChoice()
    }
    return this._renderAddOneProductMultipleChoices()
  }

  _handleOnPickAddOneProductToOrderSingleChoice = (evt) => {
    evt.stopPropagation()
    this._handlePullProductToOrder(0)
  }

  _handleOnPickAddOneProductToOrder = ({ item, index }) => {
    this._handlePullProductToOrder(index)
  }

  _handlePullProductToOrder = (vIndex: number) => {
    try {
      const { data, handlePullProductToOrder } = this.props
      const vQuantity = data.getIn(['v', vIndex, 'q']) || 0
      // console.log('_handleOnPickAddOneProductToOrder vQuantity => ', vQuantity)
      if (!vQuantity || vQuantity <= 0) {
        const pName = data.get('n')
        const vName = data.getIn(['v', vIndex, 'n']) || 0
        p.op.alert('สินค้าหมด', `กรุณาเติมจำนวน${p.op.t('qty')}ให้กับ ${pName} ${vName} ก่อนเปิดออเดอร์`)
        return
      }

      const variants = data.get('v') || List([])

      handlePullProductToOrder(data, variants.toArray(), vIndex)
    } catch (err) {
      // console.log('_handleOnPickAddOneProductToOrder err => ', err)
    }
    // console.log('_handleOnPickAddOneProductToOrder index => ', index)
    // console.log('_handleOnPickAddOneProductToOrder item =>', item)
  }

  _renderAddOneProductToOrderVariantItem = (data) => {
    const { sellerStoreId } = this.props
    const { item, index, disabled } = data

    // console.log('_renderAddOneProductToOrderVariantItem item =>', item)

    const v = item
    const variantName = v.get('n')
    const variantQuantity = v.get('q')
    // const variantPrice = sellerStoreId ? v.get('p') : v.getIn(['s', 0, 'p'])
    let variantPrice = v.get('p')
    if (!variantPrice && v.getIn(['s', 0, 'p'])) {
      variantPrice = v.getIn(['s', 0, 'p'])
    }
    // console.log('_renderAddOneProductToOrderVariantItem variantPrice =>', variantPrice)
    // const txtVariantPrice = `${variantName} ${xFmt.formatCurrency(variantPrice)}`

    const isOutOfStock = !variantQuantity || variantQuantity <= 0
    const textStyle = isOutOfStock ? S.TEXT_INACTIVE : S.TEXT_ACTIVE

    return (
      <VStack
        style={{
          // borderBottomWidth: p.op.isWeb() ? 0 : 1,
          borderBottomColor: COLORS.TEXT_INACTIVE,
          paddingLeft: 12,
          paddingRight: 12,
          paddingVertical: 8,
        }}>
        <View style={{ width: 286, flexWrap: 'wrap' }}>
          {/* <Text style={s.txtNormal}>{`${item.name}`}</Text> */}
          {/* <Text style={s.txtNormal}>{JSON.stringify(item)}</Text> */}
          <Text
            numberOfLines={1}
            ellipsizeMode='tail'
            // @ts-ignore
            style={StyleSheet.flatten([{ width: 286 }, textStyle])}>
            {variantName}
          </Text>
        </View>

        <View style={{ width: 70, flexWrap: 'wrap' }}>
          <Text
            // @ts-ignore
            style={StyleSheet.flatten([S.TEXT_ALIGN_RIGHT, textStyle])}>
            {isOutOfStock ? `(${xCONS.PRODUCT_STOCK.noStock})` : variantPrice}
          </Text>
        </View>
        {/* {!p.op.isWeb() ? (
          <VStack size={0} style={{ flex: 0, width: 50 }}>
            <ForwardIcon />
          </VStack>
        ) : null} */}
      </VStack>
    )
  }

  _getSumAvailableQty = () => {
    const { data } = this.props
    const variants = data.get('v') || List([])
    let qty = data.get('q')
    if (_.isNull(qty)) {
      // const sumAvailableQty = variants.reduce((prevSum, variant) => prevSum + parseInt(variant.get('available_qty')), 0)
      // qty = sumAvailableQty
      let sumAvailableQty = 0
      for (let i = 0; i < variants.size; i++) {
        const avaiQty = parseInt(variants.getIn([i, 'available_qty']))
        if (avaiQty > 0) {
          sumAvailableQty += avaiQty
        }
      }
      qty = sumAvailableQty
    }

    const isMyProduct = this._getIsMyProduct()
    if (qty < 0 && !isMyProduct) {
      qty = 0
    }
    return qty
  }

  _getAddOneProductToOrderButtonLabel = () => {
    const sumAvailableQty = this._getSumAvailableQty()
    const txtPriceDisplay = this._getDisplayPriceText()
    const hasQtyToSell = sumAvailableQty > 0
    let productButtonLabel = QUICK_ADD_PRODUCT_TO_ORDER_LABEL
    if (!hasQtyToSell) {
      productButtonLabel = OUT_OF_STOCK_LABEL
    } else if (txtPriceDisplay === '-') {
      productButtonLabel = NOT_NAME_PRICE_YET_LABEL
    }
    return productButtonLabel
  }

  _getIsAvailableToSell = () => {
    const sumAvailableQty = this._getSumAvailableQty()
    const txtPriceDisplay = this._getDisplayPriceText()
    const hasQtyToSell = sumAvailableQty > 0
    let isAvailableToSell = true
    if (!hasQtyToSell) {
      isAvailableToSell = false
    } else if (txtPriceDisplay === '-') {
      isAvailableToSell = false
    }
    return isAvailableToSell
  }

  _getDisplayPriceText = () => {
    const { data } = this.props
    const priceMin = data.get('i') || null
    const priceMax = data.get('x') || null
    let txtPriceDisplay = ''
    if (priceMax && priceMin && parseFloat(priceMax) !== parseFloat(priceMin)) {
      txtPriceDisplay = `${xFmt.formatCurrency(priceMin)} - ${xFmt.currencyFormatter.format(priceMax)}`
    } else if (parseFloat(priceMax) === parseFloat(priceMin)) {
      txtPriceDisplay = `${xFmt.formatCurrency(priceMax)}`
    } else {
      txtPriceDisplay = '-' // should be impossible case
    }

    return txtPriceDisplay
  }

  _getIsMyProduct = () => {
    const { data } = this.props
    let isMyProduct = data.get('m') || false
    if (!isMyProduct && data.has('h')) {
      isMyProduct = true
    }
    return isMyProduct
  }

  render() {
    util.logRender(this)
    const {
      index,
      data,
      width,
      height,
      thumbnailSize,
      itemMargin,
      showPulledProductIcon = false,
      showQuickAddButton = false,
      // isShowRetailPrice,
      // isUseRetailPrice,
      warningZeroWeight = false,
    } = this.props
    // util.log('_renderProductItem index', index)
    // util.log('_renderProductItem item', item.toJS())
    if (!Map.isMap(data)) {
      return null
    }
    const productId = data.get('id')
    if (!productId) {
      return null
    }
    const name = data.get('n') || ''
    const thumbnailUri = data.getIn(['thumbnail_uris', 0]) || data.getIn(['t', 0]) || ''
    const priceMin = data.get('i') || null
    const priceMax = data.get('x') || null
    const variants = data.get('v') || List([])
    // let qty = data.has('variants') ? null : data.get('q')
    // if (_.isNull(qty)) {
    //   const sumAvailableQty = variants.reduce((prevSum, variant) => {
    //     return prevSum + parseInt(variant.get('available_qty'))
    //   }, 0)
    //   qty = sumAvailableQty
    // }

    // const hasQtyToSell = qty > 0
    const isOdd = index % 2 !== 0 // RightBlock
    // const isMyProduct = data.get('m') || false
    // let isMyProduct = data.get('m') || false
    // if (!isMyProduct && data.has('parent_id') && !data.get('parent_id')) {
    //   isMyProduct = true
    // }
    // if (qty < 0 && !isMyProduct) {
    //   qty = 0
    // }

    let hasZeroWeight = data.get('z') || false

    // repeat-checking zero weight product if (it's full-structure from product detail)
    if (!hasZeroWeight && data.has('variants') && List.isList(variants)) {
      const sumWeight = variants.reduce((prevSum, variant) => prevSum + parseInt(variant.get('weight')), 0)
      hasZeroWeight = sumWeight === 0
    }
    // util.log('name: ' + name)
    // util.log(hasZeroWeight)

    // let txtPriceDisplay = ''
    // if (priceMax && priceMin && parseFloat(priceMax) !== parseFloat(priceMin)) {
    //   txtPriceDisplay = `${xFmt.formatCurrency(priceMin)} - ${xFmt.currencyFormatter.format(priceMax)}`
    // } else if (parseFloat(priceMax) === parseFloat(priceMin)) {
    //   txtPriceDisplay = `${xFmt.formatCurrency(priceMax)}`
    // } else {
    //   txtPriceDisplay = '-' // should be impossible case
    // }

    // Calculate Warning Flag sould display ?
    let infoText = null
    let infoBgColor = COLORS.BRAND_Warning // default สีเหลือง (ถ้ามี infoText)

    // FIXME: O => watching backend api again
    const priceArray = variants.map((v) => v.get('p') || v.get('price')) || List([])
    let hasNullPrice = false
    priceArray.forEach((p) => {
      if (!p) {
        hasNullPrice = true
      }
    })

    const qty = this._getSumAvailableQty()
    const isEmptyQty = qty <= 0 // ดูแล้ว avilable qty ใน product list ไม่ตรงกับใน sum variant qty
    const isLowQty = qty > 0 && qty <= 5
    // const isNoRetailPrice = hasNullPrice && isShowRetailPrice && isUseRetailPrice // เนื่องจากไม่มี flag บอก
    const isNoRetailPrice = false // deprecated

    // high priority first
    if (isEmptyQty) {
      infoText = xCONS.PRODUCT_STOCK.noStock
      infoBgColor = COLORS.BRAND_Danger
    } else if (isLowQty) {
      infoText = xCONS.PRODUCT_STOCK.lowStock
      // infoBgColor = COLORS.BRAND_Info
    } else if (isNoRetailPrice) {
      infoText = xCONS.PRODUCT_STOCK.noRetailPrice
    }
    // util.log('PLI.qty', qty)

    // let isAvailableToSell = true
    // let productButtonLabel = QUICK_ADD_PRODUCT_TO_ORDER_LABEL
    // if (!hasQtyToSell) {
    //   isAvailableToSell = false
    //   productButtonLabel = OUT_OF_STOCK_LABEL
    // } else if (txtPriceDisplay === '-') {
    //   isAvailableToSell = false
    //   productButtonLabel = NOT_NAME_PRICE_YET_LABEL
    // }

    const isAvailableToSell = this._getIsAvailableToSell()
    const txtPriceDisplay = this._getDisplayPriceText()
    const productButtonLabel = this._getAddOneProductToOrderButtonLabel()

    // APP CONFIG
    let isShowStockQty = true
    // const isParent = data.has('parent_id') ? data.get('parent_id') : false

    const isMyProduct = this._getIsMyProduct()
    if (!isMyProduct && !APP_CONFIG.product.show_non_stock_qty) {
      isShowStockQty = false
    }

    return (
      <TouchableOpacity
        // onPress={ () => this._onPress(productId) }
        onPress={this._onPress}
        style={{
          // margin: 5,
          flexDirection: 'column',
          marginLeft: isOdd ? itemMargin : 0,
          marginBottom: itemMargin,
          width,
          height,
          backgroundColor: 'white',
          shadowColor: '#000',
          shadowOffset: { width: 0, height: 2 },
          shadowOpacity: 0.1,
          shadowRadius: 1.5,
          elevation: 3,
        }}>
        {this._renderProductImage({
          thumbnail_uri: thumbnailUri,
          width,
          thumbnailSize,
          infoText,
          infoBgColor,
        })}
        {/* <View */}
        {/* style={{ */}
        {/* height: width, */}
        {/* width: width, */}
        {/* padding: 5 }}> */}
        {/* <Image */}
        {/* resizeMode='cover' */}
        {/* style={{ height: thumbnailSize, width: thumbnailSize }} */}
        {/* source={{ uri: thumbnail_uri }} /> */}
        {/* <View */}
        {/* resizeMode='cover' */}
        {/* style={{ */}
        {/* height: thumbnailSize / 2, */}
        {/* width: thumbnailSize * (0.97), */}
        {/* position: 'absolute', */}
        {/* // at least 5 because card padding */}
        {/* top: 10, */}
        {/* left: 5, */}
        {/* right: 10, */}
        {/* // backgroundColor: COLORS.APP_MAIN, */}
        {/* backgroundColor: 'transparent', */}
        {/* borderStyle: 'solid', */}
        {/* borderRightWidth: 50, */}
        {/* borderTopWidth: 50, */}
        {/* borderRightColor: 'transparent', */}
        {/* borderTopColor: COLORS.BRAND_Warning, */}
        {/* opacity: 0.98, */}

        {/* }} /> */}
        {/* <Text */}
        {/* resizeMode='cover' */}
        {/* style={[STYLES.TXT_LARGER_BOLD, { */}
        {/* backgroundColor: 'transparent', */}
        {/* height: thumbnailSize / 2, */}
        {/* width: thumbnailSize * (0.7), // 70 % */}
        {/* position: 'absolute', */}
        {/* top: 10, */}
        {/* left: 5, */}
        {/* right: 5, */}
        {/* padding: 5, */}
        {/* color: 'white', */}
        {/* }]}> */}
        {/* {'ยังไม่ได้ระบุราคาขายปลีก'} */}
        {/* </Text> */}
        {/* </View> */}
        <View
          style={{
            height: PRODUCT_DESC_HEIGHT + BUTTON_BOTTOM_CARD_HEIGHT, // Manual
            borderTopWidth: 1,
            borderColor: COLORS.FORM_INACTIVE,
          }}>
          {/* LINE 1 */}
          <View
            style={{
              height: PRODUCT_DESC_HEIGHT / 3,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              paddingLeft: 5,
              paddingRight: 5,
            }}>
            <Text
              numberOfLines={1}
              style={{
                // height: STYLES.FONT_SIZE_NORMAL + 1, // มีผลต่อกันตัดบรรทัดในเว็บ
                height: STYLES.FONT_SIZE_NORMAL + 4, // ถ้า +4 สระบนล่างจะไม่ถูกตัด
                fontSize: STYLES.FONT_SIZE_NORMAL,
                color: COLORS.TEXT_INACTIVE,
              }}>
              {/* { name } */}
              {isDevOrProdTestMode() ? `${index}:: ${name}` : name}
            </Text>
          </View>

          {/* LINE 2 */}
          <View
            style={{
              height: PRODUCT_DESC_HEIGHT / 3,
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingLeft: 5,
              paddingRight: 5,
            }}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}>
              <Icon
                name='pricetag'
                style={{
                  fontSize: STYLES.FONT_ICON_SMALLEST,
                  color: COLORS.TEXT_INACTIVE,
                }}
              />
              <Text
                numberOfLines={1}
                style={{
                  paddingLeft: 5,
                  fontSize: STYLES.FONT_SIZE_SMALLER,
                  color: COLORS.TEXT_INACTIVE,
                }}>
                {txtPriceDisplay}
              </Text>
            </View>
          </View>

          {/* LINE 3 */}
          {isShowStockQty ? (
            <View
              style={{
                height: PRODUCT_DESC_HEIGHT / 3,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingLeft: 5,
                paddingRight: 5,
              }}>
              <View
                // @ts-ignore
                style={s.pItemL3View}>
                <Icon name='albums' style={s.pItemL3Icon} />
                <Text numberOfLines={1} style={s.pItemL3Qty}>
                  {qty ? xFmt.currencyFormatter.format(qty) : '-'}
                </Text>
              </View>
              {warningZeroWeight && hasZeroWeight && isMyProduct ? (
                <View
                  // @ts-ignore
                  style={s.pItemWeightView}>
                  <Text
                    // @ts-ignore
                    style={isMyProduct ? s.pItemWeightLabelProdMy : s.pItemWeightLabelProdSeller}>
                    (น้ำหนักไม่ระบุ)
                  </Text>
                </View>
              ) : (
                <View style={s.pItemWeightLabelBlank} />
              )}
              {isMyProduct || showPulledProductIcon ? null : (
                <Image source={require('xui/img/tb/tb_seller0.png')} style={s.iconSellerProduct} />
              )}
            </View>
          ) : (
            <View
              style={{
                height: PRODUCT_DESC_HEIGHT / 3,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingLeft: 5,
                paddingRight: 5,
              }}
            />
          )}

          {/* LINE 4 */}
          {/* ถ้าเป็น App จะใช้ ActionSheet */}
          {showQuickAddButton && !p.op.isWeb() ? (
            <View
              style={{
                height: BUTTON_BOTTOM_CARD_HEIGHT, // Manual
                flexDirection: 'row',
                alignItems: 'flex-start',
                paddingLeft: 5,
                paddingRight: 5,
              }}>
              <TouchableOpacity
                // disabled={!hasQtyToSell}
                disabled={!isAvailableToSell}
                // onPress={ () => this._onPressAddOneProductToCart(data, isShowRetailPrice, isUseRetailPrice) }
                onPress={this._onPressAddOneProductToCart}
                style={[
                  NO_MARGIN_PADDING,
                  {
                    flex: 1,
                    height: '85%',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: isAvailableToSell ? COLORS.FORM_PRIMARY : COLORS.FORM_INACTIVE,
                    borderWidth: 1,
                    borderRadius: 7,
                    borderColor: isAvailableToSell ? COLORS.FORM_PRIMARY : COLORS.FORM_INACTIVE,
                  },
                ]}>
                <Text
                  style={[
                    NO_MARGIN_PADDING,
                    {
                      fontSize: STYLES.FONT_SIZE_LARGER,
                      color: isAvailableToSell ? COLORS.TEXT_ACTIVE_DARK : COLORS.TEXT_ACTIVE,
                      fontWeight: 'bold',
                      textAlign: 'center',
                    },
                  ]}>
                  {/* {hasQtyToSell ? QUICK_ADD_PRODUCT_TO_ORDER_LABEL : OUT_OF_STOCK_LABEL} */}
                  {productButtonLabel}
                </Text>
              </TouchableOpacity>
            </View>
          ) : null}
          {/* ุถ้าเป็น web จะใช้ XFlatPicker แทน */}
          {showQuickAddButton && p.op.isWeb() ? this._renderAddOneProductToOrderButton() : null}
        </View>
      </TouchableOpacity>
    )
  }

  // {showQuickAddButton ? (
  //   <View
  //     style={{
  //       height: BUTTON_BOTTOM_CARD_HEIGHT, // Manual
  //       flexDirection: 'row',
  //       alignItems: 'flex-start',
  //       paddingLeft: 5,
  //       paddingRight: 5,
  //     }}>
  //     <TouchableOpacity
  //       // disabled={!hasQtyToSell}
  //       disabled={!isAvailableToSell}
  //       // onPress={ () => this._onPressAddOneProductToCart(data, isShowRetailPrice, isUseRetailPrice) }
  //       onPress={this._onPressAddOneProductToCart}
  //       style={[
  //         NO_MARGIN_PADDING,
  //         {
  //           flex: 1,
  //           height: '85%',
  //           width: '100%',
  //           justifyContent: 'center',
  //           alignItems: 'center',
  //           backgroundColor: isAvailableToSell ? COLORS.FORM_PRIMARY : COLORS.FORM_INACTIVE,
  //           borderWidth: 1,
  //           borderRadius: 7,
  //           borderColor: isAvailableToSell ? COLORS.FORM_PRIMARY : COLORS.FORM_INACTIVE,
  //         },
  //       ]}>
  //       <Text
  //         style={[
  //           NO_MARGIN_PADDING,
  //           {
  //             fontSize: STYLES.FONT_SIZE_LARGER,
  //             color: isAvailableToSell ? COLORS.TEXT_ACTIVE_DARK : COLORS.TEXT_ACTIVE,
  //             fontWeight: 'bold',
  //             textAlign: 'center',
  //           },
  //         ]}>
  //         {/*{hasQtyToSell ? QUICK_ADD_PRODUCT_TO_ORDER_LABEL : OUT_OF_STOCK_LABEL}*/}
  //         {productButtonLabel}
  //       </Text>
  //     </TouchableOpacity>
  //   </View>
  // ) : null
  // }
}

const _s = {
  infoTextContainer: {
    height: 10,
    width: '100%',
    position: 'absolute',
    top: -32,
    right: 10,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderRightWidth: 50,
    borderTopWidth: 50,
    borderRightColor: 'transparent',
  },
  infoText: {
    ...STYLES.TXT_LARGER_BOLD,
    backgroundColor: 'transparent',
    fontSize: 10,
    position: 'absolute',
    top: 2,
    left: 4,
    right: 5,
    color: 'white',
  },
}

export const s = {
  // ICON_SELLER_STORE: require('../../img/tb/tb_seller0.png'),
  productContainer: {
    paddingLeft: 10,
    paddingRight: 10,
    // backgroundColor: COLORS.SUCCESS_MAIN,
  },
  variantGrid: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  variantGridColor: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.BG_LIGHT_GREY,
  },
  variantColLeft: {
    // width: '15%',
    // backgroundColor: 'powderblue',
    flex: 3,
    flexDirection: 'column',
    alignItems: 'center',
    padding: 5,
  },
  variantColRight: {
    flex: 17,
    // backgroundColor: 'skyblue',
    justifyContent: 'center',
    padding: 5,
  },
  variantColRightColor: {
    width: '80%',
    backgroundColor: 'rgba(15,185,215,0.2)',
    justifyContent: 'center',
    padding: 5,
  },
  variantFullGrid: {
    width: '100%',
    // backgroundColor: 'skyblue',
    justifyContent: 'center',
    padding: 5,
  },
  variantAddItem: {
    // backgroundColor: 'lightgreen',
    padding: 10,
  },
  cardStyle: {
    elevation: 3,
    width: '100%',
  },
  cardBody: {
    width: '100%',
    height: 220,
  },
  cardImg: {
    alignSelf: 'center',
    width: '100%',
    height: 200,
  },
  textEmptyStore: {
    marginTop: '40%',
    alignSelf: 'center',
  },
  fullBtnContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  txtHeader: {
    fontSize: STYLES.FONT_SIZE_ORDERS_HEADER_ROW2,
    fontWeight: 'bold',
  },
  txtNormal: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
  },
  txtHL: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_PRIMARY,
  },
  txtHLBG: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_PRIMARY,
    backgroundColor: COLORS.FORM_PRIMARY_BG,
  },
  labelMultiple: {
    width: '100%',
    height: 100,
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
  },
  txtMultiple: {
    width: '100%',
    minHeight: 100,
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_ACTIVE,
  },
  txtMultipleHL: {
    width: '100%',
    height: 100,
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_PRIMARY,
    backgroundColor: COLORS.FORM_PRIMARY_BG,
  },
  txtLabel: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
  },
  txtLabelShippingRate: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
    paddingTop: 4,
  },
  txtLabelVariant: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
    marginTop: 14,
    height: 32,
    // backgroundColor: 'red',
  },
  txtRight: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    textAlign: 'right',
  },
  txtHLRight: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_PRIMARY,
    backgroundColor: COLORS.FORM_PRIMARY_BG,
    textAlign: 'right',
  },
  labelRight: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
    textAlign: 'right',
  },
  input: {
    width: 100,
    alignSelf: 'flex-end',
    paddingRight: 3,
    paddingTop: 0,
    ...Platform.select({
      android: {
        paddingBottom: 5,
      },
    }),
  },
  shippingRateLabel: {
    ...Platform.select({
      android: {
        paddingTop: 8,
      },
      ios: {
        paddingTop: 4,
      },
    }),
  },
  shippingRateInputQty: {
    width: 38,
  },
  shippingRateInputPrice: {
    width: 48,
  },
  shippingRateInputCommon: {
    textAlign: 'center',
    ...Platform.select({
      android: {
        height: 32,
        paddingTop: 0,
        paddingBottom: 8,
      },
      ios: {
        height: 25,
      },
    }),
  },
  shippingRateInputQtyContainer: {
    width: 40,
    ...Platform.select({
      android: {
        height: 32,
      },
      ios: {
        height: 25,
      },
    }),
  },
  shippingRateInputPriceContainer: {
    width: 50,
    height: 25,
  },
  formItem: { paddingTop: 2, paddingBottom: 2, height: 50, borderBottomWidth: 0 },
  formVItem: { paddingTop: 2, paddingBottom: 2, height: 36, borderBottomWidth: 0 },
  formNormalText: { height: 40 },
  listFooter: {
    paddingVertical: 20,
  },
  iconSellerProduct: {
    height: STYLES.FONT_ICON_SMALLEST + 2,
    width: STYLES.FONT_ICON_SMALLEST + 2,
    paddingRight: 4,
    marginTop: -4,
  },
  pItemL3View: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  pItemL3Icon: {
    fontSize: STYLES.FONT_ICON_SMALLEST,
    color: COLORS.TEXT_INACTIVE,
  },
  pItemL3Qty: {
    paddingLeft: 5,
    fontSize: STYLES.FONT_SIZE_SMALLER,
    color: COLORS.TEXT_INACTIVE,
  },
  pItemWeightView: {
    flex: 1,
    alignItems: 'flex-end',
    paddingBottom: 3,
  },
  pItemWeightLabelProdMy: {
    fontSize: 8,
    paddingTop: 1,
    alignItems: 'flex-end',
    color: COLORS.TEXT_INACTIVE,
    paddingRight: 0,
  },
  pItemWeightLabelProdSeller: {
    fontSize: 8,
    paddingTop: 1,
    alignItems: 'flex-end',
    color: COLORS.TEXT_INACTIVE,
    paddingRight: 4,
  },
  pItemWeightLabelBlank: {
    flex: 1,
  },
  txtNoProductNameInfo: {
    flex: 1,
    fontSize: STYLES.FONT_SIZE_SMALLER,
    color: COLORS.TEXT_INACTIVE,
    // backgroundColor: 'red',
    paddingLeft: 15,
    paddingTop: 15,
    paddingBottom: 15,
    // alignSelf: 'center',
  },
}
