import React from 'react'
import { TouchableOpacity } from 'react-native'

import HStack from './HStack'
import XIcon from './XIcon'
import XText from './XText'

interface IXCheckableItemProps<DataType = any> {
  disabled?: boolean
  label: string
  checked: boolean
  onPress: (checked: boolean) => void
}

export default class XCheckableItem<T> extends React.Component<IXCheckableItemProps<T>> {
  _onPress = () => {
    const { checked, onPress } = this.props
    onPress(checked)
  }

  render() {
    const { checked, disabled, label } = this.props

    return (
      <HStack pb='1'>
        <TouchableOpacity disabled={disabled} onPress={this._onPress}>
          <HStack
            px='2'
            py='1'
            space='1'
            alignItems='center'
            borderWidth='1'
            borderColor={checked ? 'gray.400' : 'gray.200'}
            borderRadius='lg'>
            {checked ? <XIcon name='checkmark' /> : <XIcon name='checkmark' color='transparent' />}
            {/* <MkpLogo mkpId={ch.mkp_id} width={24} height={24} /> */}
            <XText variant={checked ? 'active' : 'inactive'} numberOfLines={1}>
              {label}
            </XText>
          </HStack>
        </TouchableOpacity>
      </HStack>
    )
  }
}
