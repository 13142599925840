import React from 'react'
// import { Drawer } from 'native-base'
import { TouchableOpacity, View } from 'react-native'
import _ from 'lodash'
import { diff } from 'deep-object-diff'

import { COLORS } from 'x/config/styles'
import {
  ICategory,
  IMKPChannelDetail,
  IProductListFilterViewProps,
  IProductListFilterViewState,
  IProductListFilterState,
  IXDateRangeSelectorChangeParams,
  categoryByStore,
  IProductListFilterTemplate,
  IProductListSortType,
} from 'x/index'

// import NavHeaderButton from './NavHeaderButton'
// import XCheckbox from './XCheckbox'
// import XCheckButton from './XCheckButton'
import p from 'x/config/platform-specific'
import CONS from 'x/config/constants'
import * as util from 'x/utils/util'
import HStack from 'xui/components/HStack'
import XContainer from 'xui/components/XContainer'
import Icon from 'xui/components/Icon'
import XIcon from 'xui/components/XIcon'
import XButton from 'xui/components/XButton'
import XText from 'xui/components/XText'
import Box from 'xui/components/Box'
import VStack from 'xui/components/VStack'
import XDateRangeSelectorButton from 'xui/components/XDateRangeSelectorButton'
import Segment from 'xui/components/Segment'
import MKPChannelsActive from 'xui/components/MKPChannelsActive'
import XImageIcon from 'xui/components/XImageIcon'
import MkpLogo from 'xui/components/MkpLogo'
import XContent from 'xui/components/XContent'
import CollapsableCard from 'xui/components/CollapsableCard'
import XCheckableItem from 'xui/components/XCheckableItem'
import XCheckbox from 'xui/components/XCheckbox'
import XInput from 'xui/components/XInput'
import XNumericInput from 'xui/components/XNumericInput'
import XIconButton from 'xui/components/XIconButton'
import XSegment from 'xui/components/XSegment'
import XRadioList from 'xui/components/XRadioList'

const logoMkpChannel = require('xui/img/about/icon_mkp_channel.png')

const {
  STOCK_ALMOST_EMPTY,
  STOCK_EMPTY,
  STOCK_DEAD_STOCK,
  STOCK_CUSTOM,
  STOCK_EXCEEDED_QTY,
  STOCK_HAVE_RESERVED,
  STOCK_NOT_HAVE_RESERVED,
} = CONS.PRODUCT_LIST_FILTER_STOCK_KEYS
const { COST_FILTER_EQUAL, COST_FILTER_CUSTOM } = CONS.PRODUCT_LIST_FILTER_COST_KEYS
// const STOCK_ALMOST_EMPTY = 'almost_empty'
// const STOCK_EMPTY = 'empty'
// const STOCK_DEAD_STOCK = 'dead_stock'
// const STOCK_CUSTOM = 'custom'
// const STOCK_EXCEEDED_QTY = 'exceeded_qty'
// const STOCK_HAVE_RESERVED = 'have_reserved'
// const STOCK_NOT_HAVE_RESERVED = 'not_have_reserved'

// const STOCK_QTY_OPTIONS = [
//   { key: STOCK_ALMOST_EMPTY, label: 'ใกล้หมด' },
//   { key: STOCK_EMPTY, label: 'หมดแล้ว' },
//   { key: STOCK_DEAD_STOCK, label: 'ค้างสต๊อก' },
//   { key: STOCK_CUSTOM, label: 'ระบุช่วง' },
// ]

// const STOCK_AVAILABLE_QTY_OPTIONS = [
//   { key: STOCK_ALMOST_EMPTY, label: 'ใกล้หมด' },
//   { key: STOCK_EMPTY, label: 'หมดแล้ว' },
//   { key: STOCK_DEAD_STOCK, label: 'ค้างสต๊อก' },
//   { key: STOCK_EXCEEDED_QTY, label: 'ขายเกินสต๊อกคงเหลือ' },
//   { key: STOCK_CUSTOM, label: 'ระบุช่วง' },
// ]

// const STOCK_QTY_OVERDUE_OPTIONS = [
//   { key: STOCK_HAVE_RESERVED, label: 'มีจองอยู่' },
//   { key: STOCK_NOT_HAVE_RESERVED, label: 'ไม่มีจอง' },
//   { key: STOCK_CUSTOM, label: 'ระบุช่วง' },
// ]

// const COST_FILTER_EQUAL = 'equal'
// const COST_FILTER_CUSTOM = 'custom'

// const COST_FILTER_OPTIONS = [
//   { key: COST_FILTER_EQUAL, label: 'เท่ากับ' },
//   { key: COST_FILTER_CUSTOM, label: 'ระบุช่วง' },
// ]

class BaseUIProductListFilterView<P extends IProductListFilterViewProps, S extends IProductListFilterViewState> extends React.Component<
  P,
  S
> {
  static displayName = 'BaseUIProductListFilterView'

  // static defaultProps: Partial<IProductListFilterViewProps> = {
  //   defaultSetting: {},
  //   currentSetting: {},
  // }

  // loadProps: boolean

  inProcess?: boolean

  constructor(props: P) {
    super(props)
    // this.drawerRef = React.createRef()

    // @ts-ignore
    this.state = {
      // allChildren: true,
      categoryList: null,
      // levelCategoryList: null,
      // selectedCategoryId: null,

      isModalOpen: false,
      channels: util.getMKPChannels(),
      // mkpChIds: [],

      hasCreatedByMkpChannels: util.getMKPChannels(),
      // hasCreatedByMkpChMkpChIds: [],

      // mkpChannelActiveMap: {},
      // mkpChannelNotInActiveMap: {},

      defaultSetting: {},
      currentSetting: {},
      appliedSetting: {},
    }

    // this.loadProps = true
    // this.drawerCatRef = React.createRef()
  }

  async componentDidMount(): Promise<void> {
    await this._setupCategoryList()
    await this._setupFilter()
    await this._setupExtended()
  }

  _setupExtended = async () => {
    // do nothing
  }

  isHiddenSortFilterCard = () => false

  isHiddenStockFilterCard = () => false

  isHiddenCostFilterCard = () => false

  isHiddenDatetimeFilterCard = () => false

  isHiddenMkpFilterCard = () => false

  isHiddenCategoryFilterCard = () => false

  _setupCategoryList = async () => {
    const storeId = util.getNavParam(this.props, 'store_id')
    const { categoryList } = this.props
    const catGroupList = categoryList.toJS()

    const foundIndex = catGroupList.findIndex((catG: categoryByStore) => catG.store_id === storeId)
    if (foundIndex >= 0) {
      const catGroup = catGroupList[foundIndex]
      await util.setStatePromise(this, { categoryList: catGroup.category })
    }
  }

  _setupFilter = async () => {
    // const currentSetting = util.getNavParam(this.props, 'currentSetting', {})
    const defaultSetting = util.getNavParam(this.props, 'defaultSetting', {}) as IProductListFilterState
    const appliedSetting = util.getNavParam(this.props, 'appliedSetting', {}) as IProductListFilterState

    if (defaultSetting && _.isString(defaultSetting.createdAtRangeOptionKey) && defaultSetting.createdAtRangeOptionKey !== 'Custom') {
      const dr = util.getDateRangeFromOption(defaultSetting.createdAtRangeOptionKey)
      defaultSetting.createdAtFrom = dr.begin
      defaultSetting.createdAtTo = dr.end
    }

    if (defaultSetting && _.isString(defaultSetting.updatedAtRangeOptionKey) && defaultSetting.updatedAtRangeOptionKey !== 'Custom') {
      const dr = util.getDateRangeFromOption(defaultSetting.updatedAtRangeOptionKey)
      defaultSetting.updatedAtFrom = dr.begin
      defaultSetting.updatedAtTo = dr.end
    }

    if (appliedSetting && _.isString(appliedSetting.createdAtRangeOptionKey) && appliedSetting.createdAtRangeOptionKey !== 'Custom') {
      const dr = util.getDateRangeFromOption(appliedSetting.createdAtRangeOptionKey)
      appliedSetting.createdAtFrom = dr.begin
      appliedSetting.createdAtTo = dr.end
    }

    if (appliedSetting && _.isString(appliedSetting.updatedAtRangeOptionKey) && appliedSetting.updatedAtRangeOptionKey !== 'Custom') {
      const dr = util.getDateRangeFromOption(appliedSetting.updatedAtRangeOptionKey)
      appliedSetting.updatedAtFrom = dr.begin
      appliedSetting.updatedAtTo = dr.end
    }

    await util.setStatePromise(this, { currentSetting: appliedSetting, defaultSetting, appliedSetting })
  }

  _getCurrentFilter = () => {
    const { currentSetting = {} } = this.state
    return currentSetting as IProductListFilterTemplate
  }

  _setCurrentFilter = async (newFilter: Partial<IProductListFilterTemplate>) => {
    const { currentSetting } = this.state
    const newCurrentFilter = { ...currentSetting, ...newFilter }
    await util.setStatePromise(this, { currentSetting: newCurrentFilter })
  }

  _seletedCategory = async (category: ICategory) => {
    const { selectedCategoryId } = this._getCurrentFilter()
    if (selectedCategoryId === category.id) {
      await this._setCurrentFilter({ selectedCategoryId: null })
      return
    }
    await this._setCurrentFilter({ selectedCategoryId: category.id })

    // const { levelCategoryList } = this.state
    // const newLvlelCat = []
    // levelCategoryList.map((data: ICategory) => {
    //   if (data.id === category.id) {
    //     const newCat = data
    //     newCat.selected = _.isNil(data.selected) ? true : !data.selected
    //     newLvlelCat.push(newCat)
    //   } else {
    //     const newCat = data
    //     newCat.selected = false
    //     newLvlelCat.push(newCat)
    //   }
    // })
    // await util.setStatePromise(this, {
    //   levelCategoryList: newLvlelCat,
    // })
  }

  _onPressSubmitBtn = async () => {
    const onSubmit = util.getNavParam(this.props, 'onSubmit')

    if (_.isFunction(onSubmit)) {
      await onSubmit(this._getCurrentFilter())
      this._goBack()
    }
    return

    const {
      // levelCategoryList,
      // allChildren,
      // mkpChIds,
      segmentHasMkpChIndex,
      segmentHasMkpChNotInIndex,
      mkpChannelActiveMap,
      mkpChannelNotInActiveMap,
      createdAtFrom,
      createdAtTo,
      createdAtRangeOptionKey,
      segmentCreatedAtIndex,
      segmentHasParentIndex,
      segmentUpdatedAtIndex,
      updatedAtFrom,
      updatedAtTo,
      updatedAtRangeOptionKey,
      segmentHasCreatedByMkpChIndex,
      hasCreatedByMkpChMkpChIds,
      segmentHasCreatedByMkpChSpecificIndex,
    } = this._getCurrentFilter()

    // const { onSubmit, onRequestCloseView } = util.getNavParams(this.props)

    // if (_.isFunction(onRequestCloseView)) {
    //   onRequestCloseView()
    // }
    // this.closeFilterModal()

    // let selectedCat = null
    // levelCategoryList.map((data: ICategory) => {
    //   if (data.selected) {
    //     selectedCat = data
    //   }
    // })

    const spf: IProductListFilterState = {
      // category: selectedCat,
      // allChildrenCategory: allChildren,
    }

    // if (selectedCat) {
    //   spf.category = selectedCat
    // }

    // if (!allChildren) {
    //   spf.allChildrenCategory = false
    // }

    // if (mkpChIds.length > 0) {
    //   // @ts-ignore
    //   spf.mkpChIds = mkpChIds
    // }

    // if (_.includes([1, 2], segmentHasMkpChIndex)) {
    //   spf.segmentHasMkpChIndex = segmentHasMkpChIndex
    //   spf.mkpChIds = mkpChIds
    // }

    // if (segmentHasMkpChIndex === 2) {
    //   spf.mkpChIds = mkpChIds
    // }
    if (segmentHasMkpChIndex === 1 && _.isObject(mkpChannelActiveMap) && !_.isEmpty(mkpChannelActiveMap)) {
      spf.segmentHasMkpChIndex = segmentHasMkpChIndex
      spf.mkpChannelActiveMap = mkpChannelActiveMap
    }

    if (segmentHasMkpChNotInIndex === 1 && _.isObject(mkpChannelNotInActiveMap) && !_.isEmpty(mkpChannelNotInActiveMap)) {
      spf.segmentHasMkpChNotInIndex = segmentHasMkpChNotInIndex
      spf.mkpChannelNotInActiveMap = mkpChannelNotInActiveMap
    }

    if (_.includes([1, 2], segmentHasCreatedByMkpChIndex)) {
      spf.segmentHasCreatedByMkpChIndex = segmentHasCreatedByMkpChIndex
    }

    if (segmentHasCreatedByMkpChIndex === 2 && segmentHasCreatedByMkpChSpecificIndex === 1) {
      spf.segmentHasCreatedByMkpChSpecificIndex = segmentHasCreatedByMkpChSpecificIndex
      spf.hasCreatedByMkpChMkpChIds = hasCreatedByMkpChMkpChIds
    }

    if (_.includes([1, 2], segmentHasParentIndex)) {
      spf.segmentHasParentIndex = segmentHasParentIndex
    }

    if (segmentCreatedAtIndex === 1) {
      spf.segmentCreatedAtIndex = segmentCreatedAtIndex
      spf.createdAtFrom = createdAtFrom
      spf.createdAtTo = createdAtTo
      spf.createdAtRangeOptionKey = createdAtRangeOptionKey
    }

    if (segmentUpdatedAtIndex === 1) {
      spf.segmentUpdatedAtIndex = segmentUpdatedAtIndex
      spf.updatedAtFrom = updatedAtFrom
      spf.updatedAtTo = updatedAtTo
      spf.updatedAtRangeOptionKey = updatedAtRangeOptionKey
    }

    await onSubmit(spf)
    // this.loadProps = true
  }

  _clearAllSelected = async () => {
    const { defaultSetting = {} } = this.state
    // const defaultSetting = util.getNavParam(this.props, 'defaultSetting', {})
    // const { defaultSetting = {} } = util.getNavParams(this.props)
    // const { levelCategoryList } = this.state
    // const newLevelCategoryList = []
    // levelCategoryList.map((data: ICategory) => {
    //   const xData = data
    //   xData.selected = false
    //   newLevelCategoryList.push(xData)
    // })
    // await util.setStatePromise(this, {
    //   // levelCategoryList: newLevelCategoryList,
    //   channels: util.getMKPChannels(),
    //   hasCreatedByMkpChannels: util.getMKPChannels(),

    //   allChildren: true,

    //   segmentCreatedAtIndex: 0,
    //   createdAtFrom: null,
    //   createdAtTo: null,
    //   createdAtRangeOptionKey: null,

    //   segmentUpdatedAtIndex: 0,
    //   updatedAtFrom: null,
    //   updatedAtTo: null,
    //   updatedAtRangeOptionKey: null,

    //   segmentHasParentIndex: 0,

    //   segmentHasMkpChIndex: 0,
    //   // mkpChIds: [],
    //   segmentHasMkpChNotInIndex: 0,
    //   mkpChannelActiveMap: {},
    //   mkpChannelNotInActiveMap: {},

    //   segmentHasCreatedByMkpChIndex: 0,
    //   segmentHasCreatedByMkpChSpecificIndex: 0,
    //   hasCreatedByMkpChMkpChIds: [],

    //   ...defaultSetting,
    // })

    await util.setStatePromise(this, { currentSetting: defaultSetting })
  }

  _setAllChildrenCategory = () => {
    const { isSubCategoryIncluded } = this._getCurrentFilter()
    this._setCurrentFilter({ isSubCategoryIncluded: !isSubCategoryIncluded })
  }

  isFilterDirty = () => {
    const { defaultSetting = {}, currentSetting = {} } = this.state
    // const { defaultSetting = {}, currentSetting = {} } = util.getNavParams(this.props)
    // const defaultSetting = util.getNavParam(this.props, 'defaultSetting', {})
    // const currentSetting = util.getNavParam(this.props, 'currentSetting', {})

    // @ts-ignore
    const filterDiff = diff(defaultSetting, currentSetting)
    if (_.isEmpty(filterDiff)) {
      return false
    }
    return true
  }

  // openFilterModal = () => {
  //   const { levelCategoryList, currentSetting = {} } = util.getNavParams(this.props)

  //   this.setState({ isModalOpen: true, levelCategoryList, ...currentSetting })
  // }

  // closeFilterModal = () => {
  //   this.setState({ isModalOpen: false })
  // }

  // _renderHeader = () => (
  //   <View
  //     style={{
  //       flexBasis: 44,
  //       height: 50,
  //       width: '100%',
  //       backgroundColor: '#fff',
  //       justifyContent: 'center',
  //       flexDirection: 'row',
  //       alignItems: 'center',
  //     }}>
  //     {/* {isIos ? <View style={{ marginTop: 30 }} /> : null} */}
  //     <Text style={{ color: COLORS.TEXT_ACTIVE, fontSize: STYLES.FONT_SIZE_LARGER, flex: 1, textAlign: 'center' }}>กรอง</Text>
  //     {Platform.OS === 'web' ? null : (
  //       <TouchableOpacity style={{ width: 50, height: 50, justifyContent: 'center' }} onPress={this.closeFilterModal}>
  //         <XIcon name='cancel' family='MaterialIcons' style={{ flex: 0 }} />
  //       </TouchableOpacity>
  //     )}
  //   </View>
  // )

  _renderProductCategoryButton = (data: ICategory, index: number) => {
    const { selectedCategoryId } = this._getCurrentFilter()

    if (_.isNil(selectedCategoryId) && data.p) {
      return null
    }

    if (selectedCategoryId && data.p !== selectedCategoryId) {
      return null
    }

    const marginLeft = index === 0 ? 4 : 4
    return (
      <TouchableOpacity
        key={`ProductFilterCatItem_${index.toString()}_${data.id}`}
        onPress={() => this._seletedCategory(data)}
        style={{
          // minWidth: 30,
          // width: 50,
          // maxWidth: 120,
          height: 25,
          borderColor: data.l,
          borderWidth: 0.7,
          borderRadius: 6,
          marginLeft,
          paddingLeft: 8,
          marginTop: 10,
          justifyContent: 'center',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        {data.selected ? (
          <Icon name='check' type='AntDesign' style={{ color: COLORS.APP_MAIN, minWidth: 25, fontSize: 20, width: 25 }} />
        ) : (
          <View style={{ height: 1, width: 25 }} />
        )}
        <XText
          style={{
            // fontSize: STYLES.FONT_SIZE_NORMAL,
            color: data.l ? data.l : COLORS.TEXT_INACTIVE,
            textAlign: 'center',
            paddingHorizontal: 2,
            paddingRight: 6,
          }}
          numberOfLines={1}>
          {data.n}
        </XText>
      </TouchableOpacity>
    )
  }

  onChangeSegmentHasMkpChIndex = async (index: number) => {
    const { segmentHasMkpChNotInIndex = 0, mkpChannelNotInActiveMap = {} } = this._getCurrentFilter()

    const newState: Partial<IProductListFilterState> = { segmentHasMkpChIndex: index }

    if (index === 0) {
      newState.mkpChannelActiveMap = {}
    }

    if (index === 1 && segmentHasMkpChNotInIndex === 0) {
      newState.mkpChannelActiveMap = {}
      const mkpChannels = util.getMKPChannels()
      for (let i = 0; i < mkpChannels.length; i++) {
        newState.mkpChannelActiveMap[mkpChannels[i].id] = true
      }
    }

    if (index === 1 && segmentHasMkpChNotInIndex === 1) {
      newState.mkpChannelActiveMap = {}
      const erpDocTypeNotInActiveIds = Object.keys(mkpChannelNotInActiveMap).map((key) => parseInt(key))
      for (let i = 0; i < erpDocTypeNotInActiveIds.length; i++) {
        const erpDocTypeNotInActiveId = erpDocTypeNotInActiveIds[i]
        newState.mkpChannelActiveMap[erpDocTypeNotInActiveId] = !mkpChannelNotInActiveMap[erpDocTypeNotInActiveId]
      }
    }

    // await util.setStatePromise(this, newState)
    await this._setCurrentFilter(newState)
  }

  onChangeSegmentHasMkpChNotInIndex = async (index: number) => {
    const { segmentHasMkpChIndex = 0, mkpChannelActiveMap = {} } = this._getCurrentFilter()

    const newState: Partial<IProductListFilterState> = { segmentHasMkpChNotInIndex: index }

    if (index === 0) {
      newState.mkpChannelNotInActiveMap = {}
    }

    if (index === 1 && segmentHasMkpChIndex === 0) {
      newState.mkpChannelNotInActiveMap = {}
      const mkpChannels = util.getMKPChannels()
      for (let i = 0; i < mkpChannels.length; i++) {
        newState.mkpChannelNotInActiveMap[mkpChannels[i].id] = true
      }
    }

    if (index === 1 && segmentHasMkpChIndex === 1) {
      newState.mkpChannelNotInActiveMap = {}
      const erpDocTypeNotInActiveIds = Object.keys(mkpChannelActiveMap).map((key) => parseInt(key))
      for (let i = 0; i < erpDocTypeNotInActiveIds.length; i++) {
        const erpDocTypeNotInActiveId = erpDocTypeNotInActiveIds[i]
        newState.mkpChannelNotInActiveMap[erpDocTypeNotInActiveId] = !mkpChannelActiveMap[erpDocTypeNotInActiveId]
      }
    }

    // await util.setStatePromise(this, newState)
    await this._setCurrentFilter(newState)
  }

  // -------------------------------------------------------------
  _renderMkpChannelSelector = () => {
    const mkpChannels = util.getMKPChannels()
    return mkpChannels.map(this._renderMkpChannelSelectorItem)
  }

  _renderMkpChannelSelectorItem = (ch: IMKPChannelDetail, index: number) => {
    const { mkpChannelActiveMap = {} } = this._getCurrentFilter()
    const checked = mkpChannelActiveMap[ch.id] || false

    return (
      <HStack key={`mkp-item-${ch.id}_${index}`} pb='1'>
        <TouchableOpacity onPress={() => this._onMkpChannelSelectorItemPress(ch, index)}>
          <HStack
            px='2'
            py='1'
            space='1'
            alignItems='center'
            borderWidth='1'
            borderColor={checked ? 'gray.400' : 'gray.200'}
            borderRadius='lg'>
            {checked ? <XIcon name='checkmark' /> : <XIcon name='checkmark' color='transparent' />}
            <MkpLogo mkpId={ch.mkp_id} width={24} height={24} />
            <XText variant={checked ? 'active' : 'inactive'} numberOfLines={1}>
              {ch.name}
            </XText>
          </HStack>
        </TouchableOpacity>
      </HStack>
    )
  }

  _onMkpChannelSelectorItemPress = async (ch: IMKPChannelDetail, index: number) => {
    const mkpChId = ch.id
    const { mkpChannelNotInActiveMap: notInMap = {}, mkpChannelActiveMap: inMap = {} } = this._getCurrentFilter()
    const mkpChannelActiveMap = { ...inMap }
    const mkpChannelNotInActiveMap = { ...notInMap }

    if (_.isNil(mkpChannelActiveMap[mkpChId])) {
      mkpChannelActiveMap[mkpChId] = true
      mkpChannelNotInActiveMap[mkpChId] = false
    } else if (_.isBoolean(mkpChannelActiveMap[mkpChId])) {
      const newValue = !mkpChannelActiveMap[mkpChId]
      mkpChannelActiveMap[mkpChId] = newValue
      if (newValue === true) {
        mkpChannelNotInActiveMap[mkpChId] = false
      }
    }

    // await util.setStatePromise(this, { mkpChannelActiveMap, mkpChannelNotInActiveMap })
    await this._setCurrentFilter({ mkpChannelActiveMap, mkpChannelNotInActiveMap })
  }

  //----------------------------------
  _renderMkpChannelSelectorNotIn = () => {
    const mkpChannels = util.getMKPChannels()
    return mkpChannels.map(this._renderMkpChannelSelectorNotInItem)
  }

  _renderMkpChannelSelectorNotInItem = (ch: IMKPChannelDetail, index: number) => {
    const { mkpChannelNotInActiveMap = {} } = this._getCurrentFilter()
    const checked = mkpChannelNotInActiveMap[ch.id] || false

    return (
      <HStack key={`mkp-item-not-in-${ch.id}_${index}`} pb='1'>
        <TouchableOpacity onPress={() => this._onMkpChannelSelectorNotInItemPress(ch, index)}>
          <HStack
            px='2'
            py='1'
            space='1'
            alignItems='center'
            borderWidth='1'
            borderColor={checked ? 'gray.400' : 'gray.200'}
            borderRadius='lg'>
            {checked ? <XIcon name='checkmark' /> : <XIcon name='checkmark' color='transparent' />}
            <MkpLogo mkpId={ch.mkp_id} width={24} height={24} />
            <XText variant={checked ? 'active' : 'inactive'} numberOfLines={1}>
              {ch.name}
            </XText>
          </HStack>
        </TouchableOpacity>
      </HStack>
    )
  }

  _onMkpChannelSelectorNotInItemPress = async (ch: IMKPChannelDetail, index: number) => {
    const mkpChId = ch.id
    const { mkpChannelNotInActiveMap: notInMap = {}, mkpChannelActiveMap: inMap = {} } = this._getCurrentFilter()
    const mkpChannelActiveMap = { ...inMap }
    const mkpChannelNotInActiveMap = { ...notInMap }

    if (_.isNil(mkpChannelNotInActiveMap[mkpChId])) {
      mkpChannelNotInActiveMap[mkpChId] = true
      mkpChannelActiveMap[mkpChId] = false
    } else if (_.isBoolean(mkpChannelNotInActiveMap[mkpChId])) {
      const newValue = !mkpChannelNotInActiveMap[mkpChId]
      mkpChannelNotInActiveMap[mkpChId] = newValue
      if (newValue === true) {
        mkpChannelActiveMap[mkpChId] = false
      }
    }

    // await util.setStatePromise(this, { mkpChannelActiveMap, mkpChannelNotInActiveMap })
    await this._setCurrentFilter({ mkpChannelActiveMap, mkpChannelNotInActiveMap })
  }

  _renderFilterWhichSystemIn = () => {
    const { channels } = this.state
    const { segmentHasMkpChIndex = 0, segmentHasMkpChNotInIndex = 0 } = this._getCurrentFilter()

    if (!channels || channels.length === 0) {
      return null
    }

    return (
      <VStack w='full' p='2' space='1' borderBottomWidth='1' borderColor='gray.200' bg='white'>
        <HStack alignItems='center' space='1'>
          <XImageIcon source={logoMkpChannel} />
          <XText variant='inactive'>
            <XText bold>เชื่อม</XText>อยู่กับช่องทางขาย...
          </XText>
        </HStack>

        <Segment
          selectedIndex={segmentHasMkpChIndex}
          options={['ไม่ระบุ', 'เชื่อมอยู่']}
          onSegmentChange={this.onChangeSegmentHasMkpChIndex}
        />

        {segmentHasMkpChIndex === 1 ? (
          <VStack w='full'>
            <XText variant='inactive'>เชื่อมอยู่กับช่องทางเหล่านี้...</XText>
            <HStack w='full' flexWrap='wrap' space='1'>
              {this._renderMkpChannelSelector()}
            </HStack>
          </VStack>
        ) : null}

        <HStack alignItems='center' space='1'>
          <Box>
            <XImageIcon source={logoMkpChannel} />
            <HStack
              alignItems='flex-start'
              justifyContent='flex-start'
              style={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                borderRadius: 6,
                backgroundColor: 'white',
                overflow: 'hidden',
                width: 12,
                height: 12,
              }}>
              <XIcon
                variant='danger'
                name='close'
                family='MaterialIcons'
                style={{
                  position: 'absolute',
                  top: -6,
                  fontSize: 12,
                  width: 12,
                  height: 12,
                  // backgroundColor: 'red',
                }}
              />
            </HStack>
          </Box>
          <XText variant='inactive'>
            <XText bold>ไม่เชื่อม</XText>อยู่กับช่องทางขาย...
          </XText>
        </HStack>

        <Segment
          selectedIndex={segmentHasMkpChNotInIndex}
          options={['ไม่ระบุ', 'ไม่ได้เชื่อม']}
          onSegmentChange={this.onChangeSegmentHasMkpChNotInIndex}
        />

        {segmentHasMkpChNotInIndex === 1 ? (
          <VStack w='full'>
            <XText variant='inactive'>ไม่ได้เชื่อมกับช่องทางทางเหล่านี้...</XText>
            <HStack w='full' flexWrap='wrap' space='1'>
              {this._renderMkpChannelSelectorNotIn()}
            </HStack>
          </VStack>
        ) : null}
      </VStack>
    )
  }

  onChangeSegmentHasCreatedByMkpChIndex = (idx: number) => {
    // const { hasCreatedByMkpChannels } = this.state
    const newState: Partial<IProductListFilterState> = {}
    newState.segmentHasCreatedByMkpChIndex = idx
    // if (_.includes([1, 2], idx)) {
    //   newState.hasCreatedByMkpChMkpChIds = hasCreatedByMkpChannels.filter((ch: IMKPChannelDetail) => ch.is_active).map((ch) => ch.id)
    // }
    if (_.includes([0, 1], idx)) {
      newState.segmentHasCreatedByMkpChSpecificIndex = 0
    }
    // @ts-ignore
    // this.setState(newState)
    this._setCurrentFilter(newState)
  }

  onChangeSegmentHasCreatedByMkpChSpecificIndex = (idx: number) => {
    const { hasCreatedByMkpChannels } = this.state
    const newState: Partial<IProductListFilterState> = {}
    newState.segmentHasCreatedByMkpChSpecificIndex = idx
    if (idx === 2) {
      newState.hasCreatedByMkpChMkpChIds = hasCreatedByMkpChannels.filter((ch: IMKPChannelDetail) => ch.is_active).map((ch) => ch.id)
    }
    // @ts-ignore
    // this.setState(newState)
    this._setCurrentFilter(newState)
  }

  // _renderFilterHasCreatedByMkpCh = () => {
  //   const { hasCreatedByMkpChannels, segmentHasCreatedByMkpChIndex = 0 } = this.state

  //   if (!hasCreatedByMkpChannels || hasCreatedByMkpChannels.length === 0) {
  //     return null
  //   }

  //   return (
  //     <VStack w='full' p='2' space='1' borderBottomWidth='1' borderColor='gray.200' bg='white'>
  //       <HStack alignItems='center' space='1'>
  //         {/* <XIcon variant='active' name='file-document-outline' family='MaterialCommunityIcons' /> */}
  //         <XImageIcon source={logoMkpChannel} />
  //         <XText variant='inactive'>ถูกสร้างอัตโนมัติโดยช่องทางขาย</XText>
  //       </HStack>

  //       <Segment
  //         selectedIndex={segmentHasCreatedByMkpChIndex}
  //         options={['ทั้งหมด', 'ไม่อยู่ใน', 'อยู่ใน']}
  //         onSegmentChange={this.onChangeSegmentHasCreatedByMkpChIndex}
  //       />

  //       {_.includes([1, 2], segmentHasCreatedByMkpChIndex) ? (
  //         <VStack w='full'>
  //           {segmentHasCreatedByMkpChIndex === 1 ? <XText variant='inactive'>ไม่แสดงช่องทางเหล่านี้...</XText> : null}
  //           {segmentHasCreatedByMkpChIndex === 2 ? <XText variant='inactive'>แสดงเฉพาะช่องทางเหล่านี้...</XText> : null}
  //           <HStack w='full' flexWrap='wrap'>
  //             <MKPChannelsActive channels={hasCreatedByMkpChannels} onPressChannel={this._onPressHasCreatedByMkpChMkpCh} />
  //           </HStack>
  //         </VStack>
  //       ) : null}
  //     </VStack>
  //   )
  // }

  onChangeSegmentHasParentIndex = (idx: number) => {
    const newState: Partial<IProductListFilterState> = {}
    newState.segmentHasParentIndex = idx
    if (_.includes([0, 1], idx)) {
      newState.segmentHasCreatedByMkpChIndex = 0
      newState.segmentHasCreatedByMkpChSpecificIndex = 0
    }
    // @ts-ignore
    // this.setState(newState)
    this._setCurrentFilter(newState)
  }

  _renderFilterHasParent = () => {
    const {
      segmentHasParentIndex = 0,
      segmentHasCreatedByMkpChIndex = 0,
      segmentHasCreatedByMkpChSpecificIndex = 0,
    } = this._getCurrentFilter()
    const { hasCreatedByMkpChannels } = this.state

    return (
      <VStack w='full' p='2' space='1' borderBottomWidth='1' borderColor='gray.200' bg='white'>
        <HStack alignItems='center' space='1'>
          <XIcon variant='active' name='note-add' family='MaterialIcons' />
          <XText variant='inactive'>สร้างโดย</XText>
        </HStack>

        <Segment
          selectedIndex={segmentHasParentIndex}
          options={['ทั้งหมด', 'ดึงจากร้านอื่น', 'ร้านฉัน']}
          onSegmentChange={this.onChangeSegmentHasParentIndex}
        />

        {segmentHasParentIndex === 2 ? (
          <VStack w='full' space='1'>
            <HStack alignItems='center' space='1'>
              <XIcon variant='active' name='file-document-outline' family='MaterialCommunityIcons' />
              <XText variant='inactive'>วิธีที่ถูกสร้าง</XText>
            </HStack>
            <Segment
              selectedIndex={segmentHasCreatedByMkpChIndex}
              options={['ทั้งหมด', 'สร้างเอง', 'ระบบสร้างให้']}
              onSegmentChange={this.onChangeSegmentHasCreatedByMkpChIndex}
            />
          </VStack>
        ) : null}

        {segmentHasCreatedByMkpChIndex === 2 && hasCreatedByMkpChannels && hasCreatedByMkpChannels.length > 0 ? (
          <VStack w='full' space='1'>
            <HStack alignItems='center' space='1'>
              <XImageIcon source={logoMkpChannel} />
              <XText variant='inactive'>ถูกสร้างอัตโนมัติจากการซิงค์ข้อมูลจากช่องทางขาย</XText>
            </HStack>

            <Segment
              selectedIndex={segmentHasCreatedByMkpChSpecificIndex}
              options={['ทั้งหมด', 'ระบุช่องทาง']}
              onSegmentChange={this.onChangeSegmentHasCreatedByMkpChSpecificIndex}
            />
          </VStack>
        ) : null}

        {segmentHasCreatedByMkpChSpecificIndex === 1 ? (
          <VStack w='full'>
            <XText variant='inactive'>แสดงเฉพาะช่องทางเหล่านี้...</XText>
            <HStack w='full' flexWrap='wrap'>
              <MKPChannelsActive channels={hasCreatedByMkpChannels} onPressChannel={this._onPressHasCreatedByMkpChMkpCh} />
            </HStack>
          </VStack>
        ) : null}
      </VStack>
    )
  }

  _getSelectedCategory = () => {
    const { categoryList } = this.state
    const { selectedCategoryId } = this._getCurrentFilter()
    if (_.isNil(selectedCategoryId)) {
      return null
    }

    const foundCat = categoryList.find((cat: ICategory) => cat.id === selectedCategoryId)
    return foundCat
  }

  _getSelectedCategoryBreadcrumbName = () => {
    const { categoryList } = this.state
    const selectedCategory = this._getSelectedCategory()

    let selectedCatName = selectedCategory.n

    let focusCatId = selectedCategory.p
    let isMoreParent = _.isNumber(selectedCategory.p)
    while (isMoreParent) {
      const foundCat = categoryList.find((cat: ICategory) => cat.id === focusCatId)
      if (foundCat) {
        selectedCatName = `${foundCat.n} > ${selectedCatName}`
        focusCatId = foundCat.p
        isMoreParent = _.isNumber(foundCat.p)
      } else {
        isMoreParent = false
      }
    }

    return selectedCatName
  }

  _renderCategoryCard = () => {
    // const { categoryList } = this.props
    // const { levelCategoryList, allChildren, categoryList } = this.state
    const { categoryList } = this.state
    const { isSubCategoryIncluded = false } = this._getCurrentFilter()

    // console.log('categoryList', categoryList)
    // console.log('categoryList.toJS()', categoryList.toJS())
    // if (_.isNil(levelCategoryList)) {
    //   return null
    // }
    // if (_.isNil(categoryList) || categoryList.size === 0) {
    //   return null
    // }

    if (_.isNil(categoryList) || categoryList.length === 0) {
      return null
    }

    const selectedCategory = this._getSelectedCategory()
    // console.log('levelCategoryList', levelCategoryList)
    return (
      <VStack w='full' p='2' space='1' borderBottomWidth='1' borderColor='gray.200' bg='white'>
        <HStack alignItems='center' space='1'>
          <XIcon variant='active' family='MaterialCommunityIcons' name='view-grid' />
          <XText variant='inactive'>หมวดหมู่</XText>
        </HStack>

        {selectedCategory ? (
          <HStack w='full' flexWrap='wrap'>
            <TouchableOpacity
              onPress={() => this._setCurrentFilter({ selectedCategoryId: null })}
              style={{
                height: 25,
                borderColor: selectedCategory.l,
                borderWidth: 0.7,
                borderRadius: 6,
                // marginLeft,
                paddingLeft: 8,
                marginTop: 10,
                justifyContent: 'center',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Icon name='check' type='AntDesign' style={{ color: COLORS.APP_MAIN, minWidth: 25, fontSize: 20, width: 25 }} />
              <XText
                style={{
                  color: selectedCategory.l ? selectedCategory.l : COLORS.TEXT_INACTIVE,
                  textAlign: 'center',
                  paddingHorizontal: 2,
                  paddingRight: 6,
                }}
                numberOfLines={1}>
                {this._getSelectedCategoryBreadcrumbName()}
              </XText>
            </TouchableOpacity>
          </HStack>
        ) : null}

        <HStack w='full' flexWrap='wrap'>
          {categoryList.map(this._renderProductCategoryButton)}
        </HStack>

        {selectedCategory && selectedCategory.c.length > 0 ? (
          <TouchableOpacity
            // style={{ width: 130, justifyContent: 'center', flexDirection: 'row', alignItems: 'center', minHeight: 34 }}
            onPress={this._setAllChildrenCategory}>
            <HStack alignItems='center' space='1'>
              {isSubCategoryIncluded ? (
                <XIcon family='FontAwesome' name='check-square-o' />
              ) : (
                <XIcon family='FontAwesome' name='square-o' />
              )}
              <XText>รวมหมวดหมู่ย่อย</XText>
            </HStack>
          </TouchableOpacity>
        ) : null}
      </VStack>
    )
  }

  onChangeSegmentCreatedAtIndex = (idx) => {
    const newState: Partial<IProductListFilterState> = {}
    newState.segmentCreatedAtIndex = idx
    if (idx === 0) {
      newState.createdAtRangeOptionKey = null
      newState.createdAtFrom = null
      newState.createdAtTo = null
    }
    if (idx === 1) {
      newState.createdAtRangeOptionKey = CONS.DATERANGE_SELECTOR.OPTION.Today
      const dateRange = util.getDateRangeFromOption(newState.createdAtRangeOptionKey)
      newState.createdAtFrom = dateRange.begin
      newState.createdAtTo = dateRange.end
    }
    // @ts-ignore
    // this.setState(newState)
    this._setCurrentFilter(newState)
  }

  onChangeDateCreatedAtFromTo = async (params: IXDateRangeSelectorChangeParams) => {
    const { dates, option, optionKey } = params
    const newState: Partial<IProductListFilterState> = {}
    newState.createdAtFrom = dates.begin
    newState.createdAtTo = util.changeSecondTime(dates.end, 59)
    newState.createdAtRangeOptionKey = optionKey
    // await util.setStatePromise(this, newState)
    await this._setCurrentFilter(newState)
  }

  _renderFilterCreatedAt = () => {
    const { segmentCreatedAtIndex = 0, createdAtFrom, createdAtTo, createdAtRangeOptionKey } = this._getCurrentFilter()

    const createdAtDateRange = { begin: createdAtFrom, end: createdAtTo }

    return (
      <VStack w='full' p='2' space='1' borderBottomWidth='1' borderColor='gray.200' bg='white'>
        <HStack alignItems='center' space='1'>
          <XIcon variant='active' name='back-in-time' family='Entypo' />
          <XText variant='inactive'>สร้างภายใน</XText>
        </HStack>
        <Segment selectedIndex={segmentCreatedAtIndex} options={['ทั้งหมด', 'ระบุ']} onSegmentChange={this.onChangeSegmentCreatedAtIndex} />
        {segmentCreatedAtIndex === 1 ? (
          <XDateRangeSelectorButton
            // allowDateRangeLimitDays={this.props.allowDateRangeLimitDays}
            // isOptionsScrollable={IS_SMALL_PHONE}
            isOptionsScrollable
            title='สร้างภายใน...'
            selectedDates={createdAtDateRange}
            selectedOptionKey={createdAtRangeOptionKey}
            onChange={this.onChangeDateCreatedAtFromTo}
            showTime
          />
        ) : null}
      </VStack>
    )
  }

  onChangeSegmentUpdatedAtIndex = (idx) => {
    const newState: Partial<IProductListFilterState> = {}
    newState.segmentUpdatedAtIndex = idx
    if (idx === 0) {
      newState.updatedAtRangeOptionKey = null
      newState.updatedAtFrom = null
      newState.updatedAtTo = null
    }
    if (idx === 1) {
      newState.updatedAtRangeOptionKey = CONS.DATERANGE_SELECTOR.OPTION.Today
      const dateRange = util.getDateRangeFromOption(newState.updatedAtRangeOptionKey)
      newState.updatedAtFrom = dateRange.begin
      newState.updatedAtTo = dateRange.end
    }
    // @ts-ignore
    // this.setState(newState)
    this._setCurrentFilter(newState)
  }

  onChangeDateUpdatedAtFromTo = async (params: IXDateRangeSelectorChangeParams) => {
    const { dates, option, optionKey } = params
    const newState: Partial<IProductListFilterState> = {}
    newState.updatedAtFrom = dates.begin
    newState.updatedAtTo = util.changeSecondTime(dates.end, 59)
    newState.updatedAtRangeOptionKey = optionKey
    // await util.setStatePromise(this, newState)
    await this._setCurrentFilter(newState)
  }

  _renderFilterUpdatedAt = () => {
    const { segmentUpdatedAtIndex = 0, updatedAtFrom, updatedAtTo, updatedAtRangeOptionKey } = this._getCurrentFilter()

    const updatedAtDateRange = { begin: updatedAtFrom, end: updatedAtTo }

    return (
      <VStack w='full' p='2' space='1' borderBottomWidth='1' borderColor='gray.200' bg='white'>
        <HStack alignItems='center' space='1'>
          <XIcon variant='active' name='back-in-time' family='Entypo' />
          <XText variant='inactive'>อัพเดทภายใน</XText>
        </HStack>
        <Segment selectedIndex={segmentUpdatedAtIndex} options={['ทั้งหมด', 'ระบุ']} onSegmentChange={this.onChangeSegmentUpdatedAtIndex} />
        {segmentUpdatedAtIndex === 1 ? (
          <XDateRangeSelectorButton
            // allowDateRangeLimitDays={this.props.allowDateRangeLimitDays}
            // isOptionsScrollable={IS_SMALL_PHONE}
            isOptionsScrollable
            title='อัพเดทภายใน...'
            selectedDates={updatedAtDateRange}
            selectedOptionKey={updatedAtRangeOptionKey}
            onChange={this.onChangeDateUpdatedAtFromTo}
            showTime
          />
        ) : null}
      </VStack>
    )
  }

  // _onPressMkpCh = (channels: IMKPChannelDetail[]) => {
  //   if (_.isNil(channels)) {
  //     this.setState({ mkpChIds: [] })
  //     return
  //   }

  //   const mkpChIds = []

  //   channels.forEach((ch: IMKPChannelDetail) => {
  //     if (ch.is_active) {
  //       mkpChIds.push(ch.id)
  //     }
  //   })

  //   this.setState({ mkpChIds })
  // }

  _onPressHasCreatedByMkpChMkpCh = (channels: IMKPChannelDetail[]) => {
    if (_.isNil(channels)) {
      // this.setState({ hasCreatedByMkpChMkpChIds: [] })
      this._setCurrentFilter({ hasCreatedByMkpChMkpChIds: [] })
      return
    }
    const hasCreatedByMkpChMkpChIds = []

    channels.forEach((ch: IMKPChannelDetail) => {
      if (ch.is_active) {
        hasCreatedByMkpChMkpChIds.push(ch.id)
      }
    })

    // this.setState({ hasCreatedByMkpChMkpChIds })
    this._setCurrentFilter({ hasCreatedByMkpChMkpChIds })
  }

  _renderCollapsibleContentWrapper = (content) => (
    <VStack w='full' p='2' borderBottomRadius='lg'>
      {content}
    </VStack>
  )

  _renderCollapsibleCard = ({ key, title, renderContent }) => {
    const isExpanded = _.isBoolean(this.state[`isExpanded_${key}`]) ? this.state[`isExpanded_${key}`] : true
    const colorScheme = 'gray'
    const styled = {
      borderWidth: '1',
      bg: `${colorScheme}.100`,
      borderLeftColor: `${colorScheme}.600`,
      borderTopColor: `${colorScheme}.600`,
      borderRightColor: `${colorScheme}.700`,
      borderBottomColor: `${colorScheme}.700`,
      borderTopLeftRadius: 'lg',
      borderTopRightRadius: 'lg',
      borderBottomLeftRadius: 'lg',
      borderBottomRightRadius: 'lg',
    }

    if (isExpanded) {
      styled.borderBottomLeftRadius = 'none'
      styled.borderBottomRightRadius = 'none'
    }

    return (
      <CollapsableCard
        disabledAnimation
        // disabled={props.disabled}
        expanded={isExpanded}
        onPressToggle={(nextExpanded) => {
          const nweState = {}
          nweState[`isExpanded_${key}`] = nextExpanded
          this.setState(nweState)
        }}
        contentContainerStyle={{ borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}
        renderContent={renderContent}>
        <HStack h='44px' w='full' p='2' alignItems='center' {...styled}>
          <HStack flex={1}>
            <XText variant='inactive' bold>
              {title}
            </XText>
          </HStack>

          <HStack w='38px' justifyContent='flex-end' alignItems='center'>
            <XIcon
              variant='inactive'
              size='xs'
              name={isExpanded ? 'arrow-collapse-up' : 'arrow-expand-down'}
              family='MaterialCommunityIcons'
            />
          </HStack>
        </HStack>
      </CollapsableCard>
    )
  }

  _renderGroupSortFilterContent = () => {
    const { sortTypeIndex = 0, sortByIndex = 2 } = this._getCurrentFilter()

    return (
      <VStack w='full' p='2' space='1' flexWrap='wrap'>
        <VStack w='full'>
          <XText variant='inactive'>เรียงจาก...</XText>
          <XSegment<IProductListSortType>
            // initialIndex={editingSortTypeIndex}
            // segmentContainerStyle={{ minWidth: 200 }}
            // title='เรียงจาก...'
            selectedIndex={sortTypeIndex}
            onSegmentChange={(opt, idx) => this._setCurrentFilter({ sortTypeIndex: idx })}
            options={sortByIndex === 0 ? CONS.PRODUCT_LIST_SORT_TYPE_OPTIONS_BY_NAME : CONS.PRODUCT_LIST_SORT_TYPE_OPTIONS}
          />
        </VStack>

        <VStack w='full' pt='2'>
          <XText variant='inactive'>เรียงโดย...</XText>
          <XRadioList
            // title='เรียงโดย...'
            selectedIndex={sortByIndex}
            options={CONS.PRODUCT_LIST_SORT_BY_OPTIONS}
            onRadioChange={(opt, idx) => this._setCurrentFilter({ sortByIndex: idx })}
          />
        </VStack>
      </VStack>
    )
  }

  _renderStockQtyOptionItem = (option, index) => {
    const { stockQtySelectedOptionKey } = this._getCurrentFilter()
    const isChecked = stockQtySelectedOptionKey === option.key
    return (
      // @ts-ignore
      <XCheckableItem
        key={option.key}
        label={option.label}
        checked={isChecked}
        onPress={() => this._setCurrentFilter({ stockQtySelectedOptionKey: option.key })}
      />
    )
  }

  _renderStockAvailableQtyOptionItem = (option, index) => {
    const { stockAvailableQtySelectedOptionKey } = this._getCurrentFilter()
    const isChecked = stockAvailableQtySelectedOptionKey === option.key
    return (
      <XCheckableItem
        key={option.key}
        label={option.label}
        checked={isChecked}
        // @ts-ignore
        onPress={() => this._setCurrentFilter({ stockAvailableQtySelectedOptionKey: option.key })}
      />
    )
  }

  _renderStockQtyOverdueOptionItem = (option, index) => {
    const { stockQtyOverdueSelectedOptionKey } = this._getCurrentFilter()
    const isChecked = stockQtyOverdueSelectedOptionKey === option.key
    return (
      <XCheckableItem
        key={option.key}
        label={option.label}
        checked={isChecked}
        // @ts-ignore
        onPress={() => this._setCurrentFilter({ stockQtyOverdueSelectedOptionKey: option.key })}
      />
    )
  }

  _renderGroupStockFilterContent = () => {
    const {
      stockQtyEnabled = false,
      stockAvailableQtyEnabled = false,
      stockQtyOverdueEnabled = false,
      stockQtySelectedOptionKey,
      stockAvailableQtySelectedOptionKey,
      stockQtyOverdueSelectedOptionKey,

      stockQtyNotMoveEnabled = false,
      stockQtyNotMoveMoreThan = 0,
      stockQtyValueEqual,
      stockQtyValueGreaterThan,
      stockQtyValueLessThanOrEqual,

      stockAvailableQtyNotMoveEnabled = false,
      stockAvailableQtyNotMoveMoreThan = 0,
      stockAvailableQtyValueEqual,
      stockAvailableQtyValueGreaterThan,
      stockAvailableQtyValueLessThanOrEqual,
      stockAvailableQtyValueLessThan,

      stockQtyOverdueValueGreaterThan,
      stockQtyOverdueValueEqual,
      stockQtyOverdueValueLessThanOrEqual,
    } = this._getCurrentFilter()
    return (
      <VStack w='full' p='2' space='1' flexWrap='wrap'>
        <XText>เลือกจาก...</XText>

        <HStack w='full' alignItems='center'>
          <XCheckbox
            checked={stockQtyEnabled}
            onPress={() =>
              this._setCurrentFilter({
                stockQtyEnabled: !stockQtyEnabled,
                stockQtySelectedOptionKey: stockQtyEnabled ? null : CONS.PRODUCT_LIST_FILTER_STOCK_QTY_OPTIONS[0].key,
                stockQtyNotMoveEnabled: false,
                stockQtyNotMoveMoreThan: 0,
                stockQtyValueEqual: 0,
                stockQtyValueGreaterThan: 0,
                stockQtyValueLessThanOrEqual: 10,
              })
            }
          />
          <XText>{p.op.t('qty')}</XText>
        </HStack>

        {stockQtyEnabled && (
          <VStack w='full' pl='6'>
            <HStack w='full' space='1.5' flexWrap='wrap'>
              {CONS.PRODUCT_LIST_FILTER_STOCK_QTY_OPTIONS.map(this._renderStockQtyOptionItem)}
            </HStack>

            {stockQtySelectedOptionKey === STOCK_ALMOST_EMPTY && (
              <HStack w='full' alignItems='center' space='1'>
                <XText>จำนวน น้อยกว่าหรือเท่ากับ</XText>
                <XNumericInput
                  w='40px'
                  value={stockQtyValueLessThanOrEqual}
                  InputLeftElement={null}
                  InputRightElement={null}
                  onChangeValue={(value) => this._setCurrentFilter({ stockQtyValueLessThanOrEqual: value })}
                />
              </HStack>
            )}

            {stockQtySelectedOptionKey === STOCK_EMPTY && (
              <HStack w='full' alignItems='center' space='1'>
                <XText>จำนวนเท่ากับ</XText>
                <XNumericInput
                  w='40px'
                  value={stockQtyValueEqual}
                  InputLeftElement={null}
                  InputRightElement={null}
                  onChangeValue={(value) => this._setCurrentFilter({ stockQtyValueEqual: value })}
                />
              </HStack>
            )}

            {stockQtySelectedOptionKey === STOCK_DEAD_STOCK && (
              <VStack w='full'>
                <HStack w='full' alignItems='center' space='1'>
                  <XText>จำนวน มากกว่า</XText>
                  <XInput w='40px' value='0' />
                </HStack>

                <HStack w='full' alignItems='center' space='1'>
                  <XCheckbox
                    checked={stockQtyNotMoveEnabled}
                    onPress={() =>
                      this._setCurrentFilter({
                        stockQtyNotMoveEnabled: !stockQtyNotMoveEnabled,
                        stockQtyNotMoveMoreThan: stockQtyNotMoveEnabled ? 0 : 180,
                      })
                    }
                  />
                  <XText>ไม่มีการเคลื่อนไหวมากกว่า</XText>
                  <XNumericInput
                    w='40px'
                    disabled={!stockQtyNotMoveEnabled}
                    isDisabled={!stockQtyNotMoveEnabled}
                    value={stockQtyNotMoveMoreThan}
                    InputLeftElement={null}
                    InputRightElement={null}
                    onChangeValue={(value) => this._setCurrentFilter({ stockQtyNotMoveMoreThan: value })}
                  />
                  <XText>วัน</XText>
                </HStack>
              </VStack>
            )}

            {stockQtySelectedOptionKey === STOCK_CUSTOM && (
              <VStack w='full' pl='6'>
                <HStack w='full' alignItems='center' space='1' flexWrap='wrap'>
                  <XText>จำนวน มากกว่า</XText>
                  <XNumericInput
                    w='40px'
                    value={stockQtyValueGreaterThan}
                    InputLeftElement={null}
                    InputRightElement={null}
                    onChangeValue={(value) => this._setCurrentFilter({ stockQtyValueGreaterThan: value })}
                  />
                  <XText>น้อยกว่าหรือเท่ากับ</XText>
                  <XNumericInput
                    w='40px'
                    value={stockQtyValueLessThanOrEqual}
                    InputLeftElement={null}
                    InputRightElement={null}
                    onChangeValue={(value) => this._setCurrentFilter({ stockQtyValueLessThanOrEqual: value })}
                  />
                </HStack>

                <HStack w='full' alignItems='center' space='1'>
                  <XCheckbox
                    checked={stockQtyNotMoveEnabled}
                    onPress={() =>
                      this._setCurrentFilter({
                        stockQtyNotMoveEnabled: !stockQtyNotMoveEnabled,
                        stockQtyNotMoveMoreThan: stockQtyNotMoveEnabled ? 0 : 30,
                      })
                    }
                  />
                  <XText>ไม่มีการเคลื่อนไหวมากกว่า</XText>
                  <XNumericInput
                    w='40px'
                    disabled={!stockQtyNotMoveEnabled}
                    isDisabled={!stockQtyNotMoveEnabled}
                    value={stockQtyNotMoveMoreThan}
                    InputLeftElement={null}
                    InputRightElement={null}
                    onChangeValue={(value) => this._setCurrentFilter({ stockQtyNotMoveMoreThan: value })}
                  />
                  <XText>วัน</XText>
                </HStack>
              </VStack>
            )}
          </VStack>
        )}

        <HStack w='full' alignItems='center'>
          <XCheckbox
            checked={stockAvailableQtyEnabled}
            onPress={() =>
              this._setCurrentFilter({
                stockAvailableQtyEnabled: !stockAvailableQtyEnabled,
                stockAvailableQtySelectedOptionKey: stockAvailableQtyEnabled
                  ? null
                  : CONS.PRODUCT_LIST_FILTER_STOCK_AVAILABLE_QTY_OPTIONS[0].key,
                stockAvailableQtyNotMoveEnabled: false,
                stockAvailableQtyNotMoveMoreThan: 0,
                stockAvailableQtyValueEqual: 0,
                stockAvailableQtyValueGreaterThan: 0,
                stockAvailableQtyValueLessThanOrEqual: 10,
                stockAvailableQtyValueLessThan: 0,
              })
            }
          />
          <XText>{p.op.t('available_qty')}</XText>
        </HStack>

        {stockAvailableQtyEnabled && (
          <VStack w='full' pl='6'>
            <HStack w='full' space='1.5' flexWrap='wrap'>
              {CONS.PRODUCT_LIST_FILTER_STOCK_AVAILABLE_QTY_OPTIONS.map(this._renderStockAvailableQtyOptionItem)}
            </HStack>

            {stockAvailableQtySelectedOptionKey === STOCK_ALMOST_EMPTY && (
              <HStack w='full' alignItems='center' space='1'>
                <XText>จำนวน น้อยกว่าหรือเท่ากับ</XText>
                <XNumericInput
                  w='40px'
                  value={stockAvailableQtyValueLessThanOrEqual}
                  InputLeftElement={null}
                  InputRightElement={null}
                  onChangeValue={(value) => this._setCurrentFilter({ stockAvailableQtyValueLessThanOrEqual: value })}
                />
              </HStack>
            )}

            {stockAvailableQtySelectedOptionKey === STOCK_EMPTY && (
              <HStack w='full' alignItems='center' space='1'>
                <XText>จำนวนเท่ากับ</XText>
                <XNumericInput
                  w='40px'
                  value={stockAvailableQtyValueEqual}
                  InputLeftElement={null}
                  InputRightElement={null}
                  onChangeValue={(value) => this._setCurrentFilter({ stockAvailableQtyValueEqual: value })}
                />
              </HStack>
            )}

            {stockAvailableQtySelectedOptionKey === STOCK_DEAD_STOCK && (
              <VStack w='full'>
                <HStack w='full' alignItems='center' space='1'>
                  <XText>จำนวน มากกว่า</XText>
                  <XNumericInput
                    w='40px'
                    value={stockAvailableQtyValueGreaterThan}
                    InputLeftElement={null}
                    InputRightElement={null}
                    onChangeValue={(value) => this._setCurrentFilter({ stockAvailableQtyValueGreaterThan: value })}
                  />
                </HStack>

                <HStack w='full' alignItems='center' space='1'>
                  <XCheckbox
                    checked={stockAvailableQtyNotMoveEnabled}
                    onPress={() =>
                      this._setCurrentFilter({
                        stockAvailableQtyNotMoveEnabled: !stockAvailableQtyNotMoveEnabled,
                        stockAvailableQtyNotMoveMoreThan: stockAvailableQtyNotMoveEnabled ? 0 : 180,
                      })
                    }
                  />
                  <XText>ไม่มีการเคลื่อนไหวมากกว่า</XText>
                  <XNumericInput
                    w='40px'
                    disabled={!stockAvailableQtyNotMoveEnabled}
                    isDisabled={!stockAvailableQtyNotMoveEnabled}
                    value={stockAvailableQtyNotMoveMoreThan}
                    InputLeftElement={null}
                    InputRightElement={null}
                    onChangeValue={(value) => this._setCurrentFilter({ stockAvailableQtyNotMoveMoreThan: value })}
                  />
                  <XText>วัน</XText>
                </HStack>
              </VStack>
            )}

            {stockAvailableQtySelectedOptionKey === STOCK_EXCEEDED_QTY && (
              <HStack w='full' alignItems='center' space='1'>
                <XText>จำนวน น้อยกว่า</XText>
                <XNumericInput
                  w='40px'
                  value={stockAvailableQtyValueLessThan}
                  InputLeftElement={null}
                  InputRightElement={null}
                  onChangeValue={(value) => this._setCurrentFilter({ stockAvailableQtyValueLessThan: value })}
                />
              </HStack>
            )}

            {stockAvailableQtySelectedOptionKey === STOCK_CUSTOM && (
              <VStack w='full' pl='6'>
                <HStack w='full' alignItems='center' space='1' flexWrap='wrap'>
                  <XText>จำนวน มากกว่า</XText>
                  <XNumericInput
                    w='40px'
                    value={stockAvailableQtyValueGreaterThan}
                    InputLeftElement={null}
                    InputRightElement={null}
                    onChangeValue={(value) => this._setCurrentFilter({ stockAvailableQtyValueGreaterThan: value })}
                  />
                  <XText>น้อยกว่าหรือเท่ากับ</XText>
                  <XNumericInput
                    w='40px'
                    value={stockAvailableQtyValueLessThanOrEqual}
                    InputLeftElement={null}
                    InputRightElement={null}
                    onChangeValue={(value) => this._setCurrentFilter({ stockAvailableQtyValueLessThanOrEqual: value })}
                  />
                </HStack>

                <HStack w='full' alignItems='center' space='1'>
                  <XCheckbox
                    checked={stockAvailableQtyNotMoveEnabled}
                    onPress={() =>
                      this._setCurrentFilter({
                        stockAvailableQtyNotMoveEnabled: !stockAvailableQtyNotMoveEnabled,
                        stockAvailableQtyNotMoveMoreThan: stockAvailableQtyNotMoveEnabled ? 0 : 30,
                      })
                    }
                  />
                  <XText>ไม่มีการเคลื่อนไหวมากกว่า</XText>
                  <XNumericInput
                    w='40px'
                    disabled={!stockAvailableQtyNotMoveEnabled}
                    isDisabled={!stockAvailableQtyNotMoveEnabled}
                    value={stockAvailableQtyNotMoveMoreThan}
                    InputLeftElement={null}
                    InputRightElement={null}
                    onChangeValue={(value) => this._setCurrentFilter({ stockAvailableQtyNotMoveMoreThan: value })}
                  />
                  <XText>วัน</XText>
                </HStack>
              </VStack>
            )}
          </VStack>
        )}

        <HStack w='full' alignItems='center'>
          <XCheckbox
            checked={stockQtyOverdueEnabled}
            onPress={() =>
              this._setCurrentFilter({
                stockQtyOverdueEnabled: !stockQtyOverdueEnabled,
                stockQtyOverdueSelectedOptionKey: stockQtyOverdueEnabled ? null : CONS.PRODUCT_LIST_FILTER_STOCK_QTY_OVERDUE_OPTIONS[0].key,
                stockQtyOverdueValueGreaterThan: 0,
                stockQtyOverdueValueEqual: 0,
                stockQtyOverdueValueLessThanOrEqual: 10,
              })
            }
          />
          <XText>ค้างส่ง</XText>
        </HStack>

        {stockQtyOverdueEnabled && (
          <VStack w='full' pl='6'>
            <HStack w='full' space='1.5' flexWrap='wrap'>
              {CONS.PRODUCT_LIST_FILTER_STOCK_QTY_OVERDUE_OPTIONS.map(this._renderStockQtyOverdueOptionItem)}
            </HStack>

            {stockQtyOverdueSelectedOptionKey === STOCK_HAVE_RESERVED && (
              <HStack w='full' alignItems='center' space='1'>
                <XText>จำนวน มากกว่า</XText>
                <XNumericInput
                  w='40px'
                  value={stockQtyOverdueValueGreaterThan}
                  InputLeftElement={null}
                  InputRightElement={null}
                  onChangeValue={(value) => this._setCurrentFilter({ stockQtyOverdueValueGreaterThan: value })}
                />
              </HStack>
            )}

            {stockQtyOverdueSelectedOptionKey === STOCK_NOT_HAVE_RESERVED && (
              <HStack w='full' alignItems='center' space='1'>
                <XText>จำนวน เท่ากับ</XText>
                <XNumericInput
                  w='40px'
                  value={stockQtyOverdueValueEqual}
                  InputLeftElement={null}
                  InputRightElement={null}
                  onChangeValue={(value) => this._setCurrentFilter({ stockQtyOverdueValueEqual: value })}
                />
              </HStack>
            )}

            {stockQtyOverdueSelectedOptionKey === STOCK_CUSTOM && (
              <HStack w='full' alignItems='center' space='1' flexWrap='wrap'>
                <XText>จำนวน มากกว่า</XText>
                <XNumericInput
                  w='40px'
                  value={stockQtyOverdueValueGreaterThan}
                  InputLeftElement={null}
                  InputRightElement={null}
                  onChangeValue={(value) => this._setCurrentFilter({ stockQtyOverdueValueGreaterThan: value })}
                />
                <XText>น้อยกว่าหรือเท่ากับ</XText>
                <XNumericInput
                  w='40px'
                  value={stockQtyOverdueValueLessThanOrEqual}
                  InputLeftElement={null}
                  InputRightElement={null}
                  onChangeValue={(value) => this._setCurrentFilter({ stockQtyOverdueValueLessThanOrEqual: value })}
                />
              </HStack>
            )}
          </VStack>
        )}
      </VStack>
    )
  }

  _renderCostFilterOptionItem = (option, index) => {
    const { costFilterSelectedOptionKey } = this._getCurrentFilter()
    const isChecked = costFilterSelectedOptionKey === option.key
    return (
      <XCheckableItem
        key={option.key}
        label={option.label}
        checked={isChecked}
        onPress={() => this._setCurrentFilter({ costFilterSelectedOptionKey: option.key })}
      />
    )
  }

  _renderGroupCostFilterContent = () => {
    const {
      costFilterEnabled = false,
      costFilterSelectedOptionKey,
      costFilterValueEqual,
      costFilterValueGreaterThan,
      costFilterValueLessThanOrEqual,
    } = this._getCurrentFilter()

    return (
      <VStack w='full' p='2' space='1' borderBottomWidth='1' borderColor='gray.200' bg='white'>
        <HStack w='full' alignItems='center'>
          <XCheckbox
            checked={costFilterEnabled}
            onPress={() =>
              this._setCurrentFilter({
                costFilterEnabled: !costFilterEnabled,
                costFilterSelectedOptionKey: costFilterEnabled ? null : CONS.PRODUCT_LIST_FILTER_COST_FILTER_OPTIONS[0].key,
                costFilterValueEqual: 0,
                costFilterValueGreaterThan: 0,
                costFilterValueLessThanOrEqual: 399,
              })
            }
          />
          <XText>ต้นทุน</XText>
        </HStack>

        {costFilterEnabled && (
          <VStack w='full' pl='6'>
            <HStack w='full' space='1.5' flexWrap='wrap'>
              {CONS.PRODUCT_LIST_FILTER_COST_FILTER_OPTIONS.map(this._renderCostFilterOptionItem)}
            </HStack>
          </VStack>
        )}

        {costFilterSelectedOptionKey === COST_FILTER_EQUAL && (
          <VStack w='full' pl='6'>
            <HStack w='full' alignItems='center' space='1' flexWrap='wrap'>
              <XText>ตุนทุน เท่ากับ</XText>
              <XNumericInput
                w='40px'
                value={costFilterValueEqual}
                InputLeftElement={null}
                InputRightElement={null}
                onChangeValue={(value) => this._setCurrentFilter({ costFilterValueEqual: value })}
              />
            </HStack>
          </VStack>
        )}

        {costFilterSelectedOptionKey === COST_FILTER_CUSTOM && (
          <VStack w='full' pl='6'>
            <HStack w='full' alignItems='center' space='1' flexWrap='wrap'>
              <XText>ต้นทุน มากกว่า</XText>
              <XNumericInput
                w='40px'
                value={costFilterValueGreaterThan}
                InputLeftElement={null}
                InputRightElement={null}
                onChangeValue={(value) => this._setCurrentFilter({ costFilterValueGreaterThan: value })}
              />
            </HStack>
            <HStack w='full' alignItems='center' space='1' flexWrap='wrap'>
              <XText>และ น้อยกว่าหรือเท่ากับ</XText>
              <XNumericInput
                w='40px'
                value={costFilterValueLessThanOrEqual}
                InputLeftElement={null}
                InputRightElement={null}
                onChangeValue={(value) => this._setCurrentFilter({ costFilterValueLessThanOrEqual: value })}
              />
            </HStack>
          </VStack>
        )}
      </VStack>
    )
  }

  _renderGroupCategoryFilterContent = () => <VStack w='full'>{this._renderCategoryCard()}</VStack>

  _renderGroupMkpFilterContent = () => (
    <VStack w='full'>
      {this._renderFilterHasParent()}
      {this._renderFilterWhichSystemIn()}
    </VStack>
  )

  _renderGroupDatetimeFilterContent = () => (
    <VStack w='full'>
      {this._renderFilterUpdatedAt()}
      {this._renderFilterCreatedAt()}
    </VStack>
  )

  _renderCollapsibleSortCard = () => {
    const isHidden = this.isHiddenSortFilterCard()
    if (isHidden) {
      return null
    }

    return this._renderCollapsibleCard({
      key: 'sort',
      title: 'การเรียงสินค้า',
      renderContent: this._renderGroupSortFilterContent,
    })
  }

  _renderCollapsibleStockCard = () => {
    const isHidden = this.isHiddenStockFilterCard()
    if (isHidden) {
      return null
    }

    return this._renderCollapsibleCard({
      key: 'stock',
      title: 'จำนวนสต๊อก',
      renderContent: this._renderGroupStockFilterContent,
    })
  }

  _renderCollapsibleCostCard = () => {
    const isHidden = this.isHiddenCostFilterCard()
    if (isHidden) {
      return null
    }

    return this._renderCollapsibleCard({
      key: 'cost',
      title: 'ต้นทุน',
      renderContent: this._renderGroupCostFilterContent,
    })
  }

  _renderCollapsibleDatetimeCard = () => {
    const isHidden = this.isHiddenDatetimeFilterCard()
    if (isHidden) {
      return null
    }

    return this._renderCollapsibleCard({
      key: 'datetime',
      title: 'อัพเดท',
      renderContent: this._renderGroupDatetimeFilterContent,
    })
  }

  _renderCollapsibleMkpCard = () => {
    const isHidden = this.isHiddenMkpFilterCard()
    if (isHidden) {
      return null
    }

    return this._renderCollapsibleCard({
      key: 'mkp',
      title: 'สถานะช่องทางภายนอก',
      renderContent: this._renderGroupMkpFilterContent,
    })
  }

  _renderCollapsibleCategoryCard = () => {
    const isHidden = this.isHiddenCategoryFilterCard()
    if (isHidden) {
      return null
    }

    return this._renderCollapsibleCard({
      key: 'category',
      title: 'หมวดหมู่',
      renderContent: this._renderGroupCategoryFilterContent,
    })
  }

  _renderMain = () => (
    <VStack w='full' px='2' py='1' space='1.5'>
      {this._renderCollapsibleSortCard()}
      {this._renderCollapsibleStockCard()}
      {this._renderCollapsibleCostCard()}
      {this._renderCollapsibleDatetimeCard()}
      {this._renderCollapsibleMkpCard()}
      {this._renderCollapsibleCategoryCard()}
    </VStack>
  )

  _renderFooter = () => (
    <HStack w='full' h='12' p='1' space='1' alignItems='center'>
      <XButton w='120px' variant='outline' onPress={this._clearAllSelected}>
        ล้าง
      </XButton>
      <XButton flex={1} onPress={this._onPressSubmitBtn}>
        กรอง
      </XButton>
    </HStack>
  )

  _goBack = () => {
    util.navGoBack(this.props)
  }

  // _renderHeader = () => <XCustomHeader title='กรอง' headerLeftProps={{ onPressItem: this._goBack, backIcon: true }} />
  _renderHeaderRight = () => null

  _getHeaderTitle = () => {
    const headerTitle = util.getNavParam(this.props, 'headerTitle', 'กรอง')
    return headerTitle
  }

  _renderHeader = () => {
    const headerTitle = this._getHeaderTitle()

    return (
      <HStack
        w='full'
        px='2'
        py='1'
        minH='44px'
        alignItems='center'
        justifyContent='center'
        space='1'
        borderBottomWidth='1'
        borderBottomColor='gray.300'>
        <HStack w='46px' alignItems='center'>
          <XIconButton name='arrow-back' onPress={this._goBack} />
        </HStack>
        <HStack flex={1} alignItems='center'>
          <XText w='full' variant='active' bold>
            {headerTitle}
          </XText>
        </HStack>
        {this._renderHeaderRight()}
      </HStack>
    )
  }

  render() {
    // const { disabled, children } = util.getNavParams(this.props)
    // const { isModalOpen } = this.state

    return (
      <XContainer>
        {this._renderHeader()}
        <XContent>{this._renderMain()}</XContent>
        {this._renderFooter()}
      </XContainer>
    )
  }
}

export default BaseUIProductListFilterView
