import React from 'react'

import _ from 'lodash'
// import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import p from 'x/config/platform-specific'
import BaseInAppPurchasePerPackageView from 'x/modules/subscription/BaseInAppPurchasePerPackageView'
import { COLORS } from 'x/config/styles'
import XCard from 'xui/components/XCard'
import XText from 'xui/components/XText'
import HStack from 'xui/components/HStack'
import VStack from 'xui/components/VStack'
import { ScaledSize, View, Dimensions, TouchableOpacity, ImageBackground } from 'react-native'

// import { withIAPContext } from 'react-native-iap'
// import { createStackNavigator } from '@react-navigation/stack'
import ProfilePackage from 'xui/components/ProfilePackage'
import PackageTag from 'xui/components/PackageTag'
import * as xFmt from 'x/utils/formatter'
import XRedDiscount from 'xui/components/XRedDiscount'
import XSpinner from 'xui/components/XSpinner'
// import XPointBurstShape from 'xui/components/XPointBurstShape'

const BRONZ_BG = require('../../img/packge_bg/bronz.png')
const SILVER_BG = require('../../img/packge_bg/silver.png')
const GOLD_BG = require('../../img/packge_bg/gold.png')
const PLATINUM_BG = require('../../img/packge_bg/platinum.png')

const PACKAGE_BG_IMG = [BRONZ_BG, SILVER_BG, GOLD_BG, PLATINUM_BG]

const window: ScaledSize = Dimensions.get('window')
// const PAGE_HIGHT = 900
const PAGE_HIGHT = p.op.isWeb() ? 900 : window.height
const PAGE_WIDTH = p.op.isWeb() ? 420 : window.width
// const ElementsText = {
//   AUTOPLAY: 'AutoPlay',
// }
const packageLabelList = ['Bronze', 'Silver', 'Gold', 'Platinum']

// const opacityStart = new Animated.Value(0)
// const opacityEnd = new Animated.Value(0)

// export const screens = [
//   {
//     name: 'Subscriptions',
//     title: 'Subscriptions',
//     component: withIAPContext(Subscriptions),
//     section: 'Context',
//     color: '#cebf38',
//   },
//   {
//     name: 'Home',
//     component: Home,
//     section: 'Context',
//     color: '#cebf38',
//   },
// ]

// const Stack = createStackNavigator()

// export const StackNavigator = () => {
//   return (
//     <Stack.Navigator screenOptions={{ title: 'MainlyPaleo Subscriptions' }}>
//       {screens.map(({ name, component, title }) => (
//         <Stack.Screen
//           key={name}
//           name={name}
//           component={component}
//           //hide the header on these screens
//           options={{
//             title: title,
//             headerShown: name === 'Home' || name === 'Subscriptions' ? false : true,
//           }}
//         />
//       ))}
//     </Stack.Navigator>
//   )
// }

export default abstract class BaseUIInAppPurchasePerPackageView extends BaseInAppPurchasePerPackageView {
  // abstract _purchase: (skuKey: string) => void
  _renderHeader = () => {
    const title = 'เลือกแพ็กเกจ'
    return (
      <XCustomHeader
        title={title}
        headerLeftProps={{ backIcon: true, onPressItem: () => this.goBack() }}
        // renderHeaderRight={this._renderHeaderRight}
      />
    )
  }

  _renderAbsolutePackageTag = (packageType: number) => (
    <View
      style={{
        position: 'absolute',
        left: 20,
        top: -16,
        zIndex: 70,
      }}>
      <PackageTag typeId={packageType} width={100} height={40} />
    </View>
  )

  _getPackageOneMonthPrice = (index: number): number => {
    const { subscriptionPackageList } = this.state
    // console.log('get subscriptionPackageList => ', subscriptionPackageList)
    if (_.isNil(subscriptionPackageList)) {
      return 0
    }
    const { items } = subscriptionPackageList
    const PACKAGE_SKU = ['PACKAGE-BRONZE-CYCLE-1', 'PACKAGE-SILVER-CYCLE-1', 'PACKAGE-GOLD-CYCLE-1', 'PACKAGE-PLATINUM-CYCLE-1']
    const sku = PACKAGE_SKU[index]
    let price = 0
    items.forEach((item) => {
      if (item.sku === sku) {
        price = item.price_with_vat
      }
    })
    return price
  }

  _getPackageOneYearPrice = (index: number): number => {
    const { subscriptionPackageList } = this.state
    // console.log('get subscriptionPackageList => ', subscriptionPackageList)
    if (_.isNil(subscriptionPackageList)) {
      return 0
    }
    const { items } = subscriptionPackageList
    const { PACKAGE_SKU } = this
    const sku = PACKAGE_SKU[index]
    let price = 0
    items.forEach((item) => {
      if (item.sku === sku) {
        price = item.price_with_vat
      }
    })
    return price
  }

  _renderPackage = (index: number) => {
    // return (
    //   <VStack bgColor={'amber.100'}>
    //     <XText>HI !!</XText>
    //   </VStack>
    // )
    // console.log('index => ', index)
    const price = this._getPackageOneMonthPrice(index)
    const priceYearly = this._getPackageOneYearPrice(index)
    const oneMonthPriceInOneYear = price * 12
    const differencePrice = oneMonthPriceInOneYear - priceYearly
    const discount = (differencePrice / oneMonthPriceInOneYear) * 100
    const averagePrice = priceYearly / 12
    // return null
    return (
      <VStack flex={1}>
        {this._renderAbsolutePackageTag(index + 2)}
        <XCard w='full' h={400} p='2' key={`_renderPackage${index}`} borderRadius={30}>
          {/* <HStack w='full' alignItems={'center'} justifyContent={'center'}>
            <XText bold fontSize={'2xl'}>
              {packName}
            </XText>
          </HStack> */}
          {/* <HStack mt='2' borderBottomColor={COLORS.BG_LIGHT_GREY} borderBottomWidth={'2'} /> */}
          <VStack w='full' alignItems='center' justifyContent='center'>
            {/* // รายเดือน */}
            <VStack
              alignItems='center'
              justifyContent='center'
              mt='8'
              pb='4'
              w='full'
              borderBottomColor={COLORS.TEXT_INACTIVE}
              borderBottomWidth='2'>
              <VStack alignItems='center'>
                <XText bold fontSize='lg'>
                  รายเดือน
                </XText>
                <HStack alignItems='center'>
                  <XText bold fontSize='2xl'>
                    {`${xFmt.formatCurrency(price)}`}
                  </XText>
                  <XText bold fontSize='lg'>
                    {' / เดือน'}
                  </XText>
                </HStack>
              </VStack>
              <TouchableOpacity
                onPress={() => null}
                style={{
                  backgroundColor: COLORS.WHITE,
                  borderColor: COLORS.APP_MAIN,
                  borderWidth: 1,
                  marginTop: 4,
                  borderRadius: 90,
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 180,
                }}>
                <XText my='4' bold fontSize='lg' color={COLORS.APP_MAIN}>
                  ซื้อแพ็กเกจ
                </XText>
              </TouchableOpacity>
            </VStack>

            {/* // รายปี */}
            <VStack alignItems='center' justifyContent='center' mt='2'>
              <VStack alignItems='center'>
                <XText bold fontSize='lg'>
                  รายปี
                </XText>
                <HStack alignItems='center'>
                  <XText bold fontSize='2xl'>
                    {`${xFmt.formatCurrency(priceYearly)}`}
                  </XText>
                  <XText bold fontSize='lg'>
                    {' / เดือน'}
                  </XText>
                </HStack>
              </VStack>
              <XText bold fontSize='lg' color={COLORS.RED}>
                {`(ประหยัดขึ้น ${Math.round(discount)}%)`}
              </XText>
              <XText bold variant='inactive'>
                {`เฉลี่ย ${xFmt.formatCurrency(averagePrice)}/เดือน`}
              </XText>
              <TouchableOpacity
                onPress={() => null}
                style={{
                  backgroundColor: COLORS.APP_MAIN,
                  marginTop: 4,
                  borderRadius: 90,
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 180,
                }}>
                <XText my='4' bold color='white' fontSize='lg'>
                  ซื้อแพ็กเกจ
                </XText>
              </TouchableOpacity>
              {/* <VStack mt='2'>
                <XText bold variant='inactive'>{`(เฉลี่ย ${averagePrice} บาท/เดือน)`}</XText>
              </VStack> */}
            </VStack>
          </VStack>
        </XCard>
      </VStack>
    )
  }

  _renderBackground = () => {
    const { openPackageIndex, contentHight } = this.state
    console.log('openPackageIndex => ', openPackageIndex)
    return (
      <View style={{ flex: 1, zIndex: 0, position: 'absolute', height: contentHight, left: 0, right: 0 }}>
        <ImageBackground source={PACKAGE_BG_IMG[openPackageIndex]} resizeMode='stretch' style={{ flex: 1 }} />
      </View>
    )
  }

  _renderOneMonthPrice = () => {
    const { subscription } = this.props
    const type = subscription.get('type')
    const dataJson = this._getItemPackage(type, '1')
    // console.log('dataJson => ', dataJson)
    if (_.isNil(dataJson)) {
      return null
    }
    const priceWithVat = dataJson.price_with_vat
    return (
      <XCard mt='2' mx={p.op.isIOS() ? '8' : '2'} py='2' w='80' alignItems='center' justifyContent='center'>
        <HStack w='full' alignItems='center' justifyContent='center'>
          <XText bold fontSize='lg'>
            รายเดือน
          </XText>
        </HStack>
        <HStack alignItems='center'>
          <XText bold fontSize='2xl'>
            {`${xFmt.formatCurrency(priceWithVat)}`}
          </XText>
          <XText bold fontSize='lg'>
            {' / เดือน'}
          </XText>
        </HStack>
        <TouchableOpacity
          onPress={() => this._onPressPackage('month')}
          style={{
            backgroundColor: COLORS.WHITE,
            borderColor: COLORS.APP_MAIN,
            borderWidth: 1,
            marginTop: 4,
            borderRadius: 90,
            alignItems: 'center',
            justifyContent: 'center',
            width: 180,
          }}>
          <XText my='4' bold fontSize='lg' color={COLORS.APP_MAIN}>
            ต่อแพ็กเกจ
          </XText>
        </TouchableOpacity>
      </XCard>
    )
  }

  _renderDiscountView = (value: number) => (
    <VStack style={{ position: 'absolute', top: 0, right: 0 }}>
      <XRedDiscount number={value} />
    </VStack>
  )

  _renderOneYearPrice = () => {
    const { subscription } = this.props
    const type = subscription.get('type')
    const dataJsonOneMonthPrice = this._getItemPackage(type, '1')
    const dataJsonOneYearPrice = this._getItemPackage(type, '12')
    // console.log('dataJson => ', dataJsonOneYearPrice)
    if (_.isNil(dataJsonOneYearPrice) || _.isNil(dataJsonOneMonthPrice)) {
      return null
    }
    const oneMonthPrice = dataJsonOneMonthPrice.price_with_vat
    const priceWithVat = dataJsonOneYearPrice.price_with_vat
    const oneMonthPriceInOneYear = oneMonthPrice * 12
    const differencePrice = oneMonthPriceInOneYear - priceWithVat
    const discount = (differencePrice / oneMonthPriceInOneYear) * 100
    const averagePrice = priceWithVat / 12
    // console.log('discount => ', Math.round(discount))
    return (
      <XCard m='2' py='2' w='80' alignItems='center' justifyContent='center'>
        {this._renderDiscountView(Math.round(discount))}
        <HStack w='full' alignItems='center' justifyContent='center'>
          <XText bold fontSize='lg'>
            รายปี
          </XText>
        </HStack>
        <HStack alignItems='center'>
          <XText bold fontSize='2xl'>
            {`${xFmt.formatCurrency(priceWithVat)}`}
          </XText>
          <XText bold fontSize='lg'>
            {' / ปี'}
          </XText>
        </HStack>
        <XText bold variant='inactive'>
          {`เฉลี่ย ${xFmt.formatCurrency(averagePrice)}/เดือน`}
        </XText>
        <TouchableOpacity
          onPress={() => this._onPressPackage('year')}
          style={{
            backgroundColor: COLORS.APP_MAIN,
            borderColor: COLORS.APP_MAIN,
            borderWidth: 1,
            marginTop: 4,
            borderRadius: 90,
            alignItems: 'center',
            justifyContent: 'center',
            width: 180,
          }}>
          <XText my='4' bold fontSize='lg' color={COLORS.WHITE}>
            ต่อแพ็กเกจ
          </XText>
        </TouchableOpacity>
      </XCard>
    )
  }

  _renderContent = () => {
    const {} = this.state
    return (
      <VStack alignItems='center' justifyContent='center'>
        <HStack alignItems='center' justifyContent='center' mt='2'>
          <XText fontSize='lg' bold>
            เลือกต่อระยะเวลาแพ็กเกจหลักของคุณ
          </XText>
        </HStack>
        {this._renderOneMonthPrice()}
        {this._renderOneYearPrice()}
      </VStack>
    )
  }

  _renderChangePackage = () => {
    const {} = this.state
    return (
      <XCard w='80' h='10' p='2' alignItems='center' justifyContent='center'>
        <VStack w='full' alignItems='center' justifyContent='center'>
          <XText
            color={COLORS.BRAND_Info}
            textDecorationLine='underline'
            textAlign='center'
            onPress={() => this._navToInAppPurchaseView('continue_package')}>
            เปลี่ยนแพ็กเกจ
          </XText>
        </VStack>
      </XCard>
    )
  }

  _renderMain = () => {
    const { subscriptionPackageList, contentHight, isLoading } = this.state
    if (_.isNil(subscriptionPackageList)) {
      return null
    }
    return (
      <VStack w='full' alignItems='center' h={contentHight}>
        {this._renderBackground()}
        {this._renderContent()}
        {this._renderChangePackage()}
        {isLoading ? (
          <VStack
            alignItems='center'
            justifyContent='center'
            bg='gray.400:alpha.50'
            style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
            <XSpinner />
          </VStack>
        ) : null}
      </VStack>
    )
  }

  _renderProfile = () => {
    const { subscription } = this.props
    const { profile } = this.state
    // console.log('profile // => ', profile)
    if (_.isNil(profile) || _.isNil(subscription)) {
      return null
    }
    const typeId: number = subscription.has('type') ? subscription.get('type') : 0
    // console.log('profile => ', profile)
    return <ProfilePackage profile={profile} typeId={typeId} />
  }

  render() {
    const { openPackageIndex, isLoading } = this.state
    return (
      <XContainer>
        {this._renderHeader()}
        {this._renderProfile()}
        <XContent
          color={COLORS.BG_SOFT_GREY}
          onLayout={(event) => {
            const { x, y, width, height } = event.nativeEvent.layout
            // console.log('height => ', height)
            this.setState({ contentHight: height })
            // util.setStatePromise(this, { loadingWidth: width, loadingHight: height })
          }}>
          {/* <ImageBackground
              source={PACKAGE_BG_IMG[openPackageIndex]}
              resizeMode='stretch'
              style={{ position: 'absolute', left: 0, top: 0, bottom: 0, right: 0 }}
            /> */}
          {this._renderMain()}
        </XContent>
      </XContainer>
    )
  }
}
