import React from 'react'
import _ from 'lodash'
import * as NavActions from 'x/utils/navigation'
import { IPackageDetails, ISubscriptionPackageList, IXScreenProps, SubscriptionPackageListItems } from 'x/types'
import * as util from 'x/utils/util'

interface IBaseNextPackageDetailProps extends IXScreenProps {
  profile: any
  subscription: any
  fetchSubscription: any
  hideSubscription: any
  navigation: any
}

interface IBaseNextPackageDetailState {
  subscriptionPackageList: ISubscriptionPackageList
  packageDetails: IPackageDetails
  nextPackageDetail: SubscriptionPackageListItems
  nextPackageBillingCycle: SubscriptionPackageListItems[]
  addOnVd: SubscriptionPackageListItems[]
  addOnUg: SubscriptionPackageListItems[]
}

export default abstract class BaseNextPackageDetail extends React.Component<IBaseNextPackageDetailProps, IBaseNextPackageDetailState> {
  // abstract async handleSubmitForm(response: any): Promise<void>

  constructor(props) {
    super(props)
    // @ts-ignore
    this.state = {
      subscriptionPackageList: null,
      packageDetails: null,
      nextPackageDetail: null,
      nextPackageBillingCycle: null,
      addOnVd: null,
      addOnUg: null,
    }
    // this.webview = React.createRef()
    // this.imgManagerIDRef = React.createRef()
  }

  async componentDidMount() {
    const { subscription } = this.props
    const params = util.getNavParams(this.props)
    // console.log(`params `, params)
    const { subscriptionPackageList } = params
    // console.log('subscriptionPackageList => ', subscriptionPackageList)
    const nextPackageDetail = util.getSubscriptionNextLastBillingCycle(subscription)

    await util.setStatePromise(this, {
      subscriptionPackageList,
      nextPackageDetail,
    })
    await this._setNextPackageBillingCycle()
    // this._setAddOnVd()
    // this._setAddOnUg()
  }

  _setNextPackageBillingCycle = async () => {
    const { subscription } = this.props
    const nextPackageBillingCycle = subscription.has('next_package_billing_cycle')
      ? subscription.get('next_package_billing_cycle').toJS()
      : null

    if (_.isNil(nextPackageBillingCycle)) {
      return
    }

    // const newNextPackageBillingCycle = []
    // @ts-ignore
    const newNextPackageBillingCycle = nextPackageBillingCycle.sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
    // console.log('newNextPackageBillingCycle => ', newNextPackageBillingCycle)
    await util.setStatePromise(this, {
      nextPackageBillingCycle: newNextPackageBillingCycle,
    })
  }

  _setAddOnVd = () => {
    const { subscription } = this.props
    const nextPackageBillingCycle = subscription.has('next_package_billing_cycle')
      ? subscription.get('next_package_billing_cycle').toJS()
      : null
    if (_.isNil(nextPackageBillingCycle)) {
      return
    }
    const vds = []
    nextPackageBillingCycle.forEach((addOn) => {
      if (addOn.sku.includes('ADDON-QUOTA-VD')) {
        vds.push(addOn)
      }
    })
    if (vds.length > 0) {
      // eslint-disable-next-line react/no-unused-state
      this.setState({ addOnVd: vds })
    }
  }

  _setAddOnUg = () => {
    const { subscription } = this.props
    const nextPackageBillingCycle = subscription.has('next_package_billing_cycle')
      ? subscription.get('next_package_billing_cycle').toJS()
      : null
    if (_.isNil(nextPackageBillingCycle)) {
      return
    }
    const ugs = []
    nextPackageBillingCycle.forEach((addOn) => {
      if (addOn.sku.includes('ADDON-QUOTA-UG')) {
        ugs.push(addOn)
      }
    })
    if (ugs.length > 0) {
      // eslint-disable-next-line react/no-unused-state
      this.setState({ addOnUg: ugs })
    }
  }

  _navToInAppPurchaseView = (mode: 'buy_package' | 'continue_package' | 'upgrade') => {
    const { subscriptionPackageList } = this.state
    const { navigation } = this.props
    navigation.dispatch(
      NavActions.navToInAppPurchaseView({
        subscriptionPackageList,
        mode,
        callBackSucceedPaymentIos: () => this.callBackSucceedPaymentIos(),
        succeedPaymentCallBack: () => this.callBackSucceedPayment(),
      })
    )
  }

  callBackSucceedPaymentIos = () => {
    const params = util.getNavParams(this.props)
    // console.log(`params `, params)
    const { callBackSucceedPaymentIos } = params
    if (_.isFunction(callBackSucceedPaymentIos)) {
      callBackSucceedPaymentIos()
    }
  }

  callBackSucceedPayment = () => {
    const params = util.getNavParams(this.props)
    // console.log(`params `, params)
    const { succeedPaymentCallBack } = params
    if (_.isFunction(succeedPaymentCallBack)) {
      succeedPaymentCallBack()
    }
  }

  _navToInAppPurchasePerPackageView = () => {
    const { navigation } = this.props
    const { subscriptionPackageList } = this.state
    const canPerPackage = util.PerPackageCheck(subscriptionPackageList)
    // console.log('canPerPackage => ', canPerPackage)
    if (!canPerPackage) {
      return
    }
    navigation.dispatch(
      NavActions.navToInAppPurchasePerPackageView({
        subscriptionPackageList,
        callBackSucceedPaymentIos: () => this.callBackSucceedPaymentIos(),
        popView: 3,
      })
    )
    // this._navToInAppPurchaseView('continue_package')
  }
}
