/* eslint-disable @typescript-eslint/no-misused-promises */
// import EntypoIcon from 'react-native-vector-icons/Entypo'
import p from 'x/config/platform-specific'
import BaseUIInAppPurchaseView from 'xui/modules/subscription/BaseUIInAppPurchaseView'
// import { Subscriptions } from 'xui/modules/subscription/Subscriptions'
import { ScaledSize, Dimensions } from 'react-native'

const BLUE_TAG = require('../../xui/x/img/discount/tag_discount.png')

// export const screens = [
//   {
//     name: 'Subscriptions',
//     title: 'Subscriptions',
//     component: withIAPContext(Subscriptions),
//     section: 'Context',
//     color: '#cebf38',
//   },
//   {
//     name: 'Home',
//     component: Home,
//     section: 'Context',
//     color: '#cebf38',
//   },
// ]

// const Stack = createStackNavigator()

// export const StackNavigator = () => {
//   return (
//     <Stack.Navigator screenOptions={{ title: 'MainlyPaleo Subscriptions' }}>
//       {screens.map(({ name, component, title }) => (
//         <Stack.Screen
//           key={name}
//           name={name}
//           component={component}
//           //hide the header on these screens
//           options={{
//             title: title,
//             headerShown: name === 'Home' || name === 'Subscriptions' ? false : true,
//           }}
//         />
//       ))}
//     </Stack.Navigator>
//   )
// }

// const errorLog = ({ message, error }: { message: string; error: unknown }) => {
//   console.error('An error happened', message, error)
// }

const window: ScaledSize = p.op.isWeb()
  ? {
      ...Dimensions.get('window'),
      width: 700,
    }
  : Dimensions.get('window')

// const PAGE_HIGHT = window.height
const PAGE_WIDTH = window.width

const packageLabelList = ['Bronze', 'Silver', 'Gold', 'Platinum']

export default class InAppPurchaseView extends BaseUIInAppPurchaseView {
  static displayName = 'InAppPurchaseView'

  _fetchSubcriptionIap = async () => {
    // console.log('_fetchSubcriptionIap =>')
  }

  _purchase = async (index: number, type: 'month' | 'year') => {
    // this._nextCarouselPage()
  }

  _subscribe = async (sku: string) => {
    //
  }

  _endConnection = () => {
    // endConnection()
  }

  _renderCarousel = () => null
}
