/* eslint-disable react/no-unused-state */
import React from 'react'
import { IPackageDetails, IProfile, IProfileMap, ISubscriptionPackageList, SubscriptionPackageListItems } from 'x/index'
import * as util from 'x/utils/util'
import _ from 'lodash'
import p from 'x/config/platform-specific'
import api from 'x/utils/api'
import CONS from 'x/config/constants'
import * as NavActions from 'x/utils/navigation'
import { Alert, EmitterSubscription } from 'react-native'

interface IBaseInAppPurchasePerPackageViewProps {
  profile: IProfileMap
  subscription: any
  navigation: any
}

interface IBaseInAppPurchasePerPackageViewState {
  openPackageIndex: number

  subscriptionPackageList: ISubscriptionPackageList

  receipt: string
  // productList: (Product | Subscription)[]
  productList: any
  availableItemsMessage: string

  profile: IProfile
  packageDetails: IPackageDetails

  contentHight: number

  isLoading: boolean
}

export default abstract class BaseInAppPurchasePerPackageView extends React.Component<
  IBaseInAppPurchasePerPackageViewProps,
  IBaseInAppPurchasePerPackageViewState
> {
  abstract _fetchSubcriptionIap: (packageData: SubscriptionPackageListItems) => void

  abstract _endConnection: () => void

  purchaseUpdate: EmitterSubscription

  purchaseError: EmitterSubscription

  promotedProduct: EmitterSubscription

  PACKAGE_SKU: string[]

  inProcess: boolean

  constructor(props) {
    super(props)
    this.state = {
      openPackageIndex: 0,
      receipt: '',
      productList: [],
      availableItemsMessage: '',
      subscriptionPackageList: null,
      profile: null,
      packageDetails: null,
      contentHight: 0,
      isLoading: true,
    }
    this.purchaseUpdate = null
    this.purchaseError = null
    this.promotedProduct = null
    // this.carouselRef = React.createRef()
    this.PACKAGE_SKU = ['PACKAGE-BRONZE-CYCLE-12', 'PACKAGE-SILVER-CYCLE-12', 'PACKAGE-GOLD-CYCLE-12', 'PACKAGE-PLATINUM-CYCLE-12']
    this.inProcess = false
  }

  async componentDidMount() {
    // const params = this._getParams()
    // const {} = params
    await this._setProfile()
    await this._fetchSubscriptionPackageList()
    // await util.delay(1000)
  }

  componentWillUnmount() {
    this._endConnection()
  }

  _getCountPackageByKey = (keyInput: string) => {
    const { openPackageIndex, subscriptionPackageList } = this.state

    if (_.isNil(subscriptionPackageList)) {
      return '-'
    }

    const { items } = subscriptionPackageList
    const { PACKAGE_SKU } = this
    const sku = PACKAGE_SKU[openPackageIndex]
    let dataJson = null
    items.forEach((item) => {
      if (item.sku === sku) {
        dataJson = item.data_json
      }
    })
    // console.log('dataJson => ', dataJson)
    if (_.isNil(dataJson)) {
      return '-'
    }
    return dataJson[keyInput]
  }

  _setProfile = async () => {
    const { profile, subscription } = this.props
    // console.log('subscription => ', subscription.toJS())
    const profileJS = _.isNil(profile) ? null : profile.toJS()
    await util.setStatePromise(this, {
      profile: profileJS,
      openPackageIndex: subscription.get('type') - 2,
    })
  }

  _fetchSubscriptionPackageList = async () => {
    const apiOptions = {
      showSpinner: true,
    }
    let PURCHASABLES_ID = CONS.PURCHASABLES_ID.SCB
    if (p.op.isIOS()) {
      PURCHASABLES_ID = CONS.PURCHASABLES_ID.IOS
    }
    const body = {
      lang: 'th',
      purchase_channel_id: PURCHASABLES_ID,
    }
    try {
      const res = await api.postV2(api.POST_SUBSCRIPTION_PACKAGE_LIST, body, apiOptions)
      // console.log('_fetchSubscriptionPackageList res => ', res)
      await util.setStatePromise(this, {
        subscriptionPackageList: res,
        isLoading: false,
      })
      // if (!res || _.isEmpty(res)) {
      // }
    } catch (err) {}
  }

  _onPressPackage = async (selectedPackageType: 'month' | 'year') => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    this.setState({ isLoading: true })
    const { subscription } = this.props
    const { subscriptionPackageList } = this.state
    if (_.isNil(subscriptionPackageList)) {
      this.inProcess = false
      this.setState({ isLoading: false })
      return
    }
    const { items } = subscriptionPackageList
    const packageCycle = selectedPackageType === 'month' ? 1 : 12
    const subscriptionType = subscription.get('type')
    const packageName = util.getSubscriptionTypeName(subscriptionType)
    let packageData = null
    items.forEach((item) => {
      if (item.item_subtype === packageName && item.is_main_package && item.billing_cycle === packageCycle) {
        packageData = item
      }
    })

    const subcriptionOrderValidate = await util.orderSubcriptionOrderValidate(packageData)
    if (subcriptionOrderValidate === 'ok') {
      this._fetchSubcriptionIap(packageData)
      return
    }
    this.inProcess = false
    this.setState({ isLoading: false })
  }

  goNext = () => {
    Alert.alert('Receipt', this.state.receipt)
  }

  _getParams = () => {
    // @ts-ignore
    const params = util.getNavParams(this.props)
    return params
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  goBack = () => {
    // @ts-ignore
    util.navGoBack(this.props)
  }

  // _setAutoPlay = (newAutoPlay: boolean) => {
  //   this.setState({ autoPlay: newAutoPlay })
  // }

  // _setIsVertical = (newVertical: boolean) => {
  //   this.setState({ isVertical: newVertical })
  // }

  // _setPagingEnabled = (newPagingEnabled: boolean) => {
  //   this.setState({ pagingEnabled: newPagingEnabled })
  // }

  // _setSnapEnabled = (newSnapEnabled: boolean) => {
  //   this.setState({ snapEnabled: newSnapEnabled })
  // }

  _getItemPackage = (subscriptionType: number, month: string): SubscriptionPackageListItems => {
    const { subscriptionPackageList } = this.state
    let packageSku = `PACKAGE-BRONZE-CYCLE-${month}`
    if (subscriptionType === 3) {
      packageSku = `PACKAGE-SILVER-CYCLE-${month}`
    }
    if (subscriptionType === 4) {
      packageSku = `PACKAGE-GOLD-CYCLE-${month}`
    }
    if (subscriptionType === 5) {
      packageSku = `PACKAGE-PLATINUM-CYCLE-${month}`
    }
    if (_.isNil(subscriptionPackageList)) {
      return null
    }
    const { items } = subscriptionPackageList
    let SelectedPackage = null

    items.forEach((itam) => {
      if (itam.sku === packageSku) {
        SelectedPackage = itam
      }
    })

    if (_.isNil(SelectedPackage)) {
      return null
    }

    return SelectedPackage
  }

  _navToInAppPurchaseView = (mode: 'continue_package' | 'upgrade') => {
    const { subscriptionPackageList } = this.state
    const { navigation } = this.props
    const params = this._getParams()
    const { callBackSucceedPaymentIos, popView, succeedPaymentCallBack } = params
    navigation.dispatch(
      NavActions.navToInAppPurchaseView({
        subscriptionPackageList,
        mode,
        popView: _.isNil(popView) ? 2 : popView,
        callBackSucceedPaymentIos: () => {
          if (_.isFunction(callBackSucceedPaymentIos)) {
            callBackSucceedPaymentIos()
          }
          // this.goBack()
        },
        succeedPaymentCallBack: () => {
          if (_.isFunction(succeedPaymentCallBack)) {
            succeedPaymentCallBack()
          }
          // this.goBack()
        },
      })
    )
  }
}
