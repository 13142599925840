import React from 'react'

// import EntypoIcon from 'react-native-vector-icons/Entypo'

// import BackIcon from '../../components/BackIcon'
// import XTabNavIcon from '../../components/XTabNavIcon'
// import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import _ from 'lodash'
import * as xFmt from 'x/utils/formatter'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
// import SRowItem from '../../components/renderRowLeftAndRight'
import BaseSelectedAddOnListView from 'x/modules/subscription/BaseSelectedAddOnListView'
import { COLORS } from 'x/config/styles'
import VStack from 'xui/components/VStack'
import { TouchableOpacity } from 'react-native'
import XText from 'xui/components/XText'
import XCard from 'xui/components/XCard'
import HStack from 'xui/components/HStack'
import XIcon from 'xui/components/XIcon'

export default abstract class BaseUISelectedAddOnListView extends BaseSelectedAddOnListView {
  _renderHeader = () => {
    const title = 'เลือกแพ็กเสริม'
    return (
      <XCustomHeader
        title={title}
        headerLeftProps={{ backIcon: true, onPressItem: () => this.goBack() }}
        // renderHeaderRight={this._renderHeaderRight}
      />
    )
  }

  _renderBody = () => {
    const { addOnList } = this.state
    if (!addOnList) {
      return null
    }
    // console.log('addOnList => ', addOnList)
    return (
      <XCard p='2' w='full'>
        <VStack space='2' w='full'>
          <XText variant='active' bold>
            เลือกแพ็กเสริม
          </XText>
          {addOnList.map((item) => {
            const { name, sku, price_with_vat } = item
            const textLable = _.isNil(sku) ? 'ไม่เพิ่มแพ็กเสริม' : name
            const priceWithVatText = _.isNil(sku) ? '' : ` (${xFmt.formatCurrency(price_with_vat)}/รอบบิล)`
            return (
              <TouchableOpacity
                key={item.sku}
                onPress={() => this._onPressSelectedAddOn(item)}
                style={{ borderRadius: 90, borderWidth: 1, borderColor: COLORS.APP_MAIN, alignItems: 'center', justifyContent: 'center' }}>
                <HStack w='full'>
                  <HStack flex={1} alignItems='center' justifyContent='center'>
                    <XText variant='active' py='2'>
                      {textLable}
                    </XText>
                    <XText variant='inactive' py='2'>
                      {priceWithVatText}
                    </XText>
                  </HStack>
                  <HStack justifyContent='flex-end' alignItems='center' w='10' mr='2'>
                    <XIcon name='arrowright' family='AntDesign' />
                  </HStack>
                </HStack>
              </TouchableOpacity>
            )
          })}
        </VStack>
      </XCard>
    )
  }

  render() {
    return (
      <XContainer>
        {this._renderHeader()}
        <XContent p='1' style={{ backgroundColor: COLORS.BG_LIGHT_GREY }}>
          {this._renderBody()}
        </XContent>
        {/* {this._renderFooter()} */}
      </XContainer>
    )
  }
}
