import React from 'react'
import { ImageSourcePropType, TouchableOpacity } from 'react-native'
import * as util from 'x/utils/util'
import _ from 'lodash'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContent from 'xui/components/XContent'
import XContainer from 'xui/components/XContainer'
import BaseStoreSettingSpxView from 'x/modules/xshipping/BaseStoreSettingSpxView'
import XCard from 'xui/components/XCard'
import XText from 'xui/components/XText'
import XInput from 'xui/components/XInput'
import VStack from 'xui/components/VStack'
import { COLORS } from 'x/config/styles'
import HStack from 'xui/components/HStack'
import XHelpModal from 'xui/components/XHelpModal'
import FAQ from 'x/config/FAQ'

const IMG_SPX: ImageSourcePropType = require('../../images/courier/spx.png')
const IMG_SHIPJUNG: ImageSourcePropType = require('../../images/courier/shipjung.png')

export default abstract class BaseUIStoreSettingSpxView extends BaseStoreSettingSpxView {
  static displayName = 'BaseUIStoreSettingSpxView'

  // abstract goBack: () => void
  // abstract renderWebView: () => void

  _renderCustomHeader = () => (
    <XCustomHeader
      headerLeftProps={{
        backIcon: true,
        onPressItem: () => {
          util.navGoBack(this.props)
        },
      }}
      title='เชื่อมต่อ SPX Express'
    />
  )

  _renderSpxNotActive = () => {
    const { userId, userSecret } = this.state

    const renderAgreement = (onPressOpenOverlay: () => void) => (
      <TouchableOpacity
        onPress={() => onPressOpenOverlay()}
        style={{ width: 250, alignItems: 'center', justifyContent: 'center', alignSelf: 'center' }}>
        <XText variant='inactive' textDecorationLine='underline'>
          วิธีการสร้าง User ID และ Secret Key
        </XText>
      </TouchableOpacity>
    )
    return (
      <VStack p='2'>
        <XText>กรุณาระบุรหัสจาก User ID จาก SPX Express</XText>
        <XInput placeholder='User ID' value={userId} onChangeText={(newText) => this.setState({ userId: newText })} />
        <HStack mt='2' />
        <XText>กรุณาระบุรหัสจาก Secret Key จาก SPX Express</XText>
        <XInput placeholder='Secret Key' value={userSecret} onChangeText={(newText) => this.setState({ userSecret: newText })} />
        <TouchableOpacity
          onPress={() => {
            this._createAccount().catch((error) => {
              console.log('Error creating account:', error)
            })
          }}
          style={{
            backgroundColor: COLORS.APP_MAIN,
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            borderRadius: 90,
            marginTop: 8,
            width: 160,
          }}>
          <XText color={COLORS.WHITE} py='2'>
            ดำเนินการเชื่อมต่อ
          </XText>
        </TouchableOpacity>
        <HStack mt='8' />
        <XHelpModal
          key='XHelpModalSpx'
          headerTitle='ข้อตกลงและวิธีการใช้งาน'
          FAQ={FAQ.XSHIPPING_SPX_AGREEMENT}
          initiateOpenIndex={[0, 1, 2, 4]}
          renderButton={(onPressOpenOverlay: () => void) => renderAgreement(onPressOpenOverlay)}
        />
      </VStack>
    )
  }

  _renderSpxActive = () => {
    const { spxConnectedData } = this.state
    const userId = spxConnectedData?.tp_user_id ? spxConnectedData.tp_user_id : 'ไม่พบ user id กรุณาลองใหม่อีกครั้งภายหลัง'
    return (
      <VStack p='2'>
        <VStack>
          <XText variant='inactive'>เชื่อมต่อกับ SPX User Id:</XText>
          <XText variant='active'>{userId}</XText>
        </VStack>
        <TouchableOpacity
          onPress={() => {
            this._deleteAccount().catch((error) => {
              console.log('Error creating account:', error)
            })
          }}
          style={{
            backgroundColor: COLORS.RED,
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            borderRadius: 90,
            marginTop: 8,
            width: 160,
          }}>
          <XText color={COLORS.WHITE} py='2'>
            ลบการเชื่อมต่อ
          </XText>
        </TouchableOpacity>
      </VStack>
    )
  }

  _renderBody = () => {
    const { spxConnectedData } = this.state
    return <XCard m='2'>{_.isNil(spxConnectedData) ? this._renderSpxNotActive() : this._renderSpxActive()}</XCard>
  }

  render() {
    return (
      <XContainer>
        {this._renderCustomHeader()}
        <XContent>{this._renderBody()}</XContent>
      </XContainer>
    )
  }
}
