/* eslint-disable react/no-unused-state */
import React from 'react'
import {
  IApiOptions,
  IProfile,
  IProfileMap,
  IResponseGetSubscriptionsOrder,
  ISelectedStoreMap,
  ISubscriptionPackageList,
  IXSellyFile,
  SubscriptionPackageListItems,
} from 'x/index'
import * as util from 'x/utils/util'
import _ from 'lodash'
import dayjs from 'dayjs'
import * as NavActions from 'x/utils/navigation'
import * as upload from 'x/utils/upload'
import api from 'x/utils/api'
import p from 'x/config/platform-specific'
import CONS from 'x/config/constants'

export interface ISelectedAddOn {
  sku: string
  billing_cycle: number
  qty: number
}

interface IBasePaymentSubscriptionViewProps {
  profile: IProfileMap
  selectedStore: ISelectedStoreMap
  subscription: any
  myStores: any
  fetchSubscription: any
}

interface IBasePaymentSubscriptionViewState {
  subscriptionPackageList: ISubscriptionPackageList
  profile: IProfile
  isLoading: boolean

  selectedOptionRangePackageIndex: number // รายบละเอียด package list จาก หลังบ้าน
  selectedMainPackage: SubscriptionPackageListItems // แพ็กหลักที่เลือก
  addOnPackages: ISelectedAddOn[] // แพ็กเสริมที่เลือก

  uploadedUrl: string
  paymentPostDate: Date

  pendingOrderData: IResponseGetSubscriptionsOrder

  mode: 'upgrade' | 'buy'
}

export default abstract class BasePaymentSubscriptionView extends React.Component<
  IBasePaymentSubscriptionViewProps,
  IBasePaymentSubscriptionViewState
> {
  inLoopPamyntChackProcess: boolean

  // eslint-disable-next-line react/no-unused-class-component-methods
  // @ts-ignore
  carouselRef: React.useRef<any>

  PACKAGE_SKU_CYCLE_1: string[]

  PACKAGE_SKU_CYCLE_12: string[]

  ADD_ON_COUNTSTANT: {
    title: string
    addOnKey: string
    cutSku: string
  }[]

  constructor(props) {
    super(props)
    this.state = {
      subscriptionPackageList: null,
      profile: null,
      selectedOptionRangePackageIndex: 3,
      selectedMainPackage: null,
      addOnPackages: null,
      uploadedUrl: null,
      paymentPostDate: new Date(),
      pendingOrderData: null,
      isLoading: true,
      mode: 'buy',
    }

    this.inLoopPamyntChackProcess = false

    this.ADD_ON_COUNTSTANT = [
      {
        title: 'ออเดอร์',
        addOnKey: 'order_count_quota',
        cutSku: 'ADDON-QUOTA-ORDER',
      },
      {
        title: 'ช่องทางขาย',
        addOnKey: 'mkp_quota',
        cutSku: 'ADDON-QUOTA-MKP',
      },
      {
        title: 'กลุ่มสมาชิก',
        addOnKey: 'ug_quota',
        cutSku: 'ADDON-QUOTA-UG',
      },
      {
        title: 'รายการราคา',
        addOnKey: 'pg_quota',
        cutSku: 'ADDON-QUOTA-PG',
      },
      {
        title: 'ผู้ช่วย',
        addOnKey: 'helper_count',
        cutSku: 'ADDON-QUOTA-HELPER',
      },
      {
        title: 'ร้านค้า',
        addOnKey: 'store_count',
        cutSku: 'ADDON-QUOTA-STORE',
      },
      // {
      //   title: 'คลัง',
      //   addOnKey: 'wh_quota',
      //   cutSku: 'ADDON-QUOTA-WH',
      // },
    ]
  }

  async componentDidMount() {
    await this._getSubscriptionPackageList()
    await this._setProfile()
    await this._getSubscriptionOrder()
  }

  _getSubscriptionOrder = async () => {
    const { subscriptionPackageList } = this.state
    const reqBody = {
      is_pending: true,
    }
    const apiOptions: IApiOptions = {
      showSpinner: true,
    }
    const res = await api.postV2('subscription/order/get', reqBody, apiOptions)
    // console.log('res => ', res)
    if (res?.id) {
      const { items } = res
      const mainPackageSku = items.find((item) => _.includes(CONS.SUBSCRIPTION_MAIN_PACKAGE_SKU, item.sku))
      const mainPackage = subscriptionPackageList.items.find((item) => item.sku === mainPackageSku.sku)
      const addOnPackages = items.filter((item) => !_.includes(CONS.SUBSCRIPTION_MAIN_PACKAGE_SKU, item.sku))
      // console.log('mainPackage => ', mainPackage)
      // console.log('addOnPackages => ', addOnPackages)
      await util.setStatePromise(this, { pendingOrderData: res, selectedMainPackage: mainPackage, addOnPackages, isLoading: false })
      if (res.status === 'pending') {
        await util.delay(5000)
        this.inLoopPamyntChackProcess = true
        await this._loopChackPayment()
      }
    } else {
      await util.setStatePromise(this, { isLoading: false })
    }
  }

  _setLoading = () => {
    this.setState({ isLoading: true })
  }

  _unSetLoading = () => {
    this.setState({ isLoading: false })
  }

  _getRecommendPackage = async () => {
    const res = {
      type: 'extend',
      main_item: {
        sku: 'PACKAGE-SILVER-CYCLE-6',
      },
      addon_items: [
        {
          billing_cycle: 6,
          sku: 'ADDON-QUOTA-MKP-1',
          qty: 2,
        },
        {
          billing_cycle: 1,
          sku: 'ADDON-QUOTA-ORDER-400',
          qty: 5,
        },
      ],
    }

    await util.setStatePromise(this, {
      addOnPackages: res.addon_items,
    })

    return res.main_item.sku
  }

  _getSubscriptionPackageList = async () => {
    // @ts-ignore
    const params = util.getNavParams(this.props)
    const { subscriptionPackageList, sku, mode } = params
    // console.log('subscriptionPackageList => ', subscriptionPackageList)
    // console.log('sku => ', sku)
    let selectedMainPackageSku = sku
    // IF sku is null or undefined, get the recommended package. (ใช้ในเคสการต่อแพ็กเกจ)
    if (_.isNil(selectedMainPackageSku)) {
      selectedMainPackageSku = await this._getRecommendPackage()
    }
    let selectedMainPackage = null
    const { items } = subscriptionPackageList
    if (!_.isNil(items)) {
      items.forEach((item) => {
        if (item.sku === selectedMainPackageSku) {
          selectedMainPackage = item
        }
      })
    }
    await util.setStatePromise(this, {
      subscriptionPackageList,
      selectedMainPackage,
      mode: _.isNil(mode) ? 'buy' : mode,
    })
  }

  // _fetchSubscriptionPackageList = async () => {
  //   const apiOptions = {
  //     showSpinner: true,
  //   }
  //   let PURCHASABLES_ID = CONS.PURCHASABLES_ID.SCB
  //   if (p.op.isIOS()) {
  //     PURCHASABLES_ID = CONS.PURCHASABLES_ID.IOS
  //   }
  //   const body = {
  //     lang: 'th',
  //     purchase_channel_id: PURCHASABLES_ID,
  //   }
  //   try {
  //     const res = await api.postV2(api.POST_SUBSCRIPTION_PACKAGE_LIST, body, apiOptions)
  //     // console.log('_fetchSubscriptionPackageList res => ', res)
  //     await util.setStatePromise(this, {
  //       subscriptionPackageList: res,
  //       isLoading: false,
  //     })
  //     // if (!res || _.isEmpty(res)) {
  //     // }
  //   } catch (err) {}
  // }

  // eslint-disable-next-line react/no-unused-class-component-methods
  goBack = () => {
    // @ts-ignore
    util.navGoBack(this.props)
  }

  _setProfile = async (): Promise<void> => {
    const { profile } = this.props
    const profileJS = profile?.toJS() || null
    await util.setStatePromise(this, {
      profile: profileJS,
    })
  }

  // _getAddOnDataByCutSku = (cutSku: string) => {
  //   const { subscriptionPackageList, addOnPackages } = this.state
  //   if (_.isNil(addOnPackages)) {
  //     return null
  //   }

  //   let fullSku = null
  //   addOnPackages.forEach((addOn) => {
  //     const { sku } = addOn
  //     const isPrefix = sku.startsWith(cutSku)
  //     if (isPrefix) {
  //       fullSku = sku
  //     }
  //   })

  //   if (_.isNil(fullSku)) {
  //     return null
  //   }

  //   const { items } = subscriptionPackageList
  //   let jsonData = null
  //   items.forEach((item) => {
  //     const { sku } = item
  //     if (sku === fullSku) {
  //       jsonData = item
  //     }
  //   })
  //   return jsonData
  // }

  _getAddOnDataByCutSku = (cutSku: string) => {
    const { subscriptionPackageList, addOnPackages } = this.state

    // If addOnPackages is null or undefined, return null.
    if (_.isNil(addOnPackages)) {
      return null
    }

    // Use the find method to locate the first add-on package whose SKU starts with cutSku.
    const fullSku = addOnPackages.find((addOn) => addOn.sku.startsWith(cutSku))?.sku

    // If no matching SKU is found, return null.
    if (_.isNil(fullSku)) {
      return null
    }

    // Use the find method to locate the item in subscriptionPackageList with the matching SKU.
    const jsonData = subscriptionPackageList.items.find((item) => item.sku === fullSku) || null

    return jsonData
  }

  // This function retrieves the selected add-on package based on the provided cutSku.
  _getAddOnSelectedData = (cutSku: string) => {
    const { addOnPackages } = this.state

    // If addOnPackages is null or undefined, return null.
    if (_.isNil(addOnPackages)) {
      return null
    }

    // Use the find method to locate the first add-on package whose SKU starts with cutSku.
    // If no matching add-on is found, return null.
    return addOnPackages.find((addOn) => addOn.sku.startsWith(cutSku)) || null
  }

  _getAddOnSelectedDataBySku = (sku: string) => {
    const { subscriptionPackageList } = this.state
    return subscriptionPackageList.items.find((item) => item.sku === sku) || null
  }

  _getStartAndEndBillingCycle = (billingCycle: number) => {
    const { subscriptionPackageList } = this.state
    const { future_billing_dates } = subscriptionPackageList
    const { start } = future_billing_dates[0]
    const { end } = future_billing_dates[billingCycle - 1]
    return `${dayjs(start, 'YYYY-MM-DD').format('D MMM YYYY')} - ${dayjs(end, 'YYYY-MM-DD').format('D MMM YYYY')}`
  }

  _callBackFormSelectedAddOn = (addOnSelectedList: ISelectedAddOn[], cutSku: string) => {
    const { addOnPackages } = this.state
    if (_.isNil(addOnPackages) || addOnPackages.length === 0) {
      this.setState({ addOnPackages: addOnSelectedList })
    } else if (_.isNil(addOnSelectedList) || addOnSelectedList.length === 0) {
      const newAddOnPackages = addOnPackages.filter((addOn) => !addOn.sku.startsWith(cutSku))
      this.setState({ addOnPackages: newAddOnPackages })
    } else {
      const oldAddOnPackages = _.cloneDeep(addOnPackages)
      const newAddOnPackages = oldAddOnPackages.filter((addOn) => !addOn.sku.startsWith(cutSku))
      addOnSelectedList.forEach((addOnSelected) => {
        newAddOnPackages.push(addOnSelected)
      })
      this.setState({ addOnPackages: newAddOnPackages })
    }
  }

  _onPressAddOn = (shortSku: string) => {
    // console.log('shortSku => ', shortSku)
    // @ts-ignore
    const { navigation } = this.props
    const { subscriptionPackageList, selectedMainPackage } = this.state
    navigation.dispatch(
      NavActions.navToSelectAddOnOrderView({
        subscriptionPackageList,
        sku: shortSku,
        selectedPackage: selectedMainPackage,
        callBackFromSelectedAddOn: (addOnSelectedList, cutSku) => this._callBackFormSelectedAddOn(addOnSelectedList, cutSku),
        selectedAddOn: null,
      })
    )
  }

  _onPressEditAddOn = (shortSku: string) => {
    // console.log('shortSku => ', shortSku)
    // @ts-ignore
    const { navigation } = this.props
    const { subscriptionPackageList, selectedMainPackage } = this.state
    const addOnFromCutSku = this._getAddOnSelectedListData(shortSku)
    navigation.dispatch(
      NavActions.navToSelectAddOnOrderView({
        subscriptionPackageList,
        sku: shortSku,
        selectedPackage: selectedMainPackage,
        callBackFromSelectedAddOn: (addOnSelectedList, cutSku) => this._callBackFormSelectedAddOn(addOnSelectedList, cutSku),
        selectedAddOn: addOnFromCutSku,
      })
    )
  }

  _getSelectedAddOn = (sku: string) => {
    if (_.isNil(sku)) {
      return null
    }
    const { subscriptionPackageList } = this.state
    const { items } = subscriptionPackageList
    let selectedAddOn = null
    items.forEach((item) => {
      if (item.sku === sku) {
        selectedAddOn = item
      }
    })
    return selectedAddOn
  }

  _getAddOnSelectedListData = (cutSku: string) => {
    const { addOnPackages } = this.state

    if (_.isNil(addOnPackages)) {
      return null
    }

    const listAddOn = []
    // addOnPackages.find((addOn) => addOn.sku.startsWith(cutSku)) || null
    addOnPackages.forEach((addOn) => {
      const { sku } = addOn
      const isPrefix = sku.startsWith(cutSku)
      if (isPrefix) {
        listAddOn.push(addOn)
      }
    })
    return listAddOn
  }

  _getAddOnCount = (addOn: { title: string; addOnKey: string; cutSku: string }) => {
    const { addOnPackages } = this.state
    let countAddOn = 0
    if (_.isNil(addOnPackages)) {
      return countAddOn
    }
    const addOnFromCutSku = this._getAddOnSelectedListData(addOn.cutSku)
    addOnFromCutSku.forEach((addOnSelected) => {
      const getAddOn = this._getAddOnSelectedDataBySku(addOnSelected.sku)
      const countQty = getAddOn.data_json[addOn.addOnKey] * addOnSelected.qty
      countAddOn += countQty
    })
    return countAddOn
  }

  _onSubmitUpload = async () => {
    this._setLoading()
    // @ts-ignore มันคือ IXSellyFile แต่ไม่ได้ประกาศใน state
    const { selectedFile, paymentPostDate } = this.state
    // console.log('_onTestUploadPress selectedFile', selectedFile)

    if (_.isNil(selectedFile)) {
      p.op.showConfirmationOkOnly('', 'กรุณาเลือกไฟล์ที่ต้องการอัพโหลด')
      this._unSetLoading()
      return
    }

    const YYMM = dayjs(paymentPostDate).format('YYMM')
    const YYMMDD = dayjs(paymentPostDate).format('YYMMDD')
    const HHMMSS = dayjs(paymentPostDate).format('HHmmss')
    const fileName = `/subs_payment_slip_${YYMMDD}_${HHMMSS}`
    const path = `subs/payment_slip/${YYMM}/${YYMMDD}_${HHMMSS}`
    try {
      // console.log('_onTestUploadPress file', selectedFile)
      const uploadResponse = await upload.uploadFile(selectedFile as IXSellyFile, { fileName, folderPath: path })
      // console.log('_onTestUploadPress uploadResponse', uploadResponse)
      // @ts-ignore
      await util.setStatePromise(this, { uploadedUrl: uploadResponse.url })
      await this._onSubmitPayment()
    } catch (error) {
      // console.log('_onTestUploadPress error', error)
      // p.op.showConfirmationOkOnly('', `เกิดข้อผิดพลาด ${error}`)
      await util.setStatePromise(this, { uploadedUrl: null })
      this._unSetLoading()
    }
  }

  _getSumTotalPayment = () => {
    const { selectedMainPackage, addOnPackages } = this.state
    if (_.isNil(selectedMainPackage)) {
      return 0
    }

    const { price_with_vat } = selectedMainPackage
    let price = price_with_vat
    if (!_.isNil(addOnPackages)) {
      addOnPackages.forEach((addOn) => {
        const { billing_cycle, qty, sku } = addOn
        const selectedPackage = this._getAddOnSelectedDataBySku(sku)
        price += selectedPackage.price_with_vat * qty * billing_cycle
      })
    }
    return price
  }

  _onChangePaymentPostDate = (newDate: Date) => {
    this.setState({ paymentPostDate: newDate })
  }

  _getParams = () => {
    // @ts-ignore
    const params = util.getNavParams(this.props)
    return params
  }

  _onSubmitPayment = async () => {
    const { addOnPackages, selectedMainPackage, profile, paymentPostDate, uploadedUrl, subscriptionPackageList } = this.state
    if (_.isNil(uploadedUrl)) {
      p.op.showConfirmationOkOnly('', 'อัพโหลดหลักฐานการชำระเงินไม่สำเร็จ')
      this._unSetLoading()
      return
    }
    const price = this._getSumTotalPayment()
    const items = _.isNil(addOnPackages) ? [] : addOnPackages
    const mainPackage = {
      sku: selectedMainPackage.sku,
      billing_cycle: selectedMainPackage.billing_cycle,
      qty: 1,
    }
    const paidAt = dayjs(paymentPostDate).format('YYYY-MM-DD HH:mm:ss')
    items.push(mainPackage)
    const reqBody = {
      user_id: profile.id,
      is_tax_withheld: false,
      is_vat: true,
      is_tax_receipt_requested: false,
      total_amount: price,
      payment_method_id: 1,
      // payment_channel_id: 1,
      added_day: 0,
      payment: {
        paid_at: paidAt,
        ref_info: uploadedUrl,
      },
      items,
    }

    const apiOptions: IApiOptions = {
      showSpinner: true,
    }

    const res: IResponseGetSubscriptionsOrder = await api.postV2('subscription/order/create', reqBody, apiOptions)
    // console.log('res => ', res)
    if (res?.id) {
      // p.op.showConfirmationOkOnly(
      //   'อัพโหลดหลักฐานการชำระเรียบร้อย',
      //   `กรุณารอระบบทำการตรวจสอบหลักฐานการชำระเงินภายใน 30 นาที ระบบจะอัพเดทแพ็กเกจของคุณให้อัตโนมัติเมื่อผ่านการตรวจสอบ`
      // )
      p.op.showToast('อัพโหลดหลักฐานการชำระเรียบร้อย', 'success')
      const mainPackageSku = res.items.find((item) => _.includes(CONS.SUBSCRIPTION_MAIN_PACKAGE_SKU, item.sku))
      const newMainPackage = subscriptionPackageList.items.find((item) => item.sku === mainPackageSku.sku)
      const newAddOnPackages = res.items.filter((item) => !_.includes(CONS.SUBSCRIPTION_MAIN_PACKAGE_SKU, item.sku))
      await util.setStatePromise(this, { pendingOrderData: res, selectedMainPackage: newMainPackage, addOnPackages: newAddOnPackages })
    }
    this._unSetLoading()
    await util.delay(3000)
    this.inLoopPamyntChackProcess = true
    await this._loopChackPayment()
  }

  _loopChackPayment = async () => {
    const { pendingOrderData } = this.state
    if (_.isNil(pendingOrderData)) {
      return
    }
    const reqBody = {
      order_id: pendingOrderData.id,
    }
    const res: IResponseGetSubscriptionsOrder = await api.postV2('subscription/order/get', reqBody)
    // console.log('_loopChackPayment res => ', res)
    if (res?.status === 'completed') {
      this.props.fetchSubscription()
      const params = this._getParams()
      const { succeedPaymentCallBack } = params
      if (_.isFunction(succeedPaymentCallBack)) {
        succeedPaymentCallBack()
      }
      p.op.showConfirmationOkOnly('', 'ระบบได้ทำการตรวจสอบการชำระเงินเรียบร้อยแล้ว', () => {
        this.goBack()
      })
      await util.setStatePromise(this, { pendingOrderData: res })
      this.inLoopPamyntChackProcess = false
      return
    }

    await util.delay(5000)
    if (this.inLoopPamyntChackProcess) {
      await this._loopChackPayment()
    }
  }

  componentWillUnmount() {
    this.inLoopPamyntChackProcess = false
  }
}
