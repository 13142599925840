import React from 'react'
// import { Drawer } from 'native-base'
import _ from 'lodash'
import { connect } from 'react-redux'
import { getSelectedStore, getCategoryList } from 'x/redux/selectors'
import { bindActionCreators } from 'redux'
import * as StoreState from 'x/modules/store/StoreState'

import { IProductListFilterViewProps, IProductListFilterViewState, IProductListFilterPreference, IProductListFilterTemplate } from 'x/index'

// import NavHeaderButton from './NavHeaderButton'
// import XCheckbox from './XCheckbox'
// import XCheckButton from './XCheckButton'
import p from 'x/config/platform-specific'
import CONS from 'x/config/constants'
import * as util from 'x/utils/util'
import VStack from 'xui/components/VStack'
import ProductVariantListFilterTemplateSelector from 'xui/components/product/ProductVariantListFilterTemplateSelector'
import dayjs from 'dayjs'
import { Map } from 'immutable'
import * as NavActions from 'x/utils/navigation'
import HStack from 'xui/components/HStack'
import XIconButton from 'xui/components/XIconButton'
import HelpButton from 'xui/components/HelpButton'
import BaseUIProductListFilterView from './BaseUIProductListFilterView'

export class ProductListFilterView extends BaseUIProductListFilterView<IProductListFilterViewProps, IProductListFilterViewState> {
  static displayName = 'ProductListFilterView'

  _getHeaderTitle = () => {
    const headerTitle = util.getNavParam(this.props, 'headerTitle', 'กรองตัวเลือกสินค้า')
    return headerTitle
  }

  _setCurrentFilter = async (newFilter: Partial<IProductListFilterTemplate>) => {
    if (!newFilter || _.isEmpty(newFilter)) {
      return
    }

    const { currentSetting } = this.state
    const newCurrentFilter = { ...currentSetting, ...newFilter }

    await util.setStatePromise(this, { currentSetting: newCurrentFilter, selectedFilterTemplate: null })
  }

  _setupExtended = async () => {
    const { currentSetting } = this.state
    if ('id' in currentSetting) {
      await util.setStatePromise(this, { selectedFilterTemplate: currentSetting })
    }
  }

  _navToCreateFilterTemplate = () => {
    const pref = this._getPref()
    const { templates = [] } = pref

    if (templates && templates.length >= 15) {
      p.op.alert('ไม่สามารถสร้างเทมเพลตตัวกรองสินค้าได้เกิน 15 รายการ', 'กรุณาลบเทมเพลตที่ไม่ได้ใช้ออกก่อน แล้วจึงสร้างใหม่')
      return
    }

    const currentSetting = this._getCurrentFilter()
    const { navigation } = this.props

    const newTemplate: IProductListFilterTemplate = {
      ...currentSetting,
      id: `${dayjs().format('YYYYMMDD').toString()}x${util.generateRandomString(5)}`,
      name: '',
      createdAt: dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString(),
      updatedAt: dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString(),
      color: '#b79ec7',
    }

    navigation.dispatch(
      NavActions.navToProductListFilterEditorView({
        headerTitle: 'สร้างเทมเพลตตัวกรองสินค้า',
        store_id: util.getNavParam(this.props, 'store_id'),
        defaultSetting: newTemplate,
        appliedSetting: newTemplate,
        // onSaveSuccess: this._onCreateFilterSaveSuccess,
        onSaveSuccess: this._onPrefManageSaveSuccess,
      })
    )
  }

  _navToProductListFilterManageView = () => {
    const { navigation } = this.props

    navigation.dispatch(
      NavActions.navToProductListFilterManageView({
        store_id: util.getNavParam(this.props, 'store_id'),
        onSaveSuccess: this._onPrefManageSaveSuccess,
      })
    )
  }

  _renderHeaderRight = () => (
    <HStack w='150px' alignItems='center' justifyContent='flex-end' space='2'>
      {/* <XButton w='34' h='34' variant='ghost' onPress={this._openFilterTemplateSaveModal}>
        <XIcon name='save' family='Ionicons' />
      </XButton> */}
      <XIconButton name='save' family='Ionicons' variant='ghost' onPress={this._navToCreateFilterTemplate} />
      <XIconButton name='settings' family='Ionicons' variant='ghost' onPress={this._navToProductListFilterManageView} />
      <HelpButton
        title='เทมเพลตตัวกรองสินค้า'
        message={
          'คุณสามารถบันทึกเทมเพลตตัวกรองโดยกดปุ่มไอคอนบันทึก ที่อยู่ในแถบด้านบนขวามือ เมื่อกดแล้วระบบจะทำการบันทึกตัวกรองปัจจุบันไว้ใช้ซ้ำได้' +
          '\n\nเมื่อทำการบันทึกแล้วกดปุ่ม "เลือกเทมเพลตตัวกรองสินค้า" เพื่อเลือกใช้งานตัวกรองที่บันทึกไว้'
        }
      />
    </HStack>
  )

  _getPref = (): IProductListFilterPreference => {
    const { selectedStore } = this.props
    const pref = selectedStore.get('pref_ui_pp_list_filter')

    if (!pref) {
      return {
        templates: [],
        createdAt: dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString(),
      }
    }

    return Map.isMap(pref) ? pref.toJS() : pref
  }

  resetSelectedTemplate = async () => {
    await util.setStatePromise(this, { selectedFilterTemplate: null })
    const filterTemplate = this._getCurrentFilter()
    if ('id' in filterTemplate) {
      const newSetting = { ...filterTemplate }
      delete newSetting.id
      this._setCurrentFilter(newSetting)
    }
  }

  _onPrefManageSaveSuccess = async (newPref: IProductListFilterPreference, savedTemplate?: IProductListFilterTemplate) => {
    // console.log('ProductListFilterManageView::onSaveSuccess')
    const { selectedFilterTemplate } = this.state
    const pref = this._getPref()

    let forceApply = false
    if (selectedFilterTemplate && pref && _.isArray(pref.templates)) {
      const tmplId = selectedFilterTemplate.id
      const foundIndex = pref.templates.findIndex((tmpl) => tmpl.id === tmplId)

      if (foundIndex < 0) {
        await this.resetSelectedTemplate()
        await this._clearAllSelected()
        forceApply = true
      }
    }

    const onSaveSuccess = util.getNavParam(this.props, 'onSaveSuccess')
    if (_.isFunction(onSaveSuccess)) {
      await util.delay(100)
      await onSaveSuccess(newPref, savedTemplate)
    }

    if (forceApply) {
      util.navPopToCurrent(this.props)
      await util.delay(200)
      await this._onPressSubmitBtn()
    }
  }

  _onSelectFilterTemplate = async (nTmpl?: IProductListFilterTemplate) => {
    if (_.isNil(nTmpl)) {
      await this._clearAllSelected()
      await this.resetSelectedTemplate()
      p.op.showToast('เลิกใช้งานนเทมเพลตตัวกรองสินค้า', 'success')
      return
    }

    await util.setStatePromise(this, { selectedFilterTemplate: nTmpl, currentSetting: nTmpl })
    // this._setCurrentFilter(nTmpl)
    p.op.showToast('ใช้งานเทมเพลตตัวกรองสินค้าแล้ว', 'success')
  }

  renderProductVariantFilterTemplateSelector = () => {
    const { navigation } = this.props
    const { selectedFilterTemplate } = this.state
    // const { currentSetting } = this.state

    return (
      <VStack w='full'>
        <ProductVariantListFilterTemplateSelector
          type='button'
          navigation={navigation}
          selectedTemplate={selectedFilterTemplate}
          onSelect={this._onSelectFilterTemplate}
          onSaveSuccess={this._onPrefManageSaveSuccess}
        />
      </VStack>
    )
  }

  _renderMain = () => (
    <VStack w='full' px='2' py='1' space='1.5'>
      {this.renderProductVariantFilterTemplateSelector()}
      {this._renderCollapsibleSortCard()}
      {this._renderCollapsibleStockCard()}
      {this._renderCollapsibleCostCard()}
      {this._renderCollapsibleDatetimeCard()}
      {this._renderCollapsibleMkpCard()}
      {this._renderCollapsibleCategoryCard()}
    </VStack>
  )
}

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    categoryList: getCategoryList(state),
  }),
  (dispatch) => ({
    dispatch,
    updateSelectedStoreByKey: bindActionCreators(StoreState.updateSelectedStoreByKey, dispatch),
  })
)(ProductListFilterView)
