import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { getSelectedStore, getCategoryList } from 'x/redux/selectors'
import * as StoreState from 'x/modules/store/StoreState'
import * as CategoryActions from 'x/modules/category/categoryState'

import { categoryByStore, ICategory, IProductCategorySelectorProps, IProductCategorySelectorState } from 'x/types'
import * as util from 'x/utils/util'

import _ from 'lodash'
import { List } from 'immutable'
import { TouchableOpacity } from 'react-native'
import { COLORS } from 'x/config/styles'
import VStack from '../VStack'
import XHorizontalScrollView from '../XHorizontalScrollView'
import Box from '../Box'
import XText from '../XText'
import HStack from '../HStack'
import XIcon from '../XIcon'

class ProductCategorySelector extends Component<IProductCategorySelectorProps, IProductCategorySelectorState> {
  static displayName = 'ProductCategorySelector'

  inProcess?: boolean

  constructor(props) {
    super(props)

    this.state = {
      isInitialized: false,
      categoryList: null,
    }
  }

  async componentDidMount(): Promise<void> {
    await this._setupCategoryList()

    this.setState({ isInitialized: true })
  }

  _setupCategoryList = async () => {
    const { storeId, categoryList = List([]) } = this.props
    const catGroupList = categoryList.toJS()

    const foundIndex = catGroupList.findIndex((catG: categoryByStore) => catG.store_id === storeId)
    if (foundIndex >= 0) {
      const catGroup = catGroupList[foundIndex]
      await util.setStatePromise(this, { categoryList: catGroup.category })
      return
    }

    const { requestCategoryList } = this.props

    const res: ICategory[] = await new Promise((resolve) => {
      requestCategoryList({
        body: {
          store_id: storeId,
        },
        successCallback: resolve,
        failedCallback: resolve,
      })
    })

    console.log('_setupCategoryList res => ', res)

    if (!_.isNil(res) && _.isArray(res)) {
      await util.setStatePromise(this, { categoryList: res })
    }
  }

  // _configCategory = async () => {
  //   const { categoryList, requestCategoryList, navigation } = this.props
  //   const { mode, store_id, seller_store_id } = this._getParams()
  //   // console.log('mode => ', mode)
  //   if (mode === MKP_MAPPING) {
  //     return null
  //   }
  //   // const { mode, store_id, seller_store_id } = this._getParams()
  //   let storeId = this.getTargetStoreId()
  //   // const storeId = this.getTargetStoreId()
  //   let CATEGORYS = null
  //   const CettoJS = _.isNil(categoryList) ? [] : categoryList.toJS()
  //   CettoJS.map((data: categoryByStore) => {
  //     if (storeId === data.store_id) {
  //       CATEGORYS = data.category
  //     }
  //   })

  //   if (mode === PULL_PRODUCT) {
  //     storeId = seller_store_id
  //   } else if (mode === PULL_SELLER_PRODUCT_TO_ORDER) {
  //     storeId = seller_store_id
  //   } else if (mode === VIEW_SELLER) {
  //     storeId = seller_store_id
  //   }
  //   if (_.isNil(CATEGORYS) || CATEGORYS.length < 1) {
  //     const res: ICategory[] = await new Promise((resolve) => {
  //       requestCategoryList({
  //         body: {
  //           store_id: storeId,
  //         },
  //         successCallback: resolve,
  //         failedCallback: resolve,
  //       })
  //     })
  //     if (!_.isNil(res) && _.isArray(res)) {
  //       CATEGORYS = res
  //     }
  //   }
  //   const catSeletedCategory = null
  //   const catLevelCategoryList = []
  //   const catSupCategory = []
  //   if (_.isNil(CATEGORYS)) {
  //     CATEGORYS = []
  //   }
  //   const haveUnknownCat = CATEGORYS.find((data: ICategory) => data.id === 0)
  //   if (_.isNil(haveUnknownCat)) {
  //     const unknownCat: ICategory = { id: 0, n: `ไม่ระบุหมวดหมู่`, l: COLORS.TEXT_INACTIVE }
  //     CATEGORYS.push(unknownCat)
  //   }
  //   CATEGORYS.map((data: ICategory) => {
  //     if (_.isNil(data.p)) {
  //       catSupCategory.push(data)
  //       catLevelCategoryList.push(data)
  //     }
  //   })

  //   await util.setStatePromise(this, {
  //     catSeletedCategory,
  //     catLevelCategoryList,
  //     catSupCategory,
  //   })
  // }

  _onPressProductCategory = async (category?: ICategory) => {
    const { onSelectCategory } = this.props

    onSelectCategory(category)
  }

  _renderCatListItem = (data: ICategory, index: number, categoryList: ICategory[]) => {
    const { selectedCategoryId } = this.props

    if (_.isNil(selectedCategoryId) && data.p) {
      return null
    }

    if (selectedCategoryId && data.p !== selectedCategoryId) {
      return null
    }

    const marginLeft = index === 0 ? 12 : 12
    const marginRight = index + 1 === categoryList.length ? 4 : 4
    const textColor = data.l ? data.l : COLORS.TEXT_INACTIVE
    return (
      <TouchableOpacity
        key={`CatListItem_${index}_${data.id}`}
        // onPress={() => this._selectedSubCategory(data)}
        onPress={() => this._onPressProductCategory(data)}
        style={{
          // minWidth: 50,
          // // width: 50,
          // // maxWidth: 120,
          // // height: 25,
          // borderColor: data.l,
          // borderWidth: 0.7,
          // borderRadius: 7,
          marginLeft,
          marginRight,
          // paddingLeft: 4,
          // paddingRight: 4,
          // justifyContent: 'center',
        }}>
        <Box minW='50px' borderWidth='1' borderRadius='lg' px='2' py='1' alignItems='center' justifyContent='center' borderColor={data.l}>
          <XText style={{ color: textColor }}>{data.n}</XText>
        </Box>
      </TouchableOpacity>
    )
  }

  _renderCatListItems = () => {
    const { categoryList } = this.state

    return categoryList.map(this._renderCatListItem)
  }

  getSelectedCategory = () => {
    const { categoryList } = this.state
    const { selectedCategoryId } = this.props

    if (_.isNil(selectedCategoryId)) {
      return null
    }

    const foundIndex = categoryList.findIndex((cat) => cat.id === selectedCategoryId)
    if (foundIndex < 0) {
      return null
    }

    return categoryList[foundIndex]
  }

  _getSelectedCategoryBreadcrumbName = () => {
    const { categoryList } = this.state
    const selectedCategory = this.getSelectedCategory()

    let selectedCatName = selectedCategory.n

    let focusCatId = selectedCategory.p
    let isMoreParent = _.isNumber(selectedCategory.p)
    while (isMoreParent) {
      const foundCat = categoryList.find((cat: ICategory) => cat.id === focusCatId)
      if (foundCat) {
        selectedCatName = `${foundCat.n} > ${selectedCatName}`
        focusCatId = foundCat.p
        isMoreParent = _.isNumber(foundCat.p)
      } else {
        isMoreParent = false
      }
    }

    return selectedCatName
  }

  _renderSelectedCategory = () => {
    const selectedCategory = this.getSelectedCategory()

    if (!selectedCategory) {
      return null
    }

    const textColor = selectedCategory.l ? selectedCategory.l : COLORS.TEXT_INACTIVE
    return (
      <HStack w='full' px='2' py='1' space='1' alignItems='center'>
        <XText>เลือกหมวดหมู่: </XText>

        <HStack
          minW='50px'
          borderWidth='1'
          borderRadius='lg'
          px='2'
          py='1'
          alignItems='center'
          justifyContent='center'
          borderColor={selectedCategory.l}
          space='1'>
          <XText style={{ color: textColor }}>{this._getSelectedCategoryBreadcrumbName()}</XText>

          <TouchableOpacity onPress={() => this._onPressProductCategory(null)}>
            <XIcon name='close' family='Ionicons' variant='danger' w='24px' h='24px' />
          </TouchableOpacity>
        </HStack>
      </HStack>
    )
  }

  renderMain = () => {
    const selectedCategory = this.getSelectedCategory()

    return (
      <VStack w='full'>
        {this._renderSelectedCategory()}

        {!selectedCategory || selectedCategory.c.length > 0 ? (
          <XHorizontalScrollView
            w='full'
            _contentContainerStyle={{ py: '2' }}
            //  _contentContainerStyle={{ py: '2' }} bg={COLORS.BG_SOFT_GREY}
          >
            {this._renderCatListItems()}
          </XHorizontalScrollView>
        ) : null}
      </VStack>
    )
  }

  render() {
    const { isInitialized } = this.state

    if (!isInitialized) {
      return null
    }

    return this.renderMain()
  }
}

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    categoryList: getCategoryList(state),
  }),
  (dispatch) => ({
    dispatch,
    requestCategoryList: bindActionCreators(CategoryActions.requestCategoryList, dispatch),
    updateSelectedStoreByKey: bindActionCreators(StoreState.updateSelectedStoreByKey, dispatch),
  })
)(ProductCategorySelector) as React.FC<
  Omit<IProductCategorySelectorProps, 'selectedStore' | 'updateSelectedStoreByKey' | 'requestCategoryList' | 'categoryList'>
>
