import React from 'react'
import { View, Platform, ScrollView, TextInput, TouchableOpacity } from 'react-native'
import VStack from 'xui/components/VStack'
import Box from 'xui/components/Box'
import HStack from 'xui/components/HStack'

import _ from 'lodash'
import moment, { Moment } from 'moment'
import { List, Map } from 'immutable'

import p from 'x/config/platform-specific'
import api from 'x/utils/api'
import { S, COLORS, PRODUCT_LIST_ITEM_WIDTH } from 'x/config/styles'
import CONS from 'x/config/constants'
import {
  IProductListViewProps,
  IProductListViewState,
  IProductItemData,
  ICategory,
  categoryByStore,
  IProductDetailItem,
  IApiOptions,
  IBarcodeScannerOutput,
  IAnyObject,
  IProductListFilterState,
  IProductListFilterTemplate,
} from 'x/index'
import * as acl from 'x/utils/acl'
import * as util from 'x/utils/util'
import * as NavActions from 'x/utils/navigation'
import NB from 'x/config/nativebase'

import ErrorListItem from 'xui/components/ErrorListItem'
import BarcodeScannerModalButton from 'xui/components/BarcodeScannerModalButton'
import XIconButton from 'xui/components/XIconButton'
import ProductCategorySelector from 'xui/components/product/ProductCategorySelector'
import XText from 'xui/components/XText'
// import ProductListFilterView from 'xui/components/ProductListFilterView'
import { diff } from 'deep-object-diff'
import InfiniteList, { InfiniteListBase } from '../../components/InfiniteList'
import ProductListItem from './ProductListItem'
import XIcon from '../../components/XIcon'
import XStatusBar from '../../components/XStatusBar'
import NavHeaderButton from '../../components/NavHeaderButton'
import ButtonClose from '../../components/ButtonClose'
import XInput from '../../components/XInput'
import XContainer from '../../components/XContainer'
// import XCustomHeader from '../../components/XCustomHeader'

// หน่วงเวลาการ search หลังจากพิมพ์เสร็จ
const SEARCH_TYPING_DELAY = 700
// หน่วงเวลาที่จะสามารถ search ด้วย keyword เดิมได้
const SEARCH_LATEST_KEYWORD_TIMEOUT = 5000

// interface IDefaulltNavParams {
//   store_id?: number
//   seller_store_id?: number
// }

const { PULL_PRODUCT, VIEW_SELLER, PULL_SELLER_PRODUCT_TO_ORDER, MKP_MAPPING } = CONS.PRODUCT_VIEW_MODE

abstract class BaseUIProductListView<
  IProps extends IProductListViewProps,
  IState extends IProductListViewState,
  ProductListItemType = IProductItemData
> extends React.Component<IProps, IState> {
  static displayName = 'BaseUIProductListView'

  // เมื่อกด ProductListItem
  abstract onPressProductItem?(item: { index?: number; data: ProductListItemType }): void | Promise<void>

  inNavigationProcess?: boolean

  // drawerCatRef?: React.RefObject<ProductListFilterView>

  mainListRef: React.RefObject<InfiniteListBase<ProductListItemType>>

  searchListRef: React.RefObject<InfiniteListBase<ProductListItemType>>

  searchTextRef: React.RefObject<TextInput>

  productCategoryScrollRef?: React.RefObject<ScrollView>

  productCategoryScrollableMaxX?: number

  productCategoryLastScrollX?: number

  returnCategoryInfo: boolean

  lastSearchText: string

  lastSearchTypingDateTime?: Moment

  lastSearchSuccessDateTime?: Moment

  lastSearchRefreshQueueTimeout?: NodeJS.Timeout

  constructor(props) {
    super(props)

    // this.drawerCatRef = React.createRef()
    this.mainListRef = React.createRef()
    this.searchListRef = React.createRef()
    this.searchTextRef = React.createRef()
    this.productCategoryScrollRef = React.createRef()

    this.productCategoryLastScrollX = 0
    this.returnCategoryInfo = false

    this.lastSearchText = ''

    // https://github.com/microsoft/TypeScript/issues/10761
    // @ts-ignore อาจจะมี generic state เพิ่มเข้ามาอีกภายหลัง
    this.state = {
      searchText: '',
      mainProductCount: null,
      mainProductNumOfColumns: 1,
      searchProductCount: null,
      isBarcodeSearching: false,
      isBarcodeCameraVisible: false,

      mainFetchErrorMessage: null,
      searchFetchErrorMessage: null,

      catLevelCategoryList: null,
      catSeletedCategory: null,
      catSupCategory: null,
      allChildrenCategory: true,

      productCategoryScrollViewProperties: {
        currentWidth: 320,
        isVisibleScrollToBeginButton: false,
        isVisibleScrollToEndButton: false,
      },
      // mkp_ch_ids: [],

      sortOptions: CONS.DEFAULT_PRODUCT_LIST_SORT_OPTIONS,

      filterState: {},
    }
  }

  // Lifecycle
  async componentDidMount() {
    // console.log('ProductListView componentDidMount => ', this.mainListRef.current)
    await this._configCategory()
  }

  // External availble Methods

  // // เป็นการ access ร้านขายส่งรึเปล่า (override ทับได้)
  // isSellerStore = () => false

  // ทำการ search
  isSearching = () => this.state.searchText.length >= 2

  // สลับ list เป็น search list แต่อาจจะ search แล้วหรือไม่ก็ได้
  isSearchMode = () => this.state.searchText.length > 0

  isHiddenMainProductList = () => this.isSearchMode()

  isHiddenSearchProductList = () => !this.isSearching()

  isOmitStockQty = () => false

  // ใช้เช็คว่า ProductListView นั้นพร้อมในการทำงานต่างๆ หรือยัง โดยอิงจาก initial fetch สำเร็จ จะได้ count กลับมา
  isProductListViewReady = () => _.isNumber(this.state.mainProductCount)

  isAddProductButtonVisible = () => true

  isRequiredProductVariants = () => false

  isUpcSearchAvailable = () => true

  isSkuSearchAvailable = () => {
    const { selectedStore } = this.props
    return selectedStore.get(CONS.STORE_SETTINGS.COMPUTED_USE_PRODUCT_SKU)
  }

  isProductListItemPressDisabled = () => false

  isProductListItemSellerIconDisabled = () => false

  isProductListItemPriceDisabled = () => false

  isProductListItemQtyDisabled = () => false

  // หลังจาก search เสร็จสิ้น ได้ response มาแล้วจะทำอะไรก่อนหรือไม่?
  onAfterFetchSeachProducts = async (
    fetchedData: { count?: number; items: ProductListItemType[] },
    requestBody: IAnyObject
  ): Promise<void> => {
    // no-op
  }

  getApiEndpoint = () => api.POST_PRODUCTS

  isGoApi = () => false

  getIsGoBackButtonHidden = (): boolean => {
    const { navigation } = this.props
    return util.getNavParam(this.props, 'isGoBackButtonHidden', false)
  }

  getTargetStoreId = () => {
    const { navigation } = this.props
    const storeId = util.getNavParam(this.props, 'store_id')
    // if (this.isSellerStore()) {
    //   storeId =util.getNavParam(this.props, 'seller_store_id')
    // } else {
    //   storeId =util.getNavParam(this.props, 'store_id')
    // }
    if (typeof storeId !== 'number') {
      throw new Error('store_id is required')
    }
    return storeId
  }

  // Bad function ไม่ควรอยู่ตรงนี้
  handleOnPullProductToOrderSuccess = (newState: Map<string, any>): void => {
    // log('success callback of quick add => newState.toJS()', newState.toJS())
    const store_products = newState.getIn(['editingOrder', 'store_products']) || null
    const store_ids = store_products.keySeq().toArray() || []
    // log('success callback of quick add => store_ids', store_ids)
    if (store_products && store_ids.length > 0) {
      let products = List([])
      for (let sIdx = 0; sIdx < store_ids.length; sIdx++) {
        const sId = store_ids[sIdx]
        const sProds = store_products.get(sId) || List([])
        products = products.concat(sProds)
      }

      let txtSummary = ''
      for (let i = 0; i < products.size; i++) {
        if (i === 0) {
          txtSummary = 'สินค้าในออเดอร์ทั้งหมด\n'
        }
        const product = products.get(i)
        const name = product.get('name')
        const variant = product.get('variant')
        const qty = product.get('qty')
        const txtProductName = variant ? `${name} (${variant})` : name
        txtSummary += `\n${txtProductName} x${qty}`
      }
      // showSuccessToast(`เพิ่ม ${txtProductName} แล้ว`)
      if (txtSummary) {
        p.op.showToast(txtSummary, 'success')
      }
    }
  }

  // getSellerStoreId = () => {
  //   const sellerStoreId = this.props.navigation.getParam('seller_store_id')
  //   if (typeof sellerStoreId !== 'number') {
  //     throw new Error('seller_store_id is required')
  //   }
  //   return sellerStoreId
  // }

  getHaveNoProductItemText = () => 'ไม่พบรายการสินค้า'

  getSearchProductListHaveNoProductItemText = () => 'ไม่พบรายการสินค้าที่ค้นหา'

  // should Override on Web:: เอาไว้คำนวณขอบเขตพื้นที่ของ ProductView
  // getScreenWidth = (): number => {
  //   return Dimensions.get('screen').width
  // }

  // เลือก reference ทีั่จะสั่งอัตโนมัติตาม search mode ว่าถ้าหาก user พิมพ์เพื่อ search จะไปใช้งาน searchListRef
  getFocusProductListRef = () => {
    if (this.isSearchMode()) {
      return this.getSearchProductListRef()
    }
    return this.getMainProductListRef()
  }

  getSearchProductListRef = () => this.searchListRef.current

  getMainProductListRef = () => this.mainListRef.current

  fetchMainProducts = async ({ offset, limit }) => {
    type FetchedData = {
      count?: number
      items: ProductListItemType[]
    }
    let requestBody = this.getCommonRequestBody({ offset, limit })
    requestBody = this.getModifiedRequestBody(requestBody)
    const fetchedData: FetchedData = await this.doFetchProducts(requestBody)
    return fetchedData
  }

  fetchSearchProducts = async ({ offset, limit }) => {
    type FetchedData = {
      count?: number
      items: ProductListItemType[]
    }
    let requestBody = this.getCommonRequestBody({ offset, limit })
    requestBody.queryTxt = this.state.searchText
    requestBody = this.getModifiedRequestBody(requestBody)
    const fetchedData: FetchedData = await this.doFetchProducts(requestBody)
    await this.onAfterFetchSeachProducts(fetchedData, requestBody)
    return fetchedData
  }

  // 1. เตรียม request body เบื้องต้่น
  getCommonRequestBody = ({ offset, limit }) => {
    let body: any = {
      store_id: this.getTargetStoreId(),
      offset,
      limit,
      sortBy: 'updated_at',
      sortType: 'desc',
    }

    // if (this.isSellerStore()) {
    //   body.store_id = this.getSellerStoreId()
    // }

    // fetch count only first time
    if (offset === 0) {
      body.c = 1
    }
    // fetch product variants ?
    if (this.isRequiredProductVariants()) {
      body.v = 1
    }

    if (this.isOmitStockQty()) {
      body.omit_stock_qty = true
    }

    body = this._applyRoleIdToRequestBody(body)

    const {
      // catSeletedCategory,
      // allChildrenCategory,
      isBarcodeSearching = false,
      // mkp_ch_ids = [],
      // sortOptions,
      filterState = {},
      // filterOptions = {},
    } = this.state

    // const filterOptions = this.getFilterOptionsFromState(filterState)
    const filterOptions = util.translateProductListFilterToRequestBody(filterState)

    // const selectedCatehory = catSeletedCategory
    // if (!_.isNil(selectedCatehory)) {
    //   body.category_id = selectedCatehory.id
    //   body.withSubCategories = allChildrenCategory
    // }

    // if (this.returnCategoryInfo) {
    //   body.returnCategoryInfo = true
    //   this.returnCategoryInfo = false
    // }

    if (isBarcodeSearching) {
      if (this.isSkuSearchAvailable()) {
        body.isSKU = true
      }
      if (this.isUpcSearchAvailable()) {
        body.isUPC = true
      }
    }

    // if (mkp_ch_ids.length > 0) {
    //   body.mkp_ch_ids = mkp_ch_ids
    // }

    // if (sortOptions.sortBy) {
    //   body.sortBy = sortOptions.sortBy
    // }

    // if (sortOptions.sortType) {
    //   body.sortType = sortOptions.sortType
    // }

    if (!_.isEmpty(filterOptions)) {
      body = { ...body, ...filterOptions }
    }

    return body
  }

  // 2. เอาไว้ modify request body สำหรับ view ที่ extends ออกไป
  getModifiedRequestBody = (body) => body

  // 3. อันนี้คือ logic สั่งยิง fetch
  doFetchProducts = async (requestBody) => {
    const apiOptions: IApiOptions = {
      axiosOptions: {
        retry: 0,
        timeout: 60000,
      },
      isErrorAlertDisabled: true,
    }
    // console.log('requestBody ->  ', requestBody)
    if (this.isGoApi()) {
      apiOptions.isApiV2 = true
    }
    const fetchedData = {
      count: undefined,
      items: [],
    }
    try {
      const res = await api.post(this.getApiEndpoint(), requestBody, apiOptions)
      if (!res || _.isEmpty(res)) {
        // FIXME: แค่ mock error เพราะดันมี {} กลับมาหลังจาก no internet connection โดยไม่ throw error
        throw {
          statusCode: 503,
          errorMessage: 'Service Unavailable',
        }
      }
      // console.log('doFetchProducts res => ', res)
      if (typeof res.count === 'number') {
        fetchedData.count = res.count
      }
      if (res.products) {
        fetchedData.items = res.products
      }
    } catch (err) {
      // console.log('doFetchProducts err => ', err)
      // console.error('doFetchProducts err => ', err)
      // console.log(JSON.parse(JSON.stringify(err)))
      if (requestBody.offset === 0) {
        if (requestBody.queryTxt) {
          await util.setStatePromise(this, { searchFetchErrorMessage: util.getErrorMessageFromErrorResponse(err) })
        } else {
          await util.setStatePromise(this, { mainFetchErrorMessage: util.getErrorMessageFromErrorResponse(err) })
        }
      }
    }

    return fetchedData
  }

  // can be override
  getDefautProductListFilterState = () => ({})

  // getFilterOptionsFromState = (fs: IProductListFilterState): IProductListFilterOptions => {
  //   const {
  //     category,
  //     // mkpChIds,
  //     segmentCreatedAtIndex,
  //     createdAtFrom,
  //     createdAtTo,
  //     createdAtRangeOptionKey,
  //     segmentUpdatedAtIndex,
  //     updatedAtFrom,
  //     updatedAtTo,

  //     segmentHasMkpChIndex,
  //     segmentHasMkpChNotInIndex,
  //     mkpChannelActiveMap,
  //     mkpChannelNotInActiveMap,

  //     segmentHasParentIndex,
  //     segmentHasCreatedByMkpChIndex,
  //     hasCreatedByMkpChMkpChIds,
  //     segmentHasCreatedByMkpChSpecificIndex,
  //   } = fs

  //   const fo: IProductListFilterOptions = {}

  //   if (segmentCreatedAtIndex === 1 && createdAtFrom && createdAtTo) {
  //     fo.created_at_from = createdAtFrom.format(CONS.SERVER_DATETIME_FORMAT)
  //     fo.created_at_to = createdAtTo.format(CONS.SERVER_DATETIME_FORMAT)
  //   }

  //   if (segmentUpdatedAtIndex === 1 && updatedAtFrom && updatedAtTo) {
  //     fo.updated_at_from = updatedAtFrom.format(CONS.SERVER_DATETIME_FORMAT)
  //     fo.updated_at_to = updatedAtTo.format(CONS.SERVER_DATETIME_FORMAT)
  //   }

  //   // if (segmentHasMkpChIndex === 1) {
  //   //   fo.has_mkp_ch = false
  //   // }

  //   // if (segmentHasMkpChIndex === 2) {
  //   //   fo.has_mkp_ch = true
  //   // }

  //   // if (segmentHasMkpChIndex === 2 && mkpChIds && mkpChIds.length > 0) {
  //   //   fo.mkp_ch_ids = mkpChIds
  //   // }

  //   if (segmentHasMkpChIndex === 1 && _.isObject(mkpChannelActiveMap) && !_.isEmpty(mkpChannelActiveMap)) {
  //     const mkp_ch_ids = Object.keys(mkpChannelActiveMap)
  //       .filter((mkpChId) => mkpChannelActiveMap[mkpChId])
  //       .map((mkpChId) => parseInt(mkpChId))

  //     if (mkp_ch_ids && mkp_ch_ids.length > 0) {
  //       fo.mkp_ch_ids = mkp_ch_ids
  //     }
  //   }

  //   if (segmentHasMkpChNotInIndex === 1 && _.isObject(mkpChannelNotInActiveMap) && !_.isEmpty(mkpChannelNotInActiveMap)) {
  //     const mkp_ch_ids_not_in = Object.keys(mkpChannelNotInActiveMap)
  //       .filter((mkpChId) => mkpChannelNotInActiveMap[mkpChId])
  //       .map((mkpChId) => parseInt(mkpChId))

  //     if (mkp_ch_ids_not_in && mkp_ch_ids_not_in.length > 0) {
  //       fo.mkp_ch_ids_not_in = mkp_ch_ids_not_in
  //     }
  //   }

  //   if (segmentHasParentIndex === 1) {
  //     fo.has_parent = true
  //   }

  //   if (segmentHasParentIndex === 2) {
  //     fo.has_parent = false
  //   }

  //   if (segmentHasCreatedByMkpChIndex === 1) {
  //     fo.has_created_by_mkp_ch = false
  //   }

  //   if (segmentHasCreatedByMkpChIndex === 2) {
  //     fo.has_created_by_mkp_ch = true
  //   }

  //   if (segmentHasCreatedByMkpChSpecificIndex === 1 && hasCreatedByMkpChMkpChIds && hasCreatedByMkpChMkpChIds.length > 0) {
  //     fo.created_by_mkp_ch_ids = hasCreatedByMkpChMkpChIds
  //   }

  //   return fo
  // }

  goBack = () => {
    const { navigation } = this.props
    if (!navigation) {
      return
    }
    util.navGoBack(this.props)
  }

  navToProductViewModeAdd = () => {
    const { navigation } = this.props
    const storeId = util.getNavParam(this.props, 'store_id')
    navigation.dispatch(
      NavActions.navToProductViewAddProduct({
        store_id: storeId,
        onSuccessAddProductCallback: this.onSuccessAddProductCallback,
        onSuccessEditProductCallback: this.onSuccessEditProductCallback,
      })
    )
  }

  // ทำการ prepend product item ใน list
  onSuccessAddProductCallback = async (addedProduct: IProductDetailItem) => {
    const mainListRef = this.getMainProductListRef()
    const productListItem = this.getProductListItemFromProductDetailItem(addedProduct)
    // @ts-ignore FIXME: พังจากการพยายาม generic ProductListItemType
    await mainListRef.prependItem(productListItem)
  }

  getProductListItemFromProductDetailItem = (pdi: IProductDetailItem): IProductItemData => {
    // // Compute all available qty of variants
    // const availableQty = pdi.variants.reduce((prevAq, vItem) => {
    //   const aq = vItem.available_qty
    //   if (!aq) {
    //     return prevAq
    //   }
    //   return prevAq + aq
    // }, 0)

    // // Construct ProductListItem
    // const item: IProductItemData = {
    //   id: pdi.id,
    //   n: pdi.name,
    //   t: pdi.thumbnail_uris,
    //   i: pdi.min,
    //   x: pdi.max,
    //   q: availableQty,
    // }

    // // is my product?
    // if (pdi.parent_id) {
    //   item.h = pdi.parent_id
    //   item.m = 1
    // }

    // return item
    const pdli = util.convertFullProductDetailToShortProductListItem(pdi)
    return pdli
  }

  // ทำการ modify product item ใน list
  onSuccessEditProductCallback = async (editedProduct: IProductDetailItem) => {
    // console.log('TOBE:Implement editedProduct => ', editedProduct)
    const productListItem = this.getProductListItemFromProductDetailItem(editedProduct)
    const searchListRef = this.getSearchProductListRef()
    // @ts-ignore FIXME: พังจากการพยายาม generic ProductListItemType
    await searchListRef.repalceItemByKey('id', editedProduct.id, productListItem)
    const mainListRef = this.getMainProductListRef()
    // @ts-ignore FIXME: พังจากการพยายาม generic ProductListItemType
    await mainListRef.repalceItemByKey('id', editedProduct.id, productListItem)
  }

  // ทำการ remove product item ใน list
  onSuccessDeleteProductCallback = async (deletedProductId: number) => {
    const searchListRef = this.getSearchProductListRef()
    await searchListRef.removeItemByKey('id', deletedProductId)
    const mainListRef = this.getMainProductListRef()
    await mainListRef.removeItemByKey('id', deletedProductId)
  }

  // openBarcodeCamera = () => this.setState({ isBarcodeCameraVisible: true })
  // closeBarcodeCamera = async () => this.setState({ isBarcodeCameraVisible: false })

  // // override me please
  // onPressProductItem = (item: { index?: number; data: ProductListItemType }) => {
  //   // this.navToProductViewModeView(item.data.id)
  // }

  // onPressProductCategoryFilter = () => {
  //   try {
  //     this.drawerCatRef.current.open()
  //   } catch (error) {
  //     util.log(error)
  //   }
  // }

  // สั่งให้ InfiniteFlatList reset state ใหม่ทั้งหมด
  doRefresh = async () => {
    this.returnCategoryInfo = true
    const newState: Partial<IProductListViewState> = {}
    if (this.isSearching()) {
      await util.setStatePromise(this, { searchFetchErrorMessage: null })
      newState.searchProductCount = null
    } else {
      await util.setStatePromise(this, { mainFetchErrorMessage: null })
      newState.mainProductCount = null
    }
    // @ts-ignore FIXME: @O type ไม่ถูกต้อง
    await util.setStatePromise(this, newState)
    await util.delay(200)
    const productListRef = this.getFocusProductListRef()
    if (productListRef && _.isFunction(productListRef.reset)) {
      await productListRef.reset()
    }
  }

  // Internal Method
  _onPressProductItem = async (item: { index?: number; data: ProductListItemType }) => {
    if (this.inNavigationProcess) {
      return
    }
    this.inNavigationProcess = true
    await util.delay(200)
    if (_.isFunction(this.onPressProductItem)) {
      await this.onPressProductItem(item)
    }
    await util.delay(200)
    this.inNavigationProcess = false
  }

  _onChangeMainProductCount = (newCount: number) => this.setState({ mainProductCount: newCount })

  _onChangeProductListNumOfColumns = (newCount: number) => this.setState({ mainProductNumOfColumns: newCount })

  _onChangeSearchProductCount = (newCount: number) => this.setState({ searchProductCount: newCount })

  _applyRoleIdToRequestBody = (body) => {
    const newBody = _.cloneDeep(body)
    // backend require if role_id === 2 (helper)
    const selectedStoreId = this.props.selectedStore.get('id')
    const roleId = this.props.selectedStore.get('role_id') || null
    if (body.store_id === selectedStoreId && roleId === 2) {
      // ถ้าเป็นการ fetch สินค้าของร้านฉันให้ส่ง role_id ของ selectedStore ของฉันไป
      newBody.role_id = roleId
    }
    return newBody
  }

  // ใช้สั่ง reset searchList เพื่อทำให้เกิดการค้นหาใหม่
  refreshSearchList = async () => {
    if (!this.isSearching()) {
      return
    }
    const now = moment()
    const diffLastTypingMs = Math.abs(now.diff(this.lastSearchTypingDateTime, 'milliseconds'))
    if (diffLastTypingMs < SEARCH_TYPING_DELAY) {
      return
    }

    // current searchText
    const { searchText } = this.state
    if (this.lastSearchSuccessDateTime) {
      const diffLastSuccessMs = Math.abs(now.diff(this.lastSearchSuccessDateTime, 'milliseconds'))
      if (this.lastSearchText === searchText && diffLastSuccessMs < SEARCH_LATEST_KEYWORD_TIMEOUT) {
        // ถ้ามีการ search text เดียวกันภายใน 5 วินาทีที่ผ่านมาให้ ignore ไปก่อน (เผื่อ user กด submit ใหม่)
        return
      }
    }
    this.lastSearchText = searchText
    await this.doRefresh()
    this.lastSearchSuccessDateTime = moment()
  }

  _handleClearSearch = () => {
    this.setState({ searchText: '', isBarcodeSearching: false })
  }

  _handleChangeSearchText = (newText: string) => {
    const newState: Partial<IProductListViewState> = { searchText: newText }
    if (newText === '') {
      newState.isBarcodeSearching = false
    }
    // await util.setStatePromise<any>(this, newState)
    // await util.delay(200)
    // this._enqueueSearch()

    // @ts-ignore
    this.setState(newState, () => {
      setTimeout(this._enqueueSearch, 600 + 50 * newText.length)
    })
  }

  _enqueueSearch = () => {
    if (this.lastSearchRefreshQueueTimeout) {
      clearTimeout(this.lastSearchRefreshQueueTimeout)
    }
    this.lastSearchTypingDateTime = moment()
    // @ts-ignore
    this.lastSearchRefreshQueueTimeout = setTimeout(this.refreshSearchList, SEARCH_TYPING_DELAY)
  }

  _handleSubmitSearchByKeyboard = async () => {
    this._enqueueSearch()
  }

  _onProductCatScrollViewLayout = (evt) => {
    try {
      const { width } = evt.nativeEvent.layout
      const editedProperties = _.cloneDeep(this.state.productCategoryScrollViewProperties)
      editedProperties.currentWidth = width
      this.setState({ productCategoryScrollViewProperties: editedProperties })
    } catch (err) {
      // console.error('_onLayout err => ', err)
    }
  }

  _onProductCategoryScrollContentSizeChange = (width: number, height: number) => {
    const { currentWidth } = this.state.productCategoryScrollViewProperties
    const w = width - currentWidth
    this.productCategoryScrollableMaxX = w > 0 ? w : 0
    this.setState({
      productCategoryScrollViewProperties: {
        currentWidth,
        isVisibleScrollToBeginButton: this.productCategoryScrollableMaxX > 0 && this.productCategoryLastScrollX > 0,
        isVisibleScrollToEndButton:
          this.productCategoryScrollableMaxX > 0 && this.productCategoryLastScrollX < this.productCategoryScrollableMaxX - 4,
      },
    })
  }

  _handleProductCategoryScroll = (e) => {
    const { currentWidth } = this.state.productCategoryScrollViewProperties
    this.productCategoryLastScrollX = e.nativeEvent.contentOffset.x
    this.setState({
      productCategoryScrollViewProperties: {
        currentWidth,
        isVisibleScrollToBeginButton: this.productCategoryScrollableMaxX > 0 && this.productCategoryLastScrollX > 0,
        isVisibleScrollToEndButton:
          this.productCategoryScrollableMaxX > 0 && this.productCategoryLastScrollX < this.productCategoryScrollableMaxX - 8,
      },
    })
  }

  _handleProductCategoryScrollToBegin = () => {
    try {
      const { currentWidth } = this.state.productCategoryScrollViewProperties
      let nextOffset = this.productCategoryLastScrollX - currentWidth + 60
      if (nextOffset < 0) {
        nextOffset = 0
      }
      this.productCategoryScrollRef.current.scrollTo({ x: nextOffset, animated: true })
    } catch (err) {
      util.log(err)
    }
  }

  _handleProductCategoryScrollToEnd = () => {
    try {
      const { currentWidth } = this.state.productCategoryScrollViewProperties
      let nextOffset = this.productCategoryLastScrollX + currentWidth - 60
      if (nextOffset > this.productCategoryScrollableMaxX) {
        nextOffset = this.productCategoryScrollableMaxX
      }
      this.productCategoryScrollRef.current.scrollTo({ x: nextOffset, animated: true })
    } catch (err) {
      util.log(err)
    }
  }

  // _setCallBackMKPChannels = async (mkp_ch_ids: number[]) => {
  //   if (!_.isNil(mkp_ch_ids)) {
  //     await util.setStatePromise(this, {
  //       mkp_ch_ids,
  //     })
  //   } else {
  //     await util.setStatePromise(this, {
  //       mkp_ch_ids: [],
  //     })
  //   }
  // }

  _onSubmitProductListFilter = async (filterState: IProductListFilterState | IProductListFilterTemplate) => {
    // _onSubmitProductListFilter = async (submit: ISubmitDrawerCategory) => {
    // const { category, allChildrenCategory = true } = filterState
    console.log('_onSubmitProductListFilter filterState => ', filterState)
    await util.setStatePromise(this, { filterState })
    await util.delay(50)

    // // await this._setCallBackMKPChannels(submit.mkp_ch_ids)
    // // await this._setallChildrenCategory(allChildrenCategory)
    // await util.setStatePromise(this, { allChildrenCategory })

    // // if (!_.isNil(category)) {
    // await this._selectedSubCategory(category)
    // // }

    await this.doRefresh()
  }

  // _setallChildrenCategory = async (value: boolean) => {
  //   await util.setStatePromise(this, { allChildrenCategory: value })
  // }

  // _selectedSubCategory = async (category: ICategory) => {
  //   if (_.isNil(category)) {
  //     await this._configCategory()
  //     // await this.doRefresh()
  //     return
  //   }

  //   const { categoryList, selectedStore, navigation } = this.props
  //   // const store_id = selectedStore.get('id')
  //   const { store_id, seller_store_id, mode } = this._getParams()
  //   let storeId = this.getTargetStoreId()
  //   if (mode === PULL_PRODUCT) {
  //     storeId = seller_store_id
  //   } else if (mode === PULL_SELLER_PRODUCT_TO_ORDER) {
  //     storeId = seller_store_id
  //   } else if (mode === VIEW_SELLER) {
  //     storeId = seller_store_id
  //   }

  //   let CATEGORYS = null
  //   const CettoJS = _.isNil(categoryList) ? [] : categoryList.toJS()
  //   CettoJS.map((data: categoryByStore) => {
  //     if (storeId === data.store_id) {
  //       CATEGORYS = data.category
  //       if (category.id === 0) {
  //         CATEGORYS.push(category)
  //       } else {
  //         const unknownCat: ICategory = { id: 0, n: `ไม่ระบุหมวดหมู่`, l: COLORS.TEXT_INACTIVE }
  //         CATEGORYS.push(unknownCat)
  //       }
  //     }
  //   })
  //   if (!_.isNil(CATEGORYS) && _.isArray(CATEGORYS)) {
  //     const newLvlCat: ICategory[] = []
  //     const newSubCat: ICategory[] = []
  //     CATEGORYS.map((xData: ICategory) => {
  //       const data = xData
  //       if (xData.id === category.id) {
  //         data.selected = true
  //       }

  //       if (!_.isNil(data.p) && data.p === category.id) {
  //         newSubCat.push(data)
  //       }
  //       if (_.isNil(category.p) && _.isNil(data.p)) {
  //         newLvlCat.push(data)
  //       } else if (data.p === category.p) {
  //         newLvlCat.push(data)
  //       }
  //     })
  //     // if (_.isNil(category.p)) {
  //     //   const unknownCat: ICategory = { id: 0, n: `ไม่ระบุหมวดหมู่`, l: COLORS.TEXT_INACTIVE }
  //     //   newLvlCat.push(unknownCat)
  //     // }

  //     const { filterState: fs = {} } = this.state
  //     const filterState: IProductListFilterState = { ...fs }
  //     if (category) {
  //       filterState.category = category
  //     }
  //     await util.setStatePromise(this, {
  //       catLevelCategoryList: newLvlCat,
  //       catSeletedCategory: category,
  //       catSupCategory: newSubCat,
  //       filterState,
  //     })
  //     // await this.doRefresh()
  //   }
  // }

  // _onPressProductCategory = async (category: ICategory) => {
  //   await this._selectedSubCategory(category)
  //   await this.doRefresh()
  // }

  _getParams() {
    // _getParams(): { store_id: number; mode: string; seller_store_id: number } {
    // return this.props.navigation.state.params
    const params = util.getNavParams(this.props)
    return params
  }

  _configCategory = async () => {
    const { categoryList, requestCategoryList, navigation } = this.props
    const { mode, store_id, seller_store_id } = this._getParams()
    // console.log('mode => ', mode)
    if (mode === MKP_MAPPING) {
      return null
    }
    // const { mode, store_id, seller_store_id } = this._getParams()
    let storeId = this.getTargetStoreId()
    // const storeId = this.getTargetStoreId()
    let CATEGORYS = null
    const CettoJS = _.isNil(categoryList) ? [] : categoryList.toJS()
    CettoJS.map((data: categoryByStore) => {
      if (storeId === data.store_id) {
        CATEGORYS = data.category
      }
    })

    if (mode === PULL_PRODUCT) {
      storeId = seller_store_id
    } else if (mode === PULL_SELLER_PRODUCT_TO_ORDER) {
      storeId = seller_store_id
    } else if (mode === VIEW_SELLER) {
      storeId = seller_store_id
    }
    if (_.isNil(CATEGORYS) || CATEGORYS.length < 1) {
      const res: ICategory[] = await new Promise((resolve) => {
        requestCategoryList({
          body: {
            store_id: storeId,
          },
          successCallback: resolve,
          failedCallback: resolve,
        })
      })
      if (!_.isNil(res) && _.isArray(res)) {
        CATEGORYS = res
      }
    }
    const catSeletedCategory = null
    const catLevelCategoryList = []
    const catSupCategory = []
    if (_.isNil(CATEGORYS)) {
      CATEGORYS = []
    }
    const haveUnknownCat = CATEGORYS.find((data: ICategory) => data.id === 0)
    if (_.isNil(haveUnknownCat)) {
      const unknownCat: ICategory = { id: 0, n: `ไม่ระบุหมวดหมู่`, l: COLORS.TEXT_INACTIVE }
      CATEGORYS.push(unknownCat)
    }
    CATEGORYS.map((data: ICategory) => {
      if (_.isNil(data.p)) {
        catSupCategory.push(data)
        catLevelCategoryList.push(data)
      }
    })

    await util.setStatePromise(this, {
      catSeletedCategory,
      catLevelCategoryList,
      catSupCategory,
    })
  }

  // Render Methods
  renderProductListItem = (pItem: { item?: ProductListItemType; index: number; itemWidth: number }) => (
    <ProductListItem
      // @ts-ignore FIXME: พังจากการพยายาม generic ProductListItemType
      // key={`PLI_${pItem.index}_${pItem.item.id}`}
      // @ts-ignore FIXME: พังจากการพยายาม generic ProductListItemType
      data={pItem.item}
      index={pItem.index}
      maxWidth={pItem.itemWidth}
      disabled={this.isProductListItemPressDisabled()}
      // @ts-ignore FIXME: พังจากการพยายาม generic ProductListItemType
      onPress={_.isFunction(this.onPressProductItem) ? this._onPressProductItem : undefined}
      // @ts-ignore FIXME: พังจากการพยายาม generic ProductListItemType
      renderBottom={this.renderProductListItemBottom}
      numOfColumns={this.state.mainProductNumOfColumns}
      disabledIsSellerProductIcon={this.isProductListItemSellerIconDisabled()}
      disabledPrice={this.isProductListItemPriceDisabled()}
      disabledQty={this.isProductListItemQtyDisabled()}
      // @ts-ignore FIXME: พังจากการพยายาม generic ProductListItemType
      renderIcon={this.renderProductListItemIcon}
    />
  )

  // Override here if need ProductListItem special Info/Button
  renderProductListItemBottom = (item: { index: number; data: ProductListItemType }) => null

  // Override here if need ProductListItem special Info/Button
  renderProductListItemIcon = (item: { index: number; data: ProductListItemType }) => null

  // _renderProductCategoryScrollToBeginButton = () => {
  //   if (!this.state.productCategoryScrollViewProperties.isVisibleScrollToBeginButton) {
  //     return null
  //   }

  //   return (
  //     <TouchableOpacity
  //       // onPress={() => this.productCategoryScrollRef.current.scrollTo({ x: 0, animated: true })}
  //       onPress={this._handleProductCategoryScrollToBegin}
  //       style={{
  //         flex: 1,
  //         position: 'absolute',
  //         left: 0,
  //         height: 35,
  //         width: 22,
  //         flexDirection: 'row',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //         backgroundColor: 'rgba(88,88,88,0.4)',
  //       }}>
  //       <XIcon name='arrow-back' />
  //     </TouchableOpacity>
  //   )
  // }

  // _renderProductCategoryScrollToEndButton = () => {
  //   if (!this.state.productCategoryScrollViewProperties.isVisibleScrollToEndButton) {
  //     return null
  //   }

  //   return (
  //     <TouchableOpacity
  //       // onPress={() => this.productCategoryScrollRef.current.scrollToEnd()}
  //       onPress={this._handleProductCategoryScrollToEnd}
  //       style={{
  //         flex: 1,
  //         position: 'absolute',
  //         right: 0,
  //         height: 35,
  //         width: 22,
  //         flexDirection: 'row',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //         backgroundColor: 'rgba(88,88,88,0.4)',
  //       }}>
  //       <XIcon name='arrow-forward' />
  //     </TouchableOpacity>
  //   )
  // }

  // _renderCatListItems = (categories) =>
  //   categories.map((data: ICategory, index: number) => {
  //     const marginLeft = index === 0 ? 12 : 12
  //     const marginRight = index + 1 === categories.length ? 4 : 4
  //     const textColor = data.l ? data.l : COLORS.TEXT_INACTIVE
  //     return (
  //       <TouchableOpacity
  //         key={`CatListItem_${index}_${data.id}`}
  //         // onPress={() => this._selectedSubCategory(data)}
  //         onPress={() => this._onPressProductCategory(data)}
  //         style={{
  //           // minWidth: 50,
  //           // // width: 50,
  //           // // maxWidth: 120,
  //           // // height: 25,
  //           // borderColor: data.l,
  //           // borderWidth: 0.7,
  //           // borderRadius: 7,
  //           marginLeft,
  //           marginRight,
  //           // paddingLeft: 4,
  //           // paddingRight: 4,
  //           // justifyContent: 'center',
  //         }}>
  //         <Box minW='50px' borderWidth='1' borderRadius='lg' px='2' py='1' alignItems='center' justifyContent='center' borderColor={data.l}>
  //           <XText style={{ color: textColor }}>{data.n}</XText>
  //         </Box>
  //       </TouchableOpacity>
  //     )
  //   })

  _onSelectCategory = (category: ICategory) => {
    const { filterState = {} } = this.state

    const newFilterState = { ...filterState } as IProductListFilterState
    if (category && category.id) {
      newFilterState.selectedCategoryId = category.id
    } else {
      newFilterState.selectedCategoryId = null
    }

    if ('id' in newFilterState) {
      delete newFilterState.id
    }

    this._onSubmitProductListFilter(newFilterState)
  }

  renderCatListBar = () => {
    const { filterState = {} } = this.state
    const storeId = this.getTargetStoreId()

    return (
      <ProductCategorySelector
        storeId={storeId}
        // @ts-ignore
        selectedCategoryId={filterState.selectedCategoryId || null}
        onSelectCategory={this._onSelectCategory}
      />
    )

    // // const { categoryList } = this.props
    // const { catSupCategory, mainProductCount } = this.state
    // if (_.isNil(mainProductCount)) {
    //   return null
    // }

    // const categories = _.isNil(catSupCategory) ? [] : catSupCategory
    // // const bodyHeight = categories.length < 1 ? 0 : 35

    // const isHidden = !categories || categories.length === 0
    // if (isHidden) {
    //   return null
    // }

    // return (
    //   <XHorizontalScrollView w='full' _contentContainerStyle={{ py: '2' }} bg={COLORS.BG_SOFT_GREY}>
    //     {this._renderCatListItems(categories)}
    //   </XHorizontalScrollView>
    // )
    // // return (
    // //   <View
    // //     style={[
    // //       S.WIDTH_FULL,
    // //       S.MIN_HEIGHT_40,
    // //       S.ROW_MIDDLE_START,
    // //       {
    // //         backgroundColor: COLORS.BG_SOFT_GREY,
    // //       },
    // //     ]}>
    // //     <ScrollView
    // //       ref={this.productCategoryScrollRef}
    // //       onLayout={this._onProductCatScrollViewLayout}
    // //       onContentSizeChange={this._onProductCategoryScrollContentSizeChange}
    // //       // style={{ maxWidth: this.getScreenWidth() }}
    // //       style={[S.WIDTH_FULL, S.MIN_HEIGHT_34]}
    // //       contentContainerStyle={[S.ROW_MIDDLE_START]}
    // //       horizontal
    // //       // showsHorizontalScrollIndicator={p.op.isWeb()}
    // //       showsHorizontalScrollIndicator={false}
    // //       onScroll={p.op.isWeb() ? this._handleProductCategoryScroll : null}
    // //       onMomentumScrollEnd={this._handleProductCategoryScroll}
    // //       onScrollEndDrag={this._handleProductCategoryScroll}>
    // //       {this._renderCatListItems(categories)}
    // //     </ScrollView>
    // //     {p.op.isWeb() ? this._renderProductCategoryScrollToBeginButton() : null}
    // //     {p.op.isWeb() ? this._renderProductCategoryScrollToEndButton() : null}
    // //   </View>
    // // )
  }

  // _renderCategoryFilterView = () => {
  //   const { categoryList } = this.props
  //   const { catLevelCategoryList } = this.state
  //   return (
  //     <CategoryFilterView
  //       onRequestCloseView={this.onRequestCloseCategoryFilterView}
  //       levelCategoryList={catLevelCategoryList}
  //       categoryList={categoryList.toJS()}
  //       // selectedCategory={catSeletedCategory}
  //       // submit={async (category: ISubmitDrawerCategory) => this._onSubmitProductListFilter(category)}
  //       onSubmit={this._onSubmitProductListFilter}
  //     />
  //   )
  // }

  renderAddProductButton = () => {
    if (!this.isAddProductButtonVisible() || this.isSearchMode()) {
      return null
    }
    const isAddProductAvailable = acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PRODUCT_ADD)
    if (!isAddProductAvailable) {
      return null
    }

    const isDisabled = !this.isProductListViewReady()
    return <XIconButton family='AntDesign' name='plus' disabled={isDisabled} onPress={this.navToProductViewModeAdd} />
  }

  isFiltered = () => {
    const { filterState = {} } = this.state
    const defaultFilterState = this.getDefautProductListFilterState()

    const filterDiff = diff(defaultFilterState, filterState)
    if (_.isEmpty(filterDiff)) {
      return false
    }
    return true
  }

  renderFilterProductsButton = () => {
    // const { catSeletedCategory } = this.state
    // const selected = !_.isNil(catSeletedCategory)
    // const isDisabled = !this.isProductListViewReady()
    // return (
    //   <TouchableOpacity disabled={isDisabled} onPress={this.onPressProductCategoryFilter}>
    //     <VStack w='9' h='9' alignItems='center' justifyContent='center'>
    //       <Box pt='1'>
    //         <XIcon family='MaterialCommunityIcons' name='filter' variant={isDisabled ? 'inactive' : 'primary'} />
    //         {selected ? (
    //           <XIcon
    //             family='MaterialCommunityIcons'
    //             name='check-circle'
    //             variant='success'
    //             position='absolute'
    //             bottom='0'
    //             right='0'
    //             size='12px'
    //             // style={{ fontSize: 12, width: 12, height: 12, color: COLORS.BRAND_Success }}
    //             // style={{ color: COLORS.BRAND_Success, fontSize: 12, position: 'absolute', paddingTop: 12, paddingLeft: 12 }}
    //           />
    //         ) : null}
    //       </Box>
    //     </VStack>
    //   </TouchableOpacity>
    // )
    // const { categoryList } = this.props
    // const { catLevelCategoryList, filterState = {} } = this.state

    // return (
    //   <ProductListFilterView
    //     // ref={this.drawerCatRef}
    //     levelCategoryList={catLevelCategoryList}
    //     categoryList={categoryList.toJS()}
    //     // selectedCategory={catSeletedCategory}
    //     // submit={async (category: ISubmitDrawerCategory) => this._onSubmitProductListFilter(category)}
    //     defaultFilter={this.getDefautProductListFilterState()}
    //     currentFilter={filterState}
    //     onSubmit={this._onSubmitProductListFilter}
    //   />
    // )

    const isFiltered = this.isFiltered()
    const isDisabled = !this.isProductListViewReady()

    return (
      <TouchableOpacity disabled={isDisabled} onPress={this.onPressProductCategoryFilter}>
        <VStack w='9' h='9' alignItems='center' justifyContent='center'>
          <Box pt='1'>
            <XIcon family='MaterialCommunityIcons' name='filter' variant={isDisabled ? 'inactive' : 'primary'} />
            {isFiltered ? (
              <XIcon
                family='MaterialCommunityIcons'
                name='check-circle'
                variant='success'
                position='absolute'
                bottom='0'
                right='0'
                size='12px'
                // style={{ fontSize: 12, width: 12, height: 12, color: COLORS.BRAND_Success }}
                // style={{ color: COLORS.BRAND_Success, fontSize: 12, position: 'absolute', paddingTop: 12, paddingLeft: 12 }}
              />
            ) : null}
          </Box>
        </VStack>
      </TouchableOpacity>
    )
  }

  onPressProductCategoryFilter = () => {
    // const { categoryList } = this.props
    // const { catLevelCategoryList, filterState = {} } = this.state
    const { filterState = {} } = this.state

    const { navigation } = this.props
    navigation.dispatch(
      NavActions.navToProductListFilterView({
        store_id: this.getTargetStoreId(),
        // levelCategoryList: catLevelCategoryList,
        // categoryList: categoryList.toJS(),
        defaultFilter: this.getDefautProductListFilterState(),
        // currentFilter: filterState,
        appliedSetting: filterState,
        onSubmit: this._onSubmitProductListFilter,
      })
    )
  }

  getSearchTextWithCountPlaceholder = (mainProductCount: number) => `ค้นหาสินค้าในร้าน ${mainProductCount}`

  getSearchTextWithoutCountPlaceholder = () => 'ไม่พบรายการสินค้า'

  getSearchTextPlaceholder = () => {
    if (!this.isProductListViewReady()) {
      return 'กำลังโหลดข้อมูล...'
    }

    // เช็คเฉพาะ mainProductCount เนื่องจาก เมื่อเราพิมพ์ลงใน search box ก็จะไม่เห็น placeholder อยู่ดี
    const { searchText = '', catSeletedCategory, mainProductCount } = this.state
    if (searchText.length > 0) {
      return ''
    }

    const hasProducts = mainProductCount > 0
    let placeholderSearch = hasProducts
      ? this.getSearchTextWithCountPlaceholder(mainProductCount)
      : this.getSearchTextWithoutCountPlaceholder()
    // if (mode === PULL_PRODUCT) {
    //   placeholderSearch = canSearch ? `ค้นหาสินค้าพร้อมดึง ${textCount}` : ''
    // }
    if (_.isObject(catSeletedCategory) && _.isString(catSeletedCategory.n)) {
      placeholderSearch = catSeletedCategory.n
      if (_.isNumber(mainProductCount) && mainProductCount > 0) {
        placeholderSearch += ` มี ${mainProductCount} รายการสินค้า`
      } else {
        placeholderSearch += ` ไม่พบรายการสินค้า`
      }
    }
    return placeholderSearch
  }

  renderSearchInfoBar = () => {
    if (!this.isSearchMode() || _.isNil(this.state.mainProductCount)) {
      return null
    }
    const { searchText = '', searchProductCount, catSeletedCategory } = this.state
    let txtSearchResult

    if (!this.isSearching()) {
      txtSearchResult = `กำลังค้นหาสินค้าที่มีชื่อประกอบด้วย "${searchText}" ...`
    } else {
      if (searchProductCount) {
        txtSearchResult = `${searchProductCount} รายการตรงกับการค้นหา "${searchText}"`
      } else {
        txtSearchResult = `ไม่พบสินค้าที่มีชื่อประกอบด้วย "${searchText}"`
      }
      if (catSeletedCategory && catSeletedCategory.n) {
        txtSearchResult += `ในหมวดหมู่ "${catSeletedCategory.n}"`
      }
    }

    return (
      <HStack w='full' p='2' alignItems='center'>
        <XText fontSize='xs' variant='inactive' numberOfLines={2}>
          {txtSearchResult}
        </XText>
      </HStack>
    )
  }

  renderSearchBar = () => {
    const { searchText } = this.state
    // const isDisabled = !this.isProductListViewReady()

    // if (p.op.isWeb()) {
    return (
      <HStack flex={1} minH='9' space='1' alignItems='center'>
        {this.renderSearchByBarcodeButton()}
        <XInput
          flex={1}
          // disabled={isDisabled}
          ref={this.searchTextRef}
          value={searchText}
          onChangeText={this._handleChangeSearchText}
          placeholder={this.getSearchTextPlaceholder()}
          onSubmitEditing={this._handleSubmitSearchByKeyboard}
          placeholderTextColor={COLORS.TEXT_INACTIVE}
          autoCapitalize='none'
          returnKeyType='search'
          // style={{ paddingLeft: 8, paddingRight: 8 }}
          px='1'
          InputLeftElement={
            <Box px='1'>
              <XIcon inactive name='search' />
            </Box>
          }
          InputRightElement={<ButtonClose isVisible={this.isSearchMode()} onPress={this._handleClearSearch} />}
        />
      </HStack>
    )
    // }

    // return (
    //   <HStack flex={1} alignItems='center'>
    //     {this.renderSearchByBarcodeButton()}
    //     <HStack flex={1} minH='9' space='1' alignItems='center'>
    //       <XInput
    //         flex={1}
    //         // disabled={isDisabled}
    //         ref={this.searchTextRef}
    //         value={searchText}
    //         onChangeText={this._handleChangeSearchText}
    //         placeholder={this.getSearchTextPlaceholder()}
    //         onSubmitEditing={this._handleSubmitSearchByKeyboard}
    //         placeholderTextColor={COLORS.TEXT_INACTIVE}
    //         autoCapitalize='none'
    //         returnKeyType='search'
    //         px='8'
    //         // style={{ paddingLeft: 8, paddingRight: 8 }}
    //       />
    //       <Box px='1' alignItems='center' justifyContent='center' position='absolute' left={0} top={0} bottom={0}>
    //         <XIcon inactive name='search' />
    //       </Box>
    //       <Box px='1' alignItems='center' justifyContent='center' position='absolute' right={0} top={0} bottom={0}>
    //         <ButtonClose isVisible={this.isSearchMode()} onPress={this._handleClearSearch} />
    //       </Box>
    //     </HStack>
    //   </HStack>
    // )
  }

  renderSearchByBarcodeButton = () => {
    if (!this.isSkuSearchAvailable() && !this.isUpcSearchAvailable()) {
      return null
    }

    // const { isBarcodeCameraVisible } = this.state
    // return (
    //   <Button
    //     // disabled={!this.isProductListViewReady()}
    //     onPress={this.openBarcodeCamera}
    //     // @ts-ignore
    //     style={StyleSheet.flatten({
    //       flex: 0,
    //       flexBasis: 'auto',
    //       height: 44,
    //       width: 44,
    //     })}>
    //     <XIcon activeDark={true} name='barcode-scan' family='MaterialCommunityIcons' />
    //     <XCamera
    //       title={`สแกนบาร์โค้ดเพื่อค้นหาสินค้า`}
    //       onBarcodeRead={this._onProductBarcodeScanned}
    //       onRequestClose={this.closeBarcodeCamera}
    //       isVisible={isBarcodeCameraVisible}
    //     />
    //   </Button>
    // )

    return (
      <BarcodeScannerModalButton
        btnStyle={[S.HEIGHT_40, S.WIDTH_40, { marginRight: 4 }]}
        saveStateKey={CONS.STATE_SAVE_KEYS.BARCODE_SCANNER_MODAL}
        headerTitle='สแกนบาร์โค้ดเพื่อค้นหาสินค้า'
        onBarcodeScanned={this._onProductBarcodeScanned}
      />
    )
  }

  // _onProductBarcodeScanned = async (barcodeText: string) => {
  _onProductBarcodeScanned = async (barcodeOutput: IBarcodeScannerOutput) => {
    // console.log('_onProductBarcodeScanned barcodeOutput => ', barcodeOutput)
    const barcodeText: string = barcodeOutput.data
    await util.setStatePromise(this, { isBarcodeSearching: true, searchText: barcodeText })
    await util.delay(200)
    this._enqueueSearch()
  }

  renderGoBackButton = () => {
    if (this.getIsGoBackButtonHidden()) {
      return null
    }
    return <NavHeaderButton backIcon onPressItem={this.goBack} />
  }

  // _onSortOptionsApplyWithSaveState = async (sortOptions: IProductListSortOptions) => {
  //   await util.setStatePromise(this, { sortOptions })
  //   await util.delay(200)
  //   this.doRefresh()
  // }

  // renderSortIconButton = () => {
  //   const { sortOptions } = this.state
  //   return (
  //     <VStack w='9' h='9' alignItems='center' justifyContent='center'>
  //       <ProductListSortIconButton
  //         // btnStyle={{ marginRight: 4 }}
  //         currentSortOptions={sortOptions}
  //         onApply={this._onSortOptionsApplyWithSaveState}
  //       />
  //     </VStack>
  //   )
  // }

  renderCustomHeader = () => (
    <VStack w='full' px='2' py='1' minH='11' justifyContent='center' _light={{ bg: NB.C.L.BG.CLEAR }} _dark={{ bg: NB.C.D.BG.CLEAR }}>
      <XStatusBar translucent backgroundColor='transparent' />
      <HStack w='full'>
        {this.renderGoBackButton()}
        {this.renderSearchBar()}
        {this.renderAddProductButton()}
        {/* {this.renderSortIconButton()} */}
        {this.renderFilterProductsButton()}
      </HStack>
    </VStack>
  )

  // renderHeaderBar = () => {
  //   return (
  //     <View style={S.HEADER_CONTAINER}>
  //       <View style={S.HEADER_BODY_LEFT}>
  //         <TouchableOpacity style={S.BUTTON_ICON} onPress={this.goBack}>
  //           <BackIcon size={STYLES.FONT_ICON_NORMAL} />
  //         </TouchableOpacity>
  //       </View>
  //       <View style={S.HEADER_BODY_CENTER}>
  //         <XText>{'title'}</XText>
  //       </View>

  //       <View style={S.HEADER_BODY_RIGHT}>
  //         <TouchableOpacity style={S.BUTTON_ICON}>
  //           <XIcon name='search' family='FontAwesome' size={STYLES.FONT_ICON_NORMAL} />
  //         </TouchableOpacity>
  //       </View>
  //     </View>
  //   )
  // }

  renderWatingSearchTypingInfo = () => {
    if (!this.isSearchMode() || this.isSearching()) {
      return null
    }
    return (
      <Box my='4' p='2' alignItems='center' justifyContent='center'>
        <XText variant='inactive'>กรุณาพิมพ์พิมพ์ 2 ตัวอักษรขึ้นไป เพื่อเริ่มการค้นหา</XText>
      </Box>
    )
  }

  // renderProductListFilterView = (children?: React.ReactNode) => {
  //   const { categoryList } = this.props
  //   const { catLevelCategoryList } = this.state
  //   return (
  //     <ProductListFilterView
  //       // ref={this.drawerCatRef}
  //       levelCategoryList={catLevelCategoryList}
  //       categoryList={categoryList.toJS()}
  //       // selectedCategory={catSeletedCategory}
  //       // submit={async (category: ISubmitDrawerCategory) => this._onSubmitProductListFilter(category)}
  //       onSubmit={this._onSubmitProductListFilter}>
  //       {children}
  //     </ProductListFilterView>
  //   )
  // }

  // แสดงเมื่อในร้านยังไม่มีสินค้าสักชิ้นเลย
  renderHaveNoProductItem = (noProductItemText: string) => (
    <View style={[S.COLUMN_CENTER, S.PADDING_8, { width: '100%', minHeight: 40, paddingTop: 20 }]}>
      <XText variant='inactive' textAlign='center'>
        {noProductItemText}
      </XText>
    </View>
  )

  renderMainProductListHaveNoProductItem = () => {
    if (this.state.mainProductCount !== 0) {
      return null
    }
    return this.renderHaveNoProductItem(this.getHaveNoProductItemText())
  }

  renderSearchProductListHaveNoProductItem = () => {
    if (this.state.searchProductCount !== 0) {
      return null
    }
    return this.renderHaveNoProductItem(this.getSearchProductListHaveNoProductItemText())
  }

  // showAlertFetchError = async (fetchErrorMessage) => {
  //   const errMessage = `${fetchErrorMessage.statusCode} ${fetchErrorMessage.statusMessage}`
  //   const isUserConfirm = await p.op.isUserConfirm(
  //     'เกิดข้อผิดพลาดในการโหลดข้อมูล',
  //     `${errMessage}\n\nกรุณาคิดลอกข้อผิดพลาดส่งมาให้ทีมงาน หากไม่ได้รับความสะดวกในการใช้งาน`,
  //     'คัดลอกข้อผิดพลาด',
  //     'ปิด'
  //   )
  //   if (isUserConfirm) {
  //     Clipboard.setString(errMessage)
  //     p.op.showToast(`คัดลอก ${errMessage} แล้ว`, 'success')
  //   }
  // }

  // แสดง error message จากการ fetch
  renderFetchErrorMessage = (fetchErrorMessage) => {
    const handleshowAlertFetchError = () => util.showAlertFetchError(fetchErrorMessage)
    // return (
    //   <View style={[S.COLUMN_CENTER, { width: '100%', minHeight: 40, paddingTop: 20 }]}>
    //     <View style={[S.ROW_CENTER]}>
    //       <XText style={[S.TEXT_INACTIVE, S.TEXT_ALIGN_CENTER]}>{'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'}</XText>
    //       <TouchableOpacity onPress={handleshowAlertFetchError}>
    //         <XIcon name='help-circle-outline' inactive={true} style={{ flex: 0, minWidth: 20, fontSize: STYLES.FONT_ICON_SMALLER }} />
    //       </TouchableOpacity>
    //     </View>
    //     <View style={[S.ROW_CENTER]}>
    //       <XText style={[S.TEXT_INACTIVE, S.TEXT_ALIGN_CENTER]}>
    //         {'หากกดปุ่มแล้วยังโหลดไม่สำเร็จ กรุณาปิดแอปแล้วเปิดใหม่ เพื่อลองอีกครั้ง'}
    //       </XText>
    //     </View>
    //     <View style={{ height: 12 }} />
    //     <TouchableOpacity
    //       onPress={this.doRefresh}
    //       style={[S.BUTTON_OUTLINE, S.BG_GREY, S.ROW_CENTER, S.PADDING_VERTICAL_4, S.PADDING_HORIZONTAL_12, { width: 100, height: 34 }]}>
    //       <XIcon
    //         name={'refresh'}
    //         family={'FontAwesome'}
    //         inactive={true}
    //         style={{ flex: 0, flexBasis: 'auto', minWidth: 0, height: STYLES.FONT_ICON_NORMAL, width: STYLES.FONT_ICON_NORMAL }}
    //       />
    //       <View style={[S.COLUMN_CENTER, S.PADDING_4]}>
    //         <XText style={[S.TEXT_ACTIVE]}>{'โหลดใหม่'}</XText>
    //       </View>
    //     </TouchableOpacity>
    //   </View>
    // )

    return <ErrorListItem onPressShowAlertInfo={handleshowAlertFetchError} onPressDoRefresh={this.doRefresh} />
  }

  renderMainListEmptyItem = () => {
    const { mainFetchErrorMessage } = this.state
    if (mainFetchErrorMessage) {
      return this.renderFetchErrorMessage(mainFetchErrorMessage)
    }

    return this.renderMainProductListHaveNoProductItem()
  }

  renderSearchListEmptyItem = () => {
    const { searchFetchErrorMessage } = this.state
    if (searchFetchErrorMessage) {
      return this.renderFetchErrorMessage(searchFetchErrorMessage)
    }

    return this.renderSearchProductListHaveNoProductItem()
  }

  renderMainList = () => (
    <InfiniteList<ProductListItemType>
      key='ProductListMain'
      isHidden={this.isHiddenMainProductList()}
      ref={this.mainListRef}
      renderItem={this.renderProductListItem}
      onFetchItems={this.fetchMainProducts}
      onChangeTotalCount={this._onChangeMainProductCount}
      onChangeNumOfColumns={this._onChangeProductListNumOfColumns}
      fetchLimit={CONS.PRODUCTS_FETCH_LIMIT_MORE}
      itemWidth={PRODUCT_LIST_ITEM_WIDTH}
      // style={{
      //   backgroundColor: COLORS.BG_LIGHT_GREY,
      // }}
      // contentContainerStyle={{
      //   paddingTop: 4,
      //   paddingBottom: 12,
      // }}
      ListEmptyComponent={this.renderMainListEmptyItem()}
    />
  )

  renderSearchList = () => (
    <InfiniteList<ProductListItemType>
      key='ProductListSearch'
      isHidden={this.isHiddenSearchProductList()}
      ref={this.searchListRef}
      renderItem={this.renderProductListItem}
      onFetchItems={this.fetchSearchProducts}
      onChangeTotalCount={this._onChangeSearchProductCount}
      fetchLimit={CONS.PRODUCTS_FETCH_LIMIT_MORE}
      itemWidth={PRODUCT_LIST_ITEM_WIDTH}
      // style={{
      //   backgroundColor: COLORS.BG_LIGHT_GREY,
      // }}
      // contentContainerStyle={{
      //   paddingTop: 4,
      //   paddingBottom: 12,
      // }}
      ListEmptyComponent={this.renderSearchListEmptyItem()}
    />
  )

  // renderVerticalSeparator = (height: number) => {
  //   return <View style={{ height, width: '100%' }} />
  // }

  // renderMain = () => this.renderProductListFilterView(this.renderContainer(this.renderContent()))

  renderMain = () => this.renderContainer(this.renderContent())

  renderContainer = (content) => <XContainer>{content}</XContainer>

  // ถ้าต้องการจัด content ใหม่ ปรับตรงนี้
  renderContent = () => (
    <VStack flex={1} w='full'>
      {this.renderCustomHeader()}
      {this.renderSearchInfoBar()}
      {this.renderCatListBar()}
      <VStack w='full' flex={1} _light={{ bg: 'coolGray.200' }} _dark={{ bg: 'warmGray.700' }}>
        {this.renderMainList()}
        {this.renderWatingSearchTypingInfo()}
        {this.renderSearchList()}
      </VStack>
      {/* {this.renderProductListFilterView()} */}
    </VStack>
  )

  // renderMainWithCategoryFilter = () => {
  //   return this.renderProductListFilterView(this.renderMain())
  // }

  render() {
    return this.renderMain()
  }
}

const s = {
  GRID_CONTAINER: {
    flex: 1,
    minHeight: 44,
  },
  GRID_COL_MIDDLE_CENTER: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  GRID_COL_MIDDLE_RIGHT: {
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  HEADER_COL_ICON: {
    flex: 0,
    minWidth: 54,
  },
  CUSTOM_HEADER: {
    backgroundColor: '#fff',
    paddingLeft: 2,
    paddingRight: 2,
    height: 54,
    marginTop: 0,
    paddingTop: 0,
    ...Platform.select({
      // android: {
      //   marginTop: getStatusBarHeight(true),
      // },
      // ios: {
      //   marginTop: isIphoneX() ? getStatusBarHeight() - 4 : undefined,
      // },
      web: {
        height: CONS.SPECIFIC_PLATFORM.WEB.HEADER_HEIGHT, // NAV_HEADER_HEIGHT
      },
    }),
  },
}

export default BaseUIProductListView
