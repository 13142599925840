import { Map, fromJS } from 'immutable'

// import api from '../../utils/api'
import { take, takeLatest, put, call, fork, all } from 'redux-saga/effects'
import _ from 'lodash'
import { flowDone } from 'x/utils/global'
import { IApiOptions } from 'x/types'
import CONS from '../../config/constants'
// import actions from '../../config/actions'
// import { getPrefFetchOrders } from './../selectors'
// import { NavigationActions } from 'react-navigation'

import * as OrderActions from '../../modules/order/OrderState'
import { getSelectedOrder, getPrefFetchOrders } from '../selectors'
// import store from '../../../src/redux/store'

import p from '../../config/platform-specific'
import actions from '../../config/actions'
import api from '../../utils/api'
// import * as util from '../../utils/util'
import { log } from '../../utils/util'
import { ActionApiParams } from '../../index'

export default function* watchAllOrders() {
  yield all([
    fork(watchStoreOrders),
    fork(watchPaymentOrders),
    // fork(watchOrdersFetchMore),

    fork(watchOrderDetailFetch),
    fork(watchOrderDetailConfirm),
    fork(watchOrderDetailEditOrderAddress),
    fork(watchOrderCreate),
    fork(watchOrderCancel),
    fork(watchOrderOptionChange),
    fork(watchShippingTypeChange),
    fork(watchShippingCostCalc),
    fork(watchShippingAutoComplete),
    fork(watchShippingCancel),
    fork(watchFetchSearchOrders),
    fork(watchPostCopyLinkOrder),
    fork(watchFetchOrderNotes),
    fork(watchAddOrderNote),
    fork(watchEditOrderNote),
    fork(watchDeleteOrderNote),
    fork(watchSelectOrderNote),
    fork(watchPostCancelTackingCode),
    fork(watchCreateTackingCode),
    fork(watchUpdateTackingCode),
    fork(watchGetShippingStatus),
    fork(watchAutoRequestTracking),
    fork(watchOrderSummary),
    fork(watchComfirmOrdersMKP),
    fork(watchfetchMarketplaceOrder),
    fork(watchOrderDateDeliveryChange),
    fork(watchDateTimeAutoCancelOrderChange),
  ])
}

function* watchStoreOrders() {
  log('In watchOrders')
  yield takeLatest(actions.STORE_ORDERS_FETCH, fetchStoreOrders)
}

// TODO: Refactor this method (combine them)
function* fetchStoreOrders(action) {
  // log('sagas/orders/fetchStoreOrders.actionNative', action)
  // log(store.getState())
  const storesApiOptions = {
    showSpinner: true,
  }

  // const fetchOrdersPref = getPrefFetchOrders(p.op.getAppState()).toJS()
  // const fetchOrdersPrefImmutable = yield select(getPrefFetchOrders)
  // const fetchOrdersLimit = fetchOrdersPrefImmutable.get('limit')
  // const fetchOrdersPref = isImmutable(fetchOrdersPrefImmutable) ? fetchOrdersPrefImmutable.toJS() : Map({})
  // based with pref then overwrite setting from payload
  // const reqBody = Object.assign(fetchOrdersPref, action.payload)
  // O: ด้านบนคือของเดิม ใช้ sortType / sortBy จาก pref เราจะเลิกใช้จากที่นั่น
  const reqBody = action.payload
  let fetchOrdersLimit

  if (!action.payload.limit) {
    reqBody.limit = CONS.DEFAULT_ORDERS_FETCH_LIMIT
  }
  fetchOrdersLimit = reqBody.limit

  if (!action.payload.sortBy) {
    reqBody.sortBy = CONS.DEFAULT_ORDERS_SORT_OPTIONS.sortBy
  }

  if (!action.payload.sortType) {
    reqBody.sortType = CONS.DEFAULT_ORDERS_SORT_OPTIONS.sortType
  }

  if (!reqBody.summary) {
    storesApiOptions.showSpinner = false
  }

  // let payload = {
  //   store_id: 1,
  //   summary: true,
  //   types: [
  //     'myTasks_confirmOrder',
  //     'myTasks_pay',
  //     'myTasks_confirmGettingPaid',
  //   ],
  //   limit: 20,
  // }

  // // BEGIN TESTING For DEV
  // reqBody.types =  [
  //     'myTasks_confirmOrder',
  //     'myTasks_pay',
  //     'myTasks_confirmGettingPaid',
  //     'myTasks_ship',
  //     'custTasks_pay',
  //     'custTasks_autoComplete',
  //     'resellerTasks_confirmOrder',
  //     'resellerTasks_pay',
  //     'resellerTasks_confirmGettingPaid',
  //     'sellerTasks_confirmOrder',
  //     'sellerTasks_confirmGettingPaid',
  //     'sellerTasks_ship',
  //     'sellerTasks_pay',
  //     'doneTasks_shipped',
  //     'doneTasks_autoCompleted',
  //     'doneTasks_cancelled',
  // ],
  // END TESTING For DEV

  try {
    // log('before calling in fetchOrders')
    let shouldResetTabStatus = false
    if (reqBody.shouldResetTabStatus) {
      shouldResetTabStatus = reqBody.shouldResetTabStatus
      delete reqBody.shouldResetTabStatus // no need to send to backend as it's for UI use only
    }

    // yield put({ type: actions.SET_ORDERS_IS_FETCHING, payload: true })
    const res = yield call(api.post, api.POST_ORDERS, reqBody, storesApiOptions)
    // log('fetchStoreOrders.res', res)
    if (shouldResetTabStatus) {
      res.shouldResetTabStatus = shouldResetTabStatus
    }

    if (fetchOrdersLimit) {
      res.fetchOrdersLimit = fetchOrdersLimit
    }

    yield put({ type: actions.STORE_SET_ORDERS_STATE, payload: fromJS(res), mode: action.mode })
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
    log(error)
    // TODO: Handle this error action type
    // yield put({ type: actions.ORDERS_REQUEST_FAILED, error })
  }
}

function* watchPaymentOrders() {
  log('In watchPaymentOrders')
  yield takeLatest(actions.PAYMENT_ORDERS_FETCH, fetchPaymentOrders)
}

function* fetchPaymentOrders(action) {
  log('sagas/orders/fetchPaymentOrders.action', action)
  const storesApiOptions = {
    showSpinner: true,
  }

  const fetchOrdersPref = getPrefFetchOrders(p.op.getAppState()).toJS()

  // based with pref then overwrite setting from payload
  const reqBody = Object.assign(fetchOrdersPref, action.payload)
  if (!reqBody.summary) {
    storesApiOptions.showSpinner = false
  }

  // let payload = {
  //   store_id: 1,
  //   summary: true,
  //   types: [
  //     'myTasks_confirmOrder',
  //     'myTasks_pay',
  //     'myTasks_confirmGettingPaid',
  //   ],
  //   limit: 20,
  // }
  log('fetchPaymentOrders.reqBody', reqBody)
  try {
    log('before calling in fetchPaymentOrders')
    // yield put({ type: actions.SET_ORDERS_IS_FETCHING, payload: true })
    const res = yield call(api.post, api.POST_ORDERS, reqBody, storesApiOptions)
    log('fetchPaymentOrders.res', res)
    yield put({ type: actions.PAYMENT_SET_ORDERS_STATE, payload: fromJS(res), mode: action.mode })
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
    log(error)
    // TODO: Handle this error action type
    // yield put({ type: actions.ORDERS_REQUEST_FAILED, error })
  }
}

/*
function* watchOrdersFetchMore() {
  log('In watchOrdersFetchMore')
  yield takeLatest(actions.ORDERS_FETCH_MORE, fetchOrdersMore)
}

function* fetchOrdersMore(action) {
  log('sagas/orders/fetchOrders.action', action)
  const storesApiOptions = {
    showSpinner: true,
  }

  const fetchOrdersPref = getPrefFetchOrders(store.getState()).toJS()

  // based with pref then overwrite setting from payload
  var reqBody = Object.assign(fetchOrdersPref, action.payload)

  // FIXME: Workaround typo until Night fix on server
  reqBody.offSet = reqBody.offset

  // let payload = {
  //   store_id: 1,
  //   summary: true,
  //   types: [
  //     'myTasks_confirmOrder',
  //     'myTasks_pay',
  //     'myTasks_confirmGettingPaid',
  //   ],
  //   limit: 20,
  // }
  log('fetchOrders.reqBody', reqBody)
  try {
    log('before calling in fetchOrders')
    // yield put({ type: actions.SET_ORDERS_IS_FETCHING, payload: true })
    const res = yield call(api.post, api.POST_ORDERS, reqBody, storesApiOptions)
    log('fetchOrders.res', res)
    yield put({ type: actions.SET_ORDERS_STATE, payload: fromJS(res) })
  } catch (error) {
    log(error)
    // TODO: Handle this error action type
    // yield put({ type: actions.ORDERS_REQUEST_FAILED, error })
  }
}
*/

/**
 *  ORDER VIEW/DETAIL API
 */
function* watchOrderDetailFetch() {
  // log('In watchOrderDetailFetch')
  yield takeLatest(actions.ORDER_FETCH, fetchOrderDetail)
}

// Fetch Order Detail and prepare for UI
export function* fetchOrderDetail(action: ActionApiParams) {
  const { body = {}, flow, successCallback = null, failedCallback = null, skipOrderReset = false, apiOptions = {} } = action
  log('Before api fetchOrderDetail')
  if (_.isEmpty(body)) {
    return
  }

  try {
    if (!skipOrderReset) {
      yield put(OrderActions.resetOrder())
    }

    const apiOpts: IApiOptions = {
      showSpinner: true,
      axiosOptions: {
        retry: 0,
        timeout: 180000,
      },
      ...apiOptions,
    }

    const res = yield call(api.post, api.POST_ORDER, body, apiOpts)
    log('After api fetchOrderDetail Response: ', res)
    yield call(handleOrderResponse, res)
    if (successCallback) {
      successCallback(res)
    }
  } catch (error) {
    flowDone(flow)
    if (failedCallback) {
      failedCallback(error)
    }
    log('After api fetch OrderDetail error: ', error)
  }
}

// Create Order
function* watchOrderCreate() {
  // log('In watchOrderCreate')
  // while (true) {
  //   const { payload, callback } = yield take(OrderActions.ACTION_ORDER_CREATE)
  //   yield call(createOrder, payload, callback)
  // }
  while (true) {
    const action = yield take(OrderActions.ACTION_ORDER_CREATE)
    yield call(createOrder, action)
  }
}

// function* createOrder(body, callback: (res: Response) => void) {
function* createOrder(action: ActionApiParams) {
  const apiOptions = {
    // messages: {
    //   successMsg: 'สร้างใบสั่งซื้อสำเร็จแล้ว',
    //   errorMsg: 'เกิดข้อผิดพลาดในการสร้างใบสั่งซื้อ',
    // },
    showSpinner: true,
    axiosOptions: {
      retry: 0,
      timeout: 180000, // long fetch 180 วินาที
    },
  }
  try {
    const { body } = action
    const res = yield call(api.put, api.PUT_ORDER_CREATE, body, apiOptions)
    // console.log('After api createOrder Response: ', res)
    yield call(handleCreateOrderResponse, res)
    yield put({ type: OrderActions.ACTION_SET_MODE, payload: { mode: CONS.ORDER_VIEW_MODE.VIEW_EDIT } })
    // yield put({ type: actions.STORE_ORDERS_SHOULD_FETCH_ALL }) // Refresh Order List
    yield put({ type: actions.SELECTED_STORE_SHOULD_FETCH })
    // if (_.isFunction(callback)) {
    //   callback(res)
    // }
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    // console.log('After api createOrder error: ', error)
    // if (error && error.message) {
    //   let errObj = JSON.parse(error.message)
    //   // Example error message => "{"error":{"message":"PP_ID 26 has 300 available, 500 ordered.","status_code":500}}"
    //   if (errObj.error.message.includes('available')) {
    //     p.op.alert('จำนวนสินค้าปัจจุบันไม่เพียงพอ', 'กรุณาอัพเดทจำนวนสินค้า และเลือกจำนวนสินค้าใหม่')
    //     yield put({ type: OrderActions.CLEAR_INCART_PRODUCTS })
    //   }
    // }

    // เพิ่มดัก err สำหรับไม่ใส่รหัสไปรษณีย์
    if (
      // @ts-ignore
      error.error &&
      // @ts-ignore
      error.error.errors &&
      // @ts-ignore
      error.error.errors['receiver.0.postal_code'][0] === `The receiver.0.postal_code must be an integer.`
    ) {
      p.op.showConfirmationOkOnly('', p.op.t('postal_code_require.m'))
    }
    yield put({ type: OrderActions.ERROR_ORDER_CREATE, error })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

// Cancel Order
function* watchOrderCancel() {
  // log('In watchOrderCancel')
  while (true) {
    const { payload } = yield take(OrderActions.CANCEL_ORDER)
    yield call(cancelOrder, payload)
  }
}

function* cancelOrder(body) {
  const apiOptions = {
    messages: {
      successMsg: 'ทำการยกเลิกใบสั่งซื้อเสร็จสิ้น',
      errorMsg: 'เกิดข้อผิดพลาดในการยกเลิกใบสั่งซื้อ',
    },
    showSpinner: true,
  }
  try {
    const res = yield call(api.post, api.POST_ORDER_CANCEL, body, apiOptions)
    // log('After api cancelOrder Response: ', res)
    yield call(handleOrderResponse, res)
    yield put({ type: OrderActions.ACTION_SET_MODE, payload: { mode: CONS.ORDER_VIEW_MODE.VIEW_EDIT } })
    yield put({ type: actions.STORE_ORDERS_SHOULD_FETCH_ALL }) // Refresh Order List
  } catch (error) {
    log('After api cancelOrder error: ', error)
    // const errorObj = JSON.parse(error.message)
    // const { errors } = errorObj.error
    // @ts-ignore
    if (error && error.message) {
      // @ts-ignore
      if (error.message.includes('already been shipped')) {
        p.op.alert('เกิดข้อผิดพลาด', 'เนื่องจากมีการจัดส่งของไปแล้วจึงไม่สามารถยกเลิกออเดอร์นี้ได้ ')
      }
    }
    yield put({ type: OrderActions.ERROR_ORDER_CANCEL })
  }
}

function* watchShippingTypeChange() {
  // log('In watchShippingTypeChange')
  while (true) {
    const { payload } = yield take(actions.ORDERS_SHIPPING_TYPE_CHANGE)
    yield call(changeShippingType, payload)
  }
}

// function* changeShippingType({ store_id, order_id, shipping_type_id, update_sales_order, update_purchase_orders}) {
function* changeShippingType({ store_id, order_id, shipping_type_id, update_purchase_orders, successCallback }) {
  if (store_id && order_id && shipping_type_id) {
    const body: any = { store_id, order_id, shipping_type_id }
    // if (update_sales_order) {
    //   body['update_sales_order'] = update_sales_order
    // }
    if (update_purchase_orders) {
      body.update_purchase_orders = update_purchase_orders
    }
    // log('changeShippingType.body: ', body)
    const apiOptions = {
      messages: {
        successMsg: 'เปลี่ยนการประเภทการจัดส่งสำเร็จ',
        errorMsg: 'เปลี่ยนการประเภทการจัดส่งล้มเหลว',
      },
      showSpinner: true,
    }
    try {
      const res = yield call(api.patch, api.PATCH_SHIPPING_TYPE, body, apiOptions)
      // console.log('res => ', res)
      yield call(handleOrderResponse, res)
      yield put({ type: actions.STORE_ORDERS_SHOULD_FETCH_ALL }) // Refresh Order List
      // log('After api changeOrderOption Response: ', res)
      if (_.isFunction(successCallback)) {
        successCallback(res.order)
      }
    } catch (error) {
      log('After api changeOrderOption error: ', error)
    }
  }
}

function* watchShippingCostCalc() {
  log('In watchShippingCostCalc')
  while (true) {
    const { payload } = yield take(actions.ORDERS_SHIPPING_COST_CALC)
    yield call(calcShippingCost, payload)
  }
}

function* calcShippingCost({ store_id, shipping_type_id, products, successCallback, failCallback, discount, etc_cost }) {
  const body = { store_id, shipping_type_id, products, discount, etc_cost }
  log('calcShippingCost.request body:', body)
  const apiOptions = {
    showSpinner: true,
  }
  try {
    const res = yield call(api.post, api.POST_SHIPPING_COST_CALC, body, apiOptions)
    yield call(successCallback, res)
  } catch (error) {
    yield call(failCallback, error)
    log('After api calcShippingCost error: ', error)
  }
}

function* watchOrderOptionChange() {
  log('In watchOrderOptionChange')
  while (true) {
    const { payload } = yield take(OrderActions.ACTION_EDITING_OPTION_ONCHANGE)
    yield call(changeOrderOption, payload)
  }
}

function* changeOrderOption({ store_id, order_id, key, value, txtToast }) {
  if (store_id && order_id && key) {
    const body = { store_id, order_id }
    body[key] = value

    const apiOptions = {
      messages: {
        successMsg: txtToast ? `เปลี่ยนการตั้งค่า ${txtToast} สำเร็จ` : null,
        errorMsg: txtToast ? `เปลี่ยนการตั้งค่า ${txtToast} ล้มเหลว` : null,
      },
      showSpinner: true,
    }
    try {
      const res = yield call(api.patch, api.PATCH_ORDER_SETTING, body, apiOptions)
      yield call(handleOrderResponse, res)
      yield put({ type: actions.STORE_ORDERS_SHOULD_FETCH_ALL }) // Refresh Order List
      // log('After api changeOrderOption Response: ', res)
    } catch (error) {
      log('After api changeOrderOption error: ', error)
    }
  }
}

function* watchShippingAutoComplete() {
  log('In watchShippingAutoComplete')
  while (true) {
    const { payload } = yield take(actions.SHIPPING_AUTO_COMPLETE)
    yield call(autoCompleteShipping, payload)
  }
}

function* autoCompleteShipping({ store_id, order_id, return_parent_order, storeName }) {
  // log('autoCompleteShipping' + storeName)
  if (store_id && order_id) {
    const body = { store_id, order_id, return_parent_order }
    const apiOptions = {
      messages: {
        successMsg: storeName ? `ปิดการจัดส่งจาก ${storeName} สำเร็จ` : null,
        errorMsg: storeName ? `ปิดการจัดส่งจาก ${storeName} ล้มเหลว` : null,
      },
      showSpinner: true,
    }
    // log('body', body, api.POST_SHIPPING_AUTO_COMPLETE)
    try {
      // const res = yield call(api.post, 'shipping/auto_complete', body, apiOptions)
      const res = yield call(api.post, api.POST_SHIPPING_AUTO_COMPLETE, body, apiOptions)
      yield call(handleOrderResponse, res)
      yield put({ type: actions.STORE_ORDERS_SHOULD_FETCH_ALL }) // Refresh Order List
      // log('After api autoCompleteShipping Response: ', res)
    } catch (error) {
      log('After api autoCompleteShipping error: ', error)
    }
  }
}

function* watchShippingCancel() {
  log('In watchShippingCancel')
  while (true) {
    const { payload } = yield take(actions.SHIPPING_CANCEL)
    yield call(cancelShipping, payload)
  }
}

function* cancelShipping(payload) {
  const apiOptions = {
    messages: {
      successMsg: 'ยกเลิกการจัดส่งสำเร็จ',
      errorMsg: 'ยกเลิกการจัดส่งล้มเหลว',
    },
    showSpinner: true,
  }
  // log('body', body, api.POST_SHIPPING_AUTO_COMPLETE)
  try {
    const res = yield call(api.post, api.POST_SHIPPING_CANCEL, payload, apiOptions)
    yield call(handleOrderResponse, res)
    yield put({ type: actions.STORE_ORDERS_SHOULD_FETCH_ALL }) // Refresh Order List
    // log('After api cancelShipping Response: ', res)
  } catch (error) {
    log('After api cancelShipping error: ', error)
  }
}

// Order edit ReceiverAddress
function* watchOrderDetailEditOrderAddress() {
  log('In watchOrderDetailEditOrderAddress')
  while (true) {
    const action = yield take(OrderActions.PATCH_ORDER_ADDRESS)
    yield call(editOrderAddress, action)
  }
}

function* editOrderAddress(action: {
  payload: { [key: string]: any }
  successCallback: (res: Response) => void
  failedCallback: (err: Error) => void
}) {
  const apiOptions = {
    messages: {
      successMsg: 'แก้ไขชื่อผู้รับสำเร็จ',
      errorMsg: 'เกิดข้อผิดพลาดในแก้ไขชื่อผู้รับ',
    },
    showSpinner: true,
  }
  try {
    log('Before api editOrderAddress action: ', action)
    const res = yield call(api.patch, api.PATCH_ORDER_ADDRESS, action.payload, apiOptions)
    log('After api editOrderAddress Response: ', res)
    yield call(handleOrderResponse, res)
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    log('After api editOrderAddress error: ', error)
    yield put({ type: OrderActions.ERROR_PATCH_ORDER_ADDRESS })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchOrderDetailConfirm() {
  log('In watchOrderDetailConfirm')
  // yield takeLatest(actions.ORDER_OPERATE_CONFIRM, handleOrderDetailConfirm)
  while (true) {
    const action = yield take(actions.ORDER_OPERATE_CONFIRM)
    yield call(handleOrderDetailConfirm, action)
  }
}

// function* handleOrderDetailConfirm(action) {
function* handleOrderDetailConfirm(action: ActionApiParams) {
  const { body, successCallback, failedCallback } = action
  try {
    const apiOptions = {
      messages: {
        successMsg: 'ยืนยันราคาสำเร็จ',
        errorMsg: 'เกิดข้อผิดพลาดในการยืนยันราคา',
      },
      showSpinner: true,
    }

    // -- Order can confirm(edit) validation flow --
    // const fetchOrderDetailAction = { payload: body }
    const fetchOrderDetailAction = action
    delete fetchOrderDetailAction.type

    const targetOrderId = body.order_id
    const targetOrder = findTargetOrder(targetOrderId)
    // log('###### handleOrderDetailConfirm ####### targetOrder => ', targetOrder)
    if (!targetOrderId || !targetOrder) {
      // log('###### !targetOrderId || !targetOrder ######### body => ', body)
      yield call(fetchOrderDetail, fetchOrderDetailAction)
      return
    }

    const targetUpdatedAt = targetOrder.get('updated_at') ? targetOrder.get('updated_at') : null
    if (!targetUpdatedAt || targetUpdatedAt !== body.updated_at) {
      // log('###### !targetUpdatedAt || (targetUpdatedAt !== body.updated_at ######### body => ', body)
      yield call(fetchOrderDetail, fetchOrderDetailAction)
      return
    }

    const orderType = targetOrder.get('type') ? targetOrder.get('type') : null
    const orderState = targetOrder.get('state') ? targetOrder.get('state') : null
    // log('###### handleOrderDetailConfirm ####### orderType => ', orderType)
    // log('###### handleOrderDetailConfirm ####### orderState => ', orderState)
    if (!orderType || !orderState) {
      yield call(fetchOrderDetail, fetchOrderDetailAction)
      return
    }

    const canConfirmKey = `_${orderType}${orderState}`
    const canConfirmOrder = CONS.ORDER_OPERATION_AVAILABILITY.CONFIRM[canConfirmKey]
    const canEditOrder =
      !(_.includes([2, 3], orderType) && orderState === 101) &&
      !(_.includes([1, 2], orderType) && orderState === 102) &&
      !(orderType === 3 && orderState === 103)

    if (!canConfirmOrder && !canEditOrder) {
      // ถ้าสถานะ ไม่สามารถ confirm || edit ได้ ignore call api ให้นี้ไป
      return
    }
    if (!canConfirmOrder && canEditOrder) {
      const bodyKeys = Object.keys(body)
      if (bodyKeys.length <= 3) {
        // ถ้า api มีแค่ 3 fields คือ store_id, order_id, updated_at หมายถึงเป็น api confirm order ให้ return
        return
      }
    }
    // -- End validation flow --

    // log('Before api fetch handleOrderDetailConfirm body: ', body)
    const res = yield call(api.post, api.POST_ORDER_CONFIRM, body, apiOptions)
    // log('After api handleOrderDetailConfirm Response: ', res)
    yield call(handleOrderResponse, res)
    yield put({ type: actions.STORE_ORDERS_SHOULD_FETCH_ALL }) // Refresh Order List
    yield put({ type: actions.STORE_ORDERS_SHOULD_FETCH_SINGLE_TAB_KEY, payload: 'myTasks_confirmOrder' }) // Refresh Order List
    yield put({ type: actions.SELECTED_STORE_SHOULD_FETCH }) // StoreMyView + Donut
    // yield put({ type: OrderActions.SHOULD_FETCH_ORDER_DETAIL }) // Refresh Order Detail

    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
  } catch (error) {
    // log('After api handleOrderDetailConfirm error: ', error)
    // Error Code: 07_041 Special Specific error with attach
    // Example response
    // "error": {
    // "status_code": 400,
    //   "error_code": "07_041",
    //   "attach": {
    //     "order": { ...new order's obj... }, // <=== we will load this to selectedOrder
    //   },
    // }
    // @ts-ignore
    if (error && error.error && error.error.attach) {
      // @ts-ignore
      yield call(handleOrderResponse, error.error.attach)
    }
    yield put({ type: OrderActions.ERROR_ORDER_CONFIRM })
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
  // }
}

// handle order response
export function* handleOrderResponse(res) {
  // declare here just because tslint auto fixed to "const" but it's must be "let"
  let modifiedOrder
  if (_.has(res, 'order')) {
    modifiedOrder = _.cloneDeep(res.order)
    // modifiedOrder.vd_discounts = [
    //   {
    //     pp_ids: [12759, 236099],
    //     ta: 20,
    //     dp: 5,
    //     vd_hid: 12,
    //   },
    //   {
    //     pp_ids: [251877],
    //     ta: 6,
    //     da: 2,
    //     vd_hid: 15,
    //   },
    //   // {
    //   //   pp_ids: [236343],
    //   //   ta: 10,
    //   //   da: 2,
    //   //   vd_hid: 15,
    //   // },
    // ]
    //
    // modifiedOrder.vdhs = [
    //   {
    //     id: 12,
    //     name: 'vd 12',
    //     type: 2,
    //     variants: [
    //       {
    //         min: 1,
    //         max: 2,
    //         val: 2,
    //       },
    //       {
    //         min: 3,
    //         max: 4,
    //         val: 5,
    //       },
    //       {
    //         min: 5,
    //         val: 8,
    //       },
    //     ],
    //   },
    //   {
    //     id: 15,
    //     name: 'vd 15',
    //     type: 1,
    //     variants: [
    //       {
    //         min: 1,
    //         max: 2,
    //         val: 1,
    //       },
    //       {
    //         min: 3,
    //         max: 4,
    //         val: 2,
    //       },
    //       {
    //         min: 5,
    //         val: 3,
    //       },
    //     ],
    //   },
    // ]

    // modifiedOrder.suborders[0].vd_discounts = [
    //   {
    //     pp_ids: [236343],
    //     ta: 10,
    //     da: 2,
    //     vd_hid: 15,
    //   },
    // ]

    // modifiedOrder.suborders[0].vdhs = [
    //   {
    //     id: 15,
    //     name: 'vd 15',
    //     type: 1,
    //     variants: [
    //       {
    //         min: 1,
    //         max: 2,
    //         val: 1,
    //       },
    //       {
    //         min: 3,
    //         max: 4,
    //         val: 2,
    //       },
    //       {
    //         min: 5,
    //         val: 3,
    //       },
    //     ],
    //   },
    // ]

    // const order = computeDiscountAndApplyToProducts(modifiedOrder)
    // const order = computeDiscountAndApplyToProducts(modifiedOrder)
    yield call(computeDiscountAndApplyToProducts, modifiedOrder)
    // log('handleOrderResponse order => ', order)
    // yield call(convertOrderDetailBuyOrderAdapter, order)
    yield call(convertOrderDetailProductsToStoreProductsAdapter, modifiedOrder)
    yield call(convertSubOrderToParentOrderAdapter, modifiedOrder) // สร้าง parent order เปล่าขึ้นมา ถ้า order เป็น child
    yield call(sortSubOrders, modifiedOrder)
    // yield call(generatePriceBuyInSubOrders, order)
    // log('Converted order => ', order)

    yield put({ type: OrderActions.ACTION_ORDER_RESET })
    yield put({ type: OrderActions.ACTION_ORDER_LOAD, payload: fromJS(modifiedOrder) })
    // เอาไว้ expand collapse card (ไม่ใช้แล้ว)
    // yield call(loadOrderToUI, order)
  }
}

// // ถ้าเป็น type 3 คือ ออเดอร์ซื้อ  ราคาซื้อของฉันก็คือราคาต้นทุน  ดังนั้น product จะให้ price = cost
// function* convertOrderDetailBuyOrderAdapter(order: { [key: string]: any } )  {
//   if (order.type === 3) {
//
//     order.products.forEach((product, index) => {
//       order.products[index].price = product.cost
//     })
//
//     if ('suborders' in order) {
//       order.suborders.forEach((suborder, subIndex) => {
//         suborder.products.forEach((product, pIndex) => {
//           order.suborders[subIndex].products[pIndex].price = product.cost
//         })
//       })
//     }
//
//   }
// }

function* convertSubOrderToParentOrderAdapter(order: { [key: string]: any }): IterableIterator<any> {
  // log('convertSubOrderToParentOrderAdapter old Order => ', order)
  if (order.parent_id && order.parent_name) {
    // && (_.isNull(order.suborders) || _.isUndefined(order.suborders) || order.suborders.length === 0)) {
    const currentSubOrder = _.cloneDeep(order)
    // log('current SubOrder => ', currentSubOrder)
    for (const member in order) {
      delete order[member]
    }
    // log('current order => ', order)
    order.id = currentSubOrder.parent_id
    order.name = currentSubOrder.parent_name
    if (currentSubOrder.siblings && currentSubOrder.siblings.length > 0) {
      order.suborders = _.cloneDeep(currentSubOrder.siblings)
      order.suborders.push(currentSubOrder)
    } else {
      order.suborders = [currentSubOrder]
    }
  }
  // log('convertSubOrderToParentOrderAdapter old Order => ', order)
}

// อ่านค่า ugpg_discounts แล้ว merge ใส่ products เพื่อเตรียมพร้อมใช้ render
// อ่านค่า vd_discounts แล้ว merge ใส่ products เพื่อเตรียมพร้อมใช้ render
function* computeDiscountAndApplyToProducts(newOrder: { [key: string]: any }): { [key: string]: any } {
  // แยกเป็น 2 กรณี
  // - order type 1/2 จะเป็นส่วนลดที่ฉันให้กับ ลูกค้า/ตัวแทน apply เข้าสินค้าร้านฉัน (sdc)
  // - order type 3 จะเป็นส่วนลดที่ฉันได้จากร้านขายส่ง (pdc)
  // const newOrder = _.cloneDeep(order)
  if (newOrder && !_.isEmpty(newOrder)) {
    // compute parent's products
    const validOrderType = _.has(newOrder, 'type') && _.includes([1, 2, 3], newOrder.type)
    const hasParentProducts = _.has(newOrder, 'products') && newOrder.products.length > 0
    const hasParentUgPgDiscounts = _.has(newOrder, 'ugpg_discounts') && newOrder.ugpg_discounts.length > 0
    const hasParentVdDiscounts = _.has(newOrder, 'vd_discounts') && newOrder.vd_discounts.length > 0
    if (validOrderType && hasParentProducts && (hasParentUgPgDiscounts || hasParentVdDiscounts)) {
      const orderType = newOrder.type
      // Compute sdc สินค้าร้านฉัน (type 1,2) หรือ Compute pdc สินค้าร้านขายส่ง (type 3)
      const parentDiscountType = orderType === 3 ? 'purchase' : 'sale'

      const newProducts = getProductsAppliedDiscountByDiscountType({
        products: newOrder.products,
        ugpgDiscounts: hasParentUgPgDiscounts ? newOrder.ugpg_discounts : [],
        vdDiscounts: hasParentVdDiscounts ? newOrder.vd_discounts : [],
        discountType: parentDiscountType,
      })

      // log('computeDiscountAndApplyToProducts newProducts => ', newProducts)
      if (newProducts && newProducts.length > 0) {
        newOrder.products = newProducts
      }
    }

    // compute each child's products
    if (newOrder && newOrder.suborders) {
      const { suborders } = newOrder
      for (let subOrderIndex = 0; subOrderIndex < suborders.length; subOrderIndex++) {
        const suborder = suborders[subOrderIndex]
        const hasSubProducts = _.has(suborder, 'products') && suborder.products.length > 0
        const hasSubUgPgDiscounts = _.has(suborder, 'ugpg_discounts') && suborder.ugpg_discounts.length > 0
        const hasSubVdDiscounts = _.has(suborder, 'vd_discounts') && suborder.vd_discounts.length > 0
        // log('computeDiscountAndApplyToProducts hasSubProducts => ', hasSubProducts)
        // log('computeDiscountAndApplyToProducts hasSubUgPgDiscounts => ', hasSubUgPgDiscounts)
        if (hasSubProducts && (hasSubUgPgDiscounts || hasSubVdDiscounts)) {
          // Compute pdc
          const newSubOrderProducts = getProductsAppliedDiscountByDiscountType({
            products: suborder.products,
            ugpgDiscounts: hasSubUgPgDiscounts ? suborder.ugpg_discounts : [],
            vdDiscounts: hasSubVdDiscounts ? suborder.vd_discounts : [],
            discountType: 'purchase',
          })

          if (newSubOrderProducts && newSubOrderProducts.length > 0) {
            newOrder.suborders[subOrderIndex].products = newSubOrderProducts
          }
        }
        if (hasSubProducts && hasParentProducts) {
          // Compute sdc สินค้าที่ถูกดึงมา
          const newSubOrderProducts = getProductsAppliedDiscountByDiscountType({
            products: suborder.products,
            ugpgDiscounts: hasSubUgPgDiscounts ? newOrder.ugpg_discounts : [],
            vdDiscounts: hasSubVdDiscounts ? newOrder.vd_discounts : [],
            discountType: 'sale',
          })
          if (newSubOrderProducts && newSubOrderProducts.length > 0) {
            newOrder.suborders[subOrderIndex].products = newSubOrderProducts
          }
        }
      }
    }
    // log('computeDiscountAndApplyToProducts newOrder => ', newOrder)
  }
  // return newOrder
}

function getProductsAppliedDiscountByDiscountType(params: {
  products: Array<{ [key: string]: any }>
  ugpgDiscounts: Array<{ [key: string]: any }>
  vdDiscounts: Array<{ [key: string]: any }>
  discountType: 'purchase' | 'sale'
}): any[] | null {
  const { products, ugpgDiscounts = [], vdDiscounts = [], discountType } = params

  const hasProducts = products.length > 0
  const hasDiscounts = ugpgDiscounts.length > 0 || vdDiscounts.length > 0
  if (hasProducts && hasDiscounts && discountType) {
    let newProducts
    newProducts = products.slice()

    // UGPG
    for (let discountIndex = 0; discountIndex < ugpgDiscounts.length; discountIndex++) {
      const ugPgDiscountItem = ugpgDiscounts[discountIndex]
      const {
        pp_ids = [], // ใช้บอกว่าสินค้าตัวไหนจะโดน apply บ้าง
        ta = null, // total_amount
        da = null, // discount_amount
        dp = null, // discount_percent
        ug_id = null, // user_group_id
        pg_id = null, // product_group_id
      } = ugPgDiscountItem
      // log('getProductsAppliedDiscountByDiscountType orderDiscounts => ', orderDiscounts)
      if (pp_ids && pp_ids.length > 0) {
        for (let productIndex = 0; productIndex < newProducts.length; productIndex++) {
          const focusedProduct = newProducts[productIndex]
          const {
            pp_id,
            cost, // maybe string
            price, // maybe string
            // ugpgPurchaseDiscountAmount = null, // front-end manual generated discount per each
            // ugpgPurchaseDiscountPercent = null, // front-end manual generated discount per each
            // ugpgSaleDiscountAmount = null, // front-end manual generated discount per each
            // ugpgSaleDiscountPercent = null, // front-end manual generated discount per each
          } = focusedProduct
          if (_.includes(pp_ids, pp_id)) {
            // ถ้า pp_id อยู่ใน pp_ids แสดงว่าได้ส่วนลด
            // log('focusedProduct pp_id => ', pp_id)
            // log('focusedProduct discount_amount => ', da)
            // log('focusedProduct discount_percent => ', dp)
            if (discountType === 'purchase') {
              // type 3จะเป็น pdc
              newProducts[productIndex].ugpgPurchaseDiscountAmount = da
              newProducts[productIndex].ugpgPurchaseDiscountPercent = dp
            } else {
              // type 1|2 จะเป็น sdc
              newProducts[productIndex].ugpgSaleDiscountAmount = da
              newProducts[productIndex].ugpgSaleDiscountPercent = dp
            }
            newProducts[productIndex].ug_id = ug_id
            newProducts[productIndex].pg_id = pg_id
          }
        }
      }
    }

    // VD
    for (let discountIndex = 0; discountIndex < vdDiscounts.length; discountIndex++) {
      const vdDiscountItem = vdDiscounts[discountIndex]
      const {
        pp_ids = [], // ใช้บอกว่าสินค้าตัวไหนจะโดน apply บ้าง
        ta = null, // total_amount
        da = null, // discount_amount
        dp = null, // discount_percent
        ug_id = null, // user_group_id
        pg_id = null, // product_group_id
        vd_hid = null, // volume discount history id
      } = vdDiscountItem
      // log('getProductsAppliedDiscountByDiscountType orderDiscounts => ', orderDiscounts)
      if (pp_ids && pp_ids.length > 0) {
        for (let productIndex = 0; productIndex < newProducts.length; productIndex++) {
          const focusedProduct = newProducts[productIndex]
          const {
            pp_id,
            cost, // maybe string
            price, // maybe string
            // ugpgPurchaseDiscountAmount = null, // front-end manual generated discount per each
            // ugpgPurchaseDiscountPercent = null, // front-end manual generated discount per each
            // ugpgSaleDiscountAmount = null, // front-end manual generated discount per each
            // ugpgSaleDiscountPercent = null, // front-end manual generated discount per each
          } = focusedProduct
          if (_.includes(pp_ids, pp_id)) {
            // ถ้า pp_id อยู่ใน pp_ids แสดงว่าได้ส่วนลด
            // log('focusedProduct pp_id => ', pp_id)
            // log('focusedProduct discount_amount => ', da)
            // log('focusedProduct discount_percent => ', dp)
            if (discountType === 'purchase') {
              // type 3จะเป็น pdc
              newProducts[productIndex].vdPurchaseDiscountAmount = da
              newProducts[productIndex].vdPurchaseDiscountPercent = dp
            } else {
              // type 1|2 จะเป็น sdc
              newProducts[productIndex].vdSaleDiscountAmount = da
              newProducts[productIndex].vdSaleDiscountPercent = dp
            }
            newProducts[productIndex].ug_id = ug_id
            newProducts[productIndex].pg_id = pg_id
            // newProducts[productIndex].vd_hid = vd_hid
          }
        }
      }
    }
    // log('getProductsAppliedDiscountByDiscountType newProducts => ', newProducts)
    return newProducts
  }
  return null
}

// แปลงจาก products เดิม เป็น store_products เพื่อใช้ในการ render พร้อมสำหลับ api confirm (edit)
// and generate tmp_PPID_to_StoreId
function* convertOrderDetailProductsToStoreProductsAdapter(order: { [key: string]: any }) {
  let store_products = Map({})
  let tmp_PPID_to_StoreId = Map({})

  // converting parent's product
  const parentStoreProducts = yield call(getStoreProductItemfromOrder, order)
  if (parentStoreProducts && parentStoreProducts.store_id && parentStoreProducts.products) {
    const { store_id, products } = parentStoreProducts
    store_products = store_products.set(store_id, fromJS(products))
    // products.forEach(product => {
    //   if (product.pp_id) {
    //     tmp_PPID_to_StoreId = tmp_PPID_to_StoreId.set(product.pp_id, store_id)
    //   }
    // })
  }

  // converting each child's product
  if (order && order.suborders) {
    const { suborders } = order
    yield suborders.forEach((suborder, subIndex) => {
      const childStoreProducts = getStoreProductItemfromOrder(suborder)
      if (childStoreProducts && childStoreProducts.store_id && childStoreProducts.products) {
        const { store_id, products } = childStoreProducts
        store_products = store_products.set(store_id, fromJS(products))
        // Add to suborders for rendering too !!
        if (_.isArray(order.suborders) && order.suborders[subIndex]) {
          const tmpChildStoreProducts = Map({})
          order.suborders[subIndex].store_products = tmpChildStoreProducts.set(store_id, fromJS(products))
        }

        products.forEach((product) => {
          if (product.h) {
            tmp_PPID_to_StoreId = tmp_PPID_to_StoreId.set(product.h, store_id)
            tmp_PPID_to_StoreId = tmp_PPID_to_StoreId.set(product.pp_id, store_id)
          } else if (product.pp_id) {
            tmp_PPID_to_StoreId = tmp_PPID_to_StoreId.set(product.pp_id, store_id)
          }
        })
      }
    })
  }
  order.store_products = store_products
  order.tmp_PPID_to_StoreId = tmp_PPID_to_StoreId
}

// order => store_product_item converting normal function
function getStoreProductItemfromOrder(order: { [key: string]: any }): { [key: string]: any } | null {
  if (order && order.store_id && _.isArray(order.products) && order.products.length > 0) {
    const { store_id } = order
    const orderType = order.type ? order.type : 0
    const products = order.products ? order.products : []
    products.forEach((product, index) => {
      products[index].isFreeze = true
      products[index].price_rate_type = order.price_rate ? order.price_rate : 1 // default price_rate = 1 คือ ขายส่ง
      products[index].price_rate_value = product.price ? product.price : 0 // ให้ price ที่เคย submit ไว้ เป็น default value
      // ถ้าเป็น type 3 คือ ออเดอร์ซื้อ  ราคาซื้อของฉันก็คือราคาต้นทุน  ดังนั้น product จะให้ price_buy = cost
      products[index].price_buy = orderType === 3 ? product.cost : 0
    })
    return { store_id, products }
  }
  return null
}

function* sortSubOrders(order: { [key: string]: any }): IterableIterator<any> {
  // log('Before sorted suborders => ', order.suborders)
  if (order && order.suborders && order.suborders.length > 0) {
    order.suborders.sort((subA, subB) => subA.id - subB.id)
  }

  if (order && order.id) {
    order.index = 0 // orderTabIndex === 0 for parent
    if (order.suborders && order.suborders.length > 0) {
      for (let i = 0; i < order.suborders.length; i++) {
        order.suborders[i].index = i + 1 // orderTabIndex === 1, 2, 3, ... for sub orders
      }
    }
  }
  // log('New sorted suborders => ', order.suborders)
}

// Add price_buy ในออเดอร์ซื้อ
// function* generatePriceBuyInSubOrders(order: { [key: string]: any } )  {
//   const orderCount = order.suborders.length
//   for (let i = 0; i < orderCount; i++) {
//     const subOrder = order.suborders[i]
//     if (subOrder.store_products && isImmutable(subOrder.store_products)) {
//       let newStoreProducts = subOrder.store_products
//     }
//
//   log('generatePriceBuyInSubOrders old Order => ', order)
// }

// handle CREATE order response
function* handleCreateOrderResponse(res) {
  if (_.has(res, 'order')) {
    // let order = _.clone(res.order)
    const { order } = res
    let orderCount = 1
    let suborderCount = 0
    // let mainOrderCreatedTxt = ''
    let txtSuccess
    let subOrderCreatedTxt = ''
    // Check mode if it's type 1 or type 3. Then display accordingly
    if (order.parent_id && order.parent_name) {
      // it's type 3
      suborderCount = 1
      if (order.siblings) {
        const siblingsLen = order.siblings.length
        suborderCount += siblingsLen
        orderCount += suborderCount
        // loop siblings for names
        subOrderCreatedTxt = `\n${suborderCount} ซื้อ : #${order.name}`
        order.siblings.forEach((sb) => {
          subOrderCreatedTxt += `, #${sb.name}`
        })
      }
      txtSuccess = `สร้าง ${orderCount} ออเดอร์สำเร็จแล้ว\n1 ขาย: #${order.parent_name} (ปิดอัตโนมัติ)`
      txtSuccess += subOrderCreatedTxt
    } else {
      // it's type 1
      suborderCount = order.suborders ? order.suborders.length : 0
      orderCount += suborderCount

      txtSuccess = `สร้าง ${orderCount} ออเดอร์สำเร็จแล้ว\n1 ขาย: #${order.name}`
      // loop for sub orders
      if (suborderCount > 0) {
        const lastIndex = suborderCount - 1
        txtSuccess += `\n${suborderCount} ซื้อ : `
        order.suborders.forEach((so, index) => {
          if (index === lastIndex) {
            txtSuccess += `#${so.name}`
          } else {
            txtSuccess += `#${so.name}, `
          }
        })
      }
    }

    // For Analytic
    if (!_.isNull(order.transaction_cost)) {
      // log('hello tranaction_cost => ', order.transaction_cost)
      p.op.aLogEvent('Order_Created', { cnt: orderCount, amt: parseFloat(order.transaction_cost) })
    }

    // เปลี่ยนไปใช้การ โหลดแบบ order detail
    // yield put({ type: OrderActions.ACTION_ORDER_LOAD, payload: fromJS(order) })
    yield call(handleOrderResponse, res)
    // ถ้าเพิ่ม Note type 3 and 4 ไม่ต้องยิงตัวนี้
    let orderNotes = false
    order.notes.map((note) => {
      if (note.type === 3 || note.type === 4) {
        orderNotes = true
      }
    })
    if (!orderNotes) {
      yield call(p.op.showToast, txtSuccess, 'success', 7000)
    }
    // เอาไว้ expand collapse card (ไม่ใช้แล้ว)
    // yield call(loadOrderToUI, order)
  }
}

function findTargetOrder(targetOrderId: number): Map<string, any> | null {
  if (!targetOrderId) {
    return null
  }

  const appState = p.op.getAppState()
  const parentOrder: Map<string, any> = getSelectedOrder(appState)
  const parentOrderId = parentOrder && parentOrder.get('id') ? parentOrder.get('id') : null
  log('findTargetOrder parentOrder.toJS() => ', parentOrder.toJS())
  log('findTargetOrder parentOrderId => ', parentOrderId)
  if (!parentOrderId) {
    return null
  }

  if (parentOrderId === targetOrderId) {
    return parentOrder
  }

  const suborders = parentOrder.get('suborders') || null

  if (!suborders) {
    return null
  }

  for (let i = 0; i < suborders.size; i++) {
    const currentOrder: Map<string, any> = suborders.get(i) || Map({})
    const currentOrderId = currentOrder && currentOrder.get('id') ? currentOrder.get('id') : 0
    if (currentOrderId === targetOrderId) {
      return currentOrder
    }
  }

  return null
}

function* watchFetchSearchOrders() {
  log('In watchQueryStoreOrders')
  yield takeLatest(actions.SEARCH_ORDERS_FETCH, fetchSearchOrders)
}

function* fetchSearchOrders(action) {
  // log('sagas/orders/fetchStoreOrders.actionNative', action)
  // log(store.getState())
  const storesApiOptions = {
    showSpinner: true,
  }

  // const fetchOrdersPref = getPrefFetchOrders(p.op.getAppState()).toJS()
  //
  // // based with pref then overwrite setting from payload
  // var reqBody = Object.assign(fetchOrdersPref, action.payload)
  // var reqBody = action.payload

  // let payload = {
  //     store_id: 3,
  //     offset: 0,
  //     limit: 20,
  //     sortBy: 'id',
  //     sortType: 'desc',
  //     order_mode: 'p', // Default to 's' where 's' = sales order
  //     // created_at_from: '2017-06-30 13:23:45',
  //     // created_at_to: '2017-07-01 13:23:45',
  //     // updated_at_from: '2017-06-30 13:23:45',
  //     // updated_at_to: '2017-07-01 13:23:45',
  //     // completed_at_from: '2017-06-30 13:23:45',
  //     // completed_at_to: '2017-07-01 13:23:45',
  //     // order_states: [102, 109]
  //     // payment_states: [],
  //     // shipping_states: [129],
  //     // shipping_type_ids: [3,4,5],
  //     // receiver_name: 'กนกว'
  //     // sender_name: 'xxx',
  //     // seller_store_ids: [12],
  //     // expiresInMins: 3600
  // }
  const reqBody = action.payload

  log('queryStoreOrders.reqBody', reqBody)
  try {
    log('before calling in queryStoreOrders')

    // yield put({ type: actions.SET_ORDERS_IS_FETCHING, payload: true })
    const res = yield call(api.post, api.POST_ORDERS_QUERY, reqBody, storesApiOptions)
    log('queryStoreOrders.res', res)

    // yield put({ type: actions.SEARCH_ORDERS_SET_STATE, payload: fromJS(res) })
    yield put({ type: actions.SEARCH_ORDERS_SET_STATE, payload: res })
  } catch (error) {
    log(error)
    // TODO: Handle this error action type
    // yield put({ type: actions.ORDERS_REQUEST_FAILED, error })
  }
}

function* watchPostCopyLinkOrder() {
  log('In watchgetCopyLinkOrder')
  while (true) {
    const action = yield take(actions.GET_COPY_LINK_ORDER)
    yield call(getCopyLinkOrder, action)
  }
}

function* getCopyLinkOrder(action: ActionApiParams) {
  const apiOptions = {
    showSpinner: true,
  }
  try {
    log('In createUser body => ', action.body)
    const bodyAction = action.body.body
    const res = yield call(api.postV2, api.POST_COPE_LINK_ORDER, bodyAction, apiOptions)
    if (_.isFunction(action.body.successCallback)) {
      action.body.successCallback(res)
    }
  } catch (error) {
    log('In createUser error => ', error)
    if (_.isFunction(action.body.failedCallback)) {
      action.body.failedCallback(error)
    }
  }
}

function* watchPostCancelTackingCode() {
  log('In watchPostCancelTackingCode')
  while (true) {
    const action = yield take(actions.CANCEL_TACKING_CODE)
    yield call(cancelTackingCode, action)
  }
}

function* cancelTackingCode(action: ActionApiParams) {
  // const apiOptions = {
  //   showSpinner: true,
  // }
  try {
    log('In createUser body => ', action.body)
    const bodyAction = action.body.body
    const res = yield call(api.postV2, api.POST_CANCEL_TACKING_CODE, bodyAction)
    // console.log('res => ', res)
    if (_.isFunction(action.body.successCallback)) {
      action.body.successCallback(res)
    }

    yield put({ type: OrderActions.ACTION_ORDER_XSHIPPING_ONCHANGE, payload: { key: 'x', value: res.x } })
  } catch (error) {
    log('In createUser error => ', error)
    if (_.isFunction(action.body.failedCallback)) {
      action.body.failedCallback(error)
    }
  }
}

function* watchCreateTackingCode() {
  while (true) {
    const action = yield take(actions.CREATE_TACKING_CODE)
    yield call(createTackingCode, action)
  }
}

function* createTackingCode(action: ActionApiParams) {
  // const apiOptions = {
  //   showSpinner: true,
  // }
  try {
    log('In createUser body => ', action.body)
    const bodyAction = action.body.body
    const res = yield call(api.post, api.POST_CREATE_TACKING_CODE, bodyAction)
    if (_.isFunction(action.body.successCallback)) {
      action.body.successCallback(res)
    }
    yield put({ type: OrderActions.ACTION_ORDER_XSHIPPING_ONCHANGE, payload: { key: 'x', value: res.x } })
  } catch (error) {
    log('In createUser error => ', error)
    if (_.isFunction(action.body.failedCallback)) {
      action.body.failedCallback(error)
    }
  }
}

function* watchUpdateTackingCode() {
  while (true) {
    const action = yield take(actions.UPDATE_TACKING_CODE)
    yield call(updateTackingCode, action)
  }
}

function* updateTackingCode(action: ActionApiParams) {
  // const apiOptions = {
  //   showSpinner: true,
  // }
  try {
    const bodyAction = action.body.body
    const res = yield call(api.postV2, api.POST_UPDATE_TACKING_CODE, bodyAction)
    if (_.isFunction(action.body.successCallback)) {
      action.body.successCallback(res)
    }
    yield put({ type: OrderActions.ACTION_ORDER_XSHIPPING_ONCHANGE, payload: { key: 'x', value: res.x } })
  } catch (error) {
    if (_.isFunction(action.body.failedCallback)) {
      action.body.failedCallback(error)
    }
  }
}

function* watchAutoRequestTracking() {
  while (true) {
    const action = yield take(actions.AUTO_REQUEST_TRACKING)
    yield call(autoRequestTracking, action)
  }
}

function* autoRequestTracking(action: ActionApiParams) {
  // const apiOptions = {
  //   showSpinner: true,
  // }
  try {
    const bodyAction = action.body.body
    const res = yield call(api.patch, api.PATCH_AUTO_REQUEST_TRACKING, bodyAction)
    if (_.isFunction(action.body.successCallback)) {
      action.body.successCallback(res)
    }
  } catch (error) {
    if (_.isFunction(action.body.failedCallback)) {
      action.body.failedCallback(error)
    }
  }
}

// // จัดระเบียบ order จาก api จัดลง UI
// function* loadOrderToUI(order) {
//   if (_.has(order, 'products')) {
//     order.products.forEach((product, index) => {
//       order.products[index].isExpanded = false          // เพื่อ support expand card
//       order.products[index].qty_firstTyping = true      // เพื่อเช็คการพิมพ์ครั้งแรก
//     })
//   }
//   yield put({ type: OrderActions.ACTION_ORDER_LOAD, payload: fromJS(order) })
// }

// function appendDataChange(source, editing, target, key, parseType) { //Get non-null changed value
//   if (source.get(key) && editing.get(key) && editing.get(key) !== '' && source.get(key) !== null) {
//     if (source.get(key) !== editing.get(key)) {
//       switch (parseType) {
//         case 'int':
//           target[key] = parseInt(editing.get(key))
//           break
//         case 'float':
//           target[key] = parseFloat(editing.get(key))
//           break
//         case 'string':
//           target[key] = editing.get(key).toString()
//           break
//         default:
//           target[key] = editing.get(key).toString()
//       }
//     }
//   }
// }

function* watchFetchOrderNotes() {
  while (true) {
    const action = yield take(OrderActions.ACTION_FETCH_ORDER_NOTES)
    yield call(fetchOrderNotes, action)
  }
}
function* fetchOrderNotes(action: ActionApiParams) {
  const apiOptions = {
    showSpinner: true,
  }
  try {
    const { body } = action
    const res = yield call(api.post, api.POST_FETCH_ORDER_NOTES, body, apiOptions)
    // yield call(handleCreateOrderResponse, res)
    // yield put({ type: OrderActions.ACTION_SET_MODE, payload: { mode: CONS.ORDER_VIEW_MODE.VIEW_EDIT } })
    // yield put({ type: actions.STORE_ORDERS_SHOULD_FETCH_ALL }) // Refresh Order List
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    // yield put({ type: OrderActions.ERROR_ORDER_CREATE, error })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchAddOrderNote() {
  while (true) {
    const action = yield take(OrderActions.ACTION_ADD_ORDER_NOTE)
    yield call(addOrderNote, action)
  }
}
function* addOrderNote(action: ActionApiParams) {
  const apiOptions = {
    showSpinner: true,
  }
  try {
    const { body } = action
    const res = yield call(api.put, api.PUT_ADD_ORDER_NOTE, body, apiOptions)
    yield call(handleOrderResponse, res)
    // yield put({ type: OrderActions.ACTION_SET_MODE, payload: { mode: CONS.ORDER_VIEW_MODE.VIEW_EDIT } })
    // yield put({ type: actions.STORE_ORDERS_SHOULD_FETCH_ALL }) // Refresh Order List
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    // yield put({ type: OrderActions.ERROR_ORDER_CREATE, error })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchEditOrderNote() {
  while (true) {
    const action = yield take(OrderActions.ACTION_EDIT_ORDER_NOTE)
    yield call(editOrderNote, action)
  }
}
function* editOrderNote(action: ActionApiParams) {
  const apiOptions = {
    showSpinner: true,
  }
  try {
    const { body } = action
    const res = yield call(api.patch, api.PATCH_EDIT_ORDER_NOTE, body, apiOptions)
    yield call(handleOrderResponse, res)
    // yield put({ type: OrderActions.ACTION_SET_MODE, payload: { mode: CONS.ORDER_VIEW_MODE.VIEW_EDIT } })
    // yield put({ type: actions.STORE_ORDERS_SHOULD_FETCH_ALL }) // Refresh Order List
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (err) {
    // yield put({ type: OrderActions.ERROR_ORDER_CREATE, error })
    // @ts-ignore
    if (err.error.status_code === 422) {
      if (_.isFunction(action.failedCallback)) {
        action.failedCallback(err)
      }
    } else {
      // @ts-ignore
      yield call(handleOrderResponse, err.error.attach.order)
      if (_.isFunction(action.failedCallback)) {
        action.failedCallback(err)
      }
    }
  }
}

function* watchDeleteOrderNote() {
  while (true) {
    const action = yield take(OrderActions.DELETE_ORDER_NOTE)
    yield call(deleteOrderNote, action)
  }
}
function* deleteOrderNote(action: ActionApiParams) {
  const apiOptions = {
    showSpinner: true,
  }
  try {
    const { body } = action
    const res = yield call(api.del, api.DELETE_ORDER_NOTE, body, apiOptions)
    yield call(handleOrderResponse, res)
    // yield call(handleCreateOrderResponse, res)
    // yield put({ type: OrderActions.ACTION_SET_MODE, payload: { mode: CONS.ORDER_VIEW_MODE.VIEW_EDIT } })
    // yield put({ type: actions.STORE_ORDERS_SHOULD_FETCH_ALL }) // Refresh Order List
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    // yield put({ type: OrderActions.ERROR_ORDER_CREATE, error })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchSelectOrderNote() {
  while (true) {
    const action = yield take(OrderActions.ACTION_SELECT_ORDER_NOTES)
    yield call(selectOrderNote, action)
  }
}
function* selectOrderNote(action: ActionApiParams) {
  const apiOptions = {
    showSpinner: true,
  }
  try {
    const { body } = action
    const res = yield call(api.post, api.POST_SELECT_ORDER_NOTES, body, apiOptions)
    yield call(handleOrderResponse, res)
    // yield call(handleCreateOrderResponse, res)
    // yield put({ type: OrderActions.ACTION_SET_MODE, payload: { mode: CONS.ORDER_VIEW_MODE.VIEW_EDIT } })
    // yield put({ type: actions.STORE_ORDERS_SHOULD_FETCH_ALL }) // Refresh Order List
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    // yield put({ type: OrderActions.ERROR_ORDER_CREATE, error })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchGetShippingStatus() {
  while (true) {
    const action = yield take(OrderActions.GET_SHIPPING_STATUS)
    yield call(getShippingStatus, action)
  }
}
function* getShippingStatus(action: ActionApiParams) {
  // const apiOptions = {
  //   showSpinner: true,
  // }
  try {
    const { body } = action
    const res = yield call(api.postV2, api.POST_GET_SHIPPING_STATUS, body)
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchOrderSummary() {
  log('In watchOrders')
  yield takeLatest(actions.ORDERS_SUMMARY_FETCH, fetchOrderSummary)
}

function* fetchOrderSummary(action: ActionApiParams) {
  const apiOptions = {
    showSpinner: true,
  }
  try {
    const { payload } = action
    const res = yield call(api.post, api.POST_ORDERS, payload, apiOptions)
    yield put({ type: actions.SET_SHOULD_FETCH_ORDER_SUMMARY, payload: false })
    if (res.summary) {
      yield put({ type: actions.UPDATE_ORDER_SUMMARY, payload: res.summary })
    }
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchComfirmOrdersMKP() {
  while (true) {
    const action = yield take(actions.ACTION_COMFIRM_ORDERS_MKP)
    yield call(comfirmOrdersMKP, action)
  }
}

function* comfirmOrdersMKP(action: ActionApiParams) {
  const apiOptions = {
    showSpinner: true,
  }
  const { body } = action
  try {
    const res = yield call(api.postV2, api.POST_COMFIRM_ORDERS_MKP, body, apiOptions)
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchfetchMarketplaceOrder() {
  while (true) {
    const action = yield take(actions.ACTION_FETCH_MARKETPLACE_ORDER)
    yield call(fetchMarketplaceOrder, action)
  }
}

function* fetchMarketplaceOrder(action: ActionApiParams) {
  const apiOptions = {
    showSpinner: true,
  }
  const { body } = action
  try {
    const res = yield call(api.postV2, api.POST_ORDER_MARKETPLACE, body, apiOptions)
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchOrderDateDeliveryChange() {
  // log('In watchShippingTypeChange')
  while (true) {
    const { payload } = yield take(actions.ORDER_DATE_DELIVERY_CHANGE)
    yield call(orderDateDeliveryChange, payload)
  }
}

function* orderDateDeliveryChange({ store_id, order_id, date_delivery, callBack }) {
  const body: any = { store_id, order_id, date_delivery }
  // if (update_purchase_orders) {
  //   body.update_purchase_orders = update_purchase_orders
  // }
  // console.log('changeShippingType.body: ', body)
  const apiOptions = {
    messages: {
      successMsg: 'เปลี่ยนวันกำหนดส่งสำเร็จ',
      errorMsg: 'เปลี่ยนวันกำหนดส่งล้มเหลว',
    },
    showSpinner: true,
  }
  try {
    const res = yield call(api.patch, api.PATCH_ORDER_SETTING, body, apiOptions)
    yield call(handleOrderResponse, res)
    yield put({ type: actions.STORE_ORDERS_SHOULD_FETCH_ALL }) // Refresh Order List
    callBack(_.has(res, 'order') ? res.order : null)
    // log('After api changeOrderOption Response: ', res)
  } catch (error) {
    log('After api changeOrderOption error: ', error)
  }
}

function* watchDateTimeAutoCancelOrderChange() {
  // log('In watchShippingTypeChange')
  while (true) {
    const { payload } = yield take(actions.ORDER_DATE_TIME_EXPIRATION_DATE_CHANGE)
    yield call(dateTimeAutoCancelOrderChange, payload)
  }
}

function* dateTimeAutoCancelOrderChange({ store_id, order_id, expiration_date, callBack }) {
  const body: any = { store_id, order_id, expiration_date }
  // if (update_purchase_orders) {
  //   body.update_purchase_orders = update_purchase_orders
  // }
  // console.log('changeShippingType.body: ', body)
  const apiOptions = {
    messages: {
      successMsg: 'เปลี่ยนออเดอร์อัตโนมัติสำเร็จ',
      errorMsg: 'เปลี่ยนออเดอร์อัตโนมัติล้มเหลว',
    },
    showSpinner: true,
  }
  try {
    const res = yield call(api.patch, api.PATCH_ORDER_SETTING, body, apiOptions)
    yield call(handleOrderResponse, res)
    yield put({ type: actions.STORE_ORDERS_SHOULD_FETCH_ALL }) // Refresh Order List
    callBack(_.has(res, 'order') ? res.order : null)
    // log('After api changeOrderOption Response: ', res)
  } catch (error) {
    log('After api changeOrderOption error: ', error)
  }
}
