import React from 'react'
import _ from 'lodash'

import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'

import { IApiOptions, IXScreenProps } from 'x/index'
import { IXShippingConfig } from 'x/config/app'
import api from 'x/utils/api'
import * as NavActions from 'x/utils/navigation'
import { IResponXShippingSenderAddresses, IXShippingSenderAddresses } from './BaseJAntTConnectView'

export interface BaseStoreSettingXShippingJAndTViewProps extends IXScreenProps {
  selectedStore: any
  getXShippingConfigFromRedux: IXShippingConfig[]
  setXShippingConfig: (newConfig: IXShippingConfig[]) => void
}

export interface BaseStoreSettingXShippingJAndTViewState {
  // J&T
  useJAndT: boolean
  useAutoXShippingIsJAndTReadyToShip: boolean
  loadingJAntTWidth: number
  loadingJAndTHeight: number
  useJAndTCOD: boolean
  isJAntTLoading: boolean
  useAutoXShippingIsJAndTCODReadyToShip: boolean
  useAutoJAndTCreateCODPaymentWhenDelivered: boolean
  loadingJAndTCODWidth: number
  loadingJAndTCODHeight: number
  isJAndTCODLoading: boolean

  // J&T X Shipjung
  useJAndTxShipjung: boolean
  useAutoXShippingIsJAndTxShipjungReadyToShip: boolean
  loadingJAntTxShipjungWidth: number
  loadingJAndTxShipjungHeight: number
  useJAndTxShipjungCOD: boolean
  isJAntTxShipjungLoading: boolean
  useAutoXShippingIsJAndTxShipjungCODReadyToShip: boolean
  // useAutoJAndTxShipjungCreateCODPaymentWhenDelivered: boolean
  loadingJAndTxShipjungCODWidth: number
  loadingJAndTxShipjungCODHeight: number
  isJAndTxShipjungCODLoading: boolean
  senderAddresses: IXShippingSenderAddresses
}

const STATE_KEY_J_AND_T_FOR_UPDATE = {
  useJAndT: 'useJAndT',
  useAutoXShippingIsJAndTReadyToShip: 'useAutoXShippingIsJAndTReadyToShip',
  useJAndTCOD: 'useJAndTCOD',
  useAutoXShippingIsJAndTCODReadyToShip: 'useAutoXShippingIsJAndTCODReadyToShip',
  useAutoJAndTCreateCODPaymentWhenDelivered: 'useAutoJAndTCreateCODPaymentWhenDelivered',
}

const STATE_KEY_J_AND_T_X_SHIPJUNG_FOR_UPDATE = {
  useJAndTxShipjung: 'useJAndTxShipjung',
  useAutoXShippingIsJAndTxShipjungReadyToShip: 'useAutoXShippingIsJAndTxShipjungReadyToShip',
  useJAndTxShipjungCOD: 'useJAndTxShipjungCOD',
  useAutoXShippingIsJAndTxShipjungCODReadyToShip: 'useAutoXShippingIsJAndTxShipjungCODReadyToShip',
}

export default abstract class BaseStoreSettingXShippingJAndTView extends React.Component<
  BaseStoreSettingXShippingJAndTViewProps,
  BaseStoreSettingXShippingJAndTViewState
> {
  // DISPLAY_TABS: IUserListTab[]
  // _tabRef: React.RefObject<any>
  // helperProfileId: number

  protected constructor(props) {
    super(props)

    this.state = {
      // J&T
      useJAndT: false,
      useAutoXShippingIsJAndTReadyToShip: true,
      loadingJAntTWidth: 0,
      loadingJAndTHeight: 0,
      isJAntTLoading: false,
      useJAndTCOD: false,
      useAutoXShippingIsJAndTCODReadyToShip: true,
      useAutoJAndTCreateCODPaymentWhenDelivered: true,
      loadingJAndTCODWidth: 0,
      loadingJAndTCODHeight: 0,
      isJAndTCODLoading: false,
      // J&T X Shipjung
      useJAndTxShipjung: false,
      useAutoXShippingIsJAndTxShipjungReadyToShip: true,
      loadingJAntTxShipjungWidth: 0,
      loadingJAndTxShipjungHeight: 0,
      isJAntTxShipjungLoading: false,
      useJAndTxShipjungCOD: false,
      useAutoXShippingIsJAndTxShipjungCODReadyToShip: true,
      // useAutoJAndTxShipjungCreateCODPaymentWhenDelivered: true,
      loadingJAndTxShipjungCODWidth: 0,
      loadingJAndTxShipjungCODHeight: 0,
      isJAndTxShipjungCODLoading: false,

      senderAddresses: null,
    }
    // this._tabRef = React.createRef()
  }

  async componentDidMount() {
    const { getXShippingConfigFromRedux } = this.props
    // const { state } = navigation
    // const { tabIndex, permissionList, permission_id } = state.params
    await this.setKerryConfig(getXShippingConfigFromRedux)
    await this._fetchSenderAddresses()
  }

  setKerryConfig = async (config: IXShippingConfig[]) => {
    if (_.isNil(config)) {
      return
    }
    if (_.isArray(config) && config.length > 0) {
      // J&T
      let useJAndT = false
      let useAutoXShippingIsJAndTReadyToShip = false
      let useJAndTCOD = false
      let useAutoXShippingIsJAndTCODReadyToShip = false
      let useAutoJAndTCreateCODPaymentWhenDelivered = false
      // J&T x Shipjung
      let useJAndTxShipjung = false
      let useAutoXShippingIsJAndTxShipjungReadyToShip = false
      let useJAndTxShipjungCOD = false
      let useAutoXShippingIsJAndTxShipjungCODReadyToShip = false
      // let useAutoJAndTxShipjungCreateCODPaymentWhenDelivered = false
      config.map((config: IXShippingConfig) => {
        if (config.shipping_type_id === 38) {
          // J&T
          useJAndT = true
          useAutoXShippingIsJAndTReadyToShip = config.set_auto_request_xshipping_on_create_order
            ? config.set_auto_request_xshipping_on_create_order
            : false
        } else if (config.shipping_type_id === 39) {
          // J&T COD
          useJAndTCOD = true
          useAutoXShippingIsJAndTCODReadyToShip = config.set_auto_request_xshipping_on_create_order
            ? config.set_auto_request_xshipping_on_create_order
            : false
          useAutoJAndTCreateCODPaymentWhenDelivered = config.create_cod_payment_when_delivered
            ? config.create_cod_payment_when_delivered
            : false
        } else if (config.shipping_type_id === 44) {
          // J&T x Shipjung
          useJAndTxShipjung = true
          useAutoXShippingIsJAndTxShipjungReadyToShip = config.set_auto_request_xshipping_on_create_order
            ? config.set_auto_request_xshipping_on_create_order
            : false
        } else if (config.shipping_type_id === 65) {
          // J&T x Shipjung COD
          useJAndTxShipjungCOD = true
          useAutoXShippingIsJAndTxShipjungCODReadyToShip = config.set_auto_request_xshipping_on_create_order
            ? config.set_auto_request_xshipping_on_create_order
            : false
        }
      })
      await util.setStatePromise(this, {
        // J&T
        useJAndT,
        useAutoXShippingIsJAndTReadyToShip,
        useJAndTCOD,
        useAutoXShippingIsJAndTCODReadyToShip,
        useAutoJAndTCreateCODPaymentWhenDelivered,
        // J&T x Shipjung
        useJAndTxShipjung,
        useAutoXShippingIsJAndTxShipjungReadyToShip,
        useJAndTxShipjungCOD,
        useAutoXShippingIsJAndTxShipjungCODReadyToShip,
      })
    }
  }

  _fetchSenderAddresses = async () => {
    const { selectedStore } = this.props
    const apiOptions: IApiOptions = {
      showSpinner: true,
    }
    const body = {
      store_id: selectedStore.get('id'),
      system_id: 3,
    }
    // IResponXShippingSenderAddresses
    const res: IResponXShippingSenderAddresses = await api.postV2(api.POST_FETCH_SENDER_ADDRESSES, body, apiOptions)
    // console.log('res => ', res)
    if (res.sender_addresses && res.sender_addresses.length > 0) {
      await util.setStatePromise(this, {
        senderAddresses: res.sender_addresses[0],
      })
    }
  }

  useJAndTOnChange = (newValue: boolean) => {
    util.setStatePromise(this, {
      useJAndT: newValue,
      isJAntTLoading: true,
    })
  }

  // useAutoXShippingIsJAndTReadyToShipOnChange = (newValue: boolean) => {
  //   util.setStatePromise(this, {
  //     useAutoXShippingIsJAndTReadyToShip: newValue,
  //   })
  // }

  // useJAndTCODOnChange = (newValue: boolean) => {
  //   util.setStatePromise(this, {
  //     useJAndTCOD: newValue,
  //   })
  // }

  // useAutoXShippingIsJAndTCODReadyToShipOnChange = (newValue: boolean) => {
  //   util.setStatePromise(this, {
  //     useAutoXShippingIsJAndTCODReadyToShip: newValue,
  //   })
  // }

  _setNewConfig = async (newValue: { isValue: boolean; stateKey: string }) => {
    // console.log('newValue => ', newValue)
    const { selectedStore, navigation, setXShippingConfig } = this.props
    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
    }

    let apiMethod = api.patchV2
    const STATE_KEY = newValue.stateKey
    const IS_VALUE = newValue.isValue
    // console.log('STATE_KEY => ', STATE_KEY)
    if (STATE_KEY === STATE_KEY_J_AND_T_FOR_UPDATE.useJAndT) {
      // if (IS_VALUE) {
      //   p.op.showConfirmationOkOnly('', p.op.t('xshipping.warning.how_to_use_xshipping'))
      //   await util.setStatePromise(this, {
      //     [STATE_KEY]: !IS_VALUE,
      //   })
      // } else {
      //   p.op.showConfirmationOkOnly('', p.op.t('xshipping.warning.how_to_un_use_xshipping'))
      //   await util.setStatePromise(this, {
      //     [STATE_KEY]: !IS_VALUE,
      //   })
      // }
      // return
      await util.setStatePromise(this, {
        isJAntTLoading: true,
      })
      body.system_id = 3
      body.shipping_type_id = 38
      // if (IS_VALUE) {
      //   apiMethod = api.putV2
      body.set_auto_request_xshipping_on_create_order = true
      // } else {
      //   apiMethod = api.delV2
      // }
    } else if (STATE_KEY === STATE_KEY_J_AND_T_FOR_UPDATE.useAutoXShippingIsJAndTReadyToShip) {
      await util.setStatePromise(this, {
        isJAntTLoading: true,
      })
      body.shipping_type_id = 38
      body.system_id = 3
      body.set_auto_request_xshipping_on_create_order = IS_VALUE
    } else if (STATE_KEY === STATE_KEY_J_AND_T_FOR_UPDATE.useJAndTCOD) {
      // console.log('PASS => ', IS_VALUE)
      // if (IS_VALUE) {
      //   p.op.showConfirmationOkOnly('', p.op.t('xshipping.warning.how_to_use_xshipping'))
      //   await util.setStatePromise(this, {
      //     [STATE_KEY]: !IS_VALUE,
      //   })
      // } else {
      //   p.op.showConfirmationOkOnly('', p.op.t('xshipping.warning.how_to_un_use_xshipping'))
      //   await util.setStatePromise(this, {
      //     [STATE_KEY]: !IS_VALUE,
      //   })
      // }
      // return
      await util.setStatePromise(this, {
        isJAndTCODLoading: true,
      })
      body.shipping_type_id = 39
      body.system_id = 3
      // if (IS_VALUE) {
      //   apiMethod = api.putV2
      body.set_auto_request_xshipping_on_create_order = true
      body.create_cod_payment_when_delivered = true
      // } else {
      //   apiMethod = api.delV2
      // }
    } else if (STATE_KEY === STATE_KEY_J_AND_T_FOR_UPDATE.useAutoXShippingIsJAndTCODReadyToShip) {
      await util.setStatePromise(this, {
        isJAndTCODLoading: true,
      })
      body.shipping_type_id = 39
      body.system_id = 3
      // body.create_cod_payment_when_delivered = true
      body.set_auto_request_xshipping_on_create_order = IS_VALUE
    } else if (STATE_KEY === STATE_KEY_J_AND_T_FOR_UPDATE.useAutoJAndTCreateCODPaymentWhenDelivered) {
      await util.setStatePromise(this, {
        isJAndTCODLoading: true,
      })
      body.shipping_type_id = 39
      body.system_id = 3
      body.create_cod_payment_when_delivered = IS_VALUE
    } else if (STATE_KEY === STATE_KEY_J_AND_T_X_SHIPJUNG_FOR_UPDATE.useJAndTxShipjung) {
      body.system_id = 2
      if (IS_VALUE) {
        p.op.showConfirmationOkOnly('', p.op.t('xshipping.warning.how_to_use_xshipping'))
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
      } else {
        p.op.showConfirmationOkOnly('', p.op.t('xshipping.warning.how_to_un_use_xshipping'))
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
      }
      return
    } else if (STATE_KEY === STATE_KEY_J_AND_T_X_SHIPJUNG_FOR_UPDATE.useJAndTxShipjungCOD) {
      body.system_id = 2
      if (IS_VALUE) {
        p.op.showConfirmationOkOnly('', p.op.t('xshipping.warning.how_to_use_xshipping'))
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
      } else {
        p.op.showConfirmationOkOnly('', p.op.t('xshipping.warning.how_to_un_use_xshipping'))
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
      }
      return
    } else if (STATE_KEY === STATE_KEY_J_AND_T_X_SHIPJUNG_FOR_UPDATE.useAutoXShippingIsJAndTxShipjungReadyToShip) {
      await util.setStatePromise(this, {
        isJAntTxShipjungLoading: true,
      })
      body.system_id = 2
      body.shipping_type_id = 44
      body.set_auto_request_xshipping_on_create_order = IS_VALUE
    } else if (STATE_KEY === STATE_KEY_J_AND_T_X_SHIPJUNG_FOR_UPDATE.useAutoXShippingIsJAndTxShipjungCODReadyToShip) {
      await util.setStatePromise(this, {
        isJAndTxShipjungCODLoading: true,
      })
      body.system_id = 2
      body.shipping_type_id = 65
      body.set_auto_request_xshipping_on_create_order = IS_VALUE
    }

    const isMainBtn =
      STATE_KEY === STATE_KEY_J_AND_T_X_SHIPJUNG_FOR_UPDATE.useJAndTxShipjung ||
      STATE_KEY === STATE_KEY_J_AND_T_X_SHIPJUNG_FOR_UPDATE.useJAndTxShipjungCOD ||
      STATE_KEY === STATE_KEY_J_AND_T_FOR_UPDATE.useJAndT ||
      STATE_KEY === STATE_KEY_J_AND_T_FOR_UPDATE.useJAndTCOD
    if (isMainBtn && !IS_VALUE) {
      apiMethod = api.delV2
    }
    let createMode = true
    const { getXShippingConfigFromRedux } = this.props
    // ปุ่มที่กดคือ JT โดยตรง
    const jt = STATE_KEY === STATE_KEY_J_AND_T_FOR_UPDATE.useJAndT
    const jtCOD = STATE_KEY === STATE_KEY_J_AND_T_FOR_UPDATE.useJAndTCOD
    // ปุ่มที่กดคือ JT x Shipjung
    const jtxShipjung = STATE_KEY === STATE_KEY_J_AND_T_X_SHIPJUNG_FOR_UPDATE.useJAndTxShipjung
    const jtxShipjungCOD = STATE_KEY === STATE_KEY_J_AND_T_X_SHIPJUNG_FOR_UPDATE.useJAndTxShipjungCOD
    // ตรวจสอบว่าเป็น mode create หรือ edit
    if (!_.isNil(getXShippingConfigFromRedux)) {
      getXShippingConfigFromRedux.map((config: IXShippingConfig) => {
        if (config.system_id === 3 && jt && config.shipping_type_id === 38) {
          createMode = false
        } else if (config.system_id === 3 && jtCOD && config.shipping_type_id === 39) {
          createMode = false
        } else if (config.system_id === 2 && jtxShipjung && config.shipping_type_id === 38) {
          createMode = false
        } else if (config.system_id === 2 && jtxShipjungCOD && config.shipping_type_id === 39) {
          createMode = false
        }
      })
    }

    if (isMainBtn && createMode) {
      apiMethod = api.putV2
      body.set_auto_request_xshipping_on_create_order = true
      body.create_cod_payment_when_delivered = true
    }
    const response: { xshipping_config: IXShippingConfig[] } = await apiMethod(api.XSHIPPING_CONFIG, body)
    if (response.xshipping_config) {
      setXShippingConfig(response.xshipping_config)
      await this.setKerryConfig(response.xshipping_config)
      // const { state } = navigation
      // const { onPressGoBack } = state.params
      // // console.log('onPressGoBack => ', onPressGoBack)
      const onPressGoBack = util.getNavParam(this.props, 'onPressGoBack')
      if (_.isFunction(onPressGoBack)) {
        onPressGoBack()
      }
    }

    if (
      STATE_KEY === STATE_KEY_J_AND_T_FOR_UPDATE.useJAndT ||
      STATE_KEY === STATE_KEY_J_AND_T_FOR_UPDATE.useAutoXShippingIsJAndTReadyToShip
    ) {
      await util.setStatePromise(this, {
        isJAntTLoading: false,
      })
    } else if (
      STATE_KEY === STATE_KEY_J_AND_T_FOR_UPDATE.useJAndTCOD ||
      STATE_KEY === STATE_KEY_J_AND_T_FOR_UPDATE.useAutoXShippingIsJAndTCODReadyToShip ||
      STATE_KEY === STATE_KEY_J_AND_T_FOR_UPDATE.useAutoJAndTCreateCODPaymentWhenDelivered
    ) {
      await util.setStatePromise(this, {
        isJAndTCODLoading: false,
      })
    } else if (
      STATE_KEY === STATE_KEY_J_AND_T_X_SHIPJUNG_FOR_UPDATE.useJAndTxShipjung ||
      STATE_KEY === STATE_KEY_J_AND_T_X_SHIPJUNG_FOR_UPDATE.useAutoXShippingIsJAndTxShipjungReadyToShip
    ) {
      await util.setStatePromise(this, {
        isJAntTxShipjungLoading: false,
      })
    } else if (
      STATE_KEY === STATE_KEY_J_AND_T_X_SHIPJUNG_FOR_UPDATE.useJAndTxShipjungCOD ||
      STATE_KEY === STATE_KEY_J_AND_T_X_SHIPJUNG_FOR_UPDATE.useAutoXShippingIsJAndTxShipjungCODReadyToShip
    ) {
      await util.setStatePromise(this, {
        isJAndTxShipjungCODLoading: false,
      })
    }

    // console.log('response => ', response)
  }

  isSwitchOnChange = async (newValue: { isValue: boolean; stateKey: string }) => {
    // console.log('isSwitchOnChange => ', newValue)
    const STATE_KEY = newValue.stateKey
    const IS_VALUE = newValue.isValue

    if (STATE_KEY === STATE_KEY_J_AND_T_FOR_UPDATE.useJAndT || STATE_KEY === STATE_KEY_J_AND_T_FOR_UPDATE.useJAndTCOD) {
      if (_.isNil(this.state.senderAddresses)) {
        p.op.showConfirmation(
          '',
          'กรุณาตั้งค่าการลงทะเบียนก่อน',
          () => this._navToJAndTConnectView(),
          () => {},
          'ตั้งค่า',
          'ปิด'
        )
        return
      }
    }
    // let alertText = ''
    // if (STATE_KEY === STATE_KEY_J_AND_T_FOR_UPDATE.useJAndT) {
    //   alertText = IS_VALUE ? 'ยืนยันการใช้งานออกเลขพัสดุ Kerry Express ใช่หรือไม่' : 'ยกเลิกการใช้งานออกเลขพัสดุ Kerry Express ใช่หรือไม่'
    // } else if (STATE_KEY === STATE_KEY_J_AND_T_FOR_UPDATE.useAutoXShippingIsJAndTReadyToShip) {
    //   alertText = IS_VALUE
    //     ? 'ยืนยันการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Kerry Express ใช่หรือไม่'
    //     : 'ยกเลิกการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Kerry Express ใช่หรือไม่'
    // } else if (STATE_KEY === STATE_KEY_J_AND_T_FOR_UPDATE.useJAndTCOD) {
    //   alertText = IS_VALUE
    //     ? 'ยืนยันการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Kerry Express [COD] ใช่หรือไม่'
    //     : 'ยกเลิกการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Kerry Express [COD] ใช่หรือไม่'
    // } else if (STATE_KEY === STATE_KEY_J_AND_T_FOR_UPDATE.useAutoXShippingIsJAndTCODReadyToShip) {
    //   alertText = IS_VALUE
    //     ? 'ยืนยันการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Kerry Express [COD] ใช่หรือไม่'
    //     : 'ยกเลิกการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Kerry Express [COD] ใช่หรือไม่'
    // } else if (STATE_KEY === STATE_KEY_J_AND_T_FOR_UPDATE.useAutoJAndTCreateCODPaymentWhenDelivered) {
    //   alertText = IS_VALUE
    //     ? 'ยืนยันการใช้งาน "บันทึกรับชำระเมื่อพัสดุถึงผู้รับแล้ว" ใช่หรือไม่'
    //     : 'ยกเลิกการใช้งาน "บันทึกรับชำระเมื่อพัสดุถึงผู้รับแล้ว" ใช่หรือไม่'
    // }

    // let isConfirm = false
    // await new Promise(async (resolve) => {
    //   p.op.showConfirmation('', alertText, () => {
    //     isConfirm = true
    //     // @ts-ignore
    //     resolve(null)
    //   }, () => {
    //     // @ts-ignore
    //     resolve(null)
    //   }, 'ตกลง', 'ยกเลิก')
    // })

    // if (!isConfirm) {
    //   await util.setStatePromise(this, {
    //     [newValue.stateKey]: !IS_VALUE,
    //   })
    //   return
    // }
    await this._setNewConfig(newValue)
    // util.setStatePromise(this, {
    //   [newValue.stateKey]: newValue.isValue,
    // })
  }

  _navToJAndTConnectView() {
    const { navigation, selectedStore } = this.props
    navigation.dispatch(
      NavActions.navToJAndTConnectView({
        callBackSenderAddress: (senderAddress: IXShippingSenderAddresses) => this._callBackSenderAddress(senderAddress),
      })
    )
  }

  _callBackSenderAddress = (senderAddress: IXShippingSenderAddresses) => {
    // console.log('senderAddress => ', senderAddress)
    util.setStatePromise(this, {
      senderAddresses: senderAddress,
    })
    this._fetchXShippingConfig()
  }

  _fetchXShippingConfig = async () => {
    const { selectedStore, setXShippingConfig } = this.props
    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
    }

    const response: { xshipping_config: IXShippingConfig[] } = await api.postV2(api.XSHIPPING_CONFIG, body)
    // console.log('_updateXshippingConfig => ', response)
    if (response.xshipping_config && _.isArray(response.xshipping_config)) {
      setXShippingConfig(response.xshipping_config)
      await this.setKerryConfig(response.xshipping_config)
    }
  }
}
