import React from 'react'
import _ from 'lodash'
import * as xNavActions from 'x/utils/navigation'
import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import { IXShippingConfig, IXShippingSystemAccount } from 'x/config/app'
import api from 'x/utils/api'
import { IXScreenProps } from 'x/types'

interface IResponse {
  link_account_url: string
}

export interface BaseStoreSettingXShippingSpxViewProps extends IXScreenProps {
  selectedStore: any
  getXShippingConfigFromRedux: IXShippingConfig[]
  setXShippingConfig: (newConfig: IXShippingConfig[]) => void
  getXshippingSystemAccountFromRedux: IXShippingSystemAccount[]
}

export interface BaseStoreSettingXShippingSpxViewState {
  spxConnectedData: IXShippingSystemAccount
  config: IXShippingConfig[]
}

export const SPX_DROPOFF_ID = 85
export const SPX_DROPOFF_COD_ID = 86
export const SPX_PICKUP_ID = 83
export const SPX_PICKUP_COD_ID = 84
export const SPX_SYSTEM_ID = 11
export const SHIPJUNG_SYSTEM_ID = 2

const alertShipJung = 'หากต้องการใช้งานเชื่อม SPX โดยตรง กรุณาปิดการใช้งานการเชื่อมผ่าน Shipjung'

export default abstract class BaseStoreSettingXShippingSpxView extends React.Component<
  BaseStoreSettingXShippingSpxViewProps,
  BaseStoreSettingXShippingSpxViewState
> {
  // DISPLAY_TABS: IUserListTab[]
  // _tabRef: React.RefObject<any>
  // webview: React.RefObject<any>

  protected constructor(props) {
    super(props)

    this.state = {
      config: null,
      spxConnectedData: null,
    }
    // this.webview = React.createRef()
    // this._tabRef = React.createRef()
  }

  async componentDidMount() {
    const { getXShippingConfigFromRedux } = this.props
    // const { state } = navigation
    // const { tabIndex, permissionList, permission_id } = state.params
    await this._setIsConnectedSpx()
    await this.setSpxConfig(getXShippingConfigFromRedux)
  }

  _setIsConnectedSpx = async () => {
    const { getXshippingSystemAccountFromRedux } = this.props
    let spxConnectedData = null
    if (!_.isNil(getXshippingSystemAccountFromRedux)) {
      getXshippingSystemAccountFromRedux.forEach((config: IXShippingSystemAccount) => {
        if (config.system_id === 11) {
          spxConnectedData = config
        }
      })
    }
    await util.setStatePromise(this, { spxConnectedData })
  }

  setSpxConfig = async (newConfig: IXShippingConfig[]) => {
    // console.log('newConfig => ', newConfig)
    if (_.isNil(newConfig)) {
      return
    }
    await util.setStatePromise(this, {
      config: newConfig,
    })
  }

  // updateKey ตือ key ที่ต้องการอัพเดท เช่น 'set_auto_request_xshipping_on_create_order' | 'create_cod_payment_when_delivered' ถ้าไม่ใส่ค่าจะเป็นการเปลี่ยนสถานะการใช้งานหลัก
  isSwitchOnChange = async (
    shipping_type_id: number,
    system_id: number,
    updateKey?: 'set_auto_request_xshipping_on_create_order' | 'create_cod_payment_when_delivered'
  ) => {
    // console.log('shipping_type_id => ', shipping_type_id)
    const { selectedStore, setXShippingConfig } = this.props

    const createMode = this._isCreateMode(shipping_type_id, system_id)

    // ถ้าเป็น SHIPJUNG_SYSTEM_ID และไม่มีการกำหนด updateKey คือจะไม่อนุญาติให้เปลี่ยนสถานะการใช้งาน
    if (system_id === SHIPJUNG_SYSTEM_ID && _.isNil(updateKey)) {
      if (createMode) {
        p.op.showConfirmationOkOnly('', p.op.t('xshipping.warning.how_to_use_xshipping'))
        await util.setStatePromise(this, {
          config: _.cloneDeep(this.state.config),
        })
        return
      }
      p.op.showConfirmationOkOnly('', p.op.t('xshipping.warning.how_to_un_use_xshipping'))
      await util.setStatePromise(this, {
        config: _.cloneDeep(this.state.config),
      })
      return
    }

    // ถ้าเป็น SPX_SYSTEM_ID และมีการใช้งาน Shipjung จะไม่อนุญาติให้เปลี่ยนสถานะการใช้งาน
    if (system_id === SPX_SYSTEM_ID) {
      const useShipJung = this._haveShipjung()
      if (useShipJung) {
        p.op.showConfirmationOkOnly('', alertShipJung)
        await util.setStatePromise(this, {
          config: _.cloneDeep(this.state.config),
        })
        return
      }
    }

    const configData = this._getConfigByShippingTypeId(shipping_type_id)
    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
    }

    const isMainSwitchChange =
      _.includes([SPX_DROPOFF_ID, SPX_DROPOFF_COD_ID, SPX_PICKUP_ID, SPX_PICKUP_COD_ID], shipping_type_id) && _.isNil(updateKey)
    let apiMethod = api.patchV2

    body.shipping_type_id = shipping_type_id
    // ถ้าเป็นปุ่มสวิตหลัก และ เป็นโหมดสร้างให้เป็น apiMethod = api.putV2
    if (isMainSwitchChange && createMode) {
      apiMethod = api.putV2
      body.set_auto_request_xshipping_on_create_order = true
      body.create_cod_payment_when_delivered = true
    }
    // ถ้าเป็นปุ่มสวิตหลัก และ เป็นไม่ใช่โหมดสร้างให้เป็น apiMethod = api.delV2
    if (isMainSwitchChange && !createMode) {
      apiMethod = api.delV2
    }

    if (!isMainSwitchChange && !createMode) {
      body[updateKey] = !configData[updateKey]
    }

    body.system_id = system_id

    const response: { xshipping_config: IXShippingConfig[] } = await apiMethod(api.XSHIPPING_CONFIG, body)
    if (response.xshipping_config) {
      setXShippingConfig(response.xshipping_config)
      await this.setSpxConfig(response.xshipping_config)
      // const { state } = navigation
      // const { onPressGoBack } = state.params
      // // console.log('onPressGoBack => ', onPressGoBack)
      const onPressGoBack = util.getNavParam(this.props, 'onPressGoBack')
      if (_.isFunction(onPressGoBack)) {
        onPressGoBack()
      }
    }
  }

  // createMode คือ การสร้าง config ใหม่ โดยดูจากการมี config หรือไม่ ถ้าไม่มีคือการสร้างใหม่
  _isCreateMode = (shipping_type_id: number, system_id: number) => {
    const { config } = this.state
    if (_.isNil(config)) {
      return true
    }
    const isActive = config.find((item: IXShippingConfig) => item.shipping_type_id === shipping_type_id && item.system_id === system_id)
    return _.isNil(isActive)
  }

  _getConfigByShippingTypeId = (shipping_type_id: number) => {
    const { config } = this.state
    if (_.isNil(config)) {
      return null
    }
    const configData = config.find((item: IXShippingConfig) => item.shipping_type_id === shipping_type_id)
    return configData
  }

  _haveShipjung = () => {
    const { config } = this.state
    if (_.isNil(config)) {
      return false
    }
    const configData = config.find(
      (item: IXShippingConfig) =>
        _.includes([SPX_DROPOFF_ID, SPX_DROPOFF_COD_ID, SPX_PICKUP_ID, SPX_PICKUP_COD_ID], item.shipping_type_id) &&
        item.system_id === SHIPJUNG_SYSTEM_ID
    )
    return !_.isNil(configData)
  }

  _setCallBackXshippingSystemAccount = async (systemAccounts) => {
    // console.log('systemAccounts xx => ', systemAccounts)
    let spxConnectedData = null
    if (!_.isNil(systemAccounts)) {
      systemAccounts.forEach((config: IXShippingSystemAccount) => {
        if (config.system_id === 11) {
          spxConnectedData = config
        }
      })
    }
    await util.setStatePromise(this, { spxConnectedData })
  }

  _navToStoreSettingSpx = () => {
    if (this._haveShipjung()) {
      p.op.showConfirmationOkOnly('', alertShipJung)
      return
    }
    const { getXShippingConfigFromRedux } = this.props
    let spxConfig = null
    if (!_.isNil(getXShippingConfigFromRedux)) {
      spxConfig = getXShippingConfigFromRedux.filter((config: IXShippingConfig) => config.shipping_type_id === 58)
      if (_.isNil(spxConfig) || spxConfig.length === 0) {
        spxConfig = getXShippingConfigFromRedux.filter((config: IXShippingConfig) => config.shipping_type_id === 59)
      }
      if (_.isNil(spxConfig) || spxConfig.length === 0) {
        spxConfig = null
      }
    }

    this.props.navigation.dispatch(
      xNavActions.navToStoreSettingSpx({
        spxConfig: !_.isNil(spxConfig) ? spxConfig[0] : null,
        succeedCallback: (newConfig, systemAccounts) => {
          this.setSpxConfig(newConfig)
          this._setCallBackXshippingSystemAccount(systemAccounts)
        },
      })
    )
  }
}
