import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { getSelectedStore, getCategoryList } from 'x/redux/selectors'

import * as StoreState from 'x/modules/store/StoreState'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import CONS from 'x/config/constants'
import * as api from 'x/utils/api'

import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import XInput from 'xui/components/XInput'
import XText from 'xui/components/XText'
import XColorPalette from 'xui/components/XColorPalette'
import { diff } from 'deep-object-diff'
import _ from 'lodash'
import NavHeaderButton from 'xui/components/NavHeaderButton'
import Box from 'xui/components/Box'
import { IProductListFilterEditorViewProps, IProductListFilterEditorViewState, IProductListFilterPreference } from 'x/index'
import { Map } from 'immutable'
import dayjs from 'dayjs'
import XIconButton from 'xui/components/XIconButton'
import BaseUIProductListFilterView from './BaseUIProductListFilterView'

class ProductListFilterEditorView extends BaseUIProductListFilterView<
  IProductListFilterEditorViewProps,
  IProductListFilterEditorViewState
> {
  static displayName = 'ProductListFilterEditorView'

  // override
  resetSelectedTemplate = async () => {
    await util.setStatePromise(this, { selectedFilterTemplate: null })
  }

  _onEditingTemplateColorChange = (newColor: string) => {
    const currentSetting = this._getCurrentFilter()
    this._setCurrentFilter({ ...currentSetting, color: newColor })
  }

  _onEditingTemplateNameChange = (newText: string) => {
    const currentSetting = this._getCurrentFilter()
    this._setCurrentFilter({ ...currentSetting, name: newText })
  }

  _renderProductTemplateBar = () => {
    const currentSetting = this._getCurrentFilter()

    return (
      <VStack w='full' p='1' space='2' bg='white'>
        {/* <VStack w='full' space='0.5'>
          <XText variant='inactive'>ID</XText>
          <XText>{currentSetting.id || ''}</XText>
        </VStack> */}

        <VStack w='full' space='0.5'>
          <XText>ชื่อเทมเพลต</XText>
          <XInput
            w='full'
            h='9'
            value={currentSetting.name || ''}
            onChangeText={this._onEditingTemplateNameChange}
            placeholder='เช่น กรองออเดอร์ที่มี COD'
          />
        </VStack>

        <VStack w='full' space='0.5'>
          <XText variant='inactive'>สีเทมเพลต</XText>
          <XColorPalette value={currentSetting.color || ''} onChange={this._onEditingTemplateColorChange} />
        </VStack>

        {/* <VStack w='full' space='0.5'>
          <XText variant='inactive'>updatedAt</XText>
          <XText>{currentSetting.updatedAt || ''}</XText>
        </VStack> */}

        {/* <VStack w='full' space='0.5'>
          <XText variant='inactive'>createdAt</XText>
          <XText>{currentSetting.createdAt || ''}</XText>
        </VStack> */}
      </VStack>
    )
  }

  _onCancel = async () => {
    if (this.inProcess) {
      return
    }

    this.inProcess = true
    const { currentSetting, appliedSetting } = this.state

    const diffSetting = diff(currentSetting, appliedSetting)
    const isDirty = !_.isEmpty(diffSetting)

    if (isDirty) {
      const isUserConfirm = await p.op.isUserConfirm(
        'ยกเลิกการแก้ไข',
        `กรุณายืนยันว่าฉันต้องการยกเลิกการเปลี่ยนแปลงทั้งหมดที่ทำไว้`,
        'ยืนยัน',
        'กลับไปแก้ไข'
      )

      if (!isUserConfirm) {
        this.inProcess = false
        return
      }
    }

    if (!this._isAlreadyExist()) {
      this._goBack()
      this.inProcess = false
    }

    await this._clearAllSelected()

    this.inProcess = false
  }

  _getPref = (): IProductListFilterPreference => {
    const { selectedStore } = this.props
    const pref = selectedStore.get('pref_ui_pp_list_filter')

    if (!pref || !pref.get('createdAt')) {
      return {
        templates: [],
        // dashboardItems: [],
        createdAt: dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString(),
      }
    }

    return Map.isMap(pref) ? pref.toJS() : pref
  }

  _isAlreadyExist = () => {
    const currentSetting = this._getCurrentFilter()
    const { id } = currentSetting
    const pref = this._getPref()
    const foundIndex = pref.templates.findIndex((t) => t.id === id)
    return foundIndex > -1
  }

  _onDelete = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    const currentSetting = this._getCurrentFilter()
    const pref = this._getPref()
    const editingPref = _.cloneDeep(pref)
    const foundIndex = editingPref.templates.findIndex((t) => t.id === currentSetting.id)

    if (foundIndex < 0) {
      this.inProcess = false
      return
    }

    const isUserConfirm = await p.op.isUserConfirm(
      'ลบเทมเพลตตัวกรองออเดอร์',
      `กรุณายืนยันว่าฉันต้องการลบเทมเพลต "${editingPref.templates[foundIndex].name}"`,
      'ลบ',
      'ยกเลิก'
    )

    if (!isUserConfirm) {
      this.inProcess = false
      return
    }

    await util.setStatePromise(this, { isSubmitting: true })

    editingPref.templates.splice(foundIndex, 1)

    const store_id = util.getNavParam(this.props, 'store_id')
    const onSaveSuccess = util.getNavParam(this.props, 'onSaveSuccess')
    const isPreventGoBackAfterSaveSuccess = util.getNavParam(this.props, 'isPreventGoBackAfterSaveSuccess')

    // const isSuccess = await api.postStoreUser({ store_id, perf_ui_order_list_filter: editingPref })
    const isSuccess = await api.postStoreUser({ store_id, pref_ui_pp_list_filter: editingPref })
    // console.log('_onSavePressed isSuccess => ', isSuccess)

    if (isSuccess) {
      const { updateSelectedStoreByKey } = this.props
      updateSelectedStoreByKey('pref_ui_pp_list_filter', editingPref)
      p.op.showToast('ลบทมเพลตตัวกรองออเดอร์เรียบร้อยแล้ว', 'success')

      if (_.isFunction(onSaveSuccess)) {
        await util.delay(100)
        // @ts-ignore
        await onSaveSuccess(editingPref)
      }

      if (!isPreventGoBackAfterSaveSuccess) {
        util.navGoBack(this.props)
      }
    } else {
      p.op.showToast('ลบทมเพลตตัวกรองออเดอร์ไม่สําเร็จ', 'danger')
    }

    await util.setStatePromise(this, { isSubmitting: false })
    this.inProcess = false
  }

  _onSave = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    const currentSetting = this._getCurrentFilter()
    const pref = this._getPref()
    const editingPref = _.cloneDeep(pref)
    const foundIndex = editingPref.templates.findIndex((t) => t.id === currentSetting.id)
    const isAlreadyExist = foundIndex > -1

    let isUserConfirm = await p.op.isUserConfirm(
      'บันทึกเทมเพลตตัวกรองออเดอร์',
      `กรุณายืนยันว่าฉันต้องการ${isAlreadyExist ? 'แก้ไข' : 'บันทึก'}เทมเพลตตัวกรองออเดอร์`,
      'ยืนยัน',
      'กลับไปแก้ไข'
    )

    if (!isUserConfirm) {
      this.inProcess = false
      return
    }

    await util.setStatePromise(this, { isSubmitting: true })

    const savedTemplate = _.cloneDeep(currentSetting)
    savedTemplate.updatedAt = dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString()

    if (!savedTemplate.name || savedTemplate.name === '') {
      savedTemplate.name = savedTemplate.id
    }

    let hasCustomDateRange = false

    if (savedTemplate.createdAtRangeOptionKey === 'Custom') {
      delete savedTemplate.createdAtRangeOptionKey
      hasCustomDateRange = true
    }
    delete savedTemplate.createdAtFrom
    delete savedTemplate.createdAtTo

    if (savedTemplate.updatedAtRangeOptionKey === 'Custom') {
      delete savedTemplate.updatedAtRangeOptionKey
      hasCustomDateRange = true
    }
    delete savedTemplate.updatedAtFrom
    delete savedTemplate.updatedAtTo

    if (hasCustomDateRange) {
      isUserConfirm = await p.op.isUserConfirm(
        'มีการตั้งค่าช่วงเวลาแบบกำหนดเอง',
        'กรุณายืนยันการบันทึกเทมเพลตโดยที่การตั้งค่าตัวกรองของช่วงเวลาแบบ "กำหนดเอง" จะไม่ถูกบันทึกไปด้วย',
        'ยืนยัน',
        'กลับไปแก้ไข'
      )

      if (!isUserConfirm) {
        this.inProcess = false
        return
      }
    }

    if (isAlreadyExist) {
      editingPref.templates[foundIndex] = savedTemplate
    } else {
      savedTemplate.createdAt = savedTemplate.updatedAt
      editingPref.templates.push(savedTemplate)
    }

    const store_id = util.getNavParam(this.props, 'store_id')
    const onSaveSuccess = util.getNavParam(this.props, 'onSaveSuccess')
    const isPreventGoBackAfterSaveSuccess = util.getNavParam(this.props, 'isPreventGoBackAfterSaveSuccess')

    // const isSuccess = await api.postStoreUser({ store_id, perf_ui_order_list_filter: editingPref })
    const isSuccess = await api.postStoreUser({ store_id, pref_ui_pp_list_filter: editingPref })
    // console.log('_onSavePressed isSuccess => ', isSuccess)

    if (isSuccess) {
      const { updateSelectedStoreByKey } = this.props
      updateSelectedStoreByKey('pref_ui_pp_list_filter', editingPref)
      p.op.showToast('บันทึกเทมเพลตตัวกรองออเดอร์เรียบร้อยแล้ว', 'success')

      if (_.isFunction(onSaveSuccess)) {
        await util.delay(100)
        await onSaveSuccess(editingPref, savedTemplate)
      }

      if (!isPreventGoBackAfterSaveSuccess) {
        util.navGoBack(this.props)
      }
    } else {
      p.op.showToast('บันทึกเทมเพลตตัวกรองออเดอร์ไม่สําเร็จ', 'danger')
    }

    await util.setStatePromise(this, { isSubmitting: false })
    this.inProcess = false
  }

  _renderHeaderLeft = () => {
    const { currentSetting, appliedSetting } = this.state
    const isAlreadyExist = this._isAlreadyExist()
    const diffSetting = diff(currentSetting, appliedSetting)
    const isDirty = !_.isEmpty(diffSetting) || !isAlreadyExist

    if (isDirty) {
      return <NavHeaderButton label='บันทึก' onPressItem={this._onSave} />
    }

    return <NavHeaderButton backIcon onPressItem={this._goBack} />
  }

  _renderHeaderRight = () => {
    const { currentSetting, appliedSetting } = this.state
    const isAlreadyExist = this._isAlreadyExist()
    const diffSetting = diff(currentSetting, appliedSetting)
    const isDirty = !_.isEmpty(diffSetting) || !isAlreadyExist

    if (isDirty) {
      return <NavHeaderButton label='ยกเลิก' onPressItem={this._onCancel} />
    }

    if (isAlreadyExist) {
      return <XIconButton name='trash' family='Ionicons' colorScheme='danger' onPress={this._onDelete} />
    }

    return <Box w='44px' h='34px' />
  }

  _renderHeader = () => {
    const headerTitle = util.getNavParam(this.props, 'headerTitle', 'กรอง')

    return (
      <HStack
        w='full'
        px='2'
        py='1'
        minH='44px'
        alignItems='center'
        justifyContent='center'
        space='1'
        borderBottomWidth='1'
        borderBottomColor='gray.300'>
        {this._renderHeaderLeft()}

        <HStack flex={1} alignItems='center' justifyContent='center'>
          <XText w='full' variant='active' bold textAlign='center'>
            {headerTitle}
          </XText>
        </HStack>

        {this._renderHeaderRight()}
      </HStack>
    )
  }

  _renderFooter = () => null

  _renderMain = () => (
    <VStack w='full' space='1.5'>
      {this._renderProductTemplateBar()}
      <VStack w='full' px='2' py='1' space='1.5'>
        {this._renderCollapsibleSortCard()}
        {this._renderCollapsibleStockCard()}
        {this._renderCollapsibleCostCard()}
        {this._renderCollapsibleDatetimeCard()}
        {this._renderCollapsibleMkpCard()}
        {this._renderCollapsibleCategoryCard()}
      </VStack>
    </VStack>
  )
}

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    categoryList: getCategoryList(state),
  }),
  (dispatch) => ({
    dispatch,
    updateSelectedStoreByKey: bindActionCreators(StoreState.updateSelectedStoreByKey, dispatch),
  })
)(ProductListFilterEditorView)
