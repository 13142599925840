import React, { ReactNode } from 'react'
import _ from 'lodash'
import { TouchableOpacity, View, Text, ViewStyle, StyleProp, StyleSheet, Animated, LayoutChangeEvent, LayoutRectangle } from 'react-native'

import { S, COLORS } from 'x/config/styles'
import p from 'x/config/platform-specific'
import VStack from './VStack'
// import ExpandableAnimatedView from './ExpandableAnimatedView'

interface IBaseCollapsableCardProps {
  disabled?: boolean

  // ใส่เมื่อต้องการ manage expand status เอง
  expanded?: boolean

  separatorEnabled?: boolean

  // สถานะที่ควรจะเป็นหลังจาก user กด toggle
  onPressToggle?: (nextExpended: boolean) => void

  containerStyle?: StyleProp<ViewStyle>
  buttonStyle?: StyleProp<ViewStyle>
  contentContainerStyle?: StyleProp<ViewStyle>

  // renderContent render content inside the card while expanded
  renderContent: () => JSX.Element
  // children render the card label or data while collapsed
  children?: ReactNode | FaCC
}

interface ICollapsableCardProps extends IBaseCollapsableCardProps {
  disabledAnimation?: boolean // default = false
}

type FaCC = (params: {
  //
  renderIcon: () => JSX.Element
}) => JSX.Element

const CollapsableCard: React.FC<ICollapsableCardProps> = (props) =>
  props.disabledAnimation ? <SimpleCollapsableCard {...props} /> : <AnimatedCollapsableCard {...props} />

const SimpleCollapsableCard: React.FC<ICollapsableCardProps> = (props) => {
  const [containerLayout, setContainerLayout] = React.useState<LayoutRectangle>(null)
  const [isExpanded, setIsExpanded] = React.useState(props.expanded || false)

  // Control isExpanded by props
  React.useEffect(() => {
    if (isExpanedPropsExist()) {
      if (isExpanded !== props.expanded) {
        setIsExpanded(props.expanded)
        // setTimeout(_slideDownContent, 200)
      }
    }
  }, [props.expanded])

  function _onContainerLayoutChange(evt: LayoutChangeEvent) {
    setContainerLayout(evt.nativeEvent.layout)
  }

  function isExpanedPropsExist() {
    return typeof props.expanded === 'boolean'
  }

  async function _onPressLabelButton() {
    const nextExpanded = !props.expanded
    if (typeof props.onPressToggle === 'function') {
      props.onPressToggle(nextExpanded)
    }

    if (isExpanedPropsExist()) {
      // skip set state เนื่องจาก parent ต้องการ manage expanded status เอง
      return
    }
    setIsExpanded(nextExpanded)
    // setTimeout(_slideDownContent, 200)
  }

  const _renderSeparatorBottomLine = () => {
    if (!props.separatorEnabled) {
      return null
    }
    return (
      <View
        style={[
          S.WIDTH_FULL,
          { height: 1, borderBottomWidth: 1, borderBottomColor: COLORS.TEXT_INACTIVE, marginBottom: isExpanded ? 4 : 0 },
        ]}
      />
    )
  }

  function _renderExpandedContent() {
    return props.renderContent()
  }

  function _hasFaCC() {
    return props.children && typeof props.children === 'function'
  }

  const _renderExpandableIcon = () => (
    <View>
      <Text>Hello Icon</Text>
    </View>
  )

  function _renderChildren() {
    if (_hasFaCC()) {
      // @ts-ignore
      return props.children({ renderIcon: _renderExpandableIcon })
    }
    return props.children
  }

  return (
    <VStack
      w='full'
      // _light={{
      //   bg: props.disabled ? 'muted.200' : 'white',
      // }}
      // overflow='hidden'
      style={[{ overflow: 'hidden' }, StyleSheet.flatten(props.containerStyle)]}
      // style={[
      //   S.COLUMN_LEFT_TOP,
      //   S.CARDLIKE_BORDERS,
      //   props.disabled ? S.BG_LIGHT_GREY : S.BG_WHITE,
      //   { overflow: 'hidden' },
      //   StyleSheet.flatten(props.containerStyle),
      // ]}
      // onLayout={_onContainerLayoutChange}
    >
      <TouchableOpacity
        disabled={props.disabled}
        // style={[
        //   S.COLUMN_LEFT_TOP,
        //   containerLayout && containerLayout.width ? { width: containerLayout.width } : S.WIDTH_FULL,
        //   StyleSheet.flatten(props.buttonStyle),
        // ]}
        onPress={_onPressLabelButton}>
        <VStack w='full' style={props.buttonStyle}>
          {_renderChildren()}
        </VStack>
      </TouchableOpacity>
      <VStack
        _light={{ bg: 'white' }}
        w='full'
        style={[
          isExpanded ? {} : { display: 'none' },
          // containerLayout && containerLayout.width ? { width: containerLayout.width } : S.WIDTH_FULL,
          // S.COLUMN_LEFT_TOP,
          StyleSheet.flatten(props.contentContainerStyle),
        ]}>
        {_renderSeparatorBottomLine()}
        {_renderExpandedContent()}
      </VStack>
    </VStack>
  )
}

const AnimatedCollapsableCard: React.FC<ICollapsableCardProps> = (props) => {
  const startingHeight = 0

  const [isExpanded, setIsExpanded] = React.useState(false)
  const [containerLayout, setContainerLayout] = React.useState<LayoutRectangle>(null)

  const [fullContentHeight, setFullContentHeight] = React.useState(startingHeight)
  // const [fullContentHeights, setFullContentHeights] = React.useState<number[]>([])
  const animatedHeight = React.useRef(new Animated.Value(startingHeight)).current
  const [isContentSlidingDown, setIsContentSlidingDown] = React.useState<boolean>(false)

  // const [slideDownCount, setSlideDownCount] = React.useState<number>(1)

  const animatedContainerViewRef = React.useRef<View>()
  const contentViewRef = React.useRef<View>()

  // Control isExpanded by props
  React.useEffect(() => {
    if (isExpanedPropsExist()) {
      if (isExpanded !== props.expanded) {
        setIsExpanded(props.expanded)
        // setTimeout(_slideDownContent, 200)
      }
    }
  }, [props.expanded])

  // React.useEffect(() => {
  //   const slideDownTimeout = slideDownCount * 10
  //   if (!isExpanded) {
  //     setTimeout(_slideDownContent, 300)
  //     setFullContentHeights([startingHeight])
  //     setSlideDownCount(1)
  //   } else {
  //     setSlideDownCount(slideDownCount + 1)
  //     console.log('CollapsableCard:: slideDownTimeout => ', slideDownTimeout)
  //     setTimeout(_slideDownContent, 700 + slideDownTimeout)
  //   }
  // }, [isExpanded])

  // React.useEffect(() => {
  //   if (!isExpanded && contentViewRef.current) {
  //     contentViewRef.current.measureLayout(
  //       // @ts-ignore
  //       animatedContainerViewRef.current,
  //       (left, top, width, height) => {
  //         setFullContentHeight(startingHeight)
  //       },
  //       () => {
  //         //
  //       }
  //     )
  //   }

  //   _slideDownContent()
  //   // setSlideDownCount(slideDownCount + 1)
  //   // const slideDownTimeout = slideDownCount * 10
  //   // console.log('CollapsableCard:: slideDownTimeout => ', slideDownTimeout)
  //   // setTimeout(_slideDownContent, 300 + slideDownTimeout)
  // }, [isExpanded, fullContentHeight])

  async function _slideDownContent() {
    // const targetHeight = isExpanded ? fullContentHeight : startingHeight
    // // const currentHeight = animatedHeight._value
    // // console.log('CollapsableCard::_slideDownContent currentHeight => ', currentHeight)
    // if (isContentSlidingDown || (isExpanded && targetHeight === startingHeight)) {
    //   return
    // }
    // const targetHeight = isExpanded ? fullContentHeight : startingHeight
    // if (isContentSlidingDown || !contentViewRef.current) {
    //   return
    // }
    // setIsContentSlidingDown(true)

    // contentViewRef.current.measureLayout(
    //   // @ts-ignore
    //   animatedContainerViewRef.current,
    //   (left, top, width, height) => {
    //     // setMeasure({ left, top, width, height })
    //     // const targetHeight = isExpanded ? height : 0
    //     const targetHeight = isExpanded ? Math.max(...fullContentHeights) : 0
    //     Animated.spring(animatedHeight, {
    //       // delay: 200,
    //       speed: 100,
    //       // bounciness: 4,
    //       // tension: 6,
    //       // friction: 9,
    //       // friction: 50,
    //       // duration: 800,
    //       toValue: targetHeight,
    //       useNativeDriver: p.op.isWeb() ? true : false,
    //     }).start((resule) => {
    //       if (resule.finished) {
    //         setIsContentSlidingDown(false)
    //       }
    //     })
    //   },
    //   () => {
    //     // on fail
    //   }
    // )

    if (_.isNil(contentViewRef.current) || _.isNil(animatedContainerViewRef.current)) {
      return
    }

    contentViewRef.current.measureLayout(
      // @ts-ignore
      animatedContainerViewRef.current,
      (left, top, width, height) => {
        // setMeasure({ left, top, width, height })
        // const targetHeight = isExpanded ? height : 0
        // const targetHeight = isExpanded ? fullContentHeight : 0
        // if (isExpanded && targetHeight < height) {
        //   return
        // }
        Animated.spring(animatedHeight, {
          // delay: 200,
          speed: 100,
          // bounciness: 4,
          // tension: 6,
          // friction: 9,
          // friction: 50,
          // duration: 800,
          // toValue: targetHeight,
          toValue: height,
          useNativeDriver: !!p.op.isWeb(),
        }).start((resule) => {
          if (resule.finished) {
            setIsContentSlidingDown(false)
          }
        })
      },
      () => {
        // on fail
      }
    )

    // console.log('CollapsableCard::_slideDownContent isExpanded => ', isExpanded)
    // console.log('CollapsableCard::_slideDownContent targetHeight => ', targetHeight)
    // Animated.spring(animatedHeight, {
    //   // delay: 200,
    //   friction: 50,
    //   // duration: 800,
    //   toValue: targetHeight,
    //   useNativeDriver: false,
    // }).start((resule) => {
    //   if (resule.finished) {
    //     setIsContentSlidingDown(false)
    //   }
    // })
  }

  function _onViewContainerLayout(evt: LayoutChangeEvent) {
    const { x, y, width, height } = evt.nativeEvent.layout
    // console.log('CollapsableCard::_onViewContainerLayout  evt.nativeEvent.layout => ', evt.nativeEvent.layout)

    // console.log('CollapsableCard::_onViewContainerLayout => ', { x, y, width, height })
    if (height > startingHeight) {
      // setTimeout(() => {
      if (height < fullContentHeight) {
        return
      }
      // console.log('CollapsableCard::_onViewContainerLayout  height set => ', height)
      setFullContentHeight(height < fullContentHeight ? fullContentHeight : height)
      // setFullContentHeights(fullContentHeights.concat(height))
      // }, 200)
      // setTimeout(_slideDownContent, 200)
    }
  }

  function _onContainerLayout(evt: LayoutChangeEvent) {
    // console.log('CollapsableCard::_onContainerLayout  evt => ', evt)

    setContainerLayout(evt.nativeEvent.layout)
  }

  // ใช้เช็คว่า parent view ต้องการจะ manage expanded status หรือไม่
  function isExpanedPropsExist() {
    return typeof props.expanded === 'boolean'
  }

  async function _onPressLabelButton() {
    const nextExpanded = !isExpanded
    // console.log('CollapsableCard::_onPressLabelButton  nextExpanded => ', nextExpanded)
    if (typeof props.onPressToggle === 'function') {
      // if (util.isAsyncFunction(props.onPressToggle)) {
      //   await props.onPressToggle(nextExpanded)
      // } else {
      //   props.onPressToggle(nextExpanded)
      // }
      props.onPressToggle(nextExpanded)
    }

    if (isExpanedPropsExist()) {
      // skip set state เนื่องจาก parent ต้องการ manage expanded status เอง
      return
    }
    setIsExpanded(nextExpanded)
    // setTimeout(_slideDownContent, 200)
  }

  function _hasFaCC() {
    return props.children && typeof props.children === 'function'
  }

  const _renderExpandableIcon = () => (
    <View>
      <Text>Hello Icon</Text>
    </View>
  )

  function _renderChildren() {
    if (_hasFaCC()) {
      // @ts-ignore
      return props.children({ renderIcon: _renderExpandableIcon })
    }
    return props.children
  }

  const _renderSeparatorBottomLine = () => {
    if (!props.separatorEnabled) {
      return null
    }
    return (
      <View
        style={[
          S.WIDTH_FULL,
          { height: 1, borderBottomWidth: 1, borderBottomColor: COLORS.TEXT_INACTIVE, marginBottom: isExpanded ? 4 : 0 },
        ]}
      />
    )
  }

  function _renderExpandedContent() {
    // if (!isExpanded) {
    //   return null
    // }
    return props.renderContent()
  }

  return (
    <View
      ref={animatedContainerViewRef}
      style={[
        S.COLUMN_LEFT_TOP,
        S.CARDLIKE_BORDERS,
        props.disabled ? S.BG_LIGHT_GREY : S.BG_WHITE,
        { flexBasis: 'auto' },
        StyleSheet.flatten(props.containerStyle),
      ]}
      onLayout={_onContainerLayout}>
      <TouchableOpacity
        disabled={props.disabled || isContentSlidingDown}
        style={[S.COLUMN_LEFT_TOP, S.WIDTH_FULL, StyleSheet.flatten(props.buttonStyle)]}
        onPress={_onPressLabelButton}>
        {_renderChildren()}
      </TouchableOpacity>
      {/* {_renderSeparatorBottomLine()} */}
      {/* <ExpandableAnimatedView expanded={isExpanded} contentContainerStyle={[S.COLUMN_LEFT_TOP, S.WIDTH_FULL]}>
        {_renderExpandedContent()}
      </ExpandableAnimatedView> */}
      <Animated.View
        style={[
          S.COLUMN_LEFT_TOP,
          S.WIDTH_FULL,
          S.BG_WHITE,
          styles.animatedContainer,
          // StyleSheet.flatten(style),
          {
            // height: isExpanded ? undefined : 0,
            height: isExpanded ? animatedHeight : startingHeight,
            // transform: [
            //   {
            //     translateY: animatedHeight,
            //   },
            // ],
          },
        ]}>
        <View
          ref={contentViewRef}
          style={[
            S.COLUMN_LEFT_TOP,
            styles.contentContainer,
            isExpanded ? styles.contentContainerSnapTop : styles.contentContainerSnapBottom,
            StyleSheet.flatten(props.contentContainerStyle),
            containerLayout ? { width: containerLayout.width } : {},
          ]}
          onLayout={_onViewContainerLayout}>
          <CollapsableEventTracker
            contentViewRef={contentViewRef}
            animatedContainerViewRef={animatedContainerViewRef}
            fullContentHeight={fullContentHeight}
            isExpanded={isExpanded}
            setFullContentHeight={setFullContentHeight}
            startingHeight={startingHeight}
            doAnimte={_slideDownContent}
          />
          {_renderSeparatorBottomLine()}
          {_renderExpandedContent()}
        </View>
      </Animated.View>
    </View>
  )
}

function CollapsableEventTracker({
  isExpanded,
  startingHeight,
  fullContentHeight,
  setFullContentHeight,
  doAnimte,
  contentViewRef,
  animatedContainerViewRef,
}) {
  React.useEffect(() => {
    if (!isExpanded && contentViewRef.current && contentViewRef.current.measureLayout) {
      contentViewRef.current.measureLayout(
        // @ts-ignore
        animatedContainerViewRef.current,
        (left, top, width, height) => {
          setFullContentHeight(startingHeight)
        },
        () => {
          //
        }
      )
    }

    doAnimte()
    // setSlideDownCount(slideDownCount + 1)
    // const slideDownTimeout = slideDownCount * 10
    // console.log('CollapsableCard:: slideDownTimeout => ', slideDownTimeout)
    // setTimeout(_slideDownContent, 300 + slideDownTimeout)
  }, [isExpanded, fullContentHeight])
  return null
}

const styles = StyleSheet.create({
  animatedContainer: {
    // flex: 1,
    overflow: 'hidden',
    // top: 12,
    // marginBottom: 20,
    // backgroundColor: 'grey',
  },
  contentContainer: {
    // flex: 1,
    // backgroundColor: 'red',
    // flex: 1,
    // position: 'absolute',
    position: 'absolute',
    // top: 0,
    // right: 0,
    // bottom: 0,
    // left: 0,
  },
  contentContainerSnapTop: {
    top: 0,
  },
  contentContainerSnapBottom: {
    bottom: 0,
  },
})

CollapsableCard.displayName = 'CollapsableCard'
CollapsableCard.defaultProps = {
  disabled: false,
  expanded: false,
  containerStyle: {},
  buttonStyle: {},
  contentContainerStyle: {},
}

export default CollapsableCard
