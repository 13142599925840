import React from 'react'
import { Image, View, ImageSourcePropType } from 'react-native'
import * as util from 'x/utils/util'
import _ from 'lodash'
import p from 'x/config/platform-specific'
import { COLORS, STYLES } from 'x/config/styles'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContent from 'xui/components/XContent'
import XContainer from 'xui/components/XContainer'
import BaseStoreSettingXShippingSpxView, {
  SHIPJUNG_SYSTEM_ID,
  SPX_DROPOFF_COD_ID,
  SPX_DROPOFF_ID,
  SPX_PICKUP_COD_ID,
  SPX_PICKUP_ID,
  SPX_SYSTEM_ID,
} from 'x/modules/xshipping/BaseStoreSettingXShippingSpxView'
import XSettingRow from 'xui/components/XSettingRow'
import XHelpModal from 'xui/components/XHelpModal'
import FAQ from 'x/config/FAQ'
import { IQuestionsAndAnswers } from 'x/index'
import Box from 'xui/components/Box'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'

const IMG_SPX: ImageSourcePropType = require('../../images/courier/spx.png')
const IMG_SHIPJUNG: ImageSourcePropType = require('../../images/courier/shipjung.png')

export default abstract class BaseUIStoreSettingXShippingSpxView extends BaseStoreSettingXShippingSpxView {
  static displayName = 'BaseUIStoreSettingXShippingSpxView'

  // abstract goBack: () => void
  // abstract renderWebView: () => void

  _renderCustomHeader = () => (
    <XCustomHeader
      headerLeftProps={{
        backIcon: true,
        onPressItem: () => {
          util.navGoBack(this.props)
        },
      }}
      title={p.op.t('Order.shippingTypeItems.spx_express')}
    />
  )

  _renderAgreement = (onPressOpenOverlay: () => void) => (
    <XButton onPress={() => onPressOpenOverlay()} w='48' variant='outline' alignSelf='center'>
      <XText variant='primary'>ดูข้อตกลงและวิธีการใช้งาน</XText>
    </XButton>
  )

  _getFAQAgreementForFlashxShipjung = (): IQuestionsAndAnswers[] => {
    const newFQA = []
    FAQ.XSHIPPING_AGREEMENT_FLASH_X_SHIPJUNG.map((faq: IQuestionsAndAnswers) => {
      newFQA.push(faq)
    })
    FAQ.XSHIPPING_AGREEMENT_SHIPJUNG_COMMON.map((faq: IQuestionsAndAnswers) => {
      newFQA.push(faq)
    })
    return newFQA
  }

  _renderSpxxShipjung = () => {
    const haveShipJung = this._haveShipjung()
    return (
      <Box
        _light={{ bg: 'white' }}
        _dark={{ bg: 'gray.500' }}
        borderRadius='md'
        style={{
          // backgroundColor: COLORS.WHITE,
          marginTop: 10,
          marginRight: 6,
          marginLeft: 6,
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 6,
          paddingBottom: 15,
        }}>
        <Box style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
          <Image
            source={IMG_SPX}
            resizeMode='stretch'
            style={{
              // position: 'absolute',
              width: 75,
              height: 30,
              backgroundColor: 'transparent',
              zIndex: 70,
              marginLeft: 0,
              marginRight: 25,
            }}
          />
          <XText style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_ACTIVE, paddingRight: 20 }}>X</XText>
          <Image
            source={IMG_SHIPJUNG}
            resizeMode='stretch'
            style={{
              // position: 'absolute',
              width: 90,
              height: 58,
              backgroundColor: 'transparent',
              zIndex: 70,
            }}
          />
        </Box>
        <HStack style={{ justifyContent: 'center', alignItems: 'center' }}>
          <XText variant='active'>ขอเลขพัสดุจาก SPX Express</XText>
          <XText variant='active' bold>
            {' ผ่าน Shipjung'}
          </XText>
        </HStack>

        <XHelpModal
          key='XHelpModalSpxXShipjung'
          headerTitle='ข้อตกลงและวิธีการใช้งาน'
          FAQ={FAQ.XSHIPPING_SPX_AGREEMENT}
          initiateOpenIndex={[0, 1, 2, 3, 4]}
          renderButton={(onPressOpenOverlay: () => void) => this._renderAgreement(onPressOpenOverlay)}
        />

        {haveShipJung ? null : (
          <Box justifyContent='center' alignItems='center' mt='2'>
            <XButton onPress={() => p.op.showConfirmationOkOnly('', p.op.t('xshipping.warning.how_to_use_xshipping'))}>
              <XText color='white' bold alignSelf='center'>
                เชื่อมต่อ SPX Express ผ่าน Shipjung
              </XText>
            </XButton>
          </Box>
        )}

        {haveShipJung ? (
          <VStack>
            <Box style={{ height: 10 }} />
            {/* // Dropoff */}
            <Box style={{ height: 10 }} />
            <Box style={{ paddingBottom: 2, flexDirection: 'row' }}>
              <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
              <XText variant='active' bold>{`${p.op.t('Order.shippingTypeItems.spx_express_dropoff')}`}</XText>
            </Box>
            {this._renderUseSpxOption(SPX_DROPOFF_ID, SHIPJUNG_SYSTEM_ID)}
            <Box style={{ height: 10 }} />
            <Box style={{ paddingBottom: 2, flexDirection: 'row' }}>
              <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
              <XText variant='active' bold>{`${p.op.t('Order.shippingTypeItems.spx_express_dropoff_cod')}`}</XText>
            </Box>
            {this._renderUseSpxOption(SPX_DROPOFF_COD_ID, SHIPJUNG_SYSTEM_ID)}
            {/* // Pickup */}
            <Box style={{ height: 10 }} />
            <Box style={{ paddingBottom: 2, flexDirection: 'row' }}>
              <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
              <XText variant='active' bold>{`${p.op.t('Order.shippingTypeItems.spx_express_pickup')}`}</XText>
            </Box>
            {this._renderUseSpxOption(SPX_PICKUP_ID, SHIPJUNG_SYSTEM_ID)}
            <Box style={{ height: 10 }} />
            <Box style={{ paddingBottom: 2, flexDirection: 'row' }}>
              <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
              <XText variant='active' bold>{`${p.op.t('Order.shippingTypeItems.spx_express_pickup_cod')}`}</XText>
            </Box>
            {this._renderUseSpxOption(SPX_PICKUP_COD_ID, SHIPJUNG_SYSTEM_ID)}
          </VStack>
        ) : null}
      </Box>
    )
  }

  _renderUseSpxOption = (shipping_type_id: number, system_id: number) => {
    const { config } = this.state
    if (_.isNil(config)) {
      return null
    }
    const textTitle = 'ใช้งานการขอเลขพัสดุ\n"แบบไม่เก็บเงินปลายทาง"'
    // console.log('useAutoXShippingIsJAndTReadyToShip => ', useAutoXShippingIsJAndTReadyToShip)
    const spxData = this._getConfigByShippingTypeId(shipping_type_id)
    const useSpx = !_.isNil(spxData) && spxData.system_id === system_id

    const SHIPPING_COD_TYPE = _.includes([SPX_DROPOFF_COD_ID, SPX_PICKUP_COD_ID], shipping_type_id)

    return (
      <View
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout
          util.setStatePromise(this, { loadingSpxWidth: width, loadingSpxHeight: height })
        }}>
        <XSettingRow
          title={textTitle}
          value={useSpx}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(shipping_type_id, system_id)}
          // stateKey='useSpxDeopoff'
          helper={{
            title: 'SPX ออกเลขพัสดุ',
            FAQ: FAQ.XSHIPPING_NOT_COD,
            initiateOpenIndex: 0,
          }}
          backgroundColor={COLORS.APP_SECONDARY}
          // disabled={useJAndT}
        />

        {useSpx ? (
          <XSettingRow
            title='ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง'
            value={spxData.set_auto_request_xshipping_on_create_order}
            onClick={(newValue: { isValue: boolean; stateKey: string }) =>
              this.isSwitchOnChange(shipping_type_id, system_id, 'set_auto_request_xshipping_on_create_order')
            }
            // stateKey='useSpxDeopoff'
            helper={{
              title: 'ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง',
              FAQ: FAQ.XSHIPPING_READY_TO_SHIP,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}

        {useSpx && SHIPPING_COD_TYPE ? (
          <XSettingRow
            title='บันทึกรับชำระอัตโนมัติเมื่อส่งพัสดุถึงผู้รับแล้ว'
            value={spxData.create_cod_payment_when_delivered}
            onClick={(newValue: { isValue: boolean; stateKey: string }) =>
              this.isSwitchOnChange(shipping_type_id, system_id, 'create_cod_payment_when_delivered')
            }
            stateKey='useAutoCreateDeopoffCODPaymentWhenDelivered'
            helper={{
              title: 'บันทึกรับชำระอัตโนมัติเมื่อส่งพัสดุถึงผู้รับแล้ว',
              FAQ: FAQ.XSHIPPING_AUTO_CREATE_COD_PAYMENT_WHEN_DELIVERED,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}

        {/* {true ? VIEW_LOADING(0, 0) : null} */}
      </View>
    )
  }

  _renderSpxDirect = () => {
    const { spxConnectedData } = this.state
    const btnText = !_.isNil(spxConnectedData) ? 'รายละเอียดการเชื่อมต่อ' : 'เชื่อมต่อ SPX Express โดยตรง'
    return (
      <Box
        _light={{ bg: 'white' }}
        _dark={{ bg: 'gray.500' }}
        borderRadius='md'
        style={{
          // backgroundColor: COLORS.WHITE,
          marginTop: 10,
          marginRight: 6,
          marginLeft: 6,
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 6,
          paddingBottom: 15,
        }}>
        <Box style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginTop: 4 }}>
          <Image
            source={IMG_SPX}
            resizeMode='stretch'
            style={{
              // position: 'absolute',
              width: 75,
              height: 30,
              backgroundColor: 'transparent',
              zIndex: 70,
              // marginLeft: -30,
            }}
          />
        </Box>
        <HStack style={{ justifyContent: 'center', alignItems: 'center', marginTop: 4 }}>
          <XText variant='active'>ขอเลขพัสดุจาก SPX Express</XText>
          <XText variant='active' bold>
            {' โดยตรง'}
          </XText>
        </HStack>
        <XHelpModal
          key='XHelpModalSpx'
          headerTitle='ข้อตกลงและวิธีการใช้งาน'
          FAQ={FAQ.XSHIPPING_SPX_AGREEMENT}
          initiateOpenIndex={[0, 1, 2, 4]}
          renderButton={(onPressOpenOverlay: () => void) => this._renderAgreement(onPressOpenOverlay)}
        />
        <Box h='2' />

        <Box justifyContent='center' alignItems='center'>
          <XButton onPress={() => this._navToStoreSettingSpx()}>
            <XText color='white' bold alignSelf='center'>
              {btnText}
            </XText>
          </XButton>
        </Box>

        {_.isNil(spxConnectedData) ? null : (
          <XText variant='inactive' textAlign='center' mt='2'>
            {`(เชื่อมต่อกับ User Id : ${spxConnectedData.tp_user_id})`}
          </XText>
        )}

        {!_.isNil(spxConnectedData) ? (
          <VStack>
            <Box style={{ height: 10 }} />
            {/* // Dropoff */}
            <Box style={{ height: 10 }} />
            <Box style={{ paddingBottom: 2, flexDirection: 'row' }}>
              <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
              <XText variant='active' bold>{`${p.op.t('Order.shippingTypeItems.spx_express_dropoff')}`}</XText>
            </Box>
            {this._renderUseSpxOption(SPX_DROPOFF_ID, SPX_SYSTEM_ID)}
            <Box style={{ height: 10 }} />
            <Box style={{ paddingBottom: 2, flexDirection: 'row' }}>
              <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
              <XText variant='active' bold>{`${p.op.t('Order.shippingTypeItems.spx_express_dropoff_cod')}`}</XText>
            </Box>
            {this._renderUseSpxOption(SPX_DROPOFF_COD_ID, SPX_SYSTEM_ID)}
            {/* // Pickup */}
            <Box style={{ height: 10 }} />
            <Box style={{ paddingBottom: 2, flexDirection: 'row' }}>
              <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
              <XText variant='active' bold>{`${p.op.t('Order.shippingTypeItems.spx_express_pickup')}`}</XText>
            </Box>
            {this._renderUseSpxOption(SPX_PICKUP_ID, SPX_SYSTEM_ID)}
            <Box style={{ height: 10 }} />
            <Box style={{ paddingBottom: 2, flexDirection: 'row' }}>
              <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
              <XText variant='active' bold>{`${p.op.t('Order.shippingTypeItems.spx_express_pickup_cod')}`}</XText>
            </Box>
            {this._renderUseSpxOption(SPX_PICKUP_COD_ID, SPX_SYSTEM_ID)}
          </VStack>
        ) : null}
      </Box>
    )
  }

  render() {
    return (
      <XContainer>
        {this._renderCustomHeader()}
        <XContent>
          <Box w='full'>
            {this._renderSpxDirect()}
            {/* {this._renderSpxxShipjung()} */}
          </Box>
        </XContent>
      </XContainer>
    )
  }
}
