import React from 'react'
import api from 'x/utils/api'
import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import _ from 'lodash'
import { IXShippingConfig, IXShippingSystemAccount } from 'x/config/app'
import { IApiOptions, IXScreenProps } from 'x/types'

interface IResponse {
  link_account_url: string
}

export interface BaseStoreSettingSpxViewProps extends IXScreenProps {
  selectedStore: any
  getXShippingConfigFromRedux: IXShippingConfig[]
  setXShippingConfig: (newConfig: IXShippingConfig[]) => void
  getXshippingSystemAccountFromRedux: IXShippingSystemAccount[]
  setXShippingSystemAccount: (newConfig: IXShippingSystemAccount[]) => void
}

export interface BaseStoreSettingSpxViewState {
  userId: string
  userSecret: string
  spxConnectedData: IXShippingSystemAccount
}

export default abstract class BaseStoreSettingSpxView extends React.Component<BaseStoreSettingSpxViewProps, BaseStoreSettingSpxViewState> {
  // DISPLAY_TABS: IUserListTab[]
  // _tabRef: React.RefObject<any>
  // webview: React.RefObject<any>

  protected constructor(props) {
    super(props)

    this.state = {
      userId: '',
      userSecret: '',
      spxConnectedData: null,
    }
    // this.webview = React.createRef()
    // this._tabRef = React.createRef()
  }

  componentDidMount() {
    const { getXshippingSystemAccountFromRedux } = this.props
    // console.log('getXshippingSystemAccountFromRedux => ', getXshippingSystemAccountFromRedux)
    let spxConnectedData = null
    if (!_.isNil(getXshippingSystemAccountFromRedux)) {
      getXshippingSystemAccountFromRedux.forEach((config: IXShippingSystemAccount) => {
        if (config.system_id === 11) {
          spxConnectedData = config
        }
      })
    }
    // console.log('spxConnectedData => ', spxConnectedData)
    // @ts-ignore
    this.setState({ spxConnectedData })
  }

  _getParams = () => {
    // @ts-ignore
    const params = util.getNavParams(this.props)
    return params
  }

  goBack = () => {
    util.navGoBack(this.props)
  }

  _createAccount = async () => {
    const { selectedStore, getXShippingConfigFromRedux, setXShippingConfig, setXShippingSystemAccount } = this.props
    const { userId, userSecret } = this.state
    const { spxConfig, succeedCallback } = this._getParams()
    const apiOptions: IApiOptions = {
      showSpinner: true,
    }

    if (!_.isNumber(+userId)) {
      p.op.showToast('กรุณากรอก User ID ให้ถูกต้อง', 'warning')
      return
    }

    const reqBody = {
      store_id: selectedStore.get('id'),
      set_auto_request_xshipping_on_create_order: true,
      create_cod_payment_when_delivered: true,
      tp_user_id: +userId,
      user_secret: userSecret,
    }
    try {
      const response = await api.postV2(api.POST_SPX_CREATE_ACCOUNT, reqBody, apiOptions)
      // console.log('response => ', response)
      if (response.system_accounts) {
        setXShippingSystemAccount(response.system_accounts)
      }
      if (response.xshipping_config) {
        const newConfig = _.cloneDeep(getXShippingConfigFromRedux)
        // newConfig.push(response.xshipping_config[0])
        const xshippingConfigResponse = response.xshipping_config
        xshippingConfigResponse.forEach((config: IXShippingConfig) => {
          newConfig.push(config)
        })
        setXShippingConfig(newConfig)
        p.op.showToast('เชื่อมต่อ SPX สำเร็จ', 'success')
        if (_.isFunction(succeedCallback)) {
          succeedCallback(newConfig, response.system_accounts)
        }
        this.goBack()
      }
    } catch (error) {
      p.op.showToast('เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง', 'warning')
      this.goBack()
    }
  }

  _deleteAccount = async () => {
    const { selectedStore } = this.props
    // const { spxConfig } = this._getParams()
    // console.log('spxConfig => ', spxConfig)
    const isUserConfirmed = await new Promise((passConfirm) => {
      p.op.showConfirmation(
        '',
        'ยืนยันลบการเชื่อมต่อกับ SPX',
        () => passConfirm(true),
        () => passConfirm(false)
      )
    })

    if (!isUserConfirmed) {
      return
    }

    const apiOptions: IApiOptions = {
      showSpinner: true,
    }
    const reqBody = {
      store_id: selectedStore.get('id'),
      system_id: 11,
      shipping_type_id: 58,
    }
    try {
      const response = await api.postV2(api.XSHIPPING_DELETE_SYSTEM_ACCOUNT, reqBody, apiOptions)
      // console.log('response => ', response)
      if (response.status === 'ok') {
        p.op.showToast('ยกเลิกการเชื่อมต่อ SPX สำเร็จ', 'success')
        this.props.navigation.pop(3)
        // this.goBack()
      }
    } catch (error) {
      p.op.showToast('เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง', 'warning')
      this.goBack()
    }
  }
}
