import { Map, List, fromJS, isImmutable } from 'immutable'

import _ from 'lodash'
import { log, deleteImmutableListAtIndex } from '../../utils/util'
import actions from '../../config/actions'
import { ActionApiParams, IManageOrderCartParams } from '../../index'

// import p from 'x/config/platform-specific'
// import { E_MSG_TYPE } from 'x/utils/op-interface'

// Enum
enum E_CART_ACTIONS {
  MERGE,
  ADD,
}

const initialChildOrder: Map<string, any> = Map({
  id: null,
  name: null,
  payment: null,
  shipping: null,
  sender: Map({}),
  receiver: Map({}),
  state: null,
  expiration_date: null,
  discount: 0,
  shipping_cost: 0,
  etc_cost: 0,
  store_id: null,
  total_price: null,
  total_cost: null,
  // type: null,
  products: List([]),
  shipping_type_id: null,
  selectedAddress: Map({
    sender: Map({ id: null }),
    receiver: Map({ id: null }),
  }),
  hidden: true,
  auto_complete: true,

  // created_at: null,
  // updated_at: null,

  // for create/edit order
  store_products: Map({}),
  tmp_PPID_to_StoreId: Map({}),

  myVdList: List([]),
  sellerVdList: List([]),
  amountMyVd: 0,
})

const initialOrder: Map<string, any> = Map({
  // ...initialChildOrder,

  suborders: List([]),
  amountProducts: null,
  amountProfit: null,
  amountQty: null,
  amountProductsBuy: null,
  amountTotalBuy: null,
  amountTotal: null,

  printLabelImages: {},
  noteCoverSheet: null,
}).mergeDeep(initialChildOrder)

// Initial state
const initialState = Map({
  // VIEW Order
  selectedOrder: initialOrder,
  editingOrder: initialOrder,
  mode: null,
  // CREATE Order
  // selectedCustomer: initialCustomerProfile,
  // selectedProducts: Map({
  //   cart: List([]),
  // }),
  // summary: initialSummary,
  // Mode (for render & compute differenct api structure)
  shouldFetch: false,
  orderTabIndex: 0,
  orderTabInit: 0,
  isDirty: false,
  isInitialized: false,
})

export const ACTION_ONCHANGE_ADDRESS = 'OrderState/ACTION_ONCHANGE_ADDRESS'
export const ACTION_ADD_NOTES_COVER = 'OrderState/ACTION_ADD_NOTES_COVER'
export const ACTION_ORDER_CREATE = 'OrderState/ACTION_ORDER_CREATE'
export const ACTION_ORDER_LOAD = 'OrderState/ACTION_ORDER_LOAD'
export const ACTION_ORDER_RESET = 'OrderState/ACTION_ORDER_RESET'
export const ACTION_ORDER_PRODUCT_REMOVE = 'OrderState/ACTION_ORDER_PRODUCT_REMOVE'
export const ACTION_ORDER_REVERT_EDITING = 'OrderState/ACTION_ORDER_REVERT_EDITING'
export const CLEAR_INCART_PRODUCTS = 'OrderState/CLEAR_INCART_PRODUCTS'

// OrderNotes
export const ACTION_FETCH_ORDER_NOTES = 'OrderState/ACTION_FETCH_ORDER_NOTES'
export const ACTION_ADD_ORDER_NOTE = 'OrderState/ACTION_ADD_ORDER_NOTE'
export const ACTION_EDIT_ORDER_NOTE = 'OrderState/ACTION_EDIT_ORDER_NOTE'
export const DELETE_ORDER_NOTE = 'OrderState/DELETE_ORDER_NOTE'
export const ACTION_SELECT_ORDER_NOTES = 'OrderState/ACTION_SELECT_ORDER_NOTES'
export const GET_SHIPPING_STATUS = 'OrderState/GET_SHIPPING_STATUS'

// Refactor onchange
export const ACTION_EDITING_ORDER_ONCHANGE = 'OrderState/ACTION_EDITING_ORDER_ONCHANGE'
export const ACTION_EDITING_OPTION_ONCHANGE = 'OrderState/ACTION_EDITING_OPTION_ONCHANGE'

export const ACTION_ORDER_XSHIPPING_ONCHANGE = 'OrderState/ACTION_ORDER_XSHIPPING_ONCHANGE'

export const ACTION_CHANGE_STORE_PRODUCT = 'OrderState/ACTION_CHANGE_STORE_PRODUCT'

// Generic (new reproduce)
export const ACTION_ORDER_PRODUCT_VARIANT_CHANGE = 'OrderState/ACTION_ORDER_PRODUCT_VARIANT_CHANGE'
export const ACTION_SUBORDER_PRODUCT_VARIANT_CHANGE = 'OrderState/ACTION_SUBORDER_PRODUCT_VARIANT_CHANGE'

export const ACTION_PULL_PRODUCT_TO_ORDER = 'OrderState/ACTION_PULL_PRODUCT_TO_ORDER'
export const ACTION_QUICK_PULL_PRODUCT_TO_ORDER = 'OrderState/ACTION_QUICK_PULL_PRODUCT_TO_ORDER'
export const ACTION_REMOVE_PRODUCT_FROM_ORDER = 'OrderState/ACTION_REMOVE_PRODUCT_FROM_ORDER'

export const ACTION_SET_IS_INITIALIZED = 'OrderState/ACTION_SET_IS_INITIALIZED'
export const ACTION_SET_MODE = 'OrderState/ACTION_SET_MODE'
export const ACTION_DO_NOTHING = 'OrderState/ACTION_DO_NOTHING'
export const SHOULD_FETCH_ORDER_DETAIL = 'OrderState/SHOULD_FETCH_ORDER_DETAIL'

export const PATCH_ORDER_ADDRESS = 'OrderState/PATCH_ORDER_ADDRESS'
export const CANCEL_ORDER = 'OrderState/CANCEL_ORDER'

export const CHANGE_ORDER_TAB_INDEX = 'OrderState/CHANGE_ORDER_TAB_INDEX'

export const ERROR_ORDER_CREATE = 'OrderState/ERROR_ORDER_CREATE'
export const ERROR_ORDER_CANCEL = 'OrderState/ERROR_ORDER_CANCEL'
export const ERROR_ORDER_CONFIRM = 'OrderState/ERROR_ORDER_CONFIRM'
export const ERROR_PATCH_ORDER_ADDRESS = 'OrderState/ERROR_PATCH_ORDER_ADDRESS'

// Local constants
const MY_VD_LIST_PATH_KEY = ['editingOrder', 'myVdList']
const SELLER_VD_LIST_PATH_KEY = ['editingOrder', 'sellerVdList']
const AMOUNT_MY_VD_PATH_KEY = ['editingOrder', 'amountMyVd']

export function autoCompleteShipping({ store_id, order_id, return_parent_order, storeName }) {
  return {
    type: actions.SHIPPING_AUTO_COMPLETE,
    payload: { store_id, order_id, return_parent_order, storeName },
  }
}

export function cancelShipping({ store_id, order_id, shipping_id }) {
  return {
    type: actions.SHIPPING_CANCEL,
    payload: { store_id, order_id, shipping_id },
  }
}

// Saga actions
export function fetchOrderDetail(params: ActionApiParams<{ store_id: number; order_id: number }>) {
  // startFlow(actions.ORDER_FETCH)
  // log('OrderState.fetchOrderDetail: store_id: ' + store_id + ' order_id: ' + order_id)
  // const { store_id, order_id, successCallback, failedCallback } = params
  return {
    ...params,
    type: actions.ORDER_FETCH,
    // payload: { store_id, order_id },
    // successCallback,
    // failedCallback,
  }
}

export function changeOrderTabIndexTo(index) {
  return {
    type: CHANGE_ORDER_TAB_INDEX,
    payload: { index },
  }
}

export function orderConfirm(params: ActionApiParams) {
  return {
    ...params,
    type: actions.ORDER_OPERATE_CONFIRM,
  }
}

export function cancelOrder(params: { store_id: number; order_id: number; updated_at: string }) {
  return {
    type: CANCEL_ORDER,
    payload: params,
  }
}

export function setMode({ mode }) {
  return {
    type: ACTION_SET_MODE,
    payload: { mode },
  }
}

export function setIsInitialized(isInited = true) {
  return {
    type: ACTION_SET_IS_INITIALIZED,
    payload: isInited,
  }
}

export function addProductToOrder(params: IManageOrderCartParams) {
  return {
    type: ACTION_PULL_PRODUCT_TO_ORDER,
    payload: params,
  }
}

export function quickAddProductToOrder(params: IManageOrderCartParams) {
  return {
    type: ACTION_QUICK_PULL_PRODUCT_TO_ORDER,
    payload: params,
  }
}

export function clearInCartProducts() {
  return {
    type: CLEAR_INCART_PRODUCTS,
  }
}

export function removeProduct(params: { store_id: number; pp_id: number }) {
  return {
    type: ACTION_REMOVE_PRODUCT_FROM_ORDER,
    payload: params,
  }
}

export function resetOrder() {
  return { type: ACTION_ORDER_RESET }
}

// Refactor to saga version
// export function createOrder(body: any, callback?: (res: any) => void) {
export function createOrder(params: ActionApiParams) {
  return {
    // type: ACTION_ORDER_CREATE,
    // payload: body,
    // callback,
    ...params,
    type: ACTION_ORDER_CREATE,
  }
}

export function editReceiverAddress(params: { body: any; successCallback?: any; failedCallback?: any }) {
  // console.log('editReceiverAddress body => ', body)
  const { body, successCallback, failedCallback } = params
  return {
    type: PATCH_ORDER_ADDRESS,
    payload: body,
    successCallback,
    failedCallback,
  }
}

export function onChangeAddress(addrObj: Map<string, any> | { [key: string]: any }) {
  // eg. { sender_id: 1 }
  return {
    type: ACTION_ONCHANGE_ADDRESS,
    payload: isImmutable(addrObj) ? addrObj : fromJS(addrObj),
  }
}

export function addNotesOrder(note) {
  // เพิ่มรูปแทนใบปะหน้า
  return {
    type: ACTION_ADD_NOTES_COVER,
    payload: fromJS(note),
  }
}

export function orderLoad(selectedOrder) {
  return {
    type: ACTION_ORDER_LOAD,
    payload: fromJS(selectedOrder),
  }
}

export function orderRevert() {
  return {
    type: ACTION_ORDER_REVERT_EDITING,
  }
}

// export function initOrdersToBePaid(orderId: number, amount: number) {
export function initOrdersToBePaid(orderData: { orderId: number; remaining_forecast_amount: number; cod_amount?: number }) {
  return {
    type: actions.ORDERS_TO_BE_PAID_INIT,
    payload: orderData,
  }
}

export function onChangeStoreProductByKey(params: { pp_id: number; key: string; value: any }) {
  return {
    type: ACTION_CHANGE_STORE_PRODUCT,
    payload: params,
  }
}

// Generic change
export function onChangeOrderProductVariantByKey(params: { index: number; value; key }) {
  return {
    type: ACTION_ORDER_PRODUCT_VARIANT_CHANGE,
    payload: params,
  }
}

export function onShippingCostCalc(payload) {
  return {
    type: actions.ORDERS_SHIPPING_COST_CALC,
    payload,
  }
}

export function onShippingTypeChange({
  store_id,
  order_id,
  shipping_type_id,
  update_sales_order,
  update_purchase_orders,
  successCallback,
}) {
  return {
    type: actions.ORDERS_SHIPPING_TYPE_CHANGE,
    payload: { store_id, order_id, shipping_type_id, update_sales_order, update_purchase_orders, successCallback },
  }
}

export function onOrderDateDeliveryChange({ store_id, order_id, date_delivery, callBack }) {
  return {
    type: actions.ORDER_DATE_DELIVERY_CHANGE,
    payload: { store_id, order_id, date_delivery, callBack },
  }
}

export function onOrderDateTimeAutoCancelOrderChange({ store_id, order_id, expiration_date, callBack }) {
  return {
    type: actions.ORDER_DATE_TIME_EXPIRATION_DATE_CHANGE,
    payload: { store_id, order_id, expiration_date, callBack },
  }
}

// idx = group_index / index = product_index in the group
export function onChangeSuborderProductVariantByKey(params: { idx: number; index: number; value: any; key: string }) {
  // idx meaning suborder index
  return {
    type: ACTION_SUBORDER_PRODUCT_VARIANT_CHANGE,
    payload: params,
  }
}

export function onEditingOrderChange(params: { key: string; value: any; orderTabIndex: number }) {
  const { key, value, orderTabIndex = 0 } = params
  return {
    type: ACTION_EDITING_ORDER_ONCHANGE,
    payload: orderTabIndex > 0 ? { key, value, subOrderIndex: orderTabIndex - 1 } : { key, value },
  }
}

export function onEditingOptionChange(params: { store_id: number; order_id: number; key: string; value: any; txtToast?: string }) {
  const { store_id, order_id, key, value, txtToast } = params
  return {
    type: ACTION_EDITING_OPTION_ONCHANGE,
    payload: { store_id, order_id, key, value, txtToast },
  }
}

export function onEditingOrderXshippingChange(params: { key: string; value: any; orderTabIndex: number }) {
  const { key, value, orderTabIndex = 0 } = params
  return {
    type: ACTION_ORDER_XSHIPPING_ONCHANGE,
    payload: orderTabIndex > 0 ? { key, value, subOrderIndex: orderTabIndex - 1 } : { key, value },
  }
}

export function shouldFetchOrderDetail() {
  return {
    type: SHOULD_FETCH_ORDER_DETAIL,
  }
}

export function getCopyLinkOrder(body: { store_id: number; order_id; key }) {
  return {
    type: actions.GET_COPY_LINK_ORDER,
    body,
  }
}

export function cancelTackingCode(body: { store_id: number; order_id; key }) {
  return {
    type: actions.CANCEL_TACKING_CODE,
    body,
  }
}

export function createTackingCode(body: { store_id: number; order_id; key }) {
  return {
    type: actions.CREATE_TACKING_CODE,
    body,
  }
}

export function updateTackingCode(body: { store_id: number; order_id; key }) {
  return {
    type: actions.UPDATE_TACKING_CODE,
    body,
  }
}

export function requestTracking(body: { store_id: number; order_id; key }) {
  return {
    type: actions.REQUEST_TRACKING,
    body,
  }
}

export function autoRequestTracking(body: { store_id: number; order_id; key }) {
  return {
    type: actions.AUTO_REQUEST_TRACKING,
    body,
  }
}

export function fetchOrderNotes(params: ActionApiParams) {
  return {
    ...params,
    type: ACTION_FETCH_ORDER_NOTES,
  }
}

export function addOrderNote(params: ActionApiParams) {
  return {
    ...params,
    type: ACTION_ADD_ORDER_NOTE,
  }
}

export function editOrderNote(params: ActionApiParams) {
  return {
    ...params,
    type: ACTION_EDIT_ORDER_NOTE,
  }
}

export function deleteOrderNote(params: ActionApiParams) {
  return {
    ...params,
    type: DELETE_ORDER_NOTE,
  }
}

export function selectOrderNote(params: ActionApiParams) {
  return {
    ...params,
    type: ACTION_SELECT_ORDER_NOTES,
  }
}

export function getShippingStatus(params: ActionApiParams) {
  return {
    ...params,
    type: GET_SHIPPING_STATUS,
  }
}

export function comfirmOrderMKP(params: ActionApiParams) {
  return {
    ...params,
    type: actions.ACTION_COMFIRM_ORDERS_MKP,
  }
}

export function fetchMarketplaceOrder(params: ActionApiParams) {
  return {
    ...params,
    type: actions.ACTION_FETCH_MARKETPLACE_ORDER,
  }
}

// Reducer
export default function OrderStateReducer(state: Map<string, any> = initialState, action: { type: string; payload?: any }) {
  const { payload, type } = action
  let newState = state
  switch (type) {
    case ACTION_ORDER_RESET:
      // const oldOrderTabIndex = newState.get('orderTabIndex')
      // const oldOrderTabInit = newState.get('orderTabInit')
      // newState = initialState.set('orderTabIndex', oldOrderTabIndex)
      // return newState.set('orderTabInit', oldOrderTabInit)
      return initialState

    case ACTION_SET_IS_INITIALIZED:
      return newState.set('isInitialized', payload)

    case ACTION_PULL_PRODUCT_TO_ORDER:
      newState = doManageCart(newState, E_CART_ACTIONS.MERGE, payload)
      return calculateAmount(newState)

    case ACTION_QUICK_PULL_PRODUCT_TO_ORDER:
      newState = doManageCart(newState, E_CART_ACTIONS.ADD, payload)
      return calculateAmount(newState)

    case ACTION_REMOVE_PRODUCT_FROM_ORDER:
      newState = onRemoveStoreProduct(newState, payload)
      return calculateAmount(newState)

    case ACTION_ONCHANGE_ADDRESS: {
      // const isReceiverAddr = Map.isMap(payload) && payload.has('receiver')
      // if (isReceiverAddr) {
      //   // newState = newState.setIn(['editingOrder', 'isDirty'], true)
      //   // newState = newState.setIn(['editingOrder', 'isDirty'], false)
      // }
      // return newState.mergeDeepIn(['editingOrder', 'selectedAddress'], payload)
      const addrKeys = payload.keySeq().toArray() || []

      for (let i = 0; i < addrKeys.length; i++) {
        const addrKey = addrKeys[i]
        newState = newState.setIn(['editingOrder', 'selectedAddress', addrKey], payload.get(addrKey))
      }

      return newState
    }

    case ACTION_ADD_NOTES_COVER:
      return newState.setIn(['editingOrder', 'notes'], payload)

    case ACTION_ORDER_LOAD:
      newState = newState.set('selectedOrder', initialOrder)
      newState = newState.mergeDeepIn(['selectedOrder'], payload)
      newState = newState.set('editingOrder', newState.get('selectedOrder'))
      newState = newState.set('shouldFetch', false)
      newState = autoSetOrderTabIndex(newState)
      return calculateAmount(newState)

    case ACTION_ORDER_REVERT_EDITING:
      newState = newState.set('editingOrder', newState.get('selectedOrder'))
      return calculateAmount(newState)
    case ACTION_ORDER_PRODUCT_REMOVE:
      return newState.deleteIn(['editingOrder', 'products', payload.index])

    case ACTION_SET_MODE:
      newState = newState.set('mode', payload.mode)
      return newState

    case CLEAR_INCART_PRODUCTS:
      newState = newState.setIn(['editingOrder', 'store_products'], Map({}))
      return calculateAmount(newState)

    case ACTION_ORDER_PRODUCT_VARIANT_CHANGE:
      newState = newState.setIn(['editingOrder', 'products', payload.index, payload.key], payload.value)
      return calculateAmount(newState)
    case ACTION_SUBORDER_PRODUCT_VARIANT_CHANGE:
      newState = newState.setIn(['editingOrder', 'suborders', payload.idx, 'products', payload.index, payload.key], payload.value)
      return calculateAmount(newState)

    case ACTION_CHANGE_STORE_PRODUCT:
      // newState = newState.setIn(['editingOrder', 'isDirty'], true)
      newState = onChangeStoreProduct(newState, payload)
      return calculateAmount(newState)

    case ACTION_EDITING_ORDER_ONCHANGE:
      if (_.isNumber(payload.subOrderIndex)) {
        newState = newState.setIn(['editingOrder', 'suborders', payload.subOrderIndex, payload.key], payload.value)
      } else {
        newState = newState.setIn(['editingOrder', payload.key], payload.value)
      }
      return calculateAmount(newState)

    case SHOULD_FETCH_ORDER_DETAIL:
      return newState.set('shouldFetch', true)

    case CHANGE_ORDER_TAB_INDEX:
      return newState.set('orderTabIndex', _.isNumber(payload.index) && payload.index >= 0 ? payload.index : 0)

    case ACTION_ORDER_XSHIPPING_ONCHANGE:
      if (_.isNumber(payload.subOrderIndex)) {
        newState = newState.setIn(['editingOrder', 'suborders', payload.subOrderIndex, payload.key], payload.value)
        newState = newState.setIn(['selectedOrder', 'suborders', payload.subOrderIndex, payload.key], payload.value)
      } else {
        newState = newState.setIn(['editingOrder', payload.key], payload.value)
        newState = newState.setIn(['selectedOrder', payload.key], payload.value)
      }
      return newState

    default:
      return state
  }
}

/// / Refactor internal function /////
// Refactor calculate amount of order
function calculateAmount(state) {
  let newState = state

  // Deprecated in Pricing 2.0
  // const discount = state.getIn(['editingOrder', 'discount']) || 0
  // const shipping_cost = state.getIn(['editingOrder', 'shipping_cost']) || 0
  // const etc_cost = state.getIn(['editingOrder', 'etc_cost']) || 0

  // const myProducts = state.getIn(['editingOrder', 'products']) || List([])
  // const subOrders = state.getIn(['editingOrder', 'suborders']) || List([])
  //
  // const sumOfMyProducts = myProducts.reduce((prevAmount, product) => {
  //   return prevAmount + ((parseInt(product.get('qty')) * parseFloat(product.get('price'))) || 0)
  // }, 0)
  //
  // const sumOfSubProducts = subOrders.reduce((prevSubAmount, suborder) => {
  //   const p = suborder.get('products') || List([])
  //   const sop = p.reduce((prevAmount, product) => {
  //     if (!product) {
  //       return prevAmount
  //     }
  //     return prevAmount + ((parseInt(product.get('qty')) * parseFloat(product.get('price'))) || 0)
  //   }, 0) || 0
  //   return prevSubAmount + sop
  // }, 0)
  //
  // let amountProducts = sumOfMyProducts + sumOfSubProducts

  // // Root store products compute
  // const parentStoreProducts = state.getIn(['editingOrder', 'store_products']) || Map({})
  // const parentAmountStoreProducts = sumAmountStoreProducts(parentStoreProducts)
  //
  // // แทรก คิด VolumeDiscount (ถ้ามีมา)
  // newState = newState.setIn(AMOUNT_MY_VD_PATH_KEY, 0)
  // newState = volumeDiscountCompute(newState, parentStoreProducts)
  // const amountMyVd = newState.getIn(AMOUNT_MY_VD_PATH_KEY)
  //
  // // Sub-Order store products compute
  // const suborders = state.getIn(['editingOrder', 'suborders']) || List([])
  // suborders.forEach((subOrder, subIndex) => {
  //   const childStoreProducts = subOrder.get('store_products') || Map({})
  //   const childAmountStoreProducts = sumAmountStoreProducts(childStoreProducts)
  //
  //   const childDiscount = subOrder.get('discount') || 0
  //   const childShippingCost = subOrder.get('shipping_cost') || 0
  //   const childEtcCost = subOrder.get('etc_cost') || 0
  //
  //   newState = newState.setIn(['editingOrder', 'suborders', subIndex, 'amountProducts'], childAmountStoreProducts.amountProducts)
  //   newState = newState.setIn(['editingOrder', 'suborders', subIndex, 'amountProfit'], childAmountStoreProducts.amountProfit)
  //   newState = newState.setIn(['editingOrder', 'suborders', subIndex, 'amountQty'], childAmountStoreProducts.amountQty)
  //   newState = newState.setIn(['editingOrder', 'suborders', subIndex, 'amountProductsBuy'], childAmountStoreProducts.amountProductsBuy)
  //
  //   const childAmountTotal = childAmountStoreProducts.amountProducts - parseFloat(childDiscount) + parseFloat(childShippingCost) + parseFloat(childEtcCost)
  //   const childAmountTotalBuy = childAmountStoreProducts.amountProductsBuy - parseFloat(childDiscount) + parseFloat(childShippingCost) + parseFloat(childEtcCost)
  //   newState = newState.setIn(['editingOrder', 'suborders', subIndex, 'amountTotal'], childAmountTotal)
  //   newState = newState.setIn(['editingOrder', 'suborders', subIndex, 'amountTotalBuy'], childAmountTotalBuy)
  // })
  //
  // newState = newState.setIn(['editingOrder', 'amountProducts'], parentAmountStoreProducts.amountProducts)
  //
  // // แทรก ถ้ามีส่วนลดลูกค้า ให้เอาไปหักจาก amountProducts
  // if (amountMyVd) {
  //   newState = newState.setIn(['editingOrder', 'amountProducts'], parentAmountStoreProducts.amountProducts - amountMyVd)
  // }
  //
  // newState = newState.setIn(['editingOrder', 'amountProfit'], parentAmountStoreProducts.amountProfit)
  // newState = newState.setIn(['editingOrder', 'amountQty'], parentAmountStoreProducts.amountQty)
  // newState = newState.setIn(['editingOrder', 'amountProductsBuy'], parentAmountStoreProducts.amountProductsBuy)
  //
  // // const { CREATE, EDIT } = CONS.ORDER_VIEW_MODE
  // // if (_.includes([CREATE, EDIT], state.get('mode'))) {
  // const parentAmountTotal = parentAmountStoreProducts.amountProducts - parseFloat(discount) + parseFloat(shipping_cost) + parseFloat(etc_cost)
  // const parentAmountTotalBuy = parentAmountStoreProducts.amountProductsBuy - parseFloat(discount) + parseFloat(shipping_cost) + parseFloat(etc_cost)
  // newState = newState.setIn(['editingOrder', 'amountTotal'], parentAmountTotal)
  //
  // // แทรก ถ้ามีส่วนลดลูกค้า ให้เอาไปหักจาก parentAmountTotal
  // if (amountMyVd) {
  //   newState = newState.setIn(['editingOrder', 'amountTotal'], parentAmountTotal - amountMyVd)
  // }
  //
  // newState = newState.setIn(['editingOrder', 'amountTotalBuy'], parentAmountTotalBuy)
  // // } else {
  // // no need to calculate. simply use the transaction_cost especially for the cancelled order as the numbers won't match
  // // newState = newState.setIn(['editingOrder', 'amountTotal'], newState.getIn(['editingOrder', 'transaction_cost']))
  // // }

  const parentOrderPath = ['editingOrder']
  newState = getComputedStateByStoreProducts(newState, parentOrderPath)

  const subOrders = state.getIn(['editingOrder', 'suborders']) || List([])
  subOrders.forEach((subOrder, subOrderIndex) => {
    const subOrderPath = ['editingOrder', 'suborders', subOrderIndex]
    newState = getComputedStateByStoreProducts(newState, subOrderPath)
  })

  return newState
}

function volumeDiscountCompute(state: Map<string, any>, storeProducts: Map<string, any>): Map<string, any> {
  let newState = state
  newState = newState.setIn(MY_VD_LIST_PATH_KEY, List([]))
  newState = newState.setIn(SELLER_VD_LIST_PATH_KEY, List([]))

  const store_ids = storeProducts.keySeq().toArray()
  for (let i = 0; i < store_ids.length; i++) {
    const storeId = store_ids[i]
    const selectedProducts = isImmutable(storeProducts.get(storeId)) ? storeProducts.get(storeId) : List([])

    selectedProducts.forEach((sp: Map<string, any>) => {
      if (Map.isMap(sp)) {
        newState = computeVolumeDiscountByKey('my', newState, sp)
        newState = computeVolumeDiscountByKey('seller', newState, sp)

        const myVdList = newState.getIn(MY_VD_LIST_PATH_KEY) || List([])
        const amountMyVd = myVdList.reduce((result, myVdItem) => {
          if (myVdItem.get('discountAmount')) {
            return parseFloat(myVdItem.get('discountAmount')) + result
          }
          return result
        }, 0)
        newState = newState.setIn(['editingOrder', 'amountMyVd'], amountMyVd)
      }
    })
  }

  return newState
}

function computeVolumeDiscountByKey(
  discountOf: 'my' | 'seller',
  state: Map<string, any>,
  selectedProduct: Map<string, any>
): Map<string, any> {
  let newState = state
  if (!_.includes(['my', 'seller'], discountOf)) {
    return state
  }

  const vdKey = `${discountOf}_vds`
  const vdTypeKey = `${discountOf}_vds_type`

  const vds = List.isList(selectedProduct.get(vdKey)) ? selectedProduct.get(vdKey) : List([])
  const vdsType = selectedProduct.get(vdTypeKey) ? selectedProduct.get(vdTypeKey) : 0

  if (vds.size >= 2 && vdsType > 0) {
    // ถ้ามี vds มาและเปิดใช้งาน
    const qty = parseInt(selectedProduct.get('qty') || 0)

    // เอา qty มาเช็คกับ VD Range
    for (let i = 0; i < vds.size; i++) {
      const vdBegin = vds.getIn([i, 'begin'])
      const vdEnd = vds.getIn([i, 'end'])
      const vdDiscount = vds.getIn([i, 'discount'])

      const pp_id = selectedProduct.get('pp_id') || ''
      const pName = selectedProduct.get('name') || ''
      const vName = selectedProduct.get('variant') || ''
      const productName = vName ? `${pName} ${vName}` : pName

      const price = parseFloat(selectedProduct.get('price') || 0)
      const cost = parseFloat(selectedProduct.get('cost') || 0)
      const price_buy = parseFloat(selectedProduct.get('price_buy') || 0)

      const pathKey = discountOf === 'my' ? MY_VD_LIST_PATH_KEY : SELLER_VD_LIST_PATH_KEY

      // const profit = (qty * (price - cost))

      if (qty >= vdBegin && qty <= vdEnd) {
        // ถ้าเจอ range ที่เข้ากัน ให้เช็คว่าได้ส่วนลดเท่าไหร่แล้วเอาไป dispay
        if (_.isNull(vdDiscount) || vdDiscount === 0) {
          // ถ้า vdDiscount = 0 ให้ suggest discount ลำดับถัดไป
          for (let j = i; j < vds.size; j++) {
            const nextVdDiscount = vds.getIn([j, 'discount'])
            const minQty = vds.getIn([j, 'begin'])
            if (!_.isNull(nextVdDiscount) && nextVdDiscount > 0 && minQty > 0) {
              const newVdList = newState.getIn(pathKey).push(
                Map({
                  pp_id,
                  qty,
                  cost,
                  price,
                  price_buy,
                  discountOf,
                  name: productName,
                  discount: nextVdDiscount,
                  vds_type: vdsType,
                  nextVdDiscount,
                  minQty,
                })
              )

              newState = newState.setIn(pathKey, newVdList)
              break
            }
          }

          // // ตอนนี้ถ้าไม่มีส่วนลดให้ ignored ไปก่อน ค่อนทำ suggest จำนวนภายหลัง
          // break
        } else {
          let discountAmount = 0
          let priceIncDiscount = 0
          let costIncDiscount = 0
          if (discountOf === 'my') {
            if (vdsType === 1) {
              discountAmount = price * qty * (vdDiscount / 100)
              priceIncDiscount = Math.round(price * (vdDiscount / 100) * 100) / 100
            } else {
              discountAmount = vdDiscount * qty
              priceIncDiscount = vdDiscount
            }
          } else if (vdsType === 1) {
            discountAmount = cost * qty * (vdDiscount / 100)
            costIncDiscount = Math.round(cost * (vdDiscount / 100) * 100) / 100
          } else {
            discountAmount = vdDiscount * qty
            costIncDiscount = vdDiscount
          }

          discountAmount = Math.round(discountAmount * 100) / 100 // ปัดทศนิยม 2 ตำแหน่งพอ

          const newVdList = newState.getIn(pathKey).push(
            Map({
              pp_id,
              qty,
              cost,
              price,
              price_buy,
              discountOf,
              name: productName,
              discount: vdDiscount,
              vds_type: vdsType,
              discountAmount,
              priceIncDiscount,
              costIncDiscount,
            })
          )

          newState = newState.setIn(pathKey, newVdList)
          break
        }
      }
    }
  }

  return newState
}

function getComputedStateByStoreProducts(state: Map<string, any>, orderPath: string[]): Map<string, any> {
  if (!_.isArray(orderPath) || orderPath.length < 1) {
    return state
  }
  let newState = state
  let store_ids
  let storeProducts
  let storeProductsPath
  let vdDiscounts
  try {
    storeProductsPath = orderPath.concat(['store_products'])
    storeProducts = newState.getIn(storeProductsPath)
    store_ids = storeProducts.keySeq().toArray()
    vdDiscounts = newState.getIn(orderPath.concat('vd_discounts')) || List([])
  } catch (e) {
    log('getComputedStateByStoreProducts e => ', e)
    return state
  }

  let amountProducts = 0
  let amountProfit = 0
  let amountQty = 0
  let amountProductsBuy = 0
  let amountPurchaseDiscount = 0
  let amountSaleDiscount = 0
  let amountVdDiscountSum = 0

  for (const storeId of store_ids) {
    const selectedProducts = List.isList(storeProducts.get(storeId)) ? storeProducts.get(storeId) : List([])

    for (let productIdx = 0; productIdx < selectedProducts.size; productIdx++) {
      const selectedProduct = selectedProducts.get(productIdx)
      if (Map.isMap(selectedProduct)) {
        const productPath = storeProductsPath.concat([storeId, productIdx])
        const ppId = parseInt(selectedProduct.get('pp_id') || 0)
        const qty = parseInt(selectedProduct.get('qty') || 0)
        const price = parseFloat(selectedProduct.get('price') || 0)
        const cost = parseFloat(selectedProduct.get('cost') || 0)
        const price_buy = parseFloat(selectedProduct.get('price_buy') || 0)

        // const price_after_discount = parseFloat(selectedProduct.get('price_after_discount') || 0.0)
        const seller_unit_discount = parseFloat(selectedProduct.get('seller_unit_discount') || 0.0)
        // const total_unit_discount = parseFloat(selectedProduct.get('total_unit_discount') || 0.0)

        const ugpgSaleDiscountPercent = selectedProduct.get('ugpgSaleDiscountPercent')
        const ugpgSaleDiscountAmount = selectedProduct.get('ugpgSaleDiscountAmount')
        const ugpgPurchaseDiscountPercent = selectedProduct.get('ugpgPurchaseDiscountPercent')
        const ugpgPurchaseDiscountAmount = selectedProduct.get('ugpgPurchaseDiscountAmount')

        // console.log(`${storeId}::${ppId} sumAmountStoreProducts ugpgSaleDiscountPercent => `, ugpgSaleDiscountPercent)
        // console.log(`${storeId}::${ppId} sumAmountStoreProducts ugpgSaleDiscountAmount => `, ugpgSaleDiscountAmount)
        // console.log(`${storeId}::${ppId} sumAmountStoreProducts ugpgPurchaseDiscountPercent => `, ugpgPurchaseDiscountPercent)
        // console.log(`${storeId}::${ppId} sumAmountStoreProducts ugpgPurchaseDiscountAmount => `, ugpgPurchaseDiscountAmount)

        let purchaseDiscountPerEach = 0
        if (ugpgPurchaseDiscountPercent && ugpgPurchaseDiscountPercent > 0 && ugpgPurchaseDiscountPercent <= 100) {
          purchaseDiscountPerEach = Math.round(cost * (ugpgPurchaseDiscountPercent / 100) * 100) / 100
        } else if (ugpgPurchaseDiscountAmount && ugpgPurchaseDiscountAmount > 0) {
          purchaseDiscountPerEach = ugpgPurchaseDiscountAmount
        }
        if (purchaseDiscountPerEach > cost) {
          // ป้องกันส่วนลดมีมากเกินค่าต้นทุน
          purchaseDiscountPerEach = cost
        }

        let saleDiscountPerEach = 0
        if (ugpgSaleDiscountPercent && ugpgSaleDiscountPercent > 0 && ugpgSaleDiscountPercent <= 100) {
          saleDiscountPerEach = Math.round(price * (ugpgSaleDiscountPercent / 100) * 100) / 100
        } else if (ugpgSaleDiscountAmount && ugpgSaleDiscountAmount > 0) {
          saleDiscountPerEach = ugpgSaleDiscountAmount
        }
        if (saleDiscountPerEach > price) {
          // ป้องกันส่วนลดมีมากเกินค่าสินค้า
          saleDiscountPerEach = price
        }

        // const productPrice = qty * price
        let priceAfterDiscount = price - seller_unit_discount
        if (priceAfterDiscount < 0) {
          priceAfterDiscount = 0
        }

        // console.log(`${storeId}::${ppId} sumAmountStoreProducts vdDiscounts => `, vdDiscounts.toJS())
        if (vdDiscounts.size > 0) {
          for (let j = 0; j < vdDiscounts.size; j++) {
            const vdPpIds = vdDiscounts.getIn([j, 'pp_ids']).toArray()
            // console.log(`${storeId}::${ppId} sumAmountStoreProducts vdPpIds => `, vdPpIds)
            if (_.includes(vdPpIds, ppId)) {
              const discountAmount = vdDiscounts.getIn([j, 'da'])
              if (discountAmount) {
                amountVdDiscountSum += qty * parseFloat(discountAmount)
              }
              const discounPercent = vdDiscounts.getIn([j, 'dp'])
              if (discounPercent) {
                amountVdDiscountSum += qty * (price * (parseFloat(discounPercent) / 100))
              }
            }
          }
        }

        const productPrice = qty * priceAfterDiscount
        const productCost = qty * cost
        const productPriceBuy = qty * price_buy
        const purchaseDiscount = qty * purchaseDiscountPerEach
        const saleDiscount = qty * saleDiscountPerEach
        const profit = productPrice - productCost + purchaseDiscount - saleDiscount

        newState = newState.setIn(productPath.concat(['productPrice']), productPrice)
        newState = newState.setIn(productPath.concat(['productCost']), productCost)
        newState = newState.setIn(productPath.concat(['productPriceBuy']), productPriceBuy)
        newState = newState.setIn(productPath.concat(['purchaseDiscount']), purchaseDiscount)
        newState = newState.setIn(productPath.concat(['saleDiscount']), saleDiscount)
        newState = newState.setIn(productPath.concat(['saleDiscountPerEach']), saleDiscountPerEach)
        newState = newState.setIn(productPath.concat(['purchaseDiscountPerEach']), purchaseDiscountPerEach)
        newState = newState.setIn(productPath.concat(['profit']), profit)

        amountQty += qty
        amountProducts += productPrice
        amountProductsBuy += productPriceBuy
        amountPurchaseDiscount += purchaseDiscount
        amountSaleDiscount += saleDiscount
        amountProfit += profit
      }
    }
  }

  // console.log('getComputedStateByStoreProducts amountPurchaseDiscount => ', amountPurchaseDiscount)
  // console.log('getComputedStateByStoreProducts amountSaleDiscount => ', amountSaleDiscount)
  newState = newState.setIn(orderPath.concat(['amountQty']), amountQty)
  newState = newState.setIn(orderPath.concat(['amountSaleDiscount']), amountSaleDiscount)
  newState = newState.setIn(orderPath.concat(['amountPurchaseDiscount']), amountSaleDiscount)
  newState = newState.setIn(orderPath.concat(['amountProducts']), amountProducts)
  newState = newState.setIn(orderPath.concat(['amountProductsBuy']), amountProductsBuy)
  newState = newState.setIn(orderPath.concat(['amountProfit']), amountProfit)

  let amountProductsIncludeDiscount = amountProducts - amountSaleDiscount
  // console.log('getComputedStateByStoreProducts amountProductsIncludeDiscount => ', amountProductsIncludeDiscount)
  if (amountProductsIncludeDiscount < 0) {
    // Set 0 เมื่อรวมส่วนลดต่างๆ แล้วค่าเกิดติดลบขึ้นมา
    amountProductsIncludeDiscount = 0
  }

  let amountProductsBuyIncludeDiscount = amountProductsBuy - amountPurchaseDiscount
  // console.log('getComputedStateByStoreProducts amountProductsBuyIncludeDiscount => ', amountProductsBuyIncludeDiscount)
  if (amountProductsBuyIncludeDiscount < 0) {
    // Set 0 เมื่อรวมส่วนลดต่างๆ แล้วค่าเกิดติดลบขึ้นมา
    amountProductsBuyIncludeDiscount = 0
  }

  newState = newState.setIn(orderPath.concat(['amountProductsIncludeDiscount']), amountProductsIncludeDiscount)
  newState = newState.setIn(orderPath.concat(['amountProductsBuyIncludeDiscount']), amountProductsBuyIncludeDiscount)

  let amountTotal = amountProductsIncludeDiscount
  // console.log('getComputedStateByStoreProducts amountVdDiscountSum => ', amountVdDiscountSum)
  amountTotal -= amountVdDiscountSum
  // console.log('getComputedStateByStoreProducts amountTotal - amountVdDiscountSum => ', amountTotal)
  if (amountTotal < 0) {
    // Set 0 เมื่อรวมส่วนลดต่างๆ แล้วค่าเกิดติดลบขึ้นมา
    amountTotal = 0
  }

  const discount = newState.getIn(orderPath.concat(['discount'])) || 0
  const shipping_cost = newState.getIn(orderPath.concat(['shipping_cost'])) || 0
  const etc_cost = newState.getIn(orderPath.concat(['etc_cost'])) || 0
  const rounding_discount = newState.getIn(orderPath.concat(['rounding_discount'])) || 0

  amountTotal -= parseFloat(discount)

  if (amountTotal < 0) {
    // Set 0 เมื่อรวมส่วนลดต่างๆ แล้วค่าเกิดติดลบขึ้นมา
    amountTotal = 0
  }
  // console.log('getComputedStateByStoreProducts amountTotal => ', amountTotal)

  amountTotal = amountTotal + parseFloat(shipping_cost) + parseFloat(etc_cost) + parseFloat(rounding_discount)

  let amountTotalBuy = amountProductsBuyIncludeDiscount
  amountTotalBuy -= amountVdDiscountSum
  if (amountTotalBuy < 0) {
    amountTotalBuy = 0
  }

  amountTotalBuy -= parseFloat(discount)
  if (amountTotal < 0) {
    // Set 0 เมื่อรวมส่วนลดต่างๆ แล้วค่าเกิดติดลบขึ้นมา
    amountTotal = 0
  }
  // console.log('getComputedStateByStoreProducts amountTotalBuy => ', amountTotalBuy)
  amountTotalBuy = amountTotalBuy + parseFloat(shipping_cost) + parseFloat(etc_cost)

  newState = newState.setIn(orderPath.concat(['amountTotal']), amountTotal)
  newState = newState.setIn(orderPath.concat(['amountTotalBuy']), amountTotalBuy)

  return newState
}

// Deprecated in pricing 2.0
// function sumAmountStoreProducts(storeProducts: Map<string,any>): {
//   amountProducts: number, amountProfit: number, amountQty: number, amountProductsBuy: number } {
//   const store_ids = storeProducts.keySeq().toArray()
//   let amountProducts = 0
//   let amountProfit = 0
//   let amountQty = 0
//   let amountProductsBuy = 0
//   let amountPurchaseDiscount = 0
//   let amountSaleDiscount = 0
//   for (let i = 0; i < store_ids.length; i++) {
//     const storeId = store_ids[i]
//     const selectedProducts = isImmutable(storeProducts.get(storeId)) ? storeProducts.get(storeId) : List([])
//
//     selectedProducts.forEach(selectedProduct => {
//       if (Map.isMap(selectedProduct)) {
//         const qty = parseInt(selectedProduct.get('qty') || 0)
//         const price = parseFloat(selectedProduct.get('price') || 0)
//         const cost = parseFloat(selectedProduct.get('cost') || 0)
//         const price_buy = parseFloat(selectedProduct.get('price_buy') || 0)
//         const ugpgSaleDiscountPercent = selectedProduct.get('ugpgSaleDiscountPercent')
//         const ugpgSaleDiscountAmount = selectedProduct.get('ugpgSaleDiscountAmount')
//         const ugpgPurchaseDiscountPercent = selectedProduct.get('ugpgPurchaseDiscountPercent')
//         const ugpgPurchaseDiscountAmount = selectedProduct.get('ugpgPurchaseDiscountAmount')
//
//         // log('sumAmountStoreProducts ugpgSaleDiscountPercent => ', ugpgSaleDiscountPercent)
//         // log('sumAmountStoreProducts ugpgSaleDiscountAmount => ', ugpgSaleDiscountAmount)
//         // log('sumAmountStoreProducts ugpgPurchaseDiscountPercent => ', ugpgPurchaseDiscountPercent)
//         // log('sumAmountStoreProducts ugpgPurchaseDiscountAmount => ', ugpgPurchaseDiscountAmount)
//         let purchaseDiscountPerEach = 0
//         if (ugpgPurchaseDiscountPercent && ugpgPurchaseDiscountPercent > 0 && ugpgPurchaseDiscountPercent <= 100) {
//           purchaseDiscountPerEach = Math.round(cost * (ugpgPurchaseDiscountPercent / 100) * 100) / 100
//         } else if (ugpgPurchaseDiscountAmount && ugpgPurchaseDiscountAmount > 0) {
//           purchaseDiscountPerEach = ugpgPurchaseDiscountAmount
//         }
//         let saleDiscountPerEach = 0
//         if (ugpgSaleDiscountPercent && ugpgSaleDiscountPercent > 0 && ugpgSaleDiscountPercent <= 100) {
//           saleDiscountPerEach = Math.round(price * (ugpgSaleDiscountPercent / 100) * 100) / 100
//         } else if (ugpgSaleDiscountAmount && ugpgSaleDiscountAmount > 0) {
//           saleDiscountPerEach = ugpgSaleDiscountAmount
//         }
//
//         const productPrice = (qty * price)
//         const productCost = (qty * cost)
//         const productPriceBuy = (qty * price_buy)
//         const purchaseDiscount = (qty * purchaseDiscountPerEach)
//         const saleDiscount = (qty * saleDiscountPerEach)
//         amountQty += qty
//         amountProducts += productPrice
//         amountProductsBuy += productPriceBuy
//         amountPurchaseDiscount += purchaseDiscount
//         amountSaleDiscount += saleDiscount
//         amountProfit += (productPrice - productCost) + purchaseDiscount - saleDiscount
//       }
//     })
//     // log('sumAmountStoreProducts amountPurchaseDiscount => ', amountPurchaseDiscount)
//     // log('sumAmountStoreProducts amountSaleDiscount => ', amountSaleDiscount)
//   }
//   return { amountProducts, amountProfit, amountQty, amountProductsBuy }
// }

// ///////////////////////////
// // TODO: Refactor to mergeProductToOrderCart
// ///////////////////////////
// function quickMergeOrderProducts(state, newProduct) {
//   let newState = state
//   const foundIndex = newState.getIn(['editingOrder', 'products']).findIndex(p => p.get('pp_id') === newProduct.pp_id)
//   if (foundIndex >= 0) {
//     const nextQty = newState.getIn(['editingOrder', 'products', foundIndex, 'qty']) + newProduct.qty
//     const availableQty = newState.getIn(['editingOrder', 'products', foundIndex, 'available_qty'])
//     if (nextQty > availableQty) {
//       util.showWarningToast('สินค้าในคลังไม่เพียงพอ')
//       return state
//     }
//     newState = newState.setIn(['editingOrder', 'products', foundIndex, 'qty'], nextQty)
//   } else if (foundIndex < 0) {
//     newState = newState.updateIn(['editingOrder', 'products'], plist => plist.push(fromJS(newProduct)))
//   }
//   // Summary products in Cart
//   const newProducts = newState.getIn(['editingOrder', 'products'])
//   let txtSummary = ''
//   for (let i = 0; i < newProducts.size; i++) {
//     if (i === 0) {
//       txtSummary = 'สินค้าในออเดอร์ทั้งหมด\n'
//     }
//     const p = newProducts.get(i)
//     const name = p.get('name')
//     const variant = p.get('variant')
//     const qty = p.get('qty')
//     const txtProductName = variant ? `${name} (${variant})` : name
//     txtSummary += `\n${txtProductName} x${qty}`
//   }
//   // util.showSuccessToast(`เพิ่ม ${txtProductName} แล้ว`)
//   if (txtSummary) {
//     util.showSuccessToast(txtSummary)
//   }
//
//   return newState
// }

function doManageCart(state: Map<string, any>, action: E_CART_ACTIONS, payload: IManageOrderCartParams): Map<string, any> {
  const { store_id, seller_store_id, orderProducts, callback, ug_id, pg_ids } = payload
  let newState = state
  const editingOrder = newState.getIn(['editingOrder', 'chooseProductParams'])
  if (!editingOrder && ((ug_id && ug_id > 0) || (_.isArray(pg_ids) && pg_ids.length > 0))) {
    newState = newState.setIn(['editingOrder', 'chooseProductParams'], fromJS({ ug_id, pg_ids }))
  }

  log('doManageCart orderProducts =>  ', orderProducts)

  if (_.isArray(orderProducts) && orderProducts.length > 0) {
    // BEGIN cart logic
    const mappingPPIDtoStoreIdPath: any[] = ['editingOrder', 'tmp_PPID_to_StoreId']
    const storeProductsPath: any[] = ['editingOrder', 'store_products']
    const targetStoreID: number = seller_store_id || store_id
    const targetIsMine: boolean = store_id && !seller_store_id // ถ้าเป้าหมายเป็นสินค้าของฉัน
    const myProductsPath = storeProductsPath.concat(store_id)
    const isExistedMyStore = newState.getIn(myProductsPath)
    if (!isExistedMyStore) {
      newState = newState.setIn(storeProductsPath.concat(store_id), List([]))
    }

    const targetProductsPath = storeProductsPath.concat(targetStoreID)
    // ถ้ายังไม่มี targetStoreID group ในนี้ ให้สร้างขึ้นมา (storeIndex == -1)
    const isExistedTargetStore = newState.getIn(storeProductsPath.concat(targetStoreID))
    if (!isExistedTargetStore) {
      newState = newState.setIn(storeProductsPath.concat(targetStoreID), List([]))
    }
    if (targetIsMine) {
      log('In doManageCart my product')
      // ถ้าเป็นสินค้าร้านฉัน
      orderProducts.forEach((product: any) => {
        const target_PPID = parseInt(product.pp_id)
        const target_H = parseInt(product.h) || null // ถ้ามี h แสดงว่าเป็นสินค้าที่ถูกดึงมา
        const target_PRICE = parseInt(product.price) || 0
        const target_QTY = parseInt(product.qty) || 0
        const target_PARENT_STORE_ID = parseInt(product.parent_store_id) // ควรจะมีแน่นอน
        log('In doManageCart my product target_PARENT_STORE_ID => ', target_PARENT_STORE_ID)
        log('In doManageCart my product product => ', product)

        if (target_PPID && target_QTY) {
          if (target_H) {
            // ถ้าเป้นสินค้าที่ถูกดึงมา ให้ไปเช็คก่อนว่ามีอยู่ใน store_products ร้านอื่นหรือไม่
            let usageH = true
            let duplicatedStoreId = newState.getIn(mappingPPIDtoStoreIdPath.concat(target_H)) || null
            if (!duplicatedStoreId) {
              usageH = false
              duplicatedStoreId = newState.getIn(mappingPPIDtoStoreIdPath.concat(target_PPID)) || null
            }
            if (duplicatedStoreId) {
              // ถ้ามีการซ้ำในร้านผู้ขายส่งเกิดขึ้น ให้เอาสินค้าของร้านผู้ขายส่งเป็นหลัก ให้ merge/add เข้าไป
              log('In doManageCart my product / H / merge or add')

              // Deprecated in pricing 2.0
              // เอาออกเพราะว่าหลังบ้านไม่สามารถแยกแยะ sdc มาให้ได้ ถ้าเปิดโดยใช้ pp_id ของผู้ขายส่งเป็นหลัก
              // const foundProductIndex = newState.getIn(storeProductsPath.concat(duplicatedStoreId))
              //   .findIndex(p => p.get('pp_id') === target_H)
              // const duplicatedProductPath = storeProductsPath.concat([duplicatedStoreId, foundProductIndex])
              // const duplicatedProductQty = parseInt(newState.getIn(duplicatedProductPath.concat('qty'))) // get old qty
              // log('In doManageCart my product / H / merge or add duplicatedProductPath => ', duplicatedProductPath)
              // log('In doManageCart my product / H / merge or add duplicatedProductQty => ', duplicatedProductQty)

              // // ถ้าไม่มีการ modify price มาก่อน และ price ของสินค้าที่ดึงมา > สินค้าจากร้านผู้ขายส่ง
              // // ให้ clone สินค้ามาแทน นอกจาก qty (จุดประสงค์เพื่อใช้ price rate ที่ set ไว้ของฉัน)
              // // FIXME: isFreeze เป็น flag มาจาก Order Detail เอามาใช้ก่อนเพื่อที่จะยังไม่ให้แก้ไขราคาสินค้า
              // const isFreeze = newState.getIn(duplicatedProductPath.concat('isFreeze')) || false
              // const isModifiedPrice = newState.getIn(duplicatedProductPath.concat('isModifiedPrice'))
              // const isMoreExpensive = target_PRICE > parseFloat(newState.getIn(duplicatedProductPath.concat('price')))
              // if (!isModifiedPrice && isMoreExpensive && !isFreeze) {
              //   let xProduct = _.cloneDeep(product) // แปลงจากสินค้าที่ถูกดึงมา เป็นสินค้า ร้านผู้ขายส่ง
              //   xProduct.pp_id = target_H     // เปลี่ยน pp_id โดยใช้ h (parent_pp_id)
              //   xProduct.price_rate_value = -1.88     // แปลง price_rate_value เพื่อให้หน้าบ้านส่ง API ออก เสมอ
              //   delete xProduct.h             // ลบ h ทิ้ง
              //   newState = newState.setIn(duplicatedProductPath, fromJS(xProduct))
              // }

              let foundProductIndex = newState
                .getIn(storeProductsPath.concat(duplicatedStoreId))
                .findIndex((p) => p.get('pp_id') === target_H)
              if (foundProductIndex < 0) {
                foundProductIndex = newState
                  .getIn(storeProductsPath.concat(duplicatedStoreId))
                  .findIndex((p) => p.get('pp_id') === target_PPID)
              }
              const duplicatedProductPath = storeProductsPath.concat([duplicatedStoreId, foundProductIndex])
              const duplicatedProductQty = parseInt(newState.getIn(duplicatedProductPath.concat('qty'))) // get old qty
              log('In doManageCart my product / H / merge or add duplicatedProductPath => ', duplicatedProductPath)
              log('In doManageCart my product / H / merge or add duplicatedProductQty => ', duplicatedProductQty)

              const isFreeze = newState.getIn(duplicatedProductPath.concat('isFreeze')) || false
              const isModifiedPrice = newState.getIn(duplicatedProductPath.concat('isModifiedPrice'))
              const isMoreExpensive = target_PRICE > parseFloat(newState.getIn(duplicatedProductPath.concat('price')))
              if (!isModifiedPrice && isMoreExpensive && !isFreeze) {
                const xProduct = _.cloneDeep(product) // แปลงจากสินค้าที่ถูกดึงมา เป็นสินค้า ร้านผู้ขายส่ง
                xProduct.useMyStoreId = store_id // แปลง price_rate_value เพื่อให้หน้าบ้านส่ง API ออก เสมอ
                // delete xProduct.h             // ลบ h ทิ้ง
                // if (!usageH) {
                //   xProduct.pp_id = usageH ? xProduct.pp_id : xProduct.h
                //   delete xProduct.h
                // }
                newState = newState.setIn(duplicatedProductPath, fromJS(xProduct))
              }

              // Change the mapping H to PPID instead
              newState = newState.setIn(mappingPPIDtoStoreIdPath.concat(target_PPID), target_PARENT_STORE_ID)
              newState = newState.setIn(mappingPPIDtoStoreIdPath.concat(target_H), target_PARENT_STORE_ID)
              //   newState = newState.deleteIn(mappingPPIDtoStoreIdPath.concat(target_H))
              //   newState = newState.setIn(mappingPPIDtoStoreIdPath.concat(target_PPID), target_PARENT_STORE_ID)

              // If ADD mode (get old duplicated qty + new qty) before replace
              if (action === E_CART_ACTIONS.ADD) {
                newState = newState.setIn(duplicatedProductPath.concat('qty'), duplicatedProductQty + target_QTY)
              } else if (action === E_CART_ACTIONS.MERGE) {
                newState = newState.setIn(duplicatedProductPath.concat('qty'), target_QTY)
              }
            } else {
              // ถ้าไม่ซ้ำกับชาวบ้านให้ push ลง "ร้านผู้ขายส่ง" แต่ pp_id "เป็นร้านฉัน"
              log('In doManageCart xProduct / H / x push')
              const parentStoreProductsPath = storeProductsPath.concat(target_PARENT_STORE_ID)
              const isExistedParentStoreProducts = newState.getIn(parentStoreProductsPath) || null
              if (!isExistedParentStoreProducts) {
                newState = newState.setIn(parentStoreProductsPath, List([]))
              }
              const xProduct = _.cloneDeep(product) // แปลงจากสินค้าที่ถูกดึงมา เป็นสินค้า ร้านผู้ขายส่ง
              xProduct.useMyStoreId = store_id
              newState = newState.updateIn(parentStoreProductsPath, (plist) => plist.push(fromJS(xProduct)))
              newState = newState.setIn(mappingPPIDtoStoreIdPath.concat(target_H), target_PARENT_STORE_ID)
              newState = newState.setIn(mappingPPIDtoStoreIdPath.concat(target_PPID), target_PARENT_STORE_ID)

              // Deprecated in pricing 2.0
              // // ถ้าไม่ซ้ำกับชาวบ้านให้ push ลง "ร้านผู้ขายส่ง"
              // log('In doManageCart xProduct / H / x push')
              // const parentStoreProductsPath = storeProductsPath.concat(target_PARENT_STORE_ID)
              // const isExistedParentStoreProducts = newState.getIn(parentStoreProductsPath) || null
              // if (!isExistedParentStoreProducts) {
              //   newState = newState.setIn(parentStoreProductsPath, List([]))
              // }
              // let xProduct = _.cloneDeep(product) // แปลงจากสินค้าที่ถูกดึงมา เป็นสินค้า ร้านผู้ขายส่ง
              // xProduct.pp_id = target_H     // เปลี่ยน pp_id โดยใช้ h (parent_pp_id)
              // xProduct.price_rate_value = -1.88     // แปลง price_rate_value เพื่อให้หน้าบ้านส่ง API ออก เสมอ
              // delete xProduct.h             // ลบ h ทิ้ง
              // newState = newState.updateIn(parentStoreProductsPath, plist => plist.push(fromJS(xProduct)))
              // newState = newState.setIn(mappingPPIDtoStoreIdPath.concat(target_H), target_PARENT_STORE_ID)
            }
          } else {
            // กรณีไม่มี H แสดงว่าเป็นสินค้าที่ฉัน (ร้านนี้ สร้างขึ้น ให้ merge/add ปกติ)
            const foundProductIndex = newState.getIn(myProductsPath).findIndex((p) => p.get('pp_id') === target_PPID)
            if (foundProductIndex > -1) {
              // ถ้ามีสินค้าซ้ำกัน ให้ merge/add
              log('In doManageCart my product / No H / normal merge or add')
              // ถ้ามี PPID ซ้ำอยู่แล้วให้ทับ qty
              const foundMyProductQtyPath = myProductsPath.concat([foundProductIndex, 'qty'])

              // MERGE OR ADD
              if (action === E_CART_ACTIONS.MERGE) {
                newState = newState.setIn(foundMyProductQtyPath, target_QTY)
              } else if (action === E_CART_ACTIONS.ADD) {
                let myOldQty = newState.getIn(foundMyProductQtyPath) || 0
                myOldQty = parseInt(myOldQty)
                newState = newState.setIn(foundMyProductQtyPath, myOldQty + target_QTY)
              }
            } else {
              // ถ้า pp_id ไม่ซ้ำแสดงว่าเพิ่ง add ใหม่ ให้ push ปกติ
              log('In doManageCart my product / Normal push')
              newState = newState.updateIn(myProductsPath, (plist) => plist.push(fromJS(product)))
            }
          }
        } else {
          log('Error: quick add my product because no PPID or QTY')
        }
      })
    } else {
      log('In doManageCart seller product')
      // ถ้าไม่ใช่สินค้าร้านฉัน ให้เอา pp_id มาเช็คดูว่า ชนกับ h ร้านฉันมั้ย
      orderProducts.forEach((product: any) => {
        const target_PPID = parseInt(product.pp_id)
        const target_QTY = parseInt(product.qty)

        if (target_QTY && target_PPID) {
          const foundIndexMatchPPIDtoMyStoreH = newState.getIn(myProductsPath).findIndex((p) => p.get('h') && target_PPID === p.get('h'))
          if (foundIndexMatchPPIDtoMyStoreH > -1) {
            // After 2.0 should be impossible case :(
            log('In doManageCart seller product / found foundIndexMatchPPIDtoMyStoreH')
            // ถ้าชน ให้ merge qty เข้าร้านฉัน
            const myMatchProductQtyPath = myProductsPath.concat([foundIndexMatchPPIDtoMyStoreH, 'qty'])

            // MERGE OR ADD
            if (action === E_CART_ACTIONS.MERGE) {
              newState = newState.setIn(myMatchProductQtyPath, target_QTY)
            } else if (action === E_CART_ACTIONS.ADD) {
              const myOldMatchQty = parseInt(newState.getIn(myMatchProductQtyPath)) || 0
              newState = newState.setIn(myMatchProductQtyPath, myOldMatchQty + target_QTY)
            }
          } else {
            // ถ้าไม่ชน
            // เช็คก่อนว่า id ซ้ำใน map มั้ย
            const duplicatedStoreId = newState.getIn(mappingPPIDtoStoreIdPath.concat(target_PPID)) || null
            if (duplicatedStoreId) {
              log('In doManageCart seller product / found duplicatedStoreId', duplicatedStoreId)

              // ถ้าซ้ำ ให้ merge qty ลงที่นั่น
              let foundDuplicatedProductIndex = newState
                .getIn(storeProductsPath.concat(duplicatedStoreId))
                .findIndex((p) => p.get('h') === target_PPID)
              let useOldProductParentPPID = true
              // ถ้าซ้ำ ให้ merge qty ลงที่นั่น
              if (foundDuplicatedProductIndex === -1) {
                useOldProductParentPPID = false
                foundDuplicatedProductIndex = newState
                  .getIn(storeProductsPath.concat(duplicatedStoreId))
                  .findIndex((p) => p.get('pp_id') === target_PPID)
              }
              const duplicatedProductQtyPath = storeProductsPath.concat([duplicatedStoreId, foundDuplicatedProductIndex, 'qty'])

              // 2.0 Addition logic สำหรับ merge โดยใช้ render ร้านขายส่งเป็นหลัก แต่ยึด pp_id ร้านฉันเป็นหลัก
              const duplicatedProductPPIDPath = storeProductsPath.concat([duplicatedStoreId, foundDuplicatedProductIndex, 'pp_id'])
              const duplicatedOldPPID = parseInt(newState.getIn(duplicatedProductPPIDPath))
              const usagePPID = useOldProductParentPPID ? duplicatedOldPPID : target_PPID
              newState = newState.setIn(duplicatedProductPPIDPath, usagePPID)
              log('In doManageCart seller product / found duplicatedStoreId duplicatedProductQtyPath', duplicatedProductQtyPath)

              // MERGE OR ADD
              if (action === E_CART_ACTIONS.MERGE) {
                newState = newState.setIn(duplicatedProductQtyPath, target_QTY)
              } else if (action === E_CART_ACTIONS.ADD) {
                const duplicatedOldQty = parseInt(newState.getIn(duplicatedProductQtyPath)) || 0
                newState = newState.setIn(duplicatedProductQtyPath, duplicatedOldQty + target_QTY)
              }
            } else {
              log('In doManageCart seller product / Normal add or merge')

              // ถ้าไม่ซ้ำ ให้ push ลง store ตัวเองปกติ และสร้าง PPID_to_StoreId Map
              newState = newState.updateIn(targetProductsPath, (plist) => plist.push(fromJS(product)))
              newState = newState.setIn(mappingPPIDtoStoreIdPath.concat(target_PPID), targetStoreID)
            }
          }
        } else {
          log('Error: quick add seller product because no PPID or QTY')
        }
      })
    }

    // ถึงจุดนี่ควรจะมี storeIndex แน่นอน
    const store_ids = newState.getIn(storeProductsPath).keySeq().toArray()
    store_ids.forEach((sId) => {
      const products = newState.getIn(storeProductsPath.concat(sId)) || List([])
      if (products.size <= 0) {
        // ถ้าไม่มีสินค้าซักชิ้น (โดน merge ออกไปหมด) ให้เอาร้านออก
        newState = newState.deleteIn(storeProductsPath.concat(sId))
      }
    })
    // END cart logic
  }

  if (_.isFunction(callback)) {
    callback(newState)
  }

  return newState
}

// function doManageCart(state: Map<string,any>, action: E_CART_ACTIONS,
//     payload: IManageOrderCartParams): Map<string,any> {
//   const { store_id, seller_store_id, orderProducts, callback } = payload
//   let newState = state
//   log('mergeProductToOrderCart orderProducts =>  ', orderProducts)
//
//   if (_.isArray(orderProducts) && orderProducts.length > 0) {
//     // BEGIN cart logic
//     const mappingPPIDtoStoreIdPath: Array<any> = ['editingOrder', 'tmp_PPID_to_StoreId']
//     const storeProductsPath: Array<any> = ['editingOrder', 'store_products']
//     const targetStoreID: number = seller_store_id ? seller_store_id : store_id
//     const targetIsMine: boolean = store_id && !seller_store_id // ถ้าเป้าหมายเป็นสินค้าของฉัน
//     const myProductsPath = storeProductsPath.concat(store_id)
//     const isExistedMyStore = newState.getIn(myProductsPath)
//     if (!isExistedMyStore) {
//       newState = newState.setIn(storeProductsPath.concat(store_id), List([]))
//     }
//
//     const targetProductsPath = storeProductsPath.concat(targetStoreID)
//     // ถ้ายังไม่มี targetStoreID group ในนี้ ให้สร้างขึ้นมา (storeIndex == -1)
//     let isExistedTargetStore = newState.getIn(storeProductsPath.concat(targetStoreID))
//     if (!isExistedTargetStore) {
//       newState = newState.setIn(storeProductsPath.concat(targetStoreID), List([]))
//     }
//     if (targetIsMine) {
//       log('In doManageCart my product')
//       // ถ้าเป็นสินค้าร้านฉัน
//       orderProducts.forEach(product => {
//         const target_PPID = parseInt(product.pp_id)
//         const target_H =  parseInt(product.h) || null
//         const target_QTY = parseInt(product.qty) || 0
//
//         if (target_PPID && target_QTY) {
//           const foundProductIndex = newState.getIn(myProductsPath).findIndex(p => p.get('pp_id') === target_PPID)
//           if (foundProductIndex > -1) {
//             log('In doManageCart my product / found foundProductIndex')
//             // ถ้ามี PPID ซ้ำอยู่แล้วให้ทับ qty
//             const foundMyProductQtyPath = myProductsPath.concat([foundProductIndex, 'qty'])
//
//             // MERGE OR ADD
//             if (action === E_CART_ACTIONS.MERGE) {
//               newState = newState.setIn(foundMyProductQtyPath, target_QTY)
//             } else if (action === E_CART_ACTIONS.ADD) {
//               let myOldQty = newState.getIn(foundMyProductQtyPath) || 0
//               myOldQty = parseInt(myOldQty)
//               newState = newState.setIn(foundMyProductQtyPath, myOldQty + target_QTY)
//             }
//
//           } else {
//             // เช็คว่า H ไปตรงกับ pp_id ของ ร้านผู้ขายส่งรึเปล่า ใน mapping
//
//             if (target_H) {
//               const duplicatedStoreId = newState.getIn(mappingPPIDtoStoreIdPath.concat(target_H)) || null
//               if (duplicatedStoreId) {
//                 log('In doManageCart my product / found duplicatedStoreId')
//                 // ถ้าเป็นสินค้าที่ถูกดึงมาจากร้านผู้ขายส่ง ให้เอาสินค้าเราเป็นหลัก
//                 // drop สินค้านั้นออกแล้ว push ของเรา และอัพเดท mapping (เอาออก)
//                 const foundProductIndex = newState.getIn(storeProductsPath.concat(duplicatedStoreId))
//                   .findIndex(p => p.get('pp_id') === target_H)
//                 const duplicatedProductPath = storeProductsPath.concat([duplicatedStoreId, foundProductIndex])
//                 const duplicatedProductQty = parseInt(newState.getIn(duplicatedProductPath.concat('qty'))) // get old qty
//                 let willMergeProduct = _.cloneDeep(product)
//
//                 // If ADD mode (get old duplicated qty + new qty) before replace
//                 if (action === E_CART_ACTIONS.ADD) {
//                   willMergeProduct.qty = target_QTY + duplicatedProductQty
//                 }
//
//                 // REPLACE_MERGE with willMergeProduct
//                 newState = newState.deleteIn(duplicatedProductPath) // Remove old product
//                 newState = newState.deleteIn(mappingPPIDtoStoreIdPath.concat(target_PPID))
//                 newState = newState.updateIn(myProductsPath, plist => plist.push(fromJS(willMergeProduct)) )
//
//               } else {
//                 // ถ้าไม่มี pp_id และไม่มี h ซ้ำชาวบ้านให้ push
//                 log('In doManageCart my product / not found duplicatedStoreId')
//                 newState = newState.updateIn(myProductsPath, plist => plist.push(fromJS(product)))
//               }
//             } else {
//               log('In doManageCart my product / Normal add or merge')
//               // ถ้าไม่มี h ซ้ำชาวบ้านให้ push
//               newState = newState.updateIn(myProductsPath, plist => plist.push(fromJS(product)))
//             }
//           }
//         } else {
//           log('Error: quick add my product because no PPID or QTY')
//         }
//       })
//     } else {
//       log('In doManageCart seller product')
//       // ถ้าไม่ใช่สินค้าร้านฉัน ให้เอา pp_id มาเช็คดูว่า ชนกับ h ร้านฉันมั้ย
//       orderProducts.forEach(product => {
//         const target_PPID = parseInt(product.pp_id)
//         const target_QTY = parseInt(product.qty)
//
//         if (target_QTY && target_PPID) {
//           const foundIndexMatchPPIDtoMyStoreH = newState.getIn(myProductsPath).findIndex(p => p.get('h') && target_PPID === p.get('h'))
//           if (foundIndexMatchPPIDtoMyStoreH > -1) {
//             log('In doManageCart seller product / found foundIndexMatchPPIDtoMyStoreH')
//             // ถ้าชน ให้ merge qty เข้าร้านฉัน
//             const myMatchProductQtyPath = myProductsPath.concat([foundIndexMatchPPIDtoMyStoreH, 'qty'])
//
//             // MERGE OR ADD
//             if (action === E_CART_ACTIONS.MERGE) {
//               newState = newState.setIn(myMatchProductQtyPath, target_QTY)
//             } else if (action === E_CART_ACTIONS.ADD) {
//               const myOldMatchQty = parseInt(newState.getIn(myMatchProductQtyPath)) || 0
//               newState = newState.setIn(myMatchProductQtyPath, myOldMatchQty + target_QTY)
//             }
//
//           } else {
//             // ถ้าไม่ชน
//             // เช็คก่อนว่า id ซ้ำใน map มั้ย
//             const duplicatedStoreId = newState.getIn(mappingPPIDtoStoreIdPath.concat(target_PPID)) || null
//             if (duplicatedStoreId) {
//               log('In doManageCart seller product / found duplicatedStoreId', duplicatedStoreId)
//
//               // ถ้าซ้ำ ให้ merge qty ลงที่นั่น
//               const foundDuplicatedProductIndex = newState.getIn(storeProductsPath.concat(duplicatedStoreId))
//                 .findIndex(p => p.get('pp_id') === target_PPID)
//               const duplicatedProductQtyPath = storeProductsPath.concat([duplicatedStoreId, foundDuplicatedProductIndex, 'qty'])
//
//               log('In doManageCart seller product / found duplicatedStoreId duplicatedProductQtyPath', duplicatedProductQtyPath)
//
//               // MERGE OR ADD
//               if (action === E_CART_ACTIONS.MERGE) {
//                 newState = newState.setIn(duplicatedProductQtyPath, target_QTY)
//               } else if (action === E_CART_ACTIONS.ADD) {
//                 const duplicatedOldQty = parseInt(newState.getIn(duplicatedProductQtyPath)) || 0
//                 newState = newState.setIn(duplicatedProductQtyPath, duplicatedOldQty + target_QTY)
//               }
//
//             } else {
//               log('In doManageCart seller product / Normal add or merge')
//
//               // ถ้าไม่ซ้ำ ให้ push ลง store ตัวเองปกติ และสร้าง PPID_to_StoreId Map
//               newState = newState.updateIn(targetProductsPath, plist => plist.push(fromJS(product)) )
//               newState = newState.setIn(mappingPPIDtoStoreIdPath.concat(target_PPID), targetStoreID)
//             }
//           }
//         } else {
//           log('Error: quick add seller product because no PPID or QTY')
//         }
//       })
//     }
//
//     // ถึงจุดนี่ควรจะมี storeIndex แน่นอน
//     const store_ids = newState.getIn(storeProductsPath).keySeq().toArray()
//     store_ids.forEach((sId) => {
//       const products = newState.getIn(storeProductsPath.concat(sId)) || List([])
//       if (products.size <= 0) { // ถ้าไม่มีสินค้าซักชิ้น (โดน merge ออกไปหมด) ให้เอาร้านออก
//         newState = newState.deleteIn(storeProductsPath.concat(sId))
//       }
//     })
//     // END cart logic
//   }
//
//   if (_.isFunction(callback)) {
//     callback(newState)
//   }
//
//   return newState
// }

function onChangeStoreProduct(state: Map<string, any>, payload: { pp_id: number; key: string; value: any }): Map<string, any> {
  // const { pp_id, key, value } = payload
  const { pp_id, key } = payload
  // const value = !_.isNull(payload.value) && !_.isUndefined(payload.value) ? parseFloat(payload.value) : 0
  let { value } = payload
  if (key === 'qty' && value === '') {
    value = 0
  }
  const oldParentOrder = state.get('selectedOrder')
  const parentOrder = state.get('editingOrder')
  let newState = state
  let isDirty = newState.getIn(['editingOrder', 'isDirty']) || false

  let oldProductPath = getStoreProductPathFromPPID(oldParentOrder, pp_id)
  let productPath = getStoreProductPathFromPPID(parentOrder, pp_id)
  if (_.isArray(productPath) && productPath.length > 0) {
    productPath = ['editingOrder'].concat(productPath)
    oldProductPath = ['selectedOrder'].concat(oldProductPath)

    // Set new value
    newState = newState.setIn(productPath.concat(key), value)

    // Check dirty
    if (!isDirty) {
      const oldStateValue = newState.getIn(oldProductPath.concat(key))
      const newStateValue = newState.getIn(productPath.concat(key))
      if (+oldStateValue !== +newStateValue) {
        newState = newState.setIn(['editingOrder', 'isDirty'], true)
        isDirty = true
      }
    }

    if (newState !== state && key === 'price') {
      const normalPrice = parseFloat(newState.getIn(productPath.concat('price_rate_value')) || 0)
      const currentPrice = parseFloat(newState.getIn(productPath.concat('price')) || 0)
      newState = newState.setIn(productPath.concat('isModifiedPrice'), currentPrice !== normalPrice)
    }
  }

  const subOrders = state.getIn(['editingOrder', 'suborders']) || List([])
  subOrders.forEach((subOrder, subIndex) => {
    let subProductPath = getStoreProductPathFromPPID(subOrder, pp_id)
    if (_.isArray(subProductPath) && subProductPath.length > 0) {
      const oldSubProductPath = ['selectedOrder', 'suborders', subIndex].concat(subProductPath)
      subProductPath = ['editingOrder', 'suborders', subIndex].concat(subProductPath)

      // Set new value
      newState = newState.setIn(subProductPath.concat(key), value)

      // Check dirty
      if (!isDirty) {
        const oldStateValue = newState.getIn(oldSubProductPath.concat(key))
        const newStateValue = newState.getIn(subProductPath.concat(key))
        if (+oldStateValue !== +newStateValue) {
          newState = newState.setIn(['editingOrder', 'isDirty'], true)
          isDirty = true
        }
      }

      if (newState !== state && key === 'price') {
        const normalPrice = parseFloat(newState.getIn(subProductPath.concat('price_rate_value')) || 0)
        const currentPrice = parseFloat(newState.getIn(subProductPath.concat('price')) || 0)
        newState = newState.setIn(subProductPath.concat('isModifiedPrice'), currentPrice !== normalPrice)
      }
    }
  })

  return newState
}

function onRemoveStoreProduct(state: Map<string, any>, payload: { store_id: number; pp_id: number }): Map<string, any> {
  const { store_id, pp_id } = payload
  if (!store_id || !pp_id) {
    log('onRemoveStoreProduct has null store_id or pp_id.')
    return state
  }
  // let productPath = getStoreProductPathFromPPID(state, pp_id)
  let newState = state
  const storeProductsPath = ['editingOrder', 'store_products']
  // @ts-ignore ถ้าใช้ store_id.toString() ซึ่งเป็น type ที่ถูกต้อง แต่จะทำให้ App Crash เพราะ findIndex ไม่ได้
  const currentStoreProductsPath = storeProductsPath.concat(store_id)
  const currentProducts = newState.getIn(currentStoreProductsPath) || Map({})
  const foundIndexInStoreProducts = currentProducts.findIndex((p) => Map.isMap(p) && p.get('pp_id') === pp_id)

  // log('onRemoveStoreProduct currentProducts.toJS() => ', currentProducts.toJS())
  // log('onRemoveStoreProduct foundIndexInStoreProducts => ', foundIndexInStoreProducts)
  if (foundIndexInStoreProducts > -1) {
    const foundProduct = currentProducts.get(foundIndexInStoreProducts)
    const productH = foundProduct.get('h')
    const mappingPPIDtoStoreIdPath: any[] = ['editingOrder', 'tmp_PPID_to_StoreId']
    const newProductsInStore = deleteImmutableListAtIndex(currentProducts, foundIndexInStoreProducts)
    newState = newState.setIn(currentStoreProductsPath, newProductsInStore)
    if (newProductsInStore.size === 0) {
      newState = newState.deleteIn(currentStoreProductsPath)
    }
    if (newState.getIn(mappingPPIDtoStoreIdPath.concat(pp_id))) {
      newState = newState.deleteIn(mappingPPIDtoStoreIdPath.concat(pp_id))
    }
    if (productH) {
      newState = newState.deleteIn(mappingPPIDtoStoreIdPath.concat(productH))
    }
    // if (newState.getIn(storeProductsPath) && newState.getIn(storeProductsPath).size === 0) {
    //   newState = newState.deleteIn(storeProductsPath)
    // }
  }
  // Old Logic
  // const { store_id, pp_id } = payload
  // // let productPath = getStoreProductPathFromPPID(state, pp_id)
  // let newState = state
  // const storePath = ['editingOrder', 'store_products', store_id]
  // const storeProducts = newState.getIn(storePath) || List([])
  // const foundIndexInStoreProducts = storeProducts.findIndex(p => isImmutable(p) && p.get('pp_id') === pp_id)
  // const productPath = foundIndexInStoreProducts > -1 ? storePath.concat(foundIndexInStoreProducts) : null
  //
  // if (productPath) {
  //   const mappingPPIDtoStoreIdPath: Array<any> = ['editingOrder', 'tmp_PPID_to_StoreId']
  //
  //   newState = newState.deleteIn(productPath)
  //   if (newState.getIn(mappingPPIDtoStoreIdPath.concat(pp_id))) {
  //     newState = newState.deleteIn(mappingPPIDtoStoreIdPath.concat(pp_id))
  //   }
  //
  //   if (newState.getIn(storePath) && newState.getIn(storePath).size === 0) {
  //     newState = newState.deleteIn(storePath)
  //   }
  // }
  return newState
}

function getStoreProductPathFromPPID(order: Map<string, any>, pp_id: number): string[] | null {
  if (!Map.isMap(order) || !order.has('store_products') || !Map.isMap(order.get('store_products'))) {
    return null
  }
  const store_ids = order.get('store_products').keySeq().toArray() || []
  let productPath = null
  for (let i = 0; i < store_ids.length; i++) {
    const store_id = store_ids[i]
    const products = order.getIn(['store_products', store_id]) || List([])
    const foundIndexInStoreProducts = products.findIndex((p) => p.get('pp_id') === pp_id)
    if (foundIndexInStoreProducts > -1) {
      productPath = ['store_products', store_id, foundIndexInStoreProducts]
    }
  }
  return productPath
}

function autoSetOrderTabIndex(state: Map<string, any>) {
  let newState = state
  const currentTabIndex = newState.get('orderTabIndex') || 0
  const currentParentOrder = newState.get('editingOrder')

  // If index has valuable
  if (currentTabIndex === 0) {
    const isValuableParentOrder = currentParentOrder.get('updated_at')
    if (isValuableParentOrder) {
      return newState.set('orderTabInit', 0)
    }
  } else if (currentTabIndex > 0) {
    const suborders = currentParentOrder.get('suborders') || List([])
    const isValuableChildOrder = suborders.getIn([currentTabIndex, 'updated_at'])
    if (isValuableChildOrder) {
      return newState.set('orderTabInit', currentTabIndex)
    }
  }

  // Find new valuable order
  const isLoadedParent = currentParentOrder.get('updated_at') || null
  if (isLoadedParent) {
    newState = newState.set('orderTabIndex', 0)
    return newState.set('orderTabInit', 0)
  }
  const suborders = currentParentOrder.get('suborders') || List([])
  for (let i = 0; i < suborders.size; i++) {
    const subOrder = suborders.get(i)
    const isLoadChild = subOrder.get('updated_at') || null
    if (isLoadChild) {
      newState = newState.set('orderTabIndex', i + 1)
      return newState.set('orderTabInit', i + 1)
    }
  }

  // don't find anything don't do anything
  return state
}
